<div class="sp-exercise-filter-category-group">

    <!-- Botón toggle -->
    <button mat-button
        [class.mat-flat-button]="menuOpened || categoryGroup.checkSelectedSubcategories()"
        [color]="menuOpened || categoryGroup.checkSelectedSubcategories()?'primary':null"
        [matMenuTriggerFor]="categoryMenu"
        (click)="onClick()">
        {{categoryGroup.name}}
        <mat-icon class="sp-icon sp-icon--outline sp-icon--button ml-1"
            svgIcon="arrow-down">
        </mat-icon>
    </button>

    <!-- Menú -->
    <mat-menu #categoryMenu="matMenu"
        (closed)="onMenuClosed()">
        <div class="sp-exercise-filter-category-group__menu">
            <!-- Campo búsqueda y seleccionar/des-seleccionar todos -->
            <div *ngIf="!readonly"
                class="d-flex justify-content-between">
                <sp-input-search [createParams]="false"
                    spClickStopPropagation
                    (valueChange)="onInputSearchValueChange($event)">
                </sp-input-search>
                <div class="d-flex align-items-center">
                    <!-- <button class="font-weight-bold"
                        mat-button
                        (click)="onSelectAllClick()">
                        Select all
                    </button> -->
                    <button class="font-weight-bold"
                        mat-button
                        (click)="onUnselectAllClick()">
                        Clear all
                    </button>
                </div>
            </div>
            <!-- Categorías -->
            <div class="sp-categories">
                <ng-container *ngIf="categoryGroup.categories?.length;else noData">
                    <div *ngFor="let category of categoryGroup.categories"
                        class="sp-category">
                        <span class="sp-category__name">
                            {{category.name}}
                        </span>
                        <!-- Subcategorías -->
                        <div class="sp-subcategories">
                            <div *ngFor="let subcategory of category.subcategories"
                                class="sp-subcategory"
                                [class.sp-subcategory--one-row]="categoryGroup.categories?.length>1"
                                [hidden]="subcategory.hidden">
                                <button class="sp-subcategory__button"
                                    mat-button
                                    [class.mat-flat-button]="subcategory.selected"
                                    [color]="subcategory.selected?'primary':null"
                                    [class.sp-disabled]="readonly"
                                    (click)="onSubcategoryClick(subcategory)">
                                    <span class="sp-subcategory__name">
                                        {{subcategory.name}}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #noData>
                    <span>No categories...</span>
                </ng-template>
            </div>
        </div>
    </mat-menu>
</div>