/**
 * Enumeración de monedas
 * ```
 * Referencias:
 * https://en.wikipedia.org/wiki/ISO_4217
 * ```
 */
export enum CurrencyISO {
    /**
     * United Arab Emirates dirham
     */
    AED = 784,
    /**
     * Afghan afghani
     */
    AFN = 971,
    /**
     * Albanian lek
     */
    ALL = 8,
    /**
     * Armenian dram
     */
    AMD = 51,
    /**
     * Netherlands Antillean guilder
     */
    ANG = 532,
    /**
     * Angolan kwanza
     */
    AOA = 973,
    /**
     * Argentine peso
     */
    ARS = 32,
    /**
     * Australian dollar
     */
    AUD = 36,
    /**
     * Aruban florin
     */
    AWG = 533,
    /**
     * Azerbaijani manat
     */
    AZN = 944,
    /**
     * Bosnia and Herzegovina convertible mark
     */
    BAM = 977,
    /**
     * Barbados dollar
     */
    BBD = 52,
    /**
     * Bangladeshi taka
     */
    BDT = 50,
    /**
     * Bulgarian lev
     */
    BGN = 975,
    /**
     * Bahraini dinar
     */
    BHD = 48,
    /**
     * Burundian franc
     */
    BIF = 108,
    /**
     * Bermudian dollar
     */
    BMD = 60,
    /**
     * Brunei dollar
     */
    BND = 96,
    /**
     * Boliviano
     */
    BOB = 68,
    /**
     * Bolivian Mvdol (funds code)
     */
    BOV = 984,


    // Complementar lista. A partir de aquí sólo se agregan los que se necesitan de momento


    /**
     * Euro
     */
    EUR = 978,
    /**
     * Hong Kong dollar
     */
    HKD = 344,
    /**
     * United States dollar
     */
    USD = 840,
    /**
     * Mexican peso
     */
    MXN = 484,
    /**
     * Brazilian real
     */
    BRL = 986,
    /**
     * Canadian dollar
     */
    CAD = 124,
    /**
     * Japanese yen
     */
    JPY = 392,
    /**
     * New Zealand dollar
     */
    NZD = 554,
    /**
     * Chinese yuan
     */
    CNY = 156,
    /**
     * Singapore dollar
     */
    SGD = 702
}