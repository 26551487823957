import {Data} from './data';
import {OnlineEducation, OnlineCourse, OnlineVideo} from './online-education';

export class OnlineEducationData extends Data<OnlineEducation> {
  constructor() {
    super(OnlineEducation);
  }
}

export class OnlineVideoData extends Data<OnlineVideo> {
  constructor() {
    super(OnlineVideo);
  }
}

export class OnlineCourseData extends Data<OnlineCourse> {
  constructor() {
    super(OnlineCourse);
  }
}
