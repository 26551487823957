<div class="sp-login">
    <!-- Backdrop -->
    <div class="sp-login__backdrop">
        <ng-scrollbar>
            <div class="sp-login__container">
                <!-- Logotipo -->
                <img [src]="logo"
                    alt="Solo Performance"
                    class="sp-login__logo">
                <!-- Formulario -->
                <form [formGroup]="loginForm"
                    class="sp-login__form "
                    cdkTrapFocus
                    [cdkTrapFocusAutoCapture]="true">
                    <!-- Nombre de usuario -->
                    <mat-form-field appearance="outline"
                        class="sp-login__form-field">
                        <mat-label>Email</mat-label>
                        <input class="sp-login__email"
                            type="email"
                            placeholder="Email"
                            matInput
                            [formControlName]="controlNames.username"
                            cdkFocusRegionStart
                            cdkFocusInitial>
                        <mat-error *ngIf="loginForm.get(controlNames.username).hasError('required')">
                            Email required
                        </mat-error>
                        <mat-error *ngIf="loginForm.get(controlNames.username).hasError('email')">
                            The email must be valid
                        </mat-error>
                    </mat-form-field>
                    <!-- Contraseña -->
                    <mat-form-field appearance="outline"
                        class="sp-login__form-field">
                        <mat-label>Password</mat-label>
                        <input [type]="passwordShown?'text':'password'"
                            placeholder="Password"
                            matInput
                            [formControlName]="controlNames.password">
                        <mat-icon class="sp-login__form-field-suffix"
                            matSuffix
                            [svgIcon]="passwordShown?'visibility-off':'visibility'"
                            (click)="passwordShown=!passwordShown">
                        </mat-icon>
                        <mat-error *ngIf="loginForm.get(controlNames.password).hasError('required')">
                            Password required
                        </mat-error>
                    </mat-form-field>
                    <div *ngIf="!isChromeNavigator"
                        class="sp-login__chrome-message alert alert-danger mb-2">
                        <span>The app is optimized for Google Chrome</span>
                        <a href="https://www.google.com/chrome/">
                            Download Chrome
                        </a>
                    </div>
                    <!-- Iniciar sesión -->
                    <button mat-flat-button
                        type="submit"
                        color="primary"
                        [disabled]="loginForm.invalid || loggingIn || !isChromeNavigator"
                        (click)="login()"
                        cdkFocusRegionEnd>
                        Login
                    </button>
                    <!-- Login error -->
                    <span class="sp-login__message-error"
                        [class.sp-login__message-error--shown]="messageError"
                        [class.sp-login__message-error--logging-in]="loggingIn">
                        {{messageError}}
                    </span>
                    <!-- Recuperar contraseña -->
                    <a class="sp-link sp-link--forgot-password"
                        [class.disabled]="!isChromeNavigator"
                        (click)="forgotPasswordClick()">
                        Forgot your password?
                    </a>
                    <!-- Separator -->
                    <div class="sp-login__separator">
                        <span class="sp-login__separator-line"></span>
                    </div>
                    <!-- Signup -->
                    <div class="sp-signup d-flex justify-content-center">
                        <span class="sp-signup__question">You don't have a user?</span>
                        <a class="sp-link ml-1"
                            [class.disabled]="!isChromeNavigator"
                            (click)="registerClick()">
                            Register here
                        </a>
                    </div>
                    <!-- Separator -->
                    <div class="sp-login__separator d-flex align-items-center justify-content-center">
                        <span class="sp-login__separator-line"></span>
                        <span class="sp-login__separator-text">OR</span>
                    </div>
                </form>
                <!-- Social media login -->
                <div class="sp-social-options d-block w-100"
                    [class.disabled]="!isChromeNavigator">
                    <sp-social-logins [focusSocialTypeButton]="focusSocialTypeButton">
                    </sp-social-logins>
                </div>
            </div>
        </ng-scrollbar>
    </div>
</div>