import { Serializer, UserPermissionResponse } from "./interfaces";

export class UserPermission implements Serializer<UserPermission>{

    /**
     * Obtiene si los permisos del usuario le permiten el acceso al dashboard/web
     * @returns 
     */
    get hasDashboardAccess(): boolean {
        return this.isManager || this.isCoach;
    }

    /**
     * Obtiene si los permisos del usuario le permiten el acceso al panel administrativo
     * @returns 
     */
    get hasAdminAccess(): boolean {
        return this.isAdmin;
    }

    constructor(
        public isAdmin?: boolean,
        public isAthlete?: boolean,
        public isCoach?: boolean,
        public isManager?: boolean
    ) { }

    fromResponse(response: UserPermissionResponse): UserPermission {
        return new UserPermission(
            response.is_admin,
            response.is_athlete,
            response.is_coach,
            response.is_manager
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}