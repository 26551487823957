import {
    ExerciseBlockCreateRequest, ExerciseBlockResponse, ExerciseBlockTemplateRequest, ExerciseBlockUpdateRequest, ExerciseBlockUpdateTitleRequest,
    Serializer
} from './interfaces';

import { Audit } from './audit';
import { Exercise } from './exercise';
import { ExerciseSerie } from './exercise-serie';
import { ExerciseParameterLink } from './exercise-parameter-link';
import { WorksetValue } from './workset-value';
import { WorksetTypeValue } from './workset-type-value';
import { ExerciseBlockIndicator } from './exercise-block-indicator';
import { ExerciseBlockCategory } from './exercise-block-category';

/**
 * Representa una relación entre bloque <-> ejercicio
 */
export class ExerciseBlock implements Serializer<ExerciseBlock> {

    /**
     * Objeto para verificar valores previos a una modificación y permitir enviar sólo los campos modificados
     */
    private initialValues: ExerciseBlock;

    get exerciseHasChanges(): boolean {
        return this.initialValues.exercise?.id !== this.exercise?.id;
    }

    get titleHasChanges(): boolean {
        return this.initialValues.title !== this.title;
    }

    get commentHasChanges(): boolean {
        return this.initialValues.comment !== this.comment;
    }

    get orderHasChanges(): boolean {
        return this.order !== this.initialValues.order;
    }

    get isSupersetHasChanges(): boolean {
        return this.isSuperset !== this.initialValues.isSuperset;
    }

    get hasLibraryHasChanges(): boolean {
        return this.hasLibrary !== this.initialValues.hasLibrary;
    }

    get priorityHasChanges(): boolean {
        return this.indicator?.value !== this.initialValues.indicator?.value;
    }

    get categoriesHasChanges(): boolean {

        if (this.categories.length !== this.initialValues.categories.length) return true;

        const ids = this.categories.map(x => x.id).sort((a, b) => a - b);
        const initialIds = this.initialValues.categories.map(x => x.id).sort((a, b) => a - b);
        return ids.join() !== initialIds.join();
    }

    /**
     * Verifica si existen cambio de información de campos respecto a sus valores iniciales y así evitar enviar a guardar innecesariamente.
     * NOTA: De momento sólo se requiere para ciertos campos por lo que son los únicos que se verifican
     */
    get hasChanges(): boolean {

        // Verifica si la información actual del valor es diferente a los iniciales.
        return (
            this.titleHasChanges ||
            this.commentHasChanges ||
            this.orderHasChanges ||
            this.isSupersetHasChanges ||
            this.hasLibraryHasChanges ||
            this.priorityHasChanges ||
            this.exerciseHasChanges ||
            this.categoriesHasChanges
        );
    }

    /**
     * Obtiene el nombre por defecto
     */
    get defaultName(): string {
        return this.title
            || this.exercise?.name
            || ((this.hasLibrary) ? '' : 'Exercise block name');
    }

    constructor(
        /**
         * Identificador de la relación entre bloque<->ejercicio
         */
        public id?: number,
        public blockId?: number,
        public exercise?: Exercise,
        public comment?: string,
        public isActive?: boolean,
        public audit?: Audit,
        /**
         * Indica que es el bloque<->ejercicio es un template set
         */
        public hasLibrary?: boolean,
        public institutionId?: number,
        public isSuperset?: boolean,
        public order?: number,
        public title?: string,
        public categories: Array<ExerciseBlockCategory> = [],
        /**
         * Parameters selected for exercise block
         */
        public parameterLinks: Array<ExerciseParameterLink> = [],
        /**
         * Values for selected parameters
         */
        public values: Array<WorksetValue> = [],
        /**
         * Types for selected parameters
         */
        public types: Array<WorksetTypeValue> = [],
        /**
         * Obtiene o establece la siguiente relación bloque<->ejercicio a la relación actual de bloque<->ejercicio. Significa por lo tanto que no es el último
         */
        public nextLink?: ExerciseBlock,
        /**
         * Obtiene o establece la relación previa bloque<->ejercicio a la relación actual de bloque<->ejercicio
         */
        public previousLink?: ExerciseBlock,
        /**
         * Indicador A1, A2, B1, B2...
         */
        public indicator = new ExerciseBlockIndicator()
    ) {
        this.initialValues = {} as ExerciseBlock;
        this.initialValues.exercise = {} as Exercise;
        this.initialValues.indicator = {} as ExerciseBlockIndicator;

        this.assignInitialValues(this);
    }

    fromResponse(response: ExerciseBlockResponse): ExerciseBlock {
        const link = new ExerciseBlock(
            response.id,
            response.block ? this.blockId : null,
            response.exercise ? new Exercise().fromResponse(response.exercise) : null,
            response.comment,
            response.active ? response.active : false,
            new Audit().fromResponse(response),
            response.has_library ? response.has_library : false,
            response.institution ? response.institution : null,
            response.superset,
            response.order,
            response.title,
            response.category ? response.category.map(x => {
                const category = new ExerciseBlockCategory();
                category.id = x;
                return category;
            }) : [],
            response.item_catalog_related ? response.item_catalog_related.map(x => new ExerciseParameterLink().fromResponse(x)) : [],
            response.values ? response.values.map(value => new WorksetValue().fromResponse(value)) : [],
            response.row_type ? response.row_type.map(type => new WorksetTypeValue().fromResponse(type)) : [],
            null,
            null,
            response.priority ? new ExerciseBlockIndicator().fromResponse(response) : new ExerciseBlockIndicator()
        );

        this.assignInitialValues(link);

        return link;
    }

    /**
     * Crea/pobla estructura para enviar a crear un bloque<->ejercicio
     */
    toRequest(): ExerciseBlockCreateRequest {
        return <ExerciseBlockCreateRequest>{
            title: this.title || undefined,
            exercise: this.exercise?.id || undefined,
            order: this.order || undefined,
            superset: this.isSuperset || undefined,
            comment: this.comment || undefined,
            has_library: this.hasLibrary || undefined,
            category: this.categories && this.categories.length
                ? this.categories.map(x => x.id)
                : undefined
        };
    }

    toTemplateRequest(): ExerciseBlockTemplateRequest {
        return <ExerciseBlockTemplateRequest>{
            title: this.title,
            category: this.categories.map(x => x.id)
        };
    }

    /**
     * Obtiene una relación bloque<->ejercicio en base al ejercicio indicado
     * @param exercise Ejercicio a asignar a la relación a crear
     * @returns Relación bloque<->ejercicio creada
     */
    static fromExercise(exercise: Exercise): ExerciseBlock {
        const link = new ExerciseBlock();
        link.exercise = exercise;
        return link;
    }

    toUpdateRequest(): ExerciseBlockUpdateRequest {
        return <ExerciseBlockUpdateRequest>{
            title: this.titleHasChanges
                ? (this.title || (this.initialValues.title ? '' : undefined))
                : undefined,
            exercise: this.exerciseHasChanges
                ? (this.exercise?.id || (this.initialValues.exercise?.id ? 0 : undefined))
                : undefined,
            order: this.orderHasChanges
                ? (this.order || (this.initialValues.order ? 0 : undefined))
                : undefined,
            superset: this.isSupersetHasChanges
                ? ((this.isSuperset !== null && this.isSuperset !== undefined) ? this.isSuperset : undefined)
                : undefined,
            comment: this.commentHasChanges
                ? (this.comment || (this.initialValues.comment ? '' : undefined))
                : undefined,
            has_library: this.hasLibraryHasChanges
                ? ((this.hasLibrary !== null && this.hasLibrary !== undefined) ? this.hasLibrary : undefined)
                : undefined,
            category: this.categoriesHasChanges
                ? this.categories.map(x => x.id)
                : undefined,
            priority: this.priorityHasChanges
                ? (this.indicator?.value.toUpperCase() || (this.initialValues.indicator?.value ? '' : undefined))
                : undefined
        };
    }

    toUpdateTitleRequest(): ExerciseBlockUpdateTitleRequest {
        return <ExerciseBlockUpdateTitleRequest>{
            title: this.title || undefined
        };
    }

    /**
     * Obtiene los números o consecutivos de las series
     * @returns Colección de números o consecutivos de los registros de series
     */
    getRowNumbers(): Array<number> {

        return this.values
            .map(value => value.rowNumber)
            .filter((rowNumber, index, self) => {
                // Ésta condición permite excluir los números iguales que NO sean el primero en la lista
                return self.indexOf(rowNumber) === index;
            });
    }

    /**
     * Update the exercise block values and types with data read from series
     * @param series Array with model used en exercise block detail modal
     */
    updateWithSeries(series: Array<ExerciseSerie>): void {

        this.values = [];
        this.types = [];

        series.filter(
            x => !x.isNewRowAux
        ).forEach(serie => {
            // Values columns
            this.values.push(...serie.columns.filter(x => x.value.id).map(x => x.value));
            // Type columns
            if (serie.type.id) {
                this.types.push(serie.type);
            }
        });
    }

    /**
     * Aplica cambios guardados en BD. Para una siguiente validación de cambios.
     * En caso que en la actualización se mapee mediante el método fromResponse no es necesario llamar a éste método
     */
    applyChanges(): void {
        this.assignInitialValues(this);
    }

    /**
     * Se inicializan los datos para validación
     * @param exerciseBlock Objeto con datos actualizados
     */
    private assignInitialValues(exerciseBlock: ExerciseBlock) {

        this.initialValues.title = exerciseBlock.title;
        this.initialValues.order = exerciseBlock.order;
        this.initialValues.comment = exerciseBlock.comment;
        this.initialValues.isSuperset = exerciseBlock.isSuperset;
        this.initialValues.hasLibrary = exerciseBlock.hasLibrary;
        this.initialValues.indicator.value = exerciseBlock.indicator?.value;
        this.initialValues.exercise.id = exerciseBlock.exercise?.id;
        this.initialValues.categories = exerciseBlock.categories.slice();
    }
}