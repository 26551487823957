import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sp-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
