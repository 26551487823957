import { Pipe, PipeTransform } from '@angular/core';

import { Day } from 'sp-core';

@Pipe({
  name: 'day'
})
export class DayPipe implements PipeTransform {

  transform(day: Day, abbreviation = false): string {
    switch (day) {
      case Day.monday:
        return abbreviation ? 'M' : 'Monday';
      case Day.tuesday:
        return abbreviation ? 'T' : 'Tuesday';
      case Day.wednesday:
        return abbreviation ? 'W' : 'Wednesday';
      case Day.thursday:
        return abbreviation ? 'T' : 'Thursday';
      case Day.friday:
        return abbreviation ? 'F' : 'Friday';
      case Day.saturday:
        return abbreviation ? 'S' : 'Saturday';
      case Day.sunday:
        return abbreviation ? 'S' : 'Sunday';
      default:
        return '';
    }
  }
}
