import { PaginationResponse } from './interfaces/pagination-response.interface';
import { Serializer } from './interfaces/serializer.interface';

import { PaginationLink } from './pagination-link';

export class Pagination implements Serializer<Pagination> {

    constructor(
        public totalRows?: number,
        public perPage?: number,
        public currentPage?: number,
        public links?: PaginationLink
    ) { }
    
    fromResponse(response: PaginationResponse): Pagination {
        return new Pagination(
            response.total_rows,
            response.per_page,
            response.current_page,
            new PaginationLink().fromResponse(response.links)
        );
    }
    
    toRequest() {
        throw new Error('Method not implemented.');
    }
    
    clone(): Pagination {
        return new Pagination(
            this.totalRows,
            this.perPage,
            this.currentPage,
            new PaginationLink(
                this.links.first,
                this.links.last,
                this.links.next,
                this.links.prev
            )
        )
    }
}