import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

import { SPF_SHORTTIME_FORMAT, User } from 'sp-core';

import { ChatMessage, SocketChatMessage } from '../../models';
import moment from 'moment';

@Component({
  selector: 'sp-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {

  @Input() user: User;

  private _message: SocketChatMessage;
  get message(): SocketChatMessage {
    return this._message;
  }
  @Input('message') set setMessage(value: SocketChatMessage) {
    this._message = value;
    this.setMessageContainerStyles();
    this.setMessageTimeStyles();
  };

  private _messageContainer: ElementRef;
  get messageContainer(): ElementRef {
    return this._messageContainer;
  }
  @ViewChild('messageContainer') set setMessageContainer(el: ElementRef) {
    this._messageContainer = el;
    this.setMessageContainerStyles();
  }

  private _messageTime: ElementRef;
  get messageTime(): ElementRef {
    return this._messageTime;
  }
  @ViewChild('messageTime') set setMessageTime(el: ElementRef) {
    this._messageTime = el;
    this.setMessageTimeStyles();
  }

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void { }

  private setMessageContainerStyles() {

    if (!this.message || !this.messageContainer?.nativeElement) return;

    if (this.message?.userId === this.user?.id)
      this.renderer.addClass(this.messageContainer.nativeElement, 'sp-chat-message__container--me');
  }

  timeString(date: string): string {
    return moment(date).format(SPF_SHORTTIME_FORMAT);
  }

  private setMessageTimeStyles() {

    if (!this.message || !this.messageTime?.nativeElement) return;

    this.renderer.addClass(this.messageTime.nativeElement,
      `align-self-${(this.message?.userId === this.user?.id) ? 'end' : 'start'}`
    );
  }
}