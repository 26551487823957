import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ExerciseCategory, ExerciseCategoryGroup, ExerciseService, ExerciseSubcategory } from 'sp-core';

@Component({
  selector: 'sp-exercise-filter',
  templateUrl: './exercise-filter.component.html',
  styleUrls: ['./exercise-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExerciseFilterComponent implements OnInit, OnChanges {

  @Input() readonly = false;
  @Input() subcategoryIds: number[] = [];
  @Output() change = new EventEmitter<Array<ExerciseSubcategory>>();

  exerciseCategoryGroups: Array<ExerciseCategoryGroup> = [];
  exerciseCategories: Array<ExerciseCategory> = [];
  exerciseSubcategories: Array<ExerciseSubcategory> = [];

  subcategoriesSelected: Array<ExerciseSubcategory> = [];

  loading = false;

  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private exerciseService: ExerciseService
  ) {
    this.iconRegistry.addSvgIcon('filter-alt', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter-alt-black.svg'));
    this.iconRegistry.addSvgIcon('remove', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['subcategoryIds']) {
      this.unselectAllSubcategories();
      this.selectSubcategoriesFromInput();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin([
      this.exerciseService.getCategoryGroups(),
      this.exerciseService.getCategories(),
      this.exerciseService.getSubcategories()
    ]).pipe(
      finalize(() => {
        this.loading = false;
      })
    ).subscribe(data => {
      this.exerciseCategoryGroups = data[0];
      this.exerciseCategories = data[1];
      this.exerciseSubcategories = data[2];
      this.setCategories();
      this.setSubcategories();

      this.selectSubcategoriesFromInput();
    });
  }

  onCategoryGroupSubcategoryChange(): void {
    this.emitSubcategoriesSelected();
  }

  onCategoryGroupSelectUnselectAll(): void {
    this.emitSubcategoriesSelected();
  }

  onClearAllClick(): void {
    this.unselectAllSubcategories();
    this.emitSubcategoriesSelected();
  }

  /**
   * Des-selecciona todas las subcategorías
   */
  private unselectAllSubcategories(): void {
    this.exerciseCategoryGroups.forEach(categoryGroup => categoryGroup.selectUnselectAllSubcategories(false));
  }

  /**
   * Selecciona las subcategorías en base a las que se enviaron de entrada (subcategoryId)
   */
  private selectSubcategoriesFromInput(): void {
    // De el array de exerciseSubcategories encuentra las subcategorias del Input y las marca como seleccionadas
    this.subcategoriesSelected = this.exerciseSubcategories
      .filter(x => this.subcategoryIds.includes(x.id))
      .map(subcategory => {
        subcategory.select();
        return subcategory;
      });
  }

  /**
   * Asigna las categorías en el grupo de categoría correspondiente.
   */
  private setCategories(): void {
    this.exerciseCategoryGroups.forEach(categoryGroup => {
      categoryGroup.categories = this.exerciseCategories.filter(category => category.categoryGroupId === categoryGroup.id);
    });
  }

  /**
   * Asigna las subcategorías en la categoría correspondiente.
   */
  private setSubcategories(): void {
    this.exerciseCategories.forEach(category => {
      category.subcategories = this.exerciseSubcategories.filter(subcategory => subcategory.categoryId === category.id);
    });
  }

  private emitSubcategoriesSelected(): void {
    let subcategoriesSelected: Array<ExerciseSubcategory> = [];
    this.exerciseCategories.forEach(category => {
      subcategoriesSelected = subcategoriesSelected.concat(category.getSubcategoriesSelected());
    });
    this.subcategoriesSelected = subcategoriesSelected;
    this.change.emit(this.subcategoriesSelected);
  }
}
