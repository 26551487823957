import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';

import {
  AuthService, AuthValidators, Institution, InstitutionManager, ParentErrorStateMatcher, ProgressSpinnerService, ServiceResponse, SocialType, Subscription,
  Team, User, UserRegister, UtilitiesService
} from 'sp-core';
import { RegisterData } from '../change-password/models/register-data.interface';

import { REGISTER_CONTROL_NAMES } from './register.constants';

@Component({
  selector: 'sp-register',
  templateUrl: './register.component.html',
  styleUrls: [
    '../styles/sp-login.scss',
    './register.component.scss'
  ]
})
export class RegisterComponent implements OnInit {

  /**
   * Clave de sitio para recaptcha. Se obtiene de la configuración realizada para recaptcha de Google.
   * En caso de que no se envíe no se solicitará la validación recaptcha
   */
  @Input() siteKey: string;

  @Input() data = <RegisterData>{
    texts: {
      email: 'Email',
      password: 'Password',
      passwordConfirm: 'Confirm password',
      validEmailMessage: 'The email must be valid',
      requiredValidationMessage: 'Field required',
      matchValidationMessage: "The password and its confirmation don't match"
    }
  };

  /**
   * Evento después de registrarse correctamente
   */
  @Output() afterRegister = new EventEmitter<UserRegister>();

  /**
   * Evento después de asignar contraseña
   */
  @Output() afterSetPassword = new EventEmitter<User>();

  @Output() goToLogin = new EventEmitter();

  /**
   * Evento cuando no se encontró usuario con el token de activación indicado
   */
  @Output() activationTokenNotFound = new EventEmitter<string>();

  /**
   * Evento cuando se modifique el usuario a activar. En caso de que se solicite registro mediante token de activación
   */
  @Output() userToActivateChange = new EventEmitter<{ token: string, user: User }>();

  @ViewChild('passwordInput') passwordInputRef: ElementRef;

  parentErrorStateMatcher = new ParentErrorStateMatcher();

  form: UntypedFormGroup;

  CONTROL_NAMES = REGISTER_CONTROL_NAMES;

  registering = false;

  errorMessage: string;

  message: string;

  recaptchaLoading = true;

  /**
   * Token de activación. En caso de que se intente registrar mediante token
   */
  activationToken: string;

  /**
   * Obtiene o establece el usuario a activar
   * ```
   * Caso 1: El usuario fué agregado en la institución y requiere activar y asignar una contraseña
   * ```
   */
  userToActivate: User;

  passwordShown = {
    password: false,
    confirm: false
  }

  get username(): string {
    return (this.usernameCtrl.value as string).toLowerCase();
  }

  get usernameCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.username) as AbstractControl;
  }

  get passwordCtrl(): AbstractControl {
    return this.form.get(`${this.CONTROL_NAMES.passwordForm}.${this.CONTROL_NAMES.password}`) as AbstractControl;
  }

  get passwordConfirmCtrl(): AbstractControl {
    return this.form.get(`${this.CONTROL_NAMES.passwordForm}.${this.CONTROL_NAMES.passwordConfirm}`) as AbstractControl;
  }

  get passwordInput(): HTMLInputElement {
    return this.passwordInputRef?.nativeElement;
  }

  get recaptchaCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.recaptcha) as AbstractControl;
  }

  get passwordForm() {
    return this.form.get(this.CONTROL_NAMES.passwordForm) as UntypedFormGroup;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private spinnerService: ProgressSpinnerService,
    private authService: AuthService,
    private zone: NgZone
  ) {
    this.createForm();
  }

  ngOnInit(): void {

    // Obtiene parámetros enviados a la ruta
    this.route.params.subscribe(params => {

      // Obtiene si se envió un token en el registro
      // Si se envió token significa que se agregó un usuario a institución y éste requiere asignar su contraseña
      this.activationToken = params['activationToken'];
      if (this.activationToken) {
        this.spinnerService.start();
        this.authService
          .getRegisteredUser(this.activationToken)
          .pipe(
            finalize(() => this.spinnerService.stop())
          ).subscribe(userRegistered => {
            this.userToActivate = userRegistered;
            this.userToActivateChange.emit({ token: this.activationToken, user: this.userToActivate });
            this.usernameCtrl.setValue(this.userToActivate.email);
            this.usernameCtrl.disable();
            if (this.passwordInput) {
              this.passwordInput.focus();
            }
          }, () => {
            this.errorMessage = 'The link is invalid or has been used';
            this.activationTokenNotFound.emit(this.activationToken);
          });
      } else {
        this.activationToken = null;
        this.userToActivate = null;
        this.userToActivateChange.emit(null);
      }
    });

    if (this.siteKey) {
      const recaptchaCtrl = this.formBuilder.control(null, [Validators.required]);
      this.form.addControl(this.CONTROL_NAMES.recaptcha, recaptchaCtrl);
    }
  }

  handleRecaptchaReady(): void {
    setTimeout(() => {
      // Debido a que se trata de un evento de una librería externa al parecer el evento no se detecta dentro de la zona de Angular
      // Las instrucciones se ejecutan en la zona de Angular para actualizar las variables que correspondan
      this.zone.run(() => {
        this.recaptchaLoading = false;
      })
    });
  }

  onRegisterClick(): void {

    // Verifica que si el formulario tiene errores
    if (this.form.invalid) return;

    // Si el usuario ya está creado, envía a activar y asignar su contraseña
    if (this.userToActivate) {
      this.activateByToken();
    }
    // Envía a registrar el usuario
    else {
      this.register();
    }
  }

  onGoToLoginClick(): void {
    this.goToLogin.emit();
  }

  private mapToModel(): UserRegister {

    const userRegister = new UserRegister();

    userRegister.institution = this.mapFormToInstitution();
    userRegister.team = this.mapFormToTeam();
    userRegister.institutionManager = this.mapFormToInstitutionManager();

    return userRegister;
  }

  private mapFormToInstitution(): Institution {

    let institution = new Institution();

    // El nombre de institución es el correo o nombre de usuario
    institution.name = this.username;

    institution.identifierName = UtilitiesService.getDbName(institution.name);

    // TODO: Al crear el usuario siempre es trial hasta el momento en que se suscribe por stripe
    institution.subscription = Subscription.trialSubscription();

    institution.isActive = true;

    return institution;
  }

  private mapFormToTeam(): Team {
    const team = new Team();
    // El nombre del equipo es la misma que la institución
    team.name = this.username;
    return team;
  }

  private mapFormToInstitutionManager(): InstitutionManager {

    const manager = new InstitutionManager();

    manager.fullName = this.username;
    manager.email = this.username;
    manager.password = this.passwordCtrl.value;
    manager.passwordConfirm = this.passwordConfirmCtrl.value;
    manager.language = 1; // Inglés

    return manager;
  }

  private createForm(): void {

    this.form = this.formBuilder.group({});

    const emailCtrl = this.formBuilder.control(null, [Validators.required, Validators.email]);
    this.form.addControl(this.CONTROL_NAMES.username, emailCtrl);

    this.form.addControl(this.CONTROL_NAMES.passwordForm, this.createPasswordForm());
  }

  private createPasswordForm(): UntypedFormGroup {

    const passwordForm = this.formBuilder.group({});

    const passwordCtrl = this.formBuilder.control(null, [Validators.required, Validators.minLength(8)]);
    passwordForm.addControl(this.CONTROL_NAMES.password, passwordCtrl);

    const passwordConfirmCtrl = this.formBuilder.control(null, [Validators.required]);
    passwordForm.addControl(this.CONTROL_NAMES.passwordConfirm, passwordConfirmCtrl);

    passwordForm.setValidators([AuthValidators.confirmPassword]);

    return passwordForm;
  }

  private activateByToken(): void {

    this.userToActivate.socialType = SocialType.default;
    this.userToActivate.password = this.passwordCtrl.value;

    this.spinnerService.start();
    this.registering = true;
    this.authService.activateByToken(
      this.activationToken,
      this.userToActivate
    ).pipe(
      finalize(() => {
        this.spinnerService.stop();
        this.registering = false;
      })
    ).subscribe(() => {
      this.errorMessage = null;
      this.afterSetPassword.emit(this.userToActivate);
    }, (error: ServiceResponse) => {
      this.errorMessage = error.errorMessage.firstError;
    });
  }

  private register(): void {

    const userRegister = this.mapToModel();

    this.spinnerService.start();
    this.registering = true;
    this.authService.register(
      userRegister
    ).pipe(
      catchError(error => {
        this.errorMessage = error;
        throw error;
      }),
      finalize(() => {
        this.spinnerService.stop();
        this.registering = false;
      })
    ).subscribe((response) => {
      if (!response.success) {
        this.errorMessage = response.errorMessage.firstError;
        return;
      }
      this.errorMessage = null;
      response.institutionManager = userRegister.institutionManager;  // Se asignan los datos de manager para tener acceso al nombre de usuario
      this.afterRegister.emit(response);
    });
  }
}