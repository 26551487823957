import { Serializer, StripeSubscriptionResponse } from "./interfaces";

export class StripeSubscription implements Serializer<StripeSubscription> {

    constructor(
        public subscriptionId?: string,
        public customerId?: string,
        public productId?: string,
        public priceId?: string
    ) { }

    fromResponse(response: StripeSubscriptionResponse): StripeSubscription {
        return new StripeSubscription(
            response.suscription_id,
            response.customer_id,
            response.prod_id,
            response.price_id
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}