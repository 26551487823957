import moment from 'moment';

import { Serializer, SocialLoginRegisterRequest } from "./interfaces";
import { SocialType, UserType } from "./enumerations";

import { Institution } from "./institution";
import { Team } from "./team";
import { InstitutionManager } from "./institution-manager";


export class SocialLoginRegister implements Serializer<SocialLoginRegister>{

    constructor(
        public socialType?: SocialType,
        public socialLoginToken?: string,
        public institution?: Institution,
        public team?: Team,
        public institutionManager?: InstitutionManager
    ) { }

    fromResponse(response: any): SocialLoginRegister {
        throw new Error("Method not implemented.");
    }
    toRequest(): SocialLoginRegisterRequest {
        return <SocialLoginRegisterRequest>{
            type: UserType.institutionManager,
            social_type: this.socialType,
            token_social_login: this.socialLoginToken,
            email: this.institutionManager.email,
            full_name: this.institutionManager.fullName,
            country_code: 52,    // TODO: Verificar captura
            region: 1,   // TODO: Verificar captura
            phone: this.institutionManager.phone ? this.institutionManager.phone : '',
            photo: this.institutionManager.photo,
            type_of_profession: this.institutionManager.professionalType,
            name: this.institution.name,
            identifier_name: this.institution.identifierName,
            ends: moment(this.institution.subscription.expirationDate).format('YYYY-MM-DD'),
            total_team: this.institution.subscription.totalTeams,
            total_athletes: this.institution.subscription.totalAthletes,
            total_coaches: this.institution.subscription.totalCoaches
        }
    }

}