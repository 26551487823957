export enum SocialType {
    facebook = 1,
    google,
    apple,
    /**
     * Registro manual
     */
    default,
    /**
     * No asignado o no establecido. Cuando se agrega desde management -> coaches
     */
    notSet
}

export const SocialTypeKey: { [key in SocialType]: string } = {
    1: 'facebook',
    2: 'google',
    3: 'apple',
    4: 'default',
    5: 'notSet'
};