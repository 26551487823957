import { Directive, ElementRef, Input } from '@angular/core';

import { ScrollService } from 'sp-core';

@Directive({
  selector: '[spScrollEnd]'
})
export class ScrollEndDirective {

  @Input() spScrollEndDisabled = false;

  constructor(
    private el: ElementRef,
    private scrollService: ScrollService
  ) {
    const container = this.el.nativeElement as HTMLElement;
    container.onscroll = () => {
      if (!this.spScrollEndDisabled) {
        // Indica que el scroll se ha desplazado hasta el final del contenedor
        if ((container.clientHeight + container.scrollTop) >= container.scrollHeight) {
          this.scrollService.end.next(this.el);
        }
      }
    }
  }
}
