import { Serializer, SubscriptionProductPricesDataResponse } from './interfaces';

import { StripeData } from './stripe-data';

import { SubscriptionProductPrice } from './subscription-product-price';

export class SubscriptionProductPricesData extends StripeData<SubscriptionProductPrice> implements Serializer<SubscriptionProductPricesData>{
    constructor() {
        super();
    }

    fromResponse(response: SubscriptionProductPricesDataResponse): SubscriptionProductPricesData {
        const pricesData = new SubscriptionProductPricesData();
        pricesData.object = response.object;
        pricesData.url = response.url;
        pricesData.hasMore = response.has_more;
        pricesData.data = (response.data && response.data.length) ? response.data.map(x => new SubscriptionProductPrice().fromResponse(x)) : [];
        return pricesData;
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }
}