import { Inject, Injectable } from '@angular/core';

import { SP_CORE_CONFIG } from '../sp-core.constants';
import { SpCoreConfig } from '../sp-core-config.interface';
import { ExerciseMedia } from '../models';

import { ApiService } from './api.service';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ExerciseMediaService {

  private API_KEY: string;

  private YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3/';

  constructor(
    @Inject(SP_CORE_CONFIG) private config: SpCoreConfig,
    private api: ApiService
  ) {
    this.API_KEY = this.config.youtubeApiKey;
  }

  getVideosThumbnails(
    exercisesMedia: Array<ExerciseMedia>
  ): Observable<boolean> {

    const youtubeRequests: Array<Observable<any>> = [];
    const vimeoRequests: Array<Observable<any>> = [];
    const youtubeVideos: Array<ExerciseMedia> = [];
    const vimeoVideos: Array<ExerciseMedia> = [];

    const exercisesMediaToProcess = exercisesMedia.filter(x => !x.isSPF && !x.thumbnail);
    if (!exercisesMediaToProcess.length) return of(true);

    exercisesMediaToProcess.forEach(exerciseMedia => {
      if (exerciseMedia.provider === 'youtube') {
        // Sólo se obtendrá el thumbail si es que aún no se tiene asignado
        youtubeVideos.push(exerciseMedia);
      } else {
        vimeoVideos.push(exerciseMedia);
      }
    });

    // Requests youtube thumbnails
    if (youtubeVideos.length) {
      const videoIds = youtubeVideos.map(x => x.videoId).join(',');
      youtubeRequests.push(
        this.api.get(`${this.YOUTUBE_API_URL}videos?part=snippet&id=${videoIds}&key=${this.API_KEY}`)
      );
    }

    // Requests vimeo thumbnails
    if (vimeoVideos.length) {
      vimeoVideos.forEach(vimeoVideo => {
        vimeoRequests.push(
          this.api.get(`https://vimeo.com/api/v2/video/${vimeoVideo.videoId}.json`)
        );
      })
    }

    return forkJoin([
      ...youtubeRequests,
      ...vimeoRequests
    ]).pipe(
      catchError(this.api.processError('ExerciseMediaService.getVideosThumbnails')),
      map(collection => {

        // Assign thumbnails to every youtube video
        const youtubeThumbnailsResponse = collection.slice(0, youtubeVideos.length);
        if (youtubeVideos.length && youtubeThumbnailsResponse.length) {
          const youtubeThumbails = youtubeThumbnailsResponse[0].items as Array<any>;
          youtubeThumbails.forEach(youtubeThumbail => {
            const youtubeVideo = youtubeVideos.find(x => x.videoId === youtubeThumbail.id);
            if (youtubeVideo) {
              // Se asigna el thumbnail. Intenta asignar primero el de máxima resolución sino existe desciende a resoluciones menores hasta encontrar alguno
              const thumbnails = youtubeThumbail.snippet?.thumbnails;
              youtubeVideo.thumbnail = thumbnails?.maxres?.url
                || thumbnails?.standard?.url
                || thumbnails?.high?.url
                || thumbnails?.medium?.url
                || thumbnails?.default?.url;
            }
          });
        }

        // Assign thumbnails to every vimeo video
        const vimeoThumbnailsResponse = collection.slice(youtubeRequests.length) as Array<any>;
        if (vimeoVideos.length && vimeoThumbnailsResponse.length) {
          vimeoThumbnailsResponse.forEach((vimeoThumbnailResponse: any) => {
            const vimeoThumbnail = vimeoThumbnailResponse[0];
            const vimeoVideo = vimeoVideos.find(x => x.videoId === vimeoThumbnail.id.toString());
            if (vimeoVideo) {
              vimeoVideo.thumbnail = vimeoThumbnail.thumbnail_large || vimeoThumbnail.thumbnail_medium || vimeoThumbnail.thumbnail_small;
            }
          });
        }

        return true;
      })
    );
  }

}
