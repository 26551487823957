import moment from "moment";
import { SPF_DATE_FORMAT } from "../constants";

import { Serializer, WorkoutLogResponse, WorkoutLogSaveRequest } from "./interfaces";

import { WorkoutLogAthleteInfo } from "./workout-log-athlete-info";

export class WorkoutLogSave implements Serializer<WorkoutLogSave> {

    constructor(
        public workoutId?: number,
        public date?: Date,
        public athletesInformation: Array<WorkoutLogAthleteInfo> = []
    ) { }

    fromResponse(response: WorkoutLogResponse): WorkoutLogSave {
        throw new Error("Method not implemented.");
    }

    toRequest(): WorkoutLogSaveRequest {
        return <WorkoutLogSaveRequest>{
            workout_id: this.workoutId,
            date: moment(this.date).format(SPF_DATE_FORMAT),
            athletes_info: this.athletesInformation.map(x => x.toRequest())
        }
    }
}