import { Serializer, WidgetAthleteStatusResponse } from "./interfaces";
import { AthleteStatusLog } from "./athlete-status-log";

export class WidgetAthleteStatus implements Serializer<WidgetAthleteStatus> {

    /**
     * Get first status row for the athlete
     */
    get first(): AthleteStatusLog {
        if (!this.status.length) return null;
        return this.status[0];
    }

    constructor(
        public athleteId?: number,
        public status: Array<AthleteStatusLog> = []
    ) { }

    static fromResponse(response: WidgetAthleteStatusResponse): WidgetAthleteStatus {
        return new WidgetAthleteStatus(
            response.athlete,
            response.status.map(y => new AthleteStatusLog().fromResponse(y))
        )
    }

    fromResponse(response: WidgetAthleteStatusResponse): WidgetAthleteStatus {
        return WidgetAthleteStatus.fromResponse(response);
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }

}