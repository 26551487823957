import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpDisableContextmenuDirective } from './sp-disable-contextmenu.directive';

const DIRECTIVES = [
  SpDisableContextmenuDirective
];

@NgModule({
  declarations: [
    ...DIRECTIVES
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class SpDisableContextmenuModule { }
