import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, from, of, Subject, throwError } from 'rxjs';
import { map, catchError, concatMap, toArray, mergeMap, mapTo } from 'rxjs/operators';

import { SpCoreConfig } from '../sp-core-config.interface';
import { SP_CORE_CONFIG } from '../sp-core.constants';

import { SubscriptionInterval, SubscriptionType } from '../models/enumerations';
import {
  SubscriptionExtraCoachDataResponse, SubscriptionProductExtraCoachResponse, SubscriptionProductPricesDataResponse, SubscriptionProductResponse, SubscriptionProductsDataResponse,
  SubscriptionResponse
} from '../models/interfaces';
import {
  ServiceResponse, Subscription, SubscriptionExtraCoachData, SubscriptionProduct, SubscriptionProductPrice,
  SubscriptionProductPricesData, SubscriptionProductsData
} from '../models';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class SubscriptionService {

  currentSubscription = new Subscription();

  private currentSubscriptionSubject$ = new BehaviorSubject<Subscription>(this.currentSubscription);
  /**
   * Subscripción actual
   */
  currentSubscription$ = this.currentSubscriptionSubject$.asObservable();

  regionNotAssignedError: ServiceResponse;
  private regionNotAssignedSubject$ = new Subject<ServiceResponse>();
  regionNotAssigned$ = this.regionNotAssignedSubject$.asObservable();

  constructor(
    @Inject(SP_CORE_CONFIG) private config: SpCoreConfig,
    private api: ApiService,
    private auth: AuthService
  ) { }

  getProductById(
    productId: string
  ): Observable<SubscriptionProduct> {
    return this.api.get<SubscriptionProductResponse>(`products/${productId}/`)
      .pipe(
        catchError(error => {
          this.api.consoleError(error, 'SubscriptionService.getProductById');
          const responseError = this.api.createResponseError(error);
          this.checkAndEmitRegionNotAssignedError(responseError);
          return throwError(responseError);
        }),
        map(response => new SubscriptionProduct().fromResponse(response))
      );
  }

  getProducts(): Observable<Array<SubscriptionProduct>> {
    return this.api.get<SubscriptionProductsDataResponse>(`products/`)
      .pipe(
        catchError(error => {
          this.api.consoleError(error, 'SubscriptionService.getProducts');
          const responseError = this.api.createResponseError(error);
          this.checkAndEmitRegionNotAssignedError(responseError);
          return throwError(responseError);
        }),
        map(response => new SubscriptionProductsData().fromResponse(response).data)
      );
  }

  getProductPrices(
    productId: string,
    interval: SubscriptionInterval
  ): Observable<Array<SubscriptionProductPrice>> {

    const params = new HttpParams().set('interval', interval);

    return this.api.get<SubscriptionProductPricesDataResponse>(
      `products/${productId}/prices/`,
      params
    ).pipe(
      catchError(this.api.processError('SubscriptionService.getProductPrices')),
      map(response => new SubscriptionProductPricesData().fromResponse(response).data)
    );
  }

  /**
   * Obtiene datos de producto y sus precios
   * @param productId Identificador de producto
   * @param interval Intervalo a obtener: Mes/Anual
   * @returns 
   */
  getProductWithPrices(
    productId: string,
    interval: SubscriptionInterval
  ): Observable<SubscriptionProduct> {
    return this.getProductById(
      productId
    ).pipe(
      catchError((error: any) => {
        throw error;
      }),
      mergeMap(product => {
        return this.getProductPrices(
          product.id,
          interval
        ).pipe(
          map(prices => {
            product.prices = prices;
            return product;
          })
        );
      })
    )
  }


  /**
   * Obtiene los precios de los productos indicados y los asigna a cada uno de los productos correspondientes
   */
  getPricesAndSetToProducts(
    products: Array<SubscriptionProduct>,
    interval = SubscriptionInterval.month
  ): Observable<Array<SubscriptionProduct>> {
    return from(products)
      .pipe(
        concatMap(product => {
          return this.getProductPrices(
            product.id,
            interval
          ).pipe(
            map(prices => {
              product.prices = prices;
              return product;
            })
          );
        }),
        toArray()
      );
  }

  getStripeSession(
    priceId: string
  ): Observable<any> {

    const body = {
      priceId: priceId
    };

    return this.api.post(`sesion-stripe/`, body)
      .pipe(
        // TODO: Mapeo a un modelo
        map(response => response)
      );
  }

  /**
   * Obtiene la suscripción actual del usuario autenticado
   * @returns Suscripción
   */
  getCurrentSubscription(): Observable<Subscription> {

    return this.api.get<SubscriptionResponse>(
      `current-subscription`
    ).pipe(
      catchError(this.api.processError('SubscriptionService.getCurrentSubscription')),
      map(response => {
        this.currentSubscription = response
          ? new Subscription().fromResponse(response)
          : null;
        return this.currentSubscription;
      }),
      // Obtiene dato de producto y lo asigna a la suscripción
      mergeMap(subscription => {

        if (!subscription.stripe?.productId) return of(subscription);

        return this.getProductWithPrices(
          subscription.stripe.productId,
          subscription.interval
        ).pipe(
          map(product => {
            subscription.product = product;
            return subscription;
          })
        );
      }),
      catchError(error => {
        throw error;
      }),
      // Obtiene el producto downgrade si aplica
      mergeMap(subscription => {

        if (!subscription.hasDowngrade) return of(subscription);

        return this.getProductWithPrices(
          subscription.downgrade.productId,
          subscription.interval
        ).pipe(
          map(product => {
            subscription.downgrade.product = product;
            return subscription;
          })
        );
      }),
      catchError(error => {
        throw error;
      }),
      // Notifica que la subscripción ha sido obtenida
      map(subscription => {
        this.currentSubscriptionSubject$.next(subscription);
        return subscription;
      })
    );
  }

  /**
   * Obtiene los productos configurados correspondientes a extra coach
   * @returns 
   */
  getExtraCoachProducts(): Observable<Array<SubscriptionProduct>> {
    return this.api.get<SubscriptionProductExtraCoachResponse>(
      'productextracoach/'
    ).pipe(
      catchError(this.api.processError('SubscriptionService.getExtraCoachProduct')),
      mergeMap((response: SubscriptionProductExtraCoachResponse) => {
        const products = response.data.map(x => new SubscriptionProduct().fromResponse(x))
        return this.getPricesAndSetToProducts(products);
      })
    );
  }

  /**
   * Obtiene el primer producto configurado correspondiente a extra coach
   * @returns 
   */
  getExtraCoachProduct(): Observable<SubscriptionProduct> {
    return this.getExtraCoachProducts().pipe(
      // Obtiene sólo el primer producto
      map(products => products[0])
    );
  }

  getExtraCoachSubscriptions(
    httpParams?: HttpParams,
    filter?: string
  ): Observable<SubscriptionExtraCoachData> {

    let params = httpParams || new HttpParams();

    if (!params.get('tab') && filter) {
      params = params.set('tab', filter);
    }

    return this.api.get<SubscriptionExtraCoachDataResponse>(
      `extra-coach-subscription-list/`,
      params
    ).pipe(
      map(response => {
        return new SubscriptionExtraCoachData().fromResponse(response);
      })
    );
  }

  /**
   * Actualiza la suscripción. A un plan superior (upgrade) o a uno inferior (downgrade)
   * @param subscriptionId Identificador de suscripción actual
   * @param priceId Identificador de precio stripe al que se quiere actualizar
   * @param upgrade Indica si la actualización será a un plan superior (upgrade) o inferior (downgrade)
   * @param productId Identificador de producto stripe al que se requiere actualizar
   * @returns 
   */
  updateSubscription(
    subscriptionId: string,
    priceId: string,
    upgrade = true,
    productId = ''
  ): Observable<boolean> {

    const body = {
      subscription: subscriptionId,
      price: priceId,
      proration: upgrade ? 'create_prorations' : 'none',
      product_id: upgrade ? undefined : productId
    }

    return this.api.post(
      'update-subscription/',
      body
    ).pipe(
      catchError(this.api.processError('SubscriptionService.updateSubscription')),
      mapTo(true)
    );
  }

  /**
   * Solicita el checkout para compra de extra coaches
   * // TODO: Crear un modelo para mapeo de datos. Ahora se utiliza any
   * @param priceId Precio del producto a comprar (extra coach)
   * @param totalCoaches Total de coaches a suscribir
   * @param successURL Ruta para redireccionar posterior a pagar
   * @param cancelURL Ruta para redireccionar en caso de que se cancele y no se proceda con el pago (botón atrás)
   * @returns 
   */
  checkoutExtraCoach(
    priceId: string,
    totalCoaches: number,
    successURL?: string,
    cancelURL?: string
  ): Observable<any> {

    const data = {
      institution: this.auth.institutionId,
      type: SubscriptionType.extraCoach,
      price_id: priceId,
      quantity: totalCoaches,
      success_url: successURL || `${this.config.baseUrl}pricing/extra-coaches/all`,
      cancel_url: cancelURL || `${this.config.baseUrl}pricing/extra-coaches/all`
    };

    return this.api.post(
      'extra-coach-subscription/',
      data
    ).pipe(
      catchError(this.api.processError('SubscriptionService.checkoutExtraCoach')),
      map(response => {
        return response;
      })
    );
  }

  /**
   * Cancela la suscripción con el identificador indicado
   * ```
   * EndPoint: cancel-subscription/
   * ```
   * @param subscriptionId Identificador de la suscripción a cancelar
   * @returns 
   */
  cancelSubscription(
    subscriptionId: string,
    isExtraCoach = false
  ): Observable<boolean> {

    const data = {
      subscription: subscriptionId,
      type: isExtraCoach ? 'COACH' : 'NORMAL'
    };

    return this.api.post(
      `cancel-subscription/`,
      data
    ).pipe(
      catchError(this.api.processError('SubscriptionService.cancelSubscription')),
      map(() => true)
    );
  }

  updateCurrentCoaches(increment: number): void {
    this.currentSubscription.currentCoaches += increment;
    this.currentSubscriptionSubject$.next(this.currentSubscription);
  }

  updateCurrentAthletes(increment: number): void {
    this.currentSubscription.currentAthletes += increment;
    this.currentSubscriptionSubject$.next(this.currentSubscription);
  }

  private checkAndEmitRegionNotAssignedError(error: ServiceResponse): void {
    if (error.errorMessage?.firstError?.indexOf('stripe_00001') >= 0) {
      this.regionNotAssignedSubject$.next(error);
    }
  }
}
