import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import moment from 'moment';

import { SPF_DATE_FORMAT, SPF_DATETIME_FORMAT } from '../constants';
import { PublishStatus } from '../models/enumerations';
import {
  DataResponse,
  ExerciseCategoriesDataResponse,
  ExerciseCategoryGroupsDataResponse,
  ExerciseResponse, AthleteParametersDataResponse, AthleteParameterResponse, ExercisesDataResponse,
  ExerciseSubcategoriesDataResponse,
  UploadVideoResponse
} from '../models/interfaces';
import {
  Data,
  Exercise,
  ExerciseBlock,
  ExerciseCategoriesData,
  ExerciseCategory,
  ExerciseCategoryGroup,
  ExerciseCategoryGroupsData,
  AthleteParameter,
  AthleteParametersData,
  ExercisesData,
  ExerciseSubcategoriesData,
  ExerciseSubcategory,
  Media,
  Athlete
} from '../models';
import { TabFilter } from '../models/types';

import { ApiService } from './api.service';
import { AthleteParameterType } from '../models/types';
import { isArray } from 'lodash';

@Injectable()
export class ExerciseService {

  private baseURL = 'exercises';

  constructor(
    private api: ApiService
  ) { }

  private exerciseSubject$ = new BehaviorSubject<Exercise>(null);
  /**
   * Escuchador de ejercicio
   * ```
   * Obtiene el ejercicio actual. El último ejercicio agregado o modificado
   * ```
   */
  exercise$ = this.exerciseSubject$.asObservable();

  getList(
    search?: string,
    pageSize?: number,
    subcategoryId?: number,
    params?: HttpParams
  ): Observable<Array<Exercise>> {
    return this.getPaginatedList(
      search,
      1,
      pageSize,
      subcategoryId,
      [],
      params
    ).pipe(
      map(response => response.data)
    );
  }

  /**
   * TODO: Revisar si se utiliza, en caso contrario eliminar
   * @param search 
   * @param page 
   * @param pageSize 
   * @returns 
   */
  getForQuickSearch(
    search?: string,
    page?: number,
    pageSize?: number
  ): Observable<ExercisesData> {
    return this.getPaginatedList(
      search,
      page,
      pageSize
    ).pipe(
      map(response => response)
    );
  }

  /**
   * Obtiene lista paginada de ejercicios
   * @param subcategoryId Identficador de subcategoría
   */
  getPaginatedList(
    search?: string,
    page?: number,
    pageSize?: number,
    subcategoryId?: number,
    subcategoryIds: Array<number> = [],
    httpParams?: HttpParams,
    tab?: TabFilter
  ): Observable<ExercisesData> {

    let params = httpParams || new HttpParams();

    params = params.set('active', '1');

    if (search) {
      params = params.set('search', search);
    }

    if (page) {
      params = params.set('page', page.toString());
    }

    if (pageSize) {
      params = params.set('perpage', pageSize.toString());
    }

    if (subcategoryId) {
      params = params.set('sub_category', subcategoryId.toString());
    }

    if (subcategoryIds && subcategoryIds.length) {
      params = params.set('sub_category', subcategoryIds.join(','));
    }

    if (tab) {
      params = params.set('tab', tab);
    } else if (!params.get('tab')) {
      params = params.set('tab', 'all');
    }

    return this.api.get<ExercisesDataResponse>(
      'exercises/',
      params
    ).pipe(
      map(response => new ExercisesData().fromResponse(response))
    );
  }

  getCategoryGroups(): Observable<Array<ExerciseCategoryGroup>> {
    return this.getPaginatedCategoryGroups().pipe(
      map(response => response.data)
    );
  }

  getPaginatedCategoryGroups(): Observable<ExerciseCategoryGroupsData> {

    let params = new HttpParams()
      .set('active', '1')
      .set('perpage', '5000');  // TODO: Solicitar se permita enviar perpage = 0 para obtener todos los registros disponibles

    return this.api.get<ExerciseCategoryGroupsDataResponse>(
      'categories-level/',
      params
    ).pipe(
      map(response => new ExerciseCategoryGroupsData().fromResponse(response))
    );
  }

  getCategories(
    categoryGroupId?: number
  ): Observable<Array<ExerciseCategory>> {
    return this.getPaginatedCategories(
      categoryGroupId
    ).pipe(
      map(response => response.data)
    );
  }

  getPaginatedCategories(
    categoryGroupId?: number
  ): Observable<ExerciseCategoriesData> {

    let params = new HttpParams()
      .set('active', '1')
      .set('perpage', '5000');  // TODO: Solicitar se permita enviar perpage = 0 para obtener todos los registros disponibles

    if (categoryGroupId) {
      params = params.set('category_level', categoryGroupId.toString());
    }

    return this.api.get<ExerciseCategoriesDataResponse>(
      'categories/',
      params
    ).pipe(
      map(response => new ExerciseCategoriesData().fromResponse(response))
    );
  }

  getSubcategories(
    categoryId?: number
  ): Observable<Array<ExerciseSubcategory>> {
    return this.getPaginatedSubcategories(
      categoryId
    ).pipe(
      map(response => response.data)
    );
  }

  getPaginatedSubcategories(
    categoryId?: number
  ): Observable<ExerciseSubcategoriesData> {

    let params = new HttpParams()
      .set('active', '1')
      .set('perpage', '5000') // TODO: Solicitar se permita enviar perpage = 0 para obtener todos los registros disponibles
      .set('ordering', 'name');

    //
    if (categoryId) {
      params = params.set('category', categoryId.toString());
    }

    return this.api.get<ExerciseSubcategoriesDataResponse>(
      'subcategories/',
      params
    ).pipe(
      map(response => new ExerciseSubcategoriesData().fromResponse(response))
    );
  }

  /**
   * Elimina una relación entre bloque<->ejercicio
   * @param blockExercise Id de asignación bloque<->ejercicio
   */
  unassignFromBlock(blockExercise: ExerciseBlock): Observable<ExerciseBlock> {
    return this.api.delete(
      `block-exercises/${blockExercise.id}/`
    ).pipe(
      map(() => blockExercise)
    );
  }

  /**
   * Obtiene ejercicios similares a la subcategoría indicada
   */
  getSimilarExercises(
    exerciseId: number,
    page?: number,
    pageSize?: number,
    search?: string
  ): Observable<Array<Exercise>> {

    return this.getPaginatedSimilarExercises(
      exerciseId,
      page,
      pageSize,
      search
    ).pipe(
      map(response => response.data)
    );
  }

  /**
   * Obtiene ejercicios similares a la subcategoría indicada. Con paginación
   */
  getPaginatedSimilarExercises(
    exerciseId: number,
    page?: number,
    pageSize?: number,
    search?: string
  ): Observable<ExercisesData> {

    let params = new HttpParams()
      .set('active', '1')
      .set('ordering', 'english_name')
      .set('tab', 'all-order')  // Para no mezclar ejercicios de otras instituciones y ordenar primero por categorías similares
      .set('exercise', exerciseId.toString());

    if (page) {
      params = params.set('page', page.toString());
    }

    if (pageSize) {
      params = params.set('perpage', pageSize.toString());
    }

    if (search) {
      params = params.set('search', search);
    }

    return this.api.get<ExercisesDataResponse>(
      `exercises-image/`,
      params
    ).pipe(
      map(response => new ExercisesData().fromResponse(response))
    );
  }

  getAthleteParametersByExercise(
    exerciseId: number,
    type: AthleteParameterType = 'rm',
  ): Observable<Array<AthleteParameter>> {

    const params = new HttpParams().set('tab', type);

    return this.api.get<Array<AthleteParameterResponse>>(
      `exercises/${exerciseId}/rm_list/`,
      params
    ).pipe(
      map(response => response && response.length
        ? response.map(x => AthleteParameter.fromResponse(x))
        : []
      )
    )
  }

  getPaginatedAthleteRMParameter(
    athleteId: number,
    exerciseId: number,
    type: AthleteParameterType = 'rm'
  ): Observable<AthleteParametersData> {

    const params = new HttpParams().set(
      'athlete', athleteId.toString()
    ).set('tab', type
    ).set('exercise', exerciseId.toString());

    return this.api.get<AthleteParametersDataResponse>(
      'rm_atlhete/',
      params
    ).pipe(
      map(response => new AthleteParametersData().fromResponse(response))
    );
  }

  getAthleteRMParameter(
    athleteId: number,
    exerciseId: number,
    type: AthleteParameterType = 'rm'
  ): Observable<Array<AthleteParameter>> {

    return this.getPaginatedAthleteRMParameter(
      athleteId,
      exerciseId,
      type
    ).pipe(
      map(response => response.data)
    );
  }

  getPaginatedAthletesParameters(
    athleteIds: Array<Athlete> | Array<number>,
    type: AthleteParameterType,
    active = false
  ): Observable<AthleteParametersData> {

    // Athlete ids
    const ids: Array<number> = Array.isArray(athleteIds)
      ? (athleteIds as Array<Athlete>).map(x => x.id)
      : athleteIds as Array<number>;

    let params = new HttpParams().set(
      'athletes', ids.join(',')
    ).set('tab', type.toLowerCase()
    ).set('perpage', 100); // To get first 100

    if (active) {
      params = params.set('active', '1')
    }

    return this.api.get<AthleteParametersDataResponse>(
      'params_atlhete/',
      params
    ).pipe(
      map(response => new AthleteParametersData().fromResponse(response))
    );
  }

  getAthletesParameters(
    athleteIds: Array<Athlete> | Array<number>,
    type: AthleteParameterType,
    active = false
  ): Observable<Array<AthleteParameter>> {

    return this.getPaginatedAthletesParameters(
      athleteIds,
      type,
      active
    ).pipe(
      map(response => response.data)
    )
  }

  create(
    exercise: Exercise
  ): Observable<Exercise> {

    return this.api.post<ExerciseResponse>(
      `exercises/`,
      exercise.toRequest()
    ).pipe(
      catchError(this.api.processError('ExerciseService.create')),
      map(response => {
        const exerciseCreated = exercise.fromResponse(response);
        this.exerciseSubject$.next(exerciseCreated);
        return exerciseCreated;
      })
    );
  }

  update(
    exerciseId: number,
    exercise: Exercise
  ): Observable<Exercise> {

    return this.api.patch<ExerciseResponse>(
      `exercises/${exerciseId}/`,
      exercise.toRequest()
    ).pipe(
      catchError(this.api.processError('ExerciseService.update')),
      map(response => {
        const exerciseUpdated = exercise.fromResponse(response);
        this.exerciseSubject$.next(exerciseUpdated);
        return exerciseUpdated;
      })
    );
  }

  /**
   * Actualiza el ejercicio con acceso de superadmin
   * ```
   * A diferencia del método ExerciseService.update éste verifica que el usuario en sesión tenga acceso de super admin
   * ```
   * @param exerciseId
   * @param exercise
   * @returns
   */
  updateAsAdmin(
    exerciseId: number,
    exercise: Exercise
  ): Observable<Exercise> {

    return this.api.patch<ExerciseResponse>(
      `super_admin/exercises/${exerciseId}/`,
      exercise.toRequest()
    ).pipe(
      catchError(this.api.processError('ExerciseService.updateAsAdmin')),
      map(response => {
        const exerciseUpdated = exercise.fromResponse(response);
        this.exerciseSubject$.next(exerciseUpdated);
        return exerciseUpdated;
      })
    );
  }

  /**
   * Actualiza el thumbnail del ejercicio indicado. Aplica para ejercicios con media youtube/ vimeo
   * @param exerciseId Identificador de ejercicio
   * @param thumbnailUrl URL de miniatura
   * @returns 
   */
  updateThumbnail(
    exerciseId: number,
    thumbnailUrl: string
  ): Observable<boolean> {

    const payload = <ExerciseResponse>{
      thumbnail_youtube: thumbnailUrl
    };

    return this.api.patch(
      `${this.baseURL}/${exerciseId}/`,
      payload
    ).pipe(
      catchError(this.api.processError('ExerciseService.updateThumbnail')),
      map(() => true)
    );
  }

  createAsAdmin(
    exercise: Exercise
  ): Observable<Exercise> {

    return this.api.post<ExerciseResponse>(
      `super_admin/exercises/`,
      exercise.toRequest()
    ).pipe(
      catchError(this.api.processError('ExerciseService.createAsAdmin')),
      map(response => {
        const exerciseUpdated = exercise.fromResponse(response);
        this.exerciseSubject$.next(exerciseUpdated);
        return exerciseUpdated;
      })
    );
  }

  /**
   * Sube archivo referente a ejercicio
   * @param media Objeto con datos de video y miniatura
   * @returns
   */
  uploadVideo(media: Media): Observable<Media> {
    return this.api.post<UploadVideoResponse>(
      'files-exersice/',
      media.toFormDataRequest()
    ).pipe(
      map(response => {
        return media.fromUploadVideoResponse(response)
      })
    );
  }

  /**
   * Asigna el estatus indicado al ejercicio
   * @param exerciseId
   * @param status
   * @returns
   */
  setPublishStatus(
    exerciseId: number,
    status: PublishStatus
  ): Observable<any> {
    switch (status) {
      case PublishStatus.empty:
        return this.cancelVerification(exerciseId);
      case PublishStatus.notVerified:
        return this.sendToVerify(exerciseId);
      case PublishStatus.verified:
        return this.approve(exerciseId);
      case PublishStatus.rejected:
        return this.reject(exerciseId);
      default:
        return of<any>(null);
    }
  }

  sendToVerify(exerciseId: number): Observable<any> {
    return this.api.post(
      `exercises/${exerciseId}/send_review/`,
      {}
    ).pipe(
      catchError(this.api.processError('ExerciseService.sendToVerify'))
    );
  }

  cancelVerification(exerciseId: number): Observable<any> {
    return this.api.post(
      `exercises/${exerciseId}/cancell_review/`,
      {}
    ).pipe(
      catchError(this.api.processError('ExerciseService.cancelVerification'))
    );
  }

  approve(exerciseId: number): Observable<any> {
    return this.api.post(
      `exercise-library/${exerciseId}/aprove_review/`,
      {}
    ).pipe(
      catchError(this.api.processError('ExerciseService.approve'))
    );
  }

  reject(exerciseId: number): Observable<any> {
    return this.api.post(
      `exercise-library/${exerciseId}/reject_review/`,
      {}
    ).pipe(
      catchError(this.api.processError('ExerciseService.reject'))
    );
  }

  createExerciseRM(
    exerciseRM: AthleteParameter
  ): Observable<AthleteParameter> {

    if (!exerciseRM.hasChanges) return of(exerciseRM);

    return this.api.post<AthleteParameterResponse>(
      'rm_atlhete/',
      exerciseRM.toCreateRequest()
    ).pipe(
      map(response => AthleteParameter.fromResponse(response)),
      tap(() => exerciseRM.applyChanges())
    );
  }

  updateExerciseRM(
    exerciseRM: AthleteParameter
  ): Observable<AthleteParameter> {

    if (!exerciseRM.hasChanges) return of(exerciseRM);

    return this.api.patch<AthleteParameterResponse>(
      `rm_atlhete/${exerciseRM.id}/`,
      exerciseRM.toRequest()
    ).pipe(
      map(response => AthleteParameter.fromResponse(response)),
      tap(() => exerciseRM.applyChanges())
    );
  }

  deleteAthleteParameter(
    athleteParameter: AthleteParameter
  ): Observable<boolean> {

    return this.api.delete<AthleteParameterResponse>(
      `rm_atlhete/${athleteParameter.id}/`
    ).pipe(
      map(() => true)
    );
  }
}
