import { Serializer, UploadVideoResponse } from "./interfaces";

/**
 * Estructura de archivo media con ruta de video y ruta de imagen miniatura
 */
export class Media implements Serializer<Media>{

    constructor(
        /**
         * Ruta del video
         */
        public path?: string,
        /**
         * Ruta de la imagen miniatura del video
         */
        public thumbnail?: string,
        /**
         * Archivo a seleccionado mediante explorador de archivos
         */
        public file?: File
    ) { }

    fromResponse(response: any): Media {
        throw new Error("Method not implemented.");
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }

    fromUploadVideoResponse(response: UploadVideoResponse): Media {
        return new Media(
            response.video,
            response.img,
            null
        );
    }

    toFormDataRequest(): FormData {
        const data = new FormData();
        data.append('file', this.file, this.file.name);
        return data;
    }

    /**
     * Obtiene una instancia de Media en base a un archivo
     * @param file Archivo
     * @returns 
     */
    static fromFile(file: File): Media {
        const media = new Media();
        media.file = file;
        return media;
    }
}