import { ExerciseMediaProvider } from './types';
import { Serializer, ExerciseMediaResponse } from './interfaces';
import { Media } from './media';

export class ExerciseMedia extends Media implements Serializer<ExerciseMedia> {

    /**
     * Obtiene si el proveedor de video es SPF, para descartar que sea Youtube o Vimeo
     */
    get isSPF() {
        return this.provider === 'spf';
    }

    /**
     * Obtiene si el proveedor de video es externo (youtube, vimeo)
     */
    get isExternalMedia() {
        return !this.isSPF;
    }

    /**
     * Obtiene si es un video externo válido. Aplica cuando el provider es youtube o vimeo
     * ```
     * Se verifica el identificador del video externo (videoId)
     * ```
     */
    get isExternalVideoValid() {
        return !!this.videoId;
    }

    constructor(
        public id?: number,
        /**
        * Tipo de video: 
        */
        public provider?: ExerciseMediaProvider,
        /**
         * Identificador de video para cuando el proveedor es youtube o vimeo
         */
        public videoId?: string
    ) {
        super();
    }

    static fromResponse(response: ExerciseMediaResponse): ExerciseMedia {
        const exerciseMedia = new ExerciseMedia(
            response.id,
            'spf'
        );

        exerciseMedia.path = response.path;
        exerciseMedia.thumbnail = response.thumbnail;

        return exerciseMedia;
    }

    fromResponse(response: ExerciseMediaResponse): ExerciseMedia {
        return ExerciseMedia.fromResponse(response);
    }

    static fromMedia(media: Media): ExerciseMedia {
        const exerciseMedia = new ExerciseMedia(
            null,
            'spf'
        );

        exerciseMedia.path = media.path;
        exerciseMedia.thumbnail = media.thumbnail;
        exerciseMedia.file = media.file;

        return exerciseMedia;
    }

    static fromUrl(url: string): ExerciseMedia {

        if (!url) return null;

        const exerciseMedia = new ExerciseMedia();
        exerciseMedia.path = url;

        // REF: https://github.com/sampotts/plyr/blob/master/src/js/plugins/youtube.js
        const youtubeRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const youtubeMatch = url.match(youtubeRegex);
        exerciseMedia.videoId = youtubeMatch ? youtubeMatch[2] : null;

        // Si encuentra el ID del video significa que es una URL de video Youtube en caso contrario se considera de Vimeo
        // De momento sólo se consideran 2 tipos de video: Youtube y Vimeo
        if (exerciseMedia.videoId) {
            exerciseMedia.provider = 'youtube';
        } else {
            exerciseMedia.provider = 'vimeo';
            // Obtiene Id de video para vimeo
            const vimeoRegex = /^.*(vimeo.com\/|video\/)(\d+).*/;
            const vimeoMatch = url.match(vimeoRegex);
            exerciseMedia.videoId = vimeoMatch ? vimeoMatch[2] : '';
        }

        return exerciseMedia;
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }

    clone(): ExerciseMedia {
        const mediaCloned = new ExerciseMedia();
        mediaCloned.id = this.id;
        mediaCloned.provider = this.provider;
        mediaCloned.videoId = this.videoId;
        mediaCloned.path = this.path;
        mediaCloned.thumbnail = this.thumbnail;
        mediaCloned.file = this.file;
        return mediaCloned;
    }
}