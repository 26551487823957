import { IsoLanguage } from "./iso-language.enum";

export enum UserLanguage {
  English = 1,
  Spanish = 2
}

export const UserLanguageCode: { [key in UserLanguage]: string } = {
  1: 'en',
  2: 'es'
};

export const UserIsoLanguage: { [key in UserLanguage]: IsoLanguage } = {
  1: IsoLanguage.enUs,
  2: IsoLanguage.esMx
};