import moment from 'moment';

import { Serializer, UserRegisterRequest, UserRegisterResponse } from "./interfaces";
import { UserType } from "./enumerations";

import { Institution } from "./institution";
import { Team } from "./team";
import { InstitutionManager } from "./institution-manager";
import { UserPermission } from './user-permission';
import { ServiceResponse } from './service-response';


export class UserRegister extends ServiceResponse implements Serializer<UserRegister>{

    constructor(
        public institution?: Institution,
        public team?: Team,
        public institutionManager?: InstitutionManager,
        public token?: string,
        public refreshToken?: string,
        public permission?: UserPermission
    ) {
        super();
    }

    fromResponse(response: UserRegisterResponse): UserRegister {

        return new UserRegister(
            null,
            null,
            null,
            response.access,
            response.refresh,
            new UserPermission().fromResponse(response.permission)
        );
    }

    toRequest(): UserRegisterRequest {
        return <UserRegisterRequest>{
            // Datos de usuario o institution manager
            type: UserType.institutionManager,
            email: this.institutionManager.email,
            password: this.institutionManager.password,
            confirm_password: this.institutionManager.passwordConfirm,
            full_name: this.institutionManager.fullName,
            country_code: 52,    // TODO: Verificar captura
            region: 1,   // TODO: Verificar captura
            phone: this.institutionManager.phone ? this.institutionManager.phone : '',
            type_of_profession: this.institutionManager.professionalType,
            // Datos de institución
            name: this.institution.name,
            identifier_name: this.institution.identifierName,
            ends: moment(this.institution.subscription.expirationDate).format('YYYY-MM-DD'),
            total_team: this.institution.subscription.totalTeams,
            total_athletes: this.institution.subscription.totalAthletes,
            total_coaches: this.institution.subscription.totalCoaches,
            institutional_enabled: true,

        }
    }

}