import { SerieIndicator } from "../models/enumerations";
import { KeyValue } from "../models/interfaces";

export const SERIE_INDICATORS: Array<KeyValue> = [
    {
        key: SerieIndicator.a.toString(),
        value: 'A'
    },
    {
        key: SerieIndicator.b.toString(),
        value: 'B'
    },
    {
        key: SerieIndicator.c.toString(),
        value: 'C'
    }
]