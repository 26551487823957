export enum IsoLanguage {
    /**
     * Inglés Estados Unidos
     */
    enUs = 'en-us',
    /**
     * Español México
     */
    esMx = 'es-mx',
    /**
     * Español España
     */
    esEs = 'es-es'
}