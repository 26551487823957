import moment from "moment";

import { Serializer, ValidityResponse } from "./interfaces";

/**
 * Estructura para objetos con vigencia: Fecha de inicio, fecha de fin
 */
export class Validity implements Serializer<Validity> {

    constructor(
        public startDate?: Date,
        public endDate?: Date
    ) { }

    fromResponse(response: ValidityResponse): Validity {
        return Validity.fromResponse(response);
    }

    static fromResponse(response: ValidityResponse): Validity {
        return new Validity(
            response.date_start ? moment(response.date_start).toDate() : null,
            response.date_end ? moment(response.date_end).toDate() : null
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}