<div class="sp-exercise-card"
    [class.sp-exercise-card--media-hovered]="mediaHover">
    <div class="sp-exercise"
        (click)="handleCardClick($event)">
        <!-- Media -->
        <sp-exercise-media *ngIf="exercise"
            class="sp-exercise-card__media"
            [exercise]="exercise"
            [stopOnMediaClick]="true"
            (mouseenter)="(exercise?.defaultMedia?.isSPF)?null:(mediaHover=true)"
            (mouseleave)="(exercise?.defaultMedia?.isSPF)?null:(mediaHover=false)"
            (mediaClick)="handleMediaClick($event)">
        </sp-exercise-media>
        <!-- Description/ options -->
        <div class="sp-exercise__footer d-flex">
            <!-- Exercise name -->
            <span class="sp-exercise__name flex-grow-1"
                [title]="exercise?.name"
                (click)="handleNameClick($event)">
                {{exercise?.name}}
            </span>
            <!-- Edit button -->
            <button *ngIf="!editOptionHidden"
                class="sp-exercise__option"
                mat-icon-button
                (click)="handleEditButtonClick($event)">
                <mat-icon svgIcon="edit"></mat-icon>
            </button>
        </div>
    </div>
</div>