export class SelectableElement {

    constructor(
        /**
         * Get or set the selected state of the element
         */
        public selected?: boolean
    ) { }

    toggleSelection(): void {
        this.selected = !this.selected;
    }
}