import { Serializer, TokenResponse } from "./interfaces";
import { UserPermission } from "./user-permission";

export class Token implements Serializer<Token>{

    constructor(
        public token?: string,
        public refreshToken?: string,
        public institutionId?: number,
        public permission?: UserPermission,
    ) { }

    fromResponse(response: TokenResponse): Token {
        return new Token(
            response.access,
            response.refresh,
            response.institution,
            new UserPermission().fromResponse(response.permission)
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}