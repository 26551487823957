import moment from "moment";

import { Serializer, WidgetProgramResponse } from "./interfaces";

export class WidgetProgram implements Serializer<WidgetProgram> {

    constructor(
        public programId?: number,
        public name?: string,
        public starts?: Date,
        public ends?: Date,
        public teams: Array<number> = []
    ) { }

    fromResponse(response: WidgetProgramResponse): WidgetProgram {
        return new WidgetProgram(
            response.id,
            response.name,
            moment(response.starts).toDate(),
            moment(response.ends).toDate(),
            response.teams
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}