import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { ProfessionalType, ProfessionalTypesData } from './../models';
import { ProfessionalTypesDataResponse } from '../models/interfaces';

@Injectable()
export class CatalogService {

  constructor(
    private api: ApiService
  ) { }

  getProfessionalTypes(
    language?: string
  ): Observable<Array<ProfessionalType>> {
    return this.getPaginatedProfessionalTypes(
      language
    ).pipe(
      map(data => data.data)
    );
  }

  getPaginatedProfessionalTypes(
    language?: string
  ): Observable<ProfessionalTypesData> {

    let params = new HttpParams();

    if (language) {
      params = params.set('languaje', language);
    }

    return this.api
      .get<ProfessionalTypesDataResponse>(
        'catalog-profesion/',
        params
      ).pipe(
        map(response => new ProfessionalTypesData().fromResponse(response))
      );
  }
}
