export enum ExerciseCardClickTarget {
    /**
     * Cuando se hace click en cualquier parte de la tarjeta
     */
    default,
    /**
     * Cuando se hace click sobre el video, imagen del ejercicio
     */
    media,
    /**
     * Cuando se hace click sobre el nombre del ejercicio
     */
    name,
    /**
     * Cuando se hace click sobre el botón editar
     */
    editButton
}