<div cdkDropList
    (cdkDropListDropped)="dropWeek($event)">
    <!-- Week list-->
    <div *ngFor="let week of weeks; let i = index;"
        class="sp-weeks-days__week"
        [class.sp-weeks-days__week--selected]="week.id===selectedWeekId"
        cdkDrag
        [cdkDragDisabled]="!weeksSortable">
        <ng-container *ngIf="!week.isRemoved">
            <!-- Week -->
            <span class="sp-weeks-days__week-text">
                Week {{week.order}}
            </span>
            <!-- Days container -->
            <div class="sp-weeks-days__days">
                <!-- Days -->
                <ng-container *ngFor="let day of week.days">
                    <ng-container *ngIf="multipleDayItems;else singleItems">
                        <button type="button"
                            class="sp-weeks-days__day"
                            mat-button
                            [style.opacity]="day.selected?1:0.7"
                            [matBadge]="day.itemsCount"
                            [matBadgeColor]="'accent'"
                            [matBadgeHidden]="day.itemsCount<=1"
                            [class.mat-flat-button]="(day.selected || day.itemsCount>0)"
                            [color]="(day.selected || day.itemsCount>0) ?'primary':null"
                            (click)="onDayClick(day, week)">
                            {{day.day | day:true}}
                        </button>
                    </ng-container>
                    <ng-template #singleItems>
                        <button type="button"
                            class="sp-weeks-days__day"
                            mat-button
                            [class.mat-flat-button]="day.selected"
                            [color]="day.selected?'primary':null"
                            (click)="onDayClick(day, week)">
                            {{day.day | day:true}}
                        </button>
                    </ng-template>
                </ng-container>
                <!-- Actions -->
                <div class="sp-weeks-days__actions d-flex">
                    <!-- Clone week -->
                    <button *ngIf="weeksClonable"
                        type="button"
                        class="sp-weeks-days__action-button"
                        mat-icon-button
                        [matTooltip]="'Clone'"
                        (click)="onCloneWeekClick(i)">
                        <svg width="12"
                            height="14"
                            viewBox="0 0 24 24"
                            width="100%"
                            fill="#6E788F"
                            xmlns="http://www.w3.org/2000/svg"
                            fit=""
                            preserveAspectRatio="xMidYMid meet"
                            focusable="false">
                            <path d="M0 0h24v24H0z"
                                fill="none"></path>
                            <path
                                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z">
                            </path>
                        </svg>
                    </button>
                    <!-- Remove week -->
                    <button *ngIf="weeksRemovable"
                        type="button"
                        class="sp-weeks-days__action-button"
                        mat-icon-button
                        [matTooltip]="'Remove'"
                        (click)="onRemoveWeekClick(i)">
                        <svg width="12"
                            height="14"
                            viewBox="0 0 12 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2 2.94824L2.64455 12.9218C2.65701 13.1147 2.80216 13.264 2.97704 13.264H9.02293C9.19787 13.264 9.343 13.1147 9.35547 12.9218L10 2.94824"
                                stroke="#6E788F"
                                stroke-width="1.5"
                                stroke-linecap="round" />
                            <path d="M0.666016 2.94824H11.3327"
                                stroke="#6E788F"
                                stroke-width="1.5"
                                stroke-linecap="round" />
                            <path d="M4.66602 5.89551V10.3166"
                                stroke="#6E788F"
                                stroke-width="1.5"
                                stroke-linecap="round" />
                            <path d="M7.33203 5.89551V10.3166"
                                stroke="#6E788F"
                                stroke-width="1.5"
                                stroke-linecap="round" />
                            <path
                                d="M8 2.94783C8 1.72699 7.1046 0.737305 6 0.737305C4.8954 0.737305 4 1.72699 4 2.94783"
                                stroke="#6E788F"
                                stroke-width="1.5"
                                stroke-linecap="round" />
                        </svg>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- week row add-->
<div *ngIf="checkNewWeekOption()"
    class="sp-weeks-days__week sp-weeks-days__week--new"
    #newWeek>
    <span class="sp-weeks-days__week-text">
        Week {{lastOrder}}
    </span>
    <div class="sp-weeks-days__days">
        <button *ngFor="let day of week.days"
            type="button"
            class="sp-weeks-days__day"
            mat-button
            (click)="addWeek(day)">
            {{day | day:true}}
        </button>
    </div>
</div>