import { CurrencyISO } from "./enumerations";

/**
 * Catálogo de monedas propias de Solo Performance
 * ```
 * En backend equivale a una lista estática o enumeración
 * Se crea una clase para asignarle el código ISO de moneda
 * ```
 */
export class SPFCurrency {

    constructor(
        public id?: number,
        public name?: string,
        public currencyISO?: CurrencyISO
    ) { }

    /**
     * Obtiene lista estática de monedas para Solo Performance
     * @returns Colección de monedas
     */
    static get currencies(): Array<SPFCurrency> {
        return [
            {
                id: 1,
                name: 'Euro',
                currencyISO: CurrencyISO.EUR
            },
            {
                id: 2,
                name: 'Dollar',
                currencyISO: CurrencyISO.USD
            },
            {
                id: 3,
                name: 'Mexican peso',
                currencyISO: CurrencyISO.MXN
            },
            {
                id: 4,
                name: 'Australian dollar',
                currencyISO: CurrencyISO.AUD
            },
            {
                id: 5,
                name: 'Brazilian real',
                currencyISO: CurrencyISO.BRL
            },
            {
                id: 6,
                name: 'Canadian dollar',
                currencyISO: CurrencyISO.CAD
            },
            {
                id: 7,
                name: 'Japanese yen',
                currencyISO: CurrencyISO.JPY
            },
            {
                id: 8,
                name: 'New Zealand dollar',
                currencyISO: CurrencyISO.NZD
            },
            {
                id: 9,
                name: 'Dolar Hongkonés',
                currencyISO: CurrencyISO.HKD
            },
            {
                id: 10,
                name: 'Singapore dollar',
                currencyISO: CurrencyISO.SGD
            }
        ];
    }

    /**
     * Obtiene la moneda que corresponde al identificador indicado
     * @param currencyId Identificador de moneda
     * @returns Moneda Solo Performance
     */
    static getById(currencyId: number): SPFCurrency {
        return this.currencies.find(x => x.id === currencyId);
    }
}