<form class="sp-request-name-dialog"
    [formGroup]="form">
    <!-- Title -->
    <h1 mat-dialog-title>{{title || ('common.name' | i18next)}}</h1>
    <!-- Message -->
    <div class="sp-dialog__content"
        mat-dialog-content>
        <mat-form-field class="w-100"
            appearance="outline">
            <mat-label>{{'common.name' | i18next}}</mat-label>
            <input matInput
                cdkFocusInitial
                formControlName="name"
                [placeholder]="'common.name' | i18next">
        </mat-form-field>
    </div>
    <!-- Action buttons -->
    <div class="justify-content-end"
        mat-dialog-actions
        style="box-sizing:initial">
        <button type="button"
            mat-button
            color="primary"
            [mat-dialog-close]="null">
            {{'common.actions.cancel' | i18next}}
        </button>
        <button type="submit"
            mat-flat-button
            color="primary"
            [disabled]="form.invalid"
            [mat-dialog-close]="form.get('name').value"
            cdkFocusInitial>
            {{'common.actions.save' | i18next}}
        </button>
    </div>
</form>