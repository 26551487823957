import { Serializer, WorkoutLogAthleteInfoRequest } from "./interfaces";

import { WorkoutJsonData } from "./workout-json-data";

export class WorkoutLogAthleteInfo implements Serializer<WorkoutLogAthleteInfo> {

    constructor(
        public athleteId?: number,
        /**
         * TODO: Verificar si se utiliza
         */
        public RPE?: number,
        public jsonData?: WorkoutJsonData
    ) { }

    fromResponse(response: any): WorkoutLogAthleteInfo {
        throw new Error("Method not implemented.");
    }

    toRequest(): WorkoutLogAthleteInfoRequest {
        return <WorkoutLogAthleteInfoRequest>{
            athlete_id: this.athleteId,
            rpe: this.RPE,
            jsonData: this.jsonData.toRequest()
        }
    }
}