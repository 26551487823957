// Login, registro, recuperar contraseña
export * from './sp-login';

// Tarjeta
export * from './sp-card';

// Diálogo
export * from './sp-dialog';

// Estructura de encabezado y grupos de encabezado
export * from './sp-header';

// Campo de búsqueda
export * from './sp-input-search';

// Panel
export * from './sp-panel';

// Spinner
export * from './sp-progress-spinner';

// Toggle button
export * from './sp-button-toggle';

// Menú
export * from './sp-menu';

// Selección de semanas y días
export * from './sp-weeks-days';

// Notificaciones tipo toastr o swal
export * from './sp-notification';

// Campo editable. Se visualiza como label y posteriormente se vuelve campo de captura
export * from './sp-editable';

// Table
export * from './sp-table';

// Tags
export * from './sp-tags';

// Buttons
export * from './sp-button';

// Componentes youtube
export * from './sp-youtube';

// Componentes para subir video, imágenes
export * from './sp-upload';

// Componentes de institución
export * from './sp-institution';

// Componentes de ejercicio
export * from './sp-exercise';

export * from './sp-photo-editor';

// Componente para heredar funcionalidad de scroll
export * from './sp-scrollable';

// Paquetes de terceros. Temporal mientras se espera su actualización en npm para versión de angular 13
export * from './ngx-drag-scroll';
export * from './ngx-mat-intl-tel-input';
