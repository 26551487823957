import { Serializer, WidgetMetadataTeamsResponse } from "./interfaces";
import { WidgetMetadataTeam } from "./widget-metadata-team";

export class WidgetMetadataTeams implements Serializer<WidgetMetadataTeams>{

    constructor(
        public institutionId?: number,
        public teams?: Array<WidgetMetadataTeam>
    ) { }

    static fromResponse(response: WidgetMetadataTeamsResponse): WidgetMetadataTeams {
        return new WidgetMetadataTeams(
            response.institution_id,
            (response.teams && response.teams.length)
                ? response.teams.map(x => WidgetMetadataTeam.fromResponse(x))
                : []
        )
    }

    fromResponse(response: WidgetMetadataTeamsResponse): WidgetMetadataTeams {
        return WidgetMetadataTeams.fromResponse(response);
    }

    toRequest(): WidgetMetadataTeamsResponse {
        return <WidgetMetadataTeamsResponse>{
            institution_id: this.institutionId,
            teams: (this.teams && this.teams.length)
                ? this.teams.map(x => x.toRequest())
                : []
        }
    }
}