import { Serializer, WidgetDataFilterResponse } from './interfaces';

import { WidgetData } from './widget-data';

export class WidgetDataFilter implements Serializer<WidgetDataFilter> {

    constructor(
        public catalogId?: number,
        public data: Array<WidgetData> = []
    ) { }

    fromResponse(response: WidgetDataFilterResponse): WidgetDataFilter {
        return new WidgetDataFilter(
            +response.objId,
            response.data.map(x => new WidgetData().fromResponse(x))
        )
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }
}