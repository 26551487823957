import moment from 'moment';

import { SubscriptionInterval, SubscriptionType } from './enumerations';
import { Serializer, SubscriptionResponse } from "./interfaces";

import { StripeSubscription } from './stripe-subscription';
import { SubscriptionDowngrade } from './subscription-downgrade';
import { SubscriptionProduct } from './subscription-product';

export class Subscription implements Serializer<Subscription> {

    /**
     * Precio a visualizar. El precio en stripe se maneja en centavos
     */
    get priceView(): number {
        return this.price / 100;
    }

    /**
     * Obtiene los días restantes para que venza la suscripción
     * @returns 
     */
    get leftDays(): number {
        if (!this.ends) return 0;
        return moment(this.ends).diff(moment(), 'days') + 1;
    }

    /**
     * Obtiene si la suscripción no está asignado (sólo se inicializa pero no tiene identificador)
     */
    get isEmpty(): boolean {
        return !this.id;
    }

    /**
     * Obtiene si la suscripción tendrá opción de actualizarse (upgrade/ downgrade)
     */
    get isUpgradeable(): boolean {
        return this.type === SubscriptionType.paying || this.type === SubscriptionType.custom
    }

    /**
     * Obtiene si la suscripción tiene una actualización downgrade aplicada.
     */
    get hasDowngrade(): boolean {
        return (this.downgrade && this.downgrade.productId)
            ? true
            : false;
    }

    constructor(
        /**
         * Identificador de la suscripción en backend
         */
        public id?: number,
        /**
         * Type of subscription
         */
        public type = SubscriptionType.trial,
        /**
         * Fecha de expiración del plan especificada para el tipo de usuario: Trial, Paying, Demo.
         */
        public expirationDate?: Date,
        public total?: number,
        public tax?: number,
        public price?: number,
        public stripeFee?: number,
        /**
         * Total de atletas permitido según el tipo de usuario
         */
        public totalAthletes = 0,
        /**
         * Total de coaches permitido según el tipo de usuario
         */
        public totalCoaches = 0,
        /**
         * Total de extra coaches sumarizados de todas las subscripciones
         */
        public totalExtraCoaches = 0,
        /**
         * Total de equipos permitidos según el tipo de usuario. Para trial siempre será 1.
         */
        public totalTeams = 0,
        /**
         * Fecha de creación del registro
         */
        public createdAt?: Date,
        public updatedAt?: Date,

        public currency?: string,
        public interval = SubscriptionInterval.month,

        public hasRenewable = false,
        public hasCancelled = false,
        public isActive = true,
        public isPayed = false,

        /**
         * Inicio de la suscripción.
         */
        public starts?: Date,
        /**
         * Fin de la suscripción.
         */
        public ends?: Date,
        /**
         * Fecha de creación de la suscripción.
         */
        public created?: Date,
        /**
         * Stripe data subscription
         */
        public stripe?: StripeSubscription,
        /**
         * Obtiene los datos correspondientes a una actualización downgrade de la suscripción
         */
        public downgrade?: SubscriptionDowngrade,
        /**
         * Obtiene o establece el conteo de los coaches actualmente activos en la aplicación
         */
        public currentCoaches?: number,
        /**
         * Obtiene o establece el conteo de los atletas actualmente activos en la aplicación
         */
        public currentAthletes?: number,
        public product?: SubscriptionProduct
    ) { }

    fromResponse(response: SubscriptionResponse): Subscription {
        return new Subscription(
            response.id,
            response.type,
            response.ends ? new Date(response.ends * 1000) : null,
            response.total,
            response.tax,
            response.price,
            response.fee_stripe,
            response.total_athletes,
            response.total_coaches,
            response.extra_coaches || 0,
            response.total_team,
            response.created_at ? moment(response.created_at, false).toDate() : null,
            response.updated_at ? moment(response.updated_at, false).toDate() : null,
            response.currency,
            response.interval as SubscriptionInterval,
            response.has_renewable,
            response.has_cancelled,
            response.is_active,
            response.is_payed,
            /**
             * Fecha de inicio de suscripción. Se convierte a milisegundos para conversión posterior a fecha
             */
            response.starts ? new Date(response.starts * 1000) : null,
            /**
             * Fecha de fin de suscripción. Se convierte a milisegundos para conversión posterior a fecha
             */
            response.ends ? new Date(response.ends * 1000) : null,
            response.created ? new Date(response.created * 1000) : null,
            new StripeSubscription().fromResponse(response),
            new SubscriptionDowngrade().fromResponse(response),
            (response.active_coaches || 0),
            (response.active_athletes || 0),
            null
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }

    /**
     * Genera la estructura de una suscripción tipo trial
     * @returns Una subscripción de tipo trial
     * @author  José Chin
     */
    static trialSubscription(): Subscription {

        const subscription = new Subscription();
        subscription.type = SubscriptionType.trial;
        // Fecha de expiración
        let currentDate = moment();
        subscription.expirationDate = currentDate.add(14, 'days').toDate();
        // Total de equipos.
        subscription.totalTeams = 1;
        // TODO: Verificar lógica de negocio
        subscription.totalAthletes = 10;
        subscription.totalCoaches = 10;
        subscription.hasRenewable = false;

        return subscription;
    }
}
