import { User } from './user';

import { Data } from './data';

/**
 * TODO: renombrar a UsersData para estandarizar con los otros modelos de éste tipo
 */
export class Users extends Data<User> {
    constructor() {
        super(User)
    }
}