/**
 * Aplicaciones o proyectos SoloPerformance
 */
export enum Application {
    /**
     * Panel administrativo
     * ```
     * Prefijo admin
     * ```
     */
    admin = 1,
    /**
     * Dashboard/Web
     * ```
     * Prefijo web
     * ```
     */
    dashboard
}