import {NationalityResponse, Serializer} from './interfaces';
import {PHONE_CODES} from '../constants';

export class Nationality implements Serializer<Nationality> {
  get isoCode(): string{
    return this.code?.toLowerCase?.() ?? '';
  }

  constructor(
    public id?: number,
    public englishName?: string,
    public spanishName?: string,
    public code?: string,
    public dialCode?: string,
  ) {
  }

  fromResponse(response: NationalityResponse): Nationality {
    return new Nationality(
      response.id,
      response.english_name,
      response.spanish_name,
      response.code,
      response.dial_code
    );
  }

  toRequest(): any {
    throw new Error('Method not implemented.');
  }

}
