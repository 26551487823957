import { SERIE_INDICATORS } from "../constants";
import { SerieIndicator } from "./enumerations";
import { ExerciseBlockResponse, KeyValue, Serializer } from "./interfaces";

export class ExerciseBlockIndicator implements KeyValue, Serializer<ExerciseBlockIndicator> {

    constructor(
        public key = SerieIndicator.a.toString(),
        public value = 'A',
        public consecutive?: number
    ) { }

    fromResponse(response: ExerciseBlockResponse): ExerciseBlockIndicator {
        const indicator = new ExerciseBlockIndicator();
        const serieFound = SERIE_INDICATORS.filter(x => x.value.toLowerCase() === response.priority.toLowerCase())
        if (serieFound.length) {
            indicator.key = serieFound[0].key;
            indicator.value = serieFound[0].value;
        }
        return indicator;
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}