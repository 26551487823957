import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Exercise } from 'sp-core';

import { ExerciseCardClickEvent, ExerciseCardClickTarget } from '../models';
import { ExerciseMediaComponent } from '../exercise-media/exercise-media.component';

@Component({
  selector: 'sp-exercise-card',
  templateUrl: './exercise-card.component.html',
  styleUrls: ['./exercise-card.component.scss']
})
export class ExerciseCardComponent {

  @Input() exercise: Exercise;

  @Input() editOptionHidden = false;

  @Output() exerciseClick = new EventEmitter<ExerciseCardClickEvent>();

  @Output() mediaPlayingChange = new EventEmitter<Exercise>();

  @ViewChild(ExerciseMediaComponent) exerciseMedia: ExerciseMediaComponent;

  ExerciseCardClickTarget = ExerciseCardClickTarget;

  mediaHover = false;

  handleCardClick(e: PointerEvent): void {
    this.handleElementClick(e, ExerciseCardClickTarget.default);
  }

  handleMediaClick(e: PointerEvent): void {
    this.handleElementClick(e, ExerciseCardClickTarget.media);
  }

  handleNameClick(e: PointerEvent): void {
    this.handleElementClick(e, ExerciseCardClickTarget.name);
  }

  handleEditButtonClick(e: PointerEvent): void {
    this.handleElementClick(e, ExerciseCardClickTarget.editButton);
  }

  private handleElementClick(
    e: PointerEvent,
    targetType: ExerciseCardClickTarget
  ): void {

    e.stopPropagation();

    if (this.exerciseMedia && this.exerciseMedia.isPlaying) {
      // Cuando el video es de youtube o vimeo detener el video provoca problemas. Se visualiza el botón propio de youtube y al intentar reproducir no iniciaba de nuevo (Se necesitaba hacer un clic adicional)
      if (this.exercise.defaultMedia?.isExternalMedia) this.exerciseMedia.initialize();
      else this.exerciseMedia.stopVideo();
    }

    this.exerciseClick.emit(<ExerciseCardClickEvent>{
      targetType: targetType,
      exercise: this.exercise
    });
  }
}
