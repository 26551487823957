<div class="sp-login">
    <!-- Backdrop -->
    <div class="sp-login__backdrop">
        <ng-scrollbar>
            <div class="sp-login__container">
                <!-- Logotipo -->
                <img src="assets/images/solo-performance.svg"
                    alt="Solo Performance"
                    class="sp-login__logo">
                <!-- Formulario -->
                <form [formGroup]="form"
                    class="sp-login__form "
                    cdkTrapFocus
                    [cdkTrapFocusAutoCapture]="true">

                    <!-- Nombre de usuario -->
                    <mat-form-field appearance="outline"
                        class="sp-login_form-field">
                        <mat-label>{{data.texts.email}}</mat-label>
                        <input class="sp-login__email"
                            type="email"
                            [placeholder]="data.texts.email"
                            matInput
                            [formControlName]="CONTROL_NAMES.username"
                            cdkFocusRegionStart
                            cdkFocusInitial>
                        <mat-error *ngIf="usernameCtrl.hasError('required')">
                            {{data.texts.requiredValidationMessage}}
                        </mat-error>
                        <mat-error *ngIf="usernameCtrl.hasError('email')">
                            {{data.texts.validEmailMessage}}
                        </mat-error>
                    </mat-form-field>

                    <!-- Password/ confirm password -->
                    <div class="sp-register__password-form"
                        [formGroupName]="CONTROL_NAMES.passwordForm">
                        <!-- Password -->
                        <mat-form-field appearance="outline"
                            class="sp-login__form-field">
                            <mat-label>{{data.texts.password}}</mat-label>
                            <input #passwordInput
                                [type]="passwordShown.password?'text':'password'"
                                [placeholder]="data.texts.password"
                                matInput
                                [formControlName]="CONTROL_NAMES.password">
                            <mat-icon class="sp-login__form-field-suffix"
                                matSuffix
                                [svgIcon]="passwordShown.password?'visibility-off':'visibility'"
                                (click)="passwordShown.password=!passwordShown.password">
                            </mat-icon>
                            <mat-error *ngIf="passwordCtrl.hasError('required')">
                                {{data.texts.requiredValidationMessage}}
                            </mat-error>
                        </mat-form-field>
                        <!-- Confirm password -->
                        <mat-form-field appearance="outline"
                            class="sp-login__form-field">
                            <mat-label>{{data.texts.passwordConfirm}}</mat-label>
                            <input #passwordInput
                                [type]="passwordShown.confirm?'text':'password'"
                                [placeholder]="data.texts.passwordConfirm"
                                matInput
                                [formControlName]="CONTROL_NAMES.passwordConfirm"
                                [errorStateMatcher]="parentErrorStateMatcher">
                            <mat-icon class="sp-login__form-field-suffix"
                                matSuffix
                                [svgIcon]="passwordShown.confirm?'visibility-off':'visibility'"
                                (click)="passwordShown.confirm=!passwordShown.confirm">
                            </mat-icon>
                            <mat-error *ngIf="passwordConfirmCtrl.hasError('required')">
                                {{data.texts.requiredValidationMessage}}
                            </mat-error>
                            <mat-error *ngIf="passwordForm.hasError('match')">
                                {{data.texts.matchValidationMessage}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Recaptcha -->
                    <div class="sp-login__recaptcha">
                        <ngx-recaptcha2 *ngIf="recaptchaCtrl"
                            [siteKey]="siteKey"
                            [formControlName]="CONTROL_NAMES.recaptcha"
                            (ready)="handleRecaptchaReady()">
                        </ngx-recaptcha2>
                        <div class="sp-spinner"
                            [class.sp-spinner--loading]="recaptchaLoading">
                            <sp-progress-spinner [isRunning]="recaptchaLoading"
                                size="small">
                            </sp-progress-spinner>
                        </div>
                    </div>

                    <!-- Register/ signup -->
                    <button mat-flat-button
                        type="submit"
                        color="primary"
                        [disabled]="form.invalid || registering"
                        (click)="onRegisterClick()"
                        cdkFocusRegionEnd>
                        {{userToActivate?'Set password':'Register'}}
                    </button>

                    <!-- Message error -->
                    <span class="sp-login__message-error"
                        [class.sp-login__message-error--shown]="errorMessage"
                        [class.sp-login__message-error--registering]="registering">
                        {{errorMessage}}
                    </span>

                    <!-- Go to login -->
                    <div class="sp-go-to-login d-flex justify-content-center">
                        <span class="sp-go-to-login__question">I have a user</span>
                        <a class="sp-link ml-1"
                            (click)="onGoToLoginClick()">
                            Login
                        </a>
                    </div>

                    <!-- Separator -->
                    <div class="sp-login__separator d-flex align-items-center justify-content-center">
                        <span class="sp-login__separator-line"></span>
                        <span class="sp-login__separator-text">OR</span>
                    </div>
                </form>

                <!-- Social media login -->
                <div class="sp-social-options d-block w-100">
                    <sp-social-logins></sp-social-logins>
                </div>
            </div>
        </ng-scrollbar>
    </div>
</div>