import { UntypedFormGroup, ValidationErrors } from "@angular/forms";

import { EXERCISE_DETAIL_CONTROL_NAMES } from './exercise-detail.constants';

export class ExerciseDetailValidators {

    /**
     * Valida que al menos un campo de nombre (English, Spanish) tenga dato
     * @param formGroup Formulario con los campos de nombres español e inglés
     * @returns 
     */
    static oneNameRequired(formGroup: UntypedFormGroup): ValidationErrors | null {

        return (formGroup.get(EXERCISE_DETAIL_CONTROL_NAMES.namesGroupEnglish).value || formGroup.get(EXERCISE_DETAIL_CONTROL_NAMES.namesGroupSpanish).value)
            ? null
            : { 'required': true };
    }
}