import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Application, Exercise } from 'sp-core';

import { ExerciseCardClickEvent, ExerciseCardClickTarget, ExerciseDetailConfig } from '../models';
import { ExerciseDetailComponent, ExerciseDetailData } from '../exercise-detail';


@Component({
  selector: 'sp-exercise-card-list',
  templateUrl: './exercise-card-list.component.html',
  styleUrls: ['./exercise-card-list.component.scss']
})
export class ExerciseCardListComponent implements OnInit {

  @Input() application = Application.admin;

  @Input() exercises: Array<Exercise> = [];

  @Input() detailConfig: ExerciseDetailConfig;

  @Output() afterDetailClosed = new EventEmitter<any>();

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void { }

  handleExerciseCardClick(
    event: ExerciseCardClickEvent
  ): void {

    // Si se hizo click sobre el objeto media y es youtube/vimeo no se procede a abrir el detalle
    if (event.targetType === ExerciseCardClickTarget.media && event.exercise.defaultMedia?.isExternalMedia) {
      return;
    }

    this.dialog.open(ExerciseDetailComponent, {
      width: '880px',
      maxWidth: '880px',
      data: <ExerciseDetailData>{
        application: this.application,
        exercise: event.exercise,
        exercisesNavigation: this.exercises,
        config: this.detailConfig
      }
    }).afterClosed().subscribe(result => {
      this.afterDetailClosed.emit(result);
    });
  }
}
