import { Serializer, UserProfileResponse } from "./interfaces";

import { Galery } from "./galery";
import { Nationality } from "./nationality";
import { UserProfileEducation } from "./user-profile-education";
import { UserProfileExperience } from "./user-profile-experience";
import { InstitutionalImage } from "./institutional-image";

export class UserProfile implements Serializer<UserProfile>{

    constructor(
        public id?: number,
        public photo?: string,
        public fullName?: string,
        public description?: string,
        public speciality?: number,
        public companyName?: string,
        public fiscalId?: string,
        public monthlyPlan?: { amount: string },
        public videoCall?: { amount: string },
        public appearMarketplace?: boolean,
        public appearCoachesList?: boolean,
        public timeZone?: string,
        public nationality?: Nationality,
        public institutionalImage?: InstitutionalImage,
        public tags: Array<string> = [],
        public galery: Array<Galery> = [],
        public education: Array<UserProfileEducation> = [],
        public experience: Array<UserProfileExperience> = []
    ) { }

    fromResponse(response: UserProfileResponse): UserProfile {
        return UserProfile.fromResponse(response);
    }

    static fromResponse(response: UserProfileResponse): UserProfile {
        return new UserProfile(
            response.id,
            response.photo,
            response.full_name,
            response.description,
            response.speciality,
            response.company_name,
            response.fiscal_id,
            response.monthly_plan,
            response.video_call,
            response.appear_marketplace,
            response.appear_coaches_list,
            response.time_zone,
            response.nationality
                ? new Nationality().fromResponse(response.nationality)
                : null,
            response.institution_image
                ? InstitutionalImage.fromResponse(response.institution_image)
                : null,
            response.tags,
            response.galery ? response.galery.map(x => Galery.fromResponse(x)) : [],
            response.education ? response.education.map(x => UserProfileEducation.fromResponse(x)) : [],
            response.experience ? response.experience.map(x => UserProfileExperience.fromResponse(x)) : [],
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}