import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { A11yModule } from '@angular/cdk/a11y';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxCaptchaModule } from 'ngx-captcha';

import { SpDialogModule } from '../sp-dialog/sp-dialog.module';
import { SpProgressSpinnerModule } from '../sp-progress-spinner';

import { SpLoginService } from './services/sp-login.service';

import { SocialLoginsComponent } from './social-logins/social-logins.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const COMPONENTS = [
  SocialLoginsComponent,
  LoginComponent,
  ForgotPasswordComponent,
  RegisterComponent,
  ChangePasswordComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    NgScrollbarModule,
    NgxCaptchaModule,
    A11yModule,
    SpDialogModule,
    SpProgressSpinnerModule
  ],
  providers: [
    SpLoginService
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SpLoginModule {
  constructor(
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry
  ) {
    this.iconRegistry.addSvgIcon('visibility', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/visibility.svg'));
    this.iconRegistry.addSvgIcon('visibility-off', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/visibility-off.svg'));
  }
}
