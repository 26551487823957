import { Component, Input, OnInit } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'sp-more-options',
  templateUrl: './more-options.component.html',
  styleUrls: ['./more-options.component.scss']
})
export class MoreOptionsComponent {

  @Input() primary = false;

  @Input() menu: MatMenu;
}
