import { ExerciseSubparameterType, InputValueControlType, InputValueType } from './enumerations';
import { ExerciseSubparameterResponse, KeyValue, Serializer } from './interfaces';

export class ExerciseSubparameter implements Serializer<ExerciseSubparameter> {

    /**
     * Según el tipo de subparámetro retorna el valor inicial que se asignará
     * ```
     * El tipo de dato nativo se maneja como cadena
     * ```
     */
    get initialValue(): string {
        switch (this.type) {
            case ExerciseSubparameterType.num:
                return '0';
            case ExerciseSubparameterType.numericCatalog:
                return '0';
            case ExerciseSubparameterType.time:
                return this.valueType === InputValueType.time ? '00:00' : '0:0:0';
            case ExerciseSubparameterType.percentage:
                return '0';
            case ExerciseSubparameterType.percentageCatalog:
                return '0';
            case ExerciseSubparameterType.decimal:
                return '0';
            default:
                // TODO: Verificar valor inicial para ExerciseSubparameterType.choice
                // TODO: Verificar valor inicial para ExerciseSubparameterType.characterOfEffort
                return '';
        }
    }

    constructor(
        public id?: number,
        public measure?: string,
        public title?: string,
        public type?: ExerciseSubparameterType,
        public parameterId?: number,
        /**
         * Indica si el elemento está o no seleccionado.
         * NOTA: No se obtiene de backend. Es un campo auxiliar para frontend
         */
        public isSelected?: boolean,
        /**
         * Indica si el subparámetro es un rango de rango. Sólo aplica para Character of effort
         */
        public isRangeOfRange = false,
        /**
         * Tipo de dato para el campo de captura
         * NO se obtiene del backend. Es un campo auxiliar para frontend
         */
        public valueType = InputValueType.text,
        /**
         * 
         * NO se obtiene del backend. Es un campo auxiliar para frontend
         */
        public controlType = InputValueControlType.input,
        /**
         * DataSource en caso de que sea del tipo catálogo
         */
        public dataSource: Array<KeyValue> = []
    ) { }

    fromResponse(response: ExerciseSubparameterResponse): ExerciseSubparameter {
        const subparameter = new ExerciseSubparameter(
            response.id,
            response.measure,
            response.title,
            response.type,
            response.block_exercise_catalog
        );
        // Valor vacío para visualizar en modo sólo lectura.
        switch (subparameter.type) {
            case ExerciseSubparameterType.num:
                subparameter.valueType = InputValueType.int;
                subparameter.controlType = InputValueControlType.input;
                break;
            case ExerciseSubparameterType.numericCatalog:
                subparameter.valueType = InputValueType.int;
                subparameter.controlType = InputValueControlType.select;
                this.setDataSource(subparameter);
                break;
            case ExerciseSubparameterType.time:
                subparameter.valueType = InputValueType.time;
                subparameter.controlType = InputValueControlType.input;
                // Caso especial para parámetro tempo
                if (subparameter.id === 37) {
                    subparameter.valueType = InputValueType.tempo;
                }
                break;
            case ExerciseSubparameterType.choice:
                subparameter.valueType = InputValueType.text;
                subparameter.controlType = InputValueControlType.input;
                break;
            case ExerciseSubparameterType.percentage:
                subparameter.valueType = InputValueType.int;
                subparameter.controlType = InputValueControlType.input;
                break;
            case ExerciseSubparameterType.characterOfEffort:
                subparameter.valueType = InputValueType.int;
                subparameter.controlType = InputValueControlType.input;
                // Indica que el subparámetro podría solicitar rango de rango
                subparameter.isRangeOfRange = true;
                break;
            case ExerciseSubparameterType.percentageCatalog:
                subparameter.valueType = InputValueType.int;
                subparameter.controlType = InputValueControlType.input;
                break;
            case ExerciseSubparameterType.decimal:
                subparameter.valueType = InputValueType.decimal;
                subparameter.controlType = InputValueControlType.input;
                break;
        }
        return subparameter;
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }

    clone(): ExerciseSubparameter {
        return new ExerciseSubparameter(
            this.id,
            this.measure,
            this.title,
            this.type,
            this.parameterId,
            this.isSelected,
            this.isRangeOfRange,
            this.valueType,
            this.controlType,
            this.dataSource
        );
    }

    private setDataSource(subparameter: ExerciseSubparameter): void {
        const dataSource: Array<KeyValue> = [];
        const range = subparameter.measure.split('-');
        for (let index = +range[0]; index <= +range[1]; index++) {
            const item = <KeyValue>{
                key: index.toString(),
                value: index.toString()
            }
            dataSource.push(item);
        }
        subparameter.dataSource = dataSource;
    }
}