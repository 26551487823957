import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class ParamsService {

  get = new BehaviorSubject(null);
  params: HttpParams;


  constructor(
    private RT: Router,
    private AR: ActivatedRoute,
  ) {
    this.AR.queryParams.subscribe(
      (params: Params) => {
        let prms: HttpParams = new HttpParams()
        Object.keys(params).forEach(function(key) {
          prms = prms.append(key, params[key]);
        });
        this.params = prms;
        this.get.next(true);
      }
    );
  }


  /**
   * Limpia todos los params de la url
   * @author Martin Batun Tec.
  */
  clean(): void {
    let prms: object;
    this.params.keys().forEach(key => {
      prms = { ...prms, ...{ [key]: null } };
    });
    this.set(prms)
  }


  /**
   * Sete nuevos params a la url.
   * @author Martin Batun Tec.
  */
  set(prms: any): void {
    const urlTree = this.RT.createUrlTree([], {
      queryParams: prms,
      queryParamsHandling: "merge",
      preserveFragment: true
    });
    this.RT.navigateByUrl(urlTree);
  }
}
