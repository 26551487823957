import {ExerciseMediaResponse, Serializer} from './interfaces';
import {
  OnlineEducationResponse,
  OnlineCourseResponse,
  OnlineVideoResponse,
  OnlineCourseVideoToRequest,
  OnlineCourseVideoFromResponse
} from './interfaces';
import {AuditUser} from './audit-user';
import {ExerciseMedia} from './exercise-media';
import {PublishStatus} from './enumerations';

export class OnlineEducation implements Serializer<OnlineEducation> {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public tags?: Array<string>,
    public oneTime?: {
      id: number;
      amount: number;
      currency_code: number;
      currency_text: string;
    } | null,
    public media?: Array<ExerciseMedia>,
    public acquired?: boolean,
    public status?: PublishStatus,
    public audit?: AuditUser,
  ) {
  }

  fromResponse(response: OnlineEducationResponse): OnlineEducation {
    return new OnlineEducation(
      response.id,
      response.name,
      response.description,
      response.tags,
      response.one_time,
      response.media ? response.media.map((m) => new ExerciseMedia().fromResponse(m)) : [],
      response.acquired,
      response.status,
      new AuditUser().fromResponse(response)
    );
  }

  toRequest(): OnlineEducationResponse {
    return {
      name: this.name,
      description: this.description,
      tags: this.tags,
      one_time: this.oneTime,
      media: this.media.map(exerciseMedia => {
        return {
          path: exerciseMedia.path,
          thumbnail: exerciseMedia.thumbnail
        } as ExerciseMediaResponse;
      })
    } as OnlineEducationResponse;
  }
}

export class OnlineVideo implements Serializer<OnlineVideo> {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public path?: string | File,
    public thumbnail?: string | File,
    public oneTime?: {
      id: number;
      amount: number;
      currency_code: number;
      currency_text: string;
    } | null,
    public acquired?: boolean,
    public status?: PublishStatus,
    public audit?: AuditUser,
    public courses?: Array<string>,
    public inCourse?: boolean
  ) {
  }
  fromResponse(response: OnlineVideoResponse): OnlineVideo {
    return new OnlineVideo(
      response.id,
      response.name,
      response.description,
      response.path,
      response.thumbnail,
      response.one_time,
      response.acquired,
      response.status,
      new AuditUser().fromResponse(response),
      response?.courses?.map?.(({name}) => name) ?? [],
      response.in_course
    );
  }

  toRequest(): OnlineVideoResponse {
    return {
      name: this.name,
      description: this.description,
      one_time: this.oneTime,
      path: this.path,
      thumbnail: this.thumbnail
    } as OnlineVideoResponse;
  }
}

export class OnlineCourse implements Serializer<OnlineCourse> {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public tags?: Array<string>,
    public oneTime?: {
      id: number;
      amount: number;
      currency_code: number;
      currency_text: string;
    } | null,
    public videos?: Array<{
      name: string;
      description: string;
    } & ExerciseMediaResponse>,
    public acquired?: boolean,
    public status?: PublishStatus,
    public audit?: AuditUser,
  ) {
  }

  fromResponse(response: OnlineCourseResponse & OnlineCourseVideoFromResponse): OnlineCourse {
    return new OnlineCourse(
      response.id,
      response.name,
      response.description,
      response.tags,
      response.one_time,
      response.videos,
      response.acquired,
      response.status,
      new AuditUser().fromResponse(response)
    );
  }

  toRequest(): OnlineCourseResponse & OnlineCourseVideoToRequest {
    return {
      name: this.name,
      description: this.description,
      tags: this.tags,
      one_time: this.oneTime,
      videos: this.videos.map(({id}) => id)
    } as OnlineCourseResponse & OnlineCourseVideoToRequest;
  }
}
