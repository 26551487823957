import { Serializer, UserProfileEducationResponse } from "./interfaces";
import { Validity } from "./validity";

export class UserProfileEducation implements Serializer<UserProfileEducation> {

    constructor(
        public id?: number,
        public level?: string,
        public title?: string,
        public school?: string,
        public file?: string,
        public image?: string,
        public verified?: boolean,
        public validity?: Validity
    ) { }

    fromResponse(response: UserProfileEducationResponse): UserProfileEducation {
        return UserProfileEducation.fromResponse(response);
    }

    static fromResponse(response: UserProfileEducationResponse): UserProfileEducation {
        return new UserProfileEducation(
            response.id,
            response.level,
            response.title,
            response.school,
            response.file,
            response.image,
            response.verified,
            Validity.fromResponse(response)
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}