import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs';

import { ErrorMessage, ServiceResponse } from '../models';

@Injectable()
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  get<T>(url: string, params?: HttpParams) {
    return this.httpClient.get<T>(url, { params: params })
  }

  post<T>(url: string, data: any, options?: { [index: string]: any }) {
    return this.httpClient.post<T>(url, data, options);
  }

  patch<T>(url: string, data: any, options?: { [index: string]: any }) {
    return this.httpClient.patch<T>(url, data, options)
  }

  put<T>(url: string, data: any, options?: { [index: string]: any }) {
    return this.httpClient.put<T>(url, data)
  }

  delete<T>(url: string) {
    return this.httpClient.delete<T>(url)
  }

  /**
   * En caso de error, lo procesa y retorna el objeto con el indicador y mensaje de error correspondiente
   * @param operation Texto descriptivo para log de errores. Ejemplo: AuthService.login
   * @param response Objeto con datos de respuesta. El modelo deberá extender de la clase ServiceResponse para tener los campos de proceso correcto o error
   * @returns
   */
  handleError(
    operation = 'operation',
    response?: ServiceResponse
  ) {
    return (error: any): Observable<any> => {
      console.error(`${operation}, ${error}`);
      // Asigna el error al objeto de respuesta
      const responseError = response || new ServiceResponse();
      if (responseError) {
        responseError.success = false;
        responseError.errorMessage = new ErrorMessage().fromResponse(error.error);
      }
      return of(responseError);
    }
  }

  /**
   * En caso de error, lo procesa y desencadena un error con dichos datos
   * @param operation Texto descriptivo para log de errores. Ejemplo: AuthService.login
   * @returns Objeto con datos de error
   */
  processError(
    operation = 'operation',
    callback?: (error: ServiceResponse) => void
  ) {
    return (error: any): Observable<any> => {
      this.consoleError(error, operation);
      const responseError = this.createResponseError(error);
      if (callback) {
        callback(responseError);
      }
      return throwError(responseError);
    }
  }

  consoleError(
    error: any,
    operation = 'operation'
  ): void {
    console.error(`${operation}, ${error}`);
  }

  createResponseError(error: any): ServiceResponse {
    // Asigna el error al objeto de respuesta
    const responseError = new ServiceResponse();
    if (responseError) {
      responseError.success = false;
      responseError.errorMessage = new ErrorMessage().fromResponse(error.error);
    }
    return responseError;
  }
}
