import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilitiesService } from 'sp-core';

const HOURS_SUFIX = 'h';
const MINUTES_SUFIX = "'";
const SECONDS_SUFIX = "''";

/**
 * Allow user to capture string in formats: 00h 00' or 00' 00''
 * @author  José Chin
 */
@Directive({
  selector: '[spTimeInputSufix]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimeInputSufixDirective),
    multi: true,
  }]
})
export class TimeInputSufixDirective implements ControlValueAccessor {

  private firstSufix = '';
  private secondSufix = '';

  @Input('spTimeInputSufix')
  set timeInputSufix(value: 'hours' | 'min') {

    if (!value) value = 'hours';

    this.firstSufix = value === 'hours' ? HOURS_SUFIX : MINUTES_SUFIX;
    this.secondSufix = value === 'hours' ? MINUTES_SUFIX : SECONDS_SUFIX;
  }

  @HostListener('keypress', ['$event'])
  onKeyPressHandler(event: KeyboardEvent): void {
    // Permite sólo dígitos del 0 al 9
    var regex = /[0-9]/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('focus', ['$event'])
  onFocusHandler(event: any) {
    if (!this.input.value) {
      this.input.value = `00${this.firstSufix} 00${this.secondSufix}`;;
    }
    this.setCursorToEnd();
  }

  @HostListener('click', ['$event'])
  onClickHandler(event: any) {
    this.setCursorToEnd();
  }

  /**
   * Se requiere para el caso que se borre (backspace) ya que keydown sólo se utiliza para captura
   * @param event 
   */
  @HostListener('input', ['$event'])
  onInputHandler(event: InputEvent) {

    let value = this.input.value;

    if (!value) {
      this.onChange(this.input.value);
      return;
    }

    value = value
      // Mantiene únicamente dígitos
      .replace(/\D/g, '')
      // Remove aditional zeros on the left to allow input next values
      .replace(/^0+/, '');

    // If the key input is backspace, remove last character
    if (event.inputType.toLowerCase() == 'deletecontentbackward') {
      value = value.slice(0, -1);
    }

    // Format value
    this.input.value = UtilitiesService.formatToTimeWithSufixes(value, this.firstSufix, this.secondSufix, true);

    // Notifica a ngModel que se ha modificado el valor
    this.onChange(this.input.value);
  }

  get input(): HTMLInputElement {
    return this.el.nativeElement;
  }

  onChange = (_: any) => { }

  constructor(
    private el: ElementRef
  ) { }

  writeValue(value: any): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }
  setDisabledState(isDisabled: boolean): void { }

  private setCursorToEnd(): void {
    this.input.selectionStart = this.input.selectionEnd = this.input.value.length;
  }
}
