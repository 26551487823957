import { ProfessionalTypeResponse, Serializer } from "./interfaces";

export class ProfessionalType implements Serializer<ProfessionalType>{

    constructor(
        public id?: number,
        public name?: string,
        public order?: number
    ) { }

    fromResponse(response: ProfessionalTypeResponse): ProfessionalType {
        return new ProfessionalType(
            response.id,
            response.name,
            response.order
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}