import { Gender } from './enumerations';
import { Serializer, InstitutionManagerResponse } from './interfaces';
export class InstitutionManager implements Serializer<InstitutionManager> {
    constructor(
        public id?: number,
        public fullName?: string,
        public photo?: string,
        public email?: string,
        public phone?: string,
        public password?: string,
        public passwordConfirm?: string,
        public gender?: Gender,
        public birthdate?: Date,
        public professionalType?: number,
        public language?: number
    ) { }
    fromResponse(response: InstitutionManagerResponse): InstitutionManager {
        return new InstitutionManager(
            response.id,
            response.full_name,
            response.photo
        );
    }
    toRequest() {
        throw new Error('Method not implemented.');
    }
}