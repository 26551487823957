import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener,
  Input
} from '@angular/core';

@Directive({
  selector: '[spDragNDrop]'
})
export class SpDragNDropDirective {

  @Input() spDragNDropDisabled = false;

  @HostBinding('class.file-over') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<FileList>();
  @Output() fileDroppedEvt = new EventEmitter<any>();

  @HostListener('dragover', ['$event'])
  onDragOver(e: DragEvent): void {
    this.preventDefault(e);
    if (this.spDragNDropDisabled) return;
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(e: DragEvent): void {
    this.preventDefault(e);
    if (this.spDragNDropDisabled) return;
    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  ondrop(e: DragEvent): void {
    this.preventDefault(e);
    if (this.spDragNDropDisabled) return;
    this.fileOver = false;
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
      this.fileDroppedEvt.emit(e);
    }
  }

  private preventDefault(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
  }
}
