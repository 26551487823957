import { EventSummaryItem } from './event-summary-item';
import { EventSummaryResponse, Serializer } from './interfaces';

export class EventSummary implements Serializer<EventSummary> {

    constructor(
        public catalogId?: number,
        public data: Array<EventSummaryItem> = []
    ) { }

    fromResponse(response: EventSummaryResponse): EventSummary {
        return new EventSummary(
            +response.objId,
            response.data.map(x => new EventSummaryItem().fromResponse(x))
        )
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }
}