<sp-dialog>
    <sp-dialog-title [title]="title || ('libraryComponents.photoEditor.title' | i18next)">
    </sp-dialog-title>
    <sp-dialog-content>
        <sp-upload-image class="d-block"
            style="width:500px;"
            [img]="photo"
            [aspectRatio]="4 / 4"
            (cropped)="handleUploadImageCropped($event)"
            (file)="handleUploadImageFile($event)"
            (onDelete)="handleUploadImageOnDelete()">
        </sp-upload-image>
    </sp-dialog-content>
    <sp-dialog-actions>
        <button mat-button
            color="primary"
            mat-dialog-close>
            {{'common.actions.cancel' | i18next}}
        </button>
        <button mat-flat-button
            color="primary"
            (click)="handleSaveClick()">
            {{'common.actions.ok' | i18next}}
        </button>
    </sp-dialog-actions>
</sp-dialog>