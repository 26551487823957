import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[spDisableContextmenu]'
})
export class SpDisableContextmenuDirective {

  constructor() {
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(e: PointerEvent): void {
    e.preventDefault();
  }

}
