import { Data } from "./data";
import { DataResponse } from "./interfaces";
import { WorkoutType } from "./workout-type";

export class WorkoutTypesData extends Data<WorkoutType>{

    /**
     * Obtiene el tipo de workout por defecto cuando el workout no tiene asignado ninguno
     */
    get default(): WorkoutType {
        if (!this.data) return null;
        return this.data.find(x => x.key === 'event_strengthworkout');
    }

    constructor() {
        super(WorkoutType);
    }

    /**
     * ```
     * Se sobre-escribe el método de la clase padre para que la instancia se cree con el tipo correcto (WorkoutTypesData).
     * De lo contrario no se carga la propiedad propia de ésta clase: default
     * ```
     * @param response
     * @returns 
     */
    fromResponse<TItemResponse>(response: DataResponse<TItemResponse>): WorkoutTypesData {

        const data = new WorkoutTypesData();

        // Obtiene los datos en base al método de la clase padre.
        const superData = super.fromResponse(response);
        data.data = superData.data;
        data.pagination = superData.pagination;

        return data;
    }
}