import { Pipe, PipeTransform } from '@angular/core';

import { SocialType, SocialTypeKey } from 'sp-core';

@Pipe({
  name: 'spSocialTypeKey'
})
export class SpSocialTypeKeyPipe implements PipeTransform {

  transform(value: SocialType): string {
    return SocialTypeKey[value || SocialType.notSet];
  }
}