export enum FontDispersion {
  WEB_APP = 1,
  ANDROID = 2,
  IOS = 3,
  ANY_SOURCE = 4,
}

export const FontDispersionString: {[keys in FontDispersion]: string} = {
  1: 'Web app',
  2: 'Android',
  3: 'IOS',
  4: 'Any source'
};
