import { ExerciseBlockParameterResponse, Serializer } from './interfaces';

export class ExerciseBlockParameter implements Serializer<ExerciseBlockParameter>{
    constructor(
        public id?: number,
        public blockExerciseLinkId?: number,
        public exerciseParameterId?: number
    ) { }
    fromResponse(response: ExerciseBlockParameterResponse): ExerciseBlockParameter {
        return new ExerciseBlockParameter(
            response.id,
            response.exercise_block,
            response.block_exercise_catalog
        );
    }
    toRequest() {
        throw new Error('Method not implemented.');
    }

}