import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from 'sp-core';

export interface ReviewNotifications {
  library: {
    program: number;
    exercise: number;
    bundle: number;
    total: number;
    education_videos?: number;
    courses?: number;
  };
  users: {
    not_verified: number;
    total: number;
  };
  education: {
    education_videos: number;
    courses: number;
    total: number;
  };
}

type ReviewKey = 'program' | 'exercise' | 'bundle' | 'total' | 'not_verified' | 'education_videos' | 'courses';

type GroupType = 'users' | 'library' | 'education';

@Injectable({
  providedIn: 'root'
})
export class PendingOperationsService {

  private handlePendingOperations = new BehaviorSubject<ReviewNotifications>({
    library: {
      program: 0,
      exercise: 0,
      bundle: 0,
      total: 0,
    },
    users: {
      total: 0,
      not_verified: 0
    },
    education: {
      education_videos: 0,
      courses: 0,
      total: 0
    }
  });

  $pendingOperations = this.handlePendingOperations.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getPendingOperations(): void {
    this.apiService.get('super_admin/review-notifications/').subscribe((notifications: ReviewNotifications) => {
      const {library: {education_videos, courses, ...restOfLibrary}} = notifications;
      this.handlePendingOperations.next({
        library: {
          ...restOfLibrary,
          total: (restOfLibrary.total)
        },
        users: {
          ...notifications.users
        },
        education: {
          education_videos,
          courses,
          total: education_videos + courses
        }
      });
    });
  }

  reduceCounter(key: ReviewKey, type: GroupType = 'library'): void {
    const values = this.handlePendingOperations.getValue();
    if (values[type][key] > 0){
      const nowCurrent: number = values[type][key] - 1;
      const total = values[type].total - 1;
      this.handlePendingOperations.next({
        ...values,
        [type]: {
          ...values[type],
          [key]: nowCurrent,
          total
        }
      });
    }
  }
}
