import moment from "moment";

import { Serializer, AthleteStatusLogRequest, AthleteStatusLogResponse } from "./interfaces";
import { AthleteStatus } from "./athlete-status";
import { Athlete } from "./athlete";
import { SPF_DATE_FORMAT } from "../constants";


export class AthleteStatusLog implements Serializer<AthleteStatusLog>{

    /**
     * Obtiene si el registro representa un estatus nuevo o existe en bd
     */
    get isNew() {
        return !this.id;
    }

    constructor(
        public id?: number,
        public startDate?: Date,
        public endDate?: Date,
        public athleteId?: number,
        /**
         * Atleta al que corresponde el registro de log
         * ```
         * Campo auxiliar para frontend. No se asigna desde el EP
         * ```
         */
        public athlete?: Athlete,
        public createdAt?: Date,
        public status = new AthleteStatus()
    ) { }

    fromResponse(response: AthleteStatusLogResponse): AthleteStatusLog {
        const status = new AthleteStatusLog(
            response.id,
            response.date_start ? moment(response.date_start, false).toDate() : null,
            response.date_end ? moment(response.date_end, false).toDate() : null,
            response.athlete,
            null,
            response.created_at ? moment(response.created_at, false).toDate() : null,
        );

        if (response.status) {
            status.status = status.status.optionsIndex[response.status];
        }

        return status;
    }

    toRequest(): AthleteStatusLogRequest {
        return <AthleteStatusLogRequest>{
            athlete: this.athleteId || undefined,
            date_start: this.startDate
                ? moment(this.startDate).format(SPF_DATE_FORMAT)
                : undefined,
            date_end: this.endDate
                ? moment(this.endDate).format(SPF_DATE_FORMAT)
                : undefined,
            status: this.status.status || undefined
        }
    }
}