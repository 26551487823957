import moment from "moment";

import { SPF_DATETIME_FORMAT } from "../constants";

import { AthleteParameterResponse, AthleteParameterConfiguration, Serializer } from "./interfaces";
import { AthleteParameterType } from "./types";
import { InitialValues } from "./abstracts";

import { Athlete } from "./athlete";
import { AuditUser } from "./audit-user";
import { Exercise } from "./exercise";

/**
 * Athlete parameter
 * ```
 * Backend table: rm athletes
 * ```
 */
export class AthleteParameter extends InitialValues<AthleteParameter> implements Serializer<AthleteParameter> {

    get isNew() {
        return !this.id
    }

    get hasChanges(): boolean {
        return this.initialValues.exerciseId !== this.exerciseId
            || this.initialValues.athlete.id !== this.athlete.id
            || this.initialValues.value !== this.value
            || this.initialValues.audit?.createdAt?.getTime() !== this.audit?.createdAt?.getTime()
            || this.initialValues.audit?.updatedAt?.getTime() !== this.audit?.updatedAt?.getTime();
    }

    get ParameterTypeConfigurations(): Array<any> {
        return null;
    }

    private _audit = new AuditUser();
    get audit(): AuditUser {
        return this._audit;
    }
    set audit(value: AuditUser) {
        this._audit = value;
    }

    get date(): Date {
        return this.audit?.updatedAt;
    }
    set date(value: Date) {
        if (!value) return;
        if (!this.audit) {
            this.audit = new AuditUser();
        }
        this.audit.updatedAt = value;
    }

    private _type: AthleteParameterType;
    get type(): AthleteParameterType {
        return this._type;
    }
    set type(value: AthleteParameterType) {
        this._type = value;
        // Set parameter configuration based on the type
        switch (this._type) {
            case 'rm':
                this.configuration = <AthleteParameterConfiguration>{
                    prefix: 'kg',
                    colKey: 'rm'
                }
                break;
            case 'bw':
                this.configuration = <AthleteParameterConfiguration>{
                    prefix: 'kg',
                    colKey: 'bw'
                }
                break;
            case 'mas':
                this.configuration = <AthleteParameterConfiguration>{
                    prefix: 'km/h',
                    colKey: 'mas'
                }
                break;
            case 'mss':
                this.configuration = <AthleteParameterConfiguration>{
                    prefix: 'km/h',
                    colKey: 'mss'
                }
                break;
            case 'hrmax':
                this.configuration = <AthleteParameterConfiguration>{
                    prefix: 'bpm',
                    colKey: 'hrmax'
                }
                break;
        }
    }

    constructor(
        public id?: number,
        public exerciseId?: number,
        public athlete?: Athlete,
        public value?: number,
        public editableDate?: Date,
        public editableValue?: number,
        public institutionId?: number,
        public configuration?: AthleteParameterConfiguration,
        /**
         * Exercise. Auxiliar field for frontend. Don't send to banckend
         */
        public exercise?: Exercise,
        public active = false
    ) {
        super();
        this.initialValues = {} as AthleteParameter;
        this.initialValues.athlete = {} as Athlete
        this.initialValues.audit = {} as AuditUser;
        this.setInitialValues(this);
    }

    static parameterFromType(type: AthleteParameterType) {
        const parameter = new AthleteParameter();
        parameter.type = type;
        return parameter;
    }

    static fromResponse(response: AthleteParameterResponse): AthleteParameter {
        const athleteParameter = new AthleteParameter(
            response.id,
            response.exercise,
            null,
            response.value,
            null,
            null,
            response.institution,
            null,
            null,
            response.active
        );

        if (response.tab) {
            athleteParameter.type = response.tab;
        }

        athleteParameter.audit = new AuditUser().fromResponse(response);

        athleteParameter.athlete = new Athlete();
        athleteParameter.athlete.id = response.athlete;

        athleteParameter.setInitialValues(athleteParameter);

        return athleteParameter;
    }

    fromResponse(response: AthleteParameterResponse): AthleteParameter {
        return AthleteParameter.fromResponse(response);
    }

    toRequest(): AthleteParameterResponse {

        let updatedAtString: string = undefined;
        if (this.audit?.updatedAt
            && this.audit?.updatedAt !== this.initialValues.audit?.updatedAt
        ) {
            const dateWithTime = this.setTimeToUpdatedAt(this.audit?.updatedAt);
            updatedAtString = moment.utc(dateWithTime).format(SPF_DATETIME_FORMAT);
        }

        return <AthleteParameterResponse>{
            exercise: (this.exerciseId !== this.initialValues.exerciseId)
                ? (this.exerciseId || (this.initialValues.exerciseId ? 0 : undefined))
                : undefined,
            /**
             * El atleta siempre se debe enviar sin importar si tuvo cambios
             */
            athlete: (this.athlete?.id || (this.initialValues.athlete?.id ? 0 : undefined)),
            value: (this.value !== this.initialValues.value)
                ? (+this.value || 0)
                : undefined,
            created_at: (this.audit?.createdAt !== this.initialValues.audit?.createdAt)
                ? (this.audit?.createdAt ? moment.utc(this.audit?.createdAt).format(SPF_DATETIME_FORMAT) : undefined)
                : undefined,
            updated_at: updatedAtString
        }
    }

    toCreateRequest(): AthleteParameterResponse {

        let updatedAtString: string = undefined;
        if (this.audit?.updatedAt
            && this.audit?.updatedAt !== this.initialValues.audit?.updatedAt
        ) {
            const dateWithTime = this.setTimeToUpdatedAt(this.audit?.updatedAt);
            updatedAtString = moment.utc(dateWithTime).format(SPF_DATETIME_FORMAT);
        } else {
            updatedAtString = moment.utc(new Date()).format(SPF_DATETIME_FORMAT);
        }

        return <AthleteParameterResponse>{
            exercise: this.exerciseId,
            athlete: this.athlete.id,
            tab: this.type?.toLowerCase() || 'rm',
            value: +this.value || 0,
            created_at: moment.utc(new Date()).format(SPF_DATETIME_FORMAT),
            updated_at: updatedAtString
        }
    }

    applyChanges(): void {
        this.setInitialValues(this);
    }

    private setTimeToUpdatedAt(updatedAt: Date): Date {

        if (!updatedAt) return null;

        const updatedAtWithTime = updatedAt;
        const newDate = new Date();
        updatedAtWithTime.setHours(newDate.getHours());
        updatedAtWithTime.setMinutes(newDate.getMinutes());
        updatedAtWithTime.setSeconds(newDate.getSeconds());
        updatedAtWithTime.setMilliseconds(newDate.getMilliseconds());

        return updatedAtWithTime;
    }

    protected setInitialValues(model: AthleteParameter): void {
        this.initialValues.exerciseId = model.exerciseId;
        this.initialValues.athlete.id = model.athlete?.id;
        this.initialValues.value = model.value;
        this.initialValues.audit.createdAt = model.audit?.createdAt;
        this.initialValues.audit.updatedAt = model.audit?.updatedAt;
    }
}