import { KeyValue } from "@angular/common";

import { TeamColor } from "./interfaces";

export class TeamColors {
    static get colors(): Array<KeyValue<string, string>> {
        return [
            { key: '#C74B50', value: 'Red' },
            { key: '#46244C', value: 'Purple' },
            { key: '#7B113A', value: 'Granate' },
            { key: '#3C415C', value: 'Blue' },
            { key: '#374045', value: 'Gray' },
            { key: '#382933', value: 'Brown' },
            { key: '#3B5249', value: 'Green' },
            { key: '#1D3E53', value: 'Blue Gray' }
        ]
    }

    /**
     * Colors with list variations
     */
    static get colorWithVariants(): Array<TeamColor> {

        // Group 1 (Frame 12 in figma)
        const group1: Array<TeamColor> = [
            {
                preview: '#58677B',
                variants: [
                    '#58677B',
                    '#515E71',
                    '#4A5667',
                    '#434E5E',
                    '#3C4654'
                ]
            },
            {
                preview: '#324F77',
                variants: [
                    '#324F77',
                    '#2D476B',
                    '#283F5F',
                    '#233753',
                    '#1E2F47'
                ]
            },
            {
                preview: '#45635B',
                variants: [
                    '#45635B',
                    '#3E5951',
                    '#374F48',
                    '#30453F',
                    '#293B36'
                ]
            }
        ];

        // Group 2 (Frame 3 in figma)
        const group2: Array<TeamColor> = [
            {
                preview: '#251287',
                variants: [
                    '#251287',
                    '#211078',
                    '#1D0E69',
                    '#190C5A',
                    '#10083C'
                ]
            },
            {
                preview: '#4D663E',
                variants: [
                    '#4D663E',
                    '#455B38',
                    '#3D5131',
                    '#35462B',
                    '#2D3C24'
                ]
            },
            {
                preview: '#5E1E7E',
                variants: [
                    '#5E1E7E',
                    '#541B71',
                    '#491763',
                    '#3F1455',
                    '#351147'
                ]
            },
            {
                preview: '#986C02',
                variants: [
                    '#986C02',
                    '#876002',
                    '#765402',
                    '#664801',
                    '#553C01'
                ]
            },
            {
                preview: '#970021',
                variants: [
                    '#970021',
                    '#86001E',
                    '#75001A',
                    '#640016',
                    '#530012'
                ]
            }
        ];

        // Group 3 (Frame 13 in figma)
        const group3: Array<TeamColor> = [
            {
                preview: '#352D1C',
                variants: [
                    '#352D1C',
                    '#2A2316',
                    '#1F1A10',
                    '#14110A',
                    '#090704'
                ]
            },
            {
                preview: '#1F2018',
                variants: [
                    '#1F2018',
                    '#161611',
                    '#0C0D0A',
                    '#030302',
                    '#000000'
                ]
            },
            {
                preview: '#0B1A43',
                variants: [
                    '#0B1A43',
                    '#091534',
                    '#060F26',
                    '#040917',
                    '#010309'
                ]
            },
            {
                preview: '#1C2134',
                variants: [
                    '#1C2134',
                    '#161A29',
                    '#10131E',
                    '#0A0C13',
                    '#040508'
                ]
            },
            {
                preview: '#3A3A3A',
                variants: [
                    '#3A3A3A',
                    '#313131',
                    '#292929',
                    '#202020',
                    '#181818'
                ]
            },
            {
                preview: '#656565',
                variants: [
                    '#656565',
                    '#5D5D5D',
                    '#545454',
                    '#4C4C4C',
                    '#444444'
                ]
            },
            {
                preview: '#3E1637',
                variants: [
                    '#3E1637',
                    '#31122C',
                    '#250D21',
                    '#180916',
                    '#0C040B'
                ]
            },
            {
                preview: '#505154',
                variants: [
                    '#505154',
                    '#48494B',
                    '#3F4043',
                    '#37383A',
                    '#2F2F31'
                ]
            }
        ];

        // Group 4 (Frame 11 in figma)
        const group4: Array<TeamColor> = [
            {
                preview: '#04722A',
                variants: [
                    '#04722A',
                    '#046124',
                    '#03511E',
                    '#024118',
                    '#023012'
                ]
            },
            {
                preview: '#859100',
                variants: [
                    '#859100',
                    '#768000',
                    '#666F00',
                    '#575E00',
                    '#474D00'
                ]
            },
            {
                preview: '#584460',
                variants: [
                    '#584460',
                    '#4F3D56',
                    '#46364C',
                    '#3D2F42',
                    '#342838'
                ]
            },
            {
                preview: '#A25A00',
                variants: [
                    '#A25A00',
                    '#915000',
                    '#804700',
                    '#6F3E00',
                    '#5E3400'
                ]
            },
            {
                preview: '#981454',
                variants: [
                    '#981454',
                    '#89124C',
                    '#7A1044',
                    '#6B0E3B',
                    '#5C0C33'
                ]
            }
        ];

        return [
            ...group1,
            ...group2,
            ...group3,
            ...group4
        ];
    }
}