import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpSocialTypeKeyPipe } from './pipes';

const PIPES = [
  SpSocialTypeKeyPipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...PIPES
  ]
})
export class SpUsersModule { }
