<div class="sp-social-logins">
    <button *ngFor="let socialLogin of socialLogins"
        class="sp-social-login"
        mat-stroked-button
        type="button"
        [@blink]="focusSocialTypeButton===socialLogin.type?state:null"
        (@blink.done)="blinkAnimationDone()"
        (click)="handleClick(socialLogin)">
        <div class="sp-social-login__content">
            <img [src]="socialLogin.icon"
                alt="">
            <span>{{socialLogin.text}}</span>
        </div>
    </button>
</div>