import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'sp-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent {

  @Input() width: number;
  @Input() img: string;
  @Input() round: boolean;
  @Input() aspectRatio: any = 1 / 1;
  @Input() crop = true;
  @Input() disabled = false;

  @Output() file = new EventEmitter<File>();
  @Output() cropped = new EventEmitter<string>();
  @Output() onDelete = new EventEmitter();
  @Output() onFileChange = new EventEmitter<File>();

  imageChangedEvent: any = '';
  transform: ImageTransform = {};
  scale = 1;

  constructor() { }

  ngOnChanges(SC: SimpleChanges) {
    if (SC.img) {
      this.imageChangedEvent = null;
    }
  }

  fileChangeEvent(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files) { this.onFileChange.next(input.files[0]); }
    this.imageChangedEvent = event;
  }

  onFileDropped(fileList: FileList): void {
    this.onFileChange.next(fileList[0]);
  }

  onFileDroppedEvt(e): void {
    // TODO: Alternative for dispatch cropper action when image was added via dropped
    this.imageChangedEvent = { ...e, target: { files: e.dataTransfer.files } };
  }

  imageCropped(event: ImageCroppedEvent) {

    this.img = event.base64;
    this.cropped.next(this.img);

    var t = this;
    (fetch(event.base64)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], Date.now() + '.jpeg', { type: 'image/jpeg' }); })
    ).then(function (file) {
      if (file) {
        t.file.next(file);
      }
    });
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  handleZoomOut(): void {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  handleZoomIn(): void {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  handleDelete(): void {
    this.imageChangedEvent = null;
    this.img = null;
    this.onDelete.emit();
  }
}
