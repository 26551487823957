import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, map } from 'rxjs/operators';
import * as Bowser from 'bowser';

import { Application, AuthService, LoginResult, SocialType } from 'sp-core';

import { CONTROL_NAMES } from './login.constants';

@Component({
  selector: 'sp-login',
  templateUrl: './login.component.html',
  styleUrls: [
    '../styles/sp-login.scss',
    './login.component.scss'
  ]
})
export class LoginComponent implements OnInit {

  @Input() application = Application.admin;

  @Output() afterLogin = new EventEmitter<LoginResult>();

  @Output() init = new EventEmitter<LoginResult>();

  @Output() forgotPassword = new EventEmitter<null>();

  @Output() register = new EventEmitter<null>();

  @Output() error = new EventEmitter<LoginResult>();

  loggingIn = false;

  logo = 'assets/images/solo-performance.svg';

  loginForm: UntypedFormGroup;

  controlNames = CONTROL_NAMES;

  messageError = '';

  passwordShown = false;

  focusSocialTypeButton: SocialType;

  isChromeNavigator = false;

  private redirectUrl = '';

  private loginResult: LoginResult;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    // Crea formulario
    this.createForm();
  }

  ngOnInit(): void {

    // Verifica que el navegador sea chrome
    // TODO: Optimizar toda la aplicación para que funcione con Chrome, Firefox y Safari
    const browser = Bowser.parse(window.navigator.userAgent);
    if (browser.browser.name.toLowerCase() === 'chrome') {
      this.isChromeNavigator = true;
    };

    // Verifica si existe sesión activa.
    this.loginResult = new LoginResult();
    if (this.authService.isUserAuthenticated()) {
      this.loginResult.isAuthenticated = true;
      this.loginResult.username = this.authService.userAuthenticated;
      // Evento al inicializar el login. Se envía nombre de usuario en sesión.
      this.init.emit(this.loginResult);
    } else {
      // Evento antes de cargar el componente de login.
      this.init.emit(this.loginResult);
      // Obtiene si se envió una ruta para redireccionamiento.
      this.activatedRoute.params.subscribe(params => {
        if (params["redirect"] != undefined) {
          this.redirectUrl = atob(params["redirect"]);
        }
      });
    }
  }

  login() {

    this.loggingIn = true;
    this.messageError = null;

    this.loginResult = new LoginResult();

    const username = ((this.loginForm.get(this.controlNames.username).value) as string).toLowerCase();
    const password = this.loginForm.get(this.controlNames.password).value;

    // Indicará si resalta algún botón de inicio de sesión social login
    this.focusSocialTypeButton = null;

    this.authService.login(
      username,
      password
    ).pipe(
      catchError(error => {

        // Si ocurre error en el login verifica si existe el tipo de usuario con social login
        return this.authService
          .findSocialLogin(username)
          .pipe(
            map(socialType => {
              // Sino existe el usuario (SocialType.notSet) o si es de tipo con usuario y contraseña (default) visualiza el error original
              if (socialType === SocialType.notSet || socialType === SocialType.default) {
                this.messageError = error && error.message ? error.message : error;
                this.loginResult.messageError = this.messageError;
              }
              else {
                // Visualiza el mensaje de que el usuario está registrado con social login
                this.messageError = `The email ${username} is registered with social login`;
                this.loginResult.messageError = this.messageError;
                // Indica qué opción de inicio de sesión social login resaltará
                this.focusSocialTypeButton = socialType;
              }
              throw (this.messageError);
            })
          );
      }),
      finalize(() => {
        this.loggingIn = false;
      })
    ).subscribe((tokenResponse) => {
      if (!tokenResponse.token) {
        // Verifica los permisos según la aplicación en la que se quiere ingresar
        if (this.application === Application.admin && !tokenResponse.permission.hasAdminAccess) {
          this.messageError = 'The user is not a administrator';
        } else if (this.application === Application.dashboard && !tokenResponse.permission.hasDashboardAccess) {
          this.messageError = 'The user is not a coach or institution manager';
        }
      } else {
        this.messageError = null;
        this.loginResult.isAuthenticated = true;
        this.loginResult.username = this.authService.userAuthenticated;
        // Verifica si se intentó acceder desde otra ruta y no desde el login para posteriormente permitir una redirección a dicha ruta.
        if (this.redirectUrl != null && this.redirectUrl.length > 0) {
          this.loginResult.redirectUrl = this.redirectUrl;
        }
        // Evento después de iniciar sesión.
        this.afterLogin.emit(this.loginResult);
      }
    }, (error) => {
      this.loginResult.exceptionError = error;
      this.error.emit(this.loginResult);
    });
  }

  forgotPasswordClick(): void {
    this.forgotPassword.emit();
  }

  private createForm() {

    this.loginForm = this.fb.group({});

    const usernameControl = this.fb.control(null, [Validators.required, Validators.email]);
    const passwordControl = this.fb.control(null, [Validators.required, Validators.minLength(8)]);

    this.loginForm.addControl(this.controlNames.username, usernameControl);
    this.loginForm.addControl(this.controlNames.password, passwordControl);
  }

  registerClick(): void {
    this.register.emit();
  }
}
