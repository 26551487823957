import { WorksetType } from "./enumerations";
import { Serializer, WorksetTypeValueResponse } from "./interfaces";


export class WorksetTypeValue implements Serializer<WorksetTypeValue> {

    private initialType: WorksetType;

    get hasChanges(): boolean {
        return this.initialType !== this.type;
    }

    get isEmpty(): boolean {
        return !this.type;
    }

    get hasValue(): boolean {
        return !this.isEmpty;
    }

    get typeString(): string {
        switch (this.type) {
            case WorksetType.regular:
                return 'R';
            case WorksetType.warmup:
                return 'W';
            case WorksetType.failure:
                return 'F';
            case WorksetType.dropset:
                return 'D';
            default:
                return '';
        }
    }

    set typeString(typeString: string) {
        switch (typeString.toUpperCase()) {
            case 'R':
                this.type = WorksetType.regular;
                break;
            case 'W':
                this.type = WorksetType.warmup;
                break;
            case 'F':
                this.type = WorksetType.failure;
                break;
            case 'D':
                this.type = WorksetType.dropset;
                break;
            default:
                break;
        }
    }

    constructor(
        public id?: number,
        public rowNumber?: number,
        public type?: WorksetType,
        public exerciseBlockId?: number
    ) {
        this.setInitialValues(this);
    }

    fromResponse(response: WorksetTypeValueResponse): WorksetTypeValue {

        const typeValue = new WorksetTypeValue(
            response.id,
            response.row,
            response.type,
            response.exercise_block
        );

        this.setInitialValues(typeValue);

        return typeValue;
    }

    toRequest(): any {
        return {
            exercise_block: this.exerciseBlockId,
            row: this.rowNumber,
            type: this.type
        }
    }

    toUpdateRequest(): any {
        return {
            type: this.type
        }
    }

    applyChanges(): void {
        this.setInitialValues(this);
    }

    clone(): WorksetTypeValue {
        const type = new WorksetTypeValue();
        type.id = this.id;
        type.rowNumber = this.rowNumber;
        type.type = this.type;
        type.exerciseBlockId = this.exerciseBlockId;
        return type;
    }

    private setInitialValues(typeValue: WorksetTypeValue): void {
        this.initialType = typeValue.type;
    }
}