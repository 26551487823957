import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import moment from 'moment';

import { InstitutionManager } from '../models';
import { UserType } from '../models/enumerations';
import { UserRequest, UserCreateResponse } from '../models/interfaces';

@Injectable()
export class InstitutionManagerService {

  constructor(
    private http: HttpClient
  ) { }

  create(
    manager: InstitutionManager,
    teamId: number,
    institutionId: number
  ): Observable<UserCreateResponse> {

    // Administrador de institución
    const userBody: UserRequest = {
      full_name: manager.fullName,
      email: manager.email,
      password: manager.password,
      confirm_password: manager.passwordConfirm,
      country_code: 52,// TODO: Definir si se captura
      phone: manager.phone,
      birthday: moment(manager.birthdate).format('YYYY-MM-DD'),
      photo: null,
      type: UserType.institutionManager,
      region: 1, // TODO: Verificar si se pide al usuario.
      gender: manager.gender,
      team: teamId,
      institution: institutionId
    };

    return this.http.post<UserCreateResponse>(`users/`, userBody);
  }
}
