import { Athlete } from "./athlete";

/**
 * Representa una asignación de atleta a bloque
 */
export class BlockAthleteLink {
    constructor(
        public athlete?: Athlete,
        public selected = false
    ) { }
}