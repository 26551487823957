import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SpDragNDropDirective} from './sp-drag-n-drop.directive';

const DIRECTIVES = [
  SpDragNDropDirective
];

@NgModule({
  declarations: [
    ...DIRECTIVES
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class SpDragNDropModule { }
