/**
 * Tipo de programa
 */
export enum ProgramType {
    /**
     * Programa tipo plantilla
     */
    template = 1,
    /**
     * Programa tipo playlist
     */
    playlist,
    /**
     * Programa tipo calendarizado
     */
    scheduled,
    /**
     * Programa tipo marketplace
     */
    marketplace
}