<sp-header class="web-header">
  <!-- Logotipo -->
  <sp-header-group>
    <a [routerLink]="['/']">
      <img [src]="logo" alt="Solo Performance" class="sp-logo">
    </a>
  </sp-header-group>
  <sp-header-group>
    <span class="web-header__option web-header__option--institution mx-4 font-weight-bold">
      Nombre de institución
    </span>
  </sp-header-group>
  <!-- Menú -->
  <sp-header-group [expanded]="true">
    <button
      mat-button
      class="web-header__option"
      routerLinkActive="sp-link-button--selected"
      [routerLink]="['/admin']">
      Administration
    </button>
    <button
      mat-button
      class="web-header__option"
      routerLinkActive="sp-link-button--selected"
      [routerLink]="['/users']"
      [queryParams]="{tab: 'all'}"
    >
      <span
        [matBadge]="notifications?.users?.total"
        matBadgeSize="small"
        matBadgeOverlap="false"
        matBadgeColor="warn"
        [matBadgeHidden]="notifications?.users?.total === 0"
      >
        Users
      </span>
    </button>
    <button
      mat-button
      class="web-header__option"
      routerLinkActive="sp-link-button--selected"
      [routerLink]="['/library']">
      <span
        [matBadge]="notifications?.library?.total"
        matBadgeSize="small"
        matBadgeOverlap="false"
        matBadgeColor="warn"
        [matBadgeHidden]="notifications?.library?.total === 0"
      >
        library
      </span>
    </button>
    <button
      mat-button
      class="web-header__option"
      routerLinkActive="sp-link-button--selected"
      [routerLink]="['/finance']">
      finance
    </button>
  </sp-header-group>
  <!-- Opciones -->
  <sp-header-group>
    <!-- Setttings -->
    <mat-icon class="web-header__icon"
      svgIcon="settings"
      matTooltip="Settings"
      [matMenuTriggerFor]="settingsMenu">
      <mat-menu #settingsMenu="matMenu" xPosition="before">
        <button mat-menu-item
          [routerLink]="['/management']">
          <span>Management</span>
        </button>
      </mat-menu>
    </mat-icon>
    <!-- Notifications -->
    <mat-icon class="web-header__icon"
      svgIcon="notifications"
      matTooltip="Notifications">
    </mat-icon>
    <!-- Usuario en sesión -->
    <button class="web-header__left-options"
      mat-button
      [matMenuTriggerFor]="menu">
      <div class="d-flex align-items-center py-3">
        <div class="web-header__user d-flex">
          <span class="web-header__user-name mx-3">
            {{user?.fullName}}
          </span>
          <mat-icon class="web-header__user-photo mr-3" svgIcon="account"></mat-icon>
          <mat-icon class="web-header__user-expand" svgIcon="expand"></mat-icon>
        </div>
      </div>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <div class="web-header__user-info">
        <mat-icon svgIcon="account"></mat-icon>
        <span>{{user?.email}}</span>
      </div>
      <button mat-menu-item
        [routerLink]="['./profile']">
        <mat-icon svgIcon="account"></mat-icon>
        <span>Profile</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon svgIcon="logout"></mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </sp-header-group>
</sp-header>
