import { Serializer, SubscriptionProductPriceResponse } from "./interfaces";
import { SubscriptionProductPriceRecurring } from "./subscription-product-price-recurring";

export class SubscriptionProductPrice implements Serializer<SubscriptionProductPrice> {

    /**
     * Obtiene el importe a mostrar ya que stripe maneja los importes en centavos
     */
    get unitAmountView(): number {
        return Math.ceil(this.unitAmount / 100);
    }

    constructor(
        public id?: string,
        public object?: string,
        public billingScheme?: string,
        public currency?: string,
        public liveMove?: boolean,
        public lookupKey?: any,
        public metadata?: any,
        public nickname?: string,
        public product?: string,
        public recurring?: SubscriptionProductPriceRecurring,
        public taxBehavior?: string,
        public tiersMode?: any,
        public transformQuantity?: any,
        public type?: string,
        public unitAmount?: number,
        public unitAmountDecimal?: number,
        public created?: number,
        public isActive?: boolean
    ) { }

    fromResponse(response: SubscriptionProductPriceResponse): SubscriptionProductPrice {
        return new SubscriptionProductPrice(
            response.id,
            response.object,
            response.billing_scheme,
            response.currency,
            response.livemove,
            response.lookup_key,
            response.metadata,
            response.nickname,
            response.product,
            new SubscriptionProductPriceRecurring().fromResponse(response.recurring),
            response.tax_behavior,
            response.tiers_mode,
            response.transform_quantity,
            response.type,
            response.unit_amount,
            response.unit_amount_decimal,
            response.created,
            response.active
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}