export abstract class InitialValues<T> {
    /**
     * Objeto para verificar valores previos a una modificación y permitir enviar sólo los campos modificados
     */
    initialValues: T;

    /**
     * Verifica si existen cambios en modelo respecto a sus valores iniciales de creación
     */
    abstract get hasChanges(): boolean;

    /**
     * Aplica los valores actuales del objeto para una siguiente validación de cambios
     */
    abstract applyChanges(): void;

    /**
     * Asigna valores iniciales a los campos de validación de cambios
     * @param model Objeto con los datos actuales
     */
    protected abstract setInitialValues(model: T): void;
}