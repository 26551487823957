<div class="progress-spinner"
    [ngClass]="containerClasses"
    [class.progress-spinner--shown]="isRunning">
    <div class="progress-spinner__spinner"
        [class.progress-spinner__spinner--sm]="size==='small'">
    </div>
    <div *ngIf="text"
        class="progress-spinner__text">
        <span>{{text}}</span>
    </div>
</div>