import { Day } from "./enumerations";

export class WeekDay {

    /**
     * Obtiene si un día de la semana es nuevo. Es decir no existe en BD
     */
    get isNew(): boolean {
        return !this.id;
    }

    constructor(
        public id: number = null,
        public day: Day,
        public itemsCount = 0,
        public selected = false,
        /**
         * Obtiene o establece el estatus inicial del día al cargar los datos.
         * Se utiliza cuando los días de la semana se selecciona pero posteriormente se elimina, entonces se retornan sus estados iniciales o de carga
         */
        public initialSelected = false,
        public initialItemsCount = 0
    ) { }

    setInitialValues(): void {
        this.initialSelected = this.selected;
        this.initialItemsCount = this.itemsCount;
    }

    resetStatus(): void {
        this.selected = this.initialSelected;
        this.itemsCount = this.initialItemsCount;
    }
}