import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  InstitutionResponse,
  InstitutionsResponse,
  InstitutionDetailResponse,
  RequestParam
} from '../models/interfaces';
import { Institution } from '../models';

import { AuthService } from './auth.service';
import { ApiService } from './api.service';

@Injectable()
export class InstitutionService {

  constructor(
    private auth: AuthService,
    private api: ApiService
  ) { }

  private defaultInstitutionSubject$ = new BehaviorSubject<Institution>(null);
  /**
   * Institución por defecto. Cuando un usuario se crea sin institución se le asigna el predeterminado (Institución cero)
   */
  defaultInstitution$ = this.defaultInstitutionSubject$.asObservable();

  private institutionSubject$ = new BehaviorSubject<Institution>(null);
  /**
   * Escuchador de institución
   * ```
   * Indica cuando una institución es modificado para permitir actualizar su información en pantalla
   * Tendrá la información más reciente de la institución
   * ```
   */
  institutionChanged$ = this.institutionSubject$.asObservable();

  /**
   * Obtiene información referente a institución
   * @param institutionId Identificador de institución
   */
  getInfo(
    institutionId: number
  ): Observable<InstitutionDetailResponse> {
    return this.api.get<InstitutionDetailResponse>(`info-institution/${institutionId.toString()}/`);
  }

  /**
   * Obtiene la institución predeterminada para los usuarios sin institución asignada (Institución Cero)
   * @returns 
   */
  getDefaultInstitution(): Observable<Institution> {
    return this.api
      .get<InstitutionResponse>(
        'default-institution/'
      ).pipe(
        map(response => {
          const defaultInstitution = new Institution().fromResponse(response);
          this.defaultInstitutionSubject$.next(defaultInstitution);
          return defaultInstitution;
        })
      );
  }

  getList(
    params?: Array<RequestParam>
  ): Observable<InstitutionsResponse> {

    // Filtros o parámetros.
    let httpParams = new HttpParams();
    if (params) {
      params.forEach(filter => {
        httpParams = httpParams.set(filter.key, filter.value);
      })
    }

    return this.api.get<InstitutionsResponse>(`institution-list/`, httpParams);
  }

  /**
   * Crea una nueva institución
   * @param institution Datos de institución
   */
  create(institution: Institution): Observable<Institution> {

    return this.api
      .post<InstitutionResponse>(
        `institution/`,
        institution.toRequest()
      ).pipe(
        map(response => new Institution().fromResponse(response))
      );
  }

  /**
   * Crea una nueva institución con respuesta de interfaz. Revisar reemplazar por create donde se utiliza el modelo Institution en lugar de InstitutionResponse
   * @param institution Datos de institución
   */
  createResponse(institution: Institution): Observable<InstitutionResponse> {

    return this.api
      .post<InstitutionResponse>(
        `institution/`,
        institution.toRequest()
      );
  }

  /**
   * Modifica los datos de institución
   * @param institution Datos de institución
   * @param sendAsFormData Indica si la información se enviará en formato FormData
   */
  update(
    institution: Institution,
    sendAsFormData = false
  ): Observable<Institution> {

    // En caso de que se envíe id de institución se utiliza éste sino se utiliza el que se tiene en sesión
    const institutionId = institution.id || this.auth.institutionId;

    return this.api
      .patch<InstitutionResponse>(
        `institution/${institutionId}/`,
        sendAsFormData ? institution.toFormDataRequest() : institution.toRequest()
      ).pipe(
        map(response => {
          const institutionUpdated = institution.fromResponse(response);
          this.institutionSubject$.next(institutionUpdated);
          return institutionUpdated;
        })
      );
  }
}