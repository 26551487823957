import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'sp-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent implements OnChanges {

  @Input() src: string;

  videoIFrame: SafeResourceUrl;

  constructor(
    private DS: DomSanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['src']) {
      this.videoIFrame = this.getVideoIframe(this.src);
    }
  }

  getVideoIframe(url: string): SafeResourceUrl {

    var video: string, results;

    if (url === null) return '';

    if (url.includes('//www.')) {
      results = url.match('[\\?&]v=([^&#]*)');
      video = (results === null) ? url : results[1];
    } else {
      let urlArray = url.split('/');
      video = urlArray[urlArray.length - 1]
    }

    return this.DS.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
  }
}
