<div class="sp-exercise-media"
    [class.sp-exercise-media--video-controls-hidden]="videoControlsHidden"
    (mouseenter)="onMouseEnterLeave(true)"
    (mouseleave)="onMouseEnterLeave(false)">
    <ng-container *ngIf="media">

        <!-- Spinner loading -->
        <div class="sp-spinner">
            <sp-progress-spinner [isRunning]="currentLoadStatus===LoadStatus.loading"
                size="small">
            </sp-progress-spinner>
        </div>

        <!-- Thumbnail -->
        <ng-container *ngIf="showThumbnail">
            <div
                [@load]="currentThumbnailStatus===LoadStatus.pending?'pending':(currentThumbnailStatus===LoadStatus.loading?'loading':'loaded')">
                <!-- Thumbnail -->
                <img [src]="media?.thumbnail"
                    class="sp-exercise-media__thumbnail"
                    alt="Exercise photo"
                    loading="lazy"
                    (click)="handleThumbnailClick($event)">
                <!-- Play button for external video -->
                <button *ngIf="!media?.isSPF"
                    type="button"
                    class="plyr__control plyr__control--overlaid"
                    [style.display]="'block'"
                    (click)="handleThumbnailClick($event)">
                    <svg viewBox="0 0 18 18">
                        <path
                            d="M15.562 8.1L3.87.225c-.818-.562-1.87 0-1.87.9v15.75c0 .9 1.052 1.462 1.87.9L15.563 9.9c.584-.45.584-1.35 0-1.8z">
                        </path>
                    </svg>
                </button>
            </div>
        </ng-container>

        <!-- Video -->
        <div #playerContainer
            class="sp-exercise-media__video-container"
            [@load]="currentVideoStatus===LoadStatus.pending?'pending':(currentVideoStatus===LoadStatus.loading?'loading':'loaded')"
            (click)="handleVideoClick($event)">
            <video loop=""></video>
        </div>

    </ng-container>
</div>