import { AthleteParameterType } from "./types";
import { Athlete } from "./athlete";
import { Exercise } from "./exercise";
import { AthleteParameter } from "./athlete-parameter";

export class EditableAthleteParameter {

    constructor(
        public athlete?: Athlete,
        public exercise?: Exercise,
        public rm?: AthleteParameter,
        public bw?: AthleteParameter,
        public mas?: AthleteParameter,
        public mss?: AthleteParameter,
        public hrmax?: AthleteParameter
    ) { }

    getParameterByType(type: AthleteParameterType): AthleteParameter {
        switch (type) {
            case 'rm':
                return this.rm;
            case 'bw':
                return this.bw;
            case 'mas':
                return this.mas;
            case 'mss':
                return this.mss;
            case 'hrmax':
                return this.hrmax;
            default:
                return null;
        }
    }

    setParameterByType(type: AthleteParameterType, value?: AthleteParameter): AthleteParameter {
        const valueToAssign = value || AthleteParameter.parameterFromType(type);
        switch (type) {
            case 'rm':
                this.rm = valueToAssign;
                return this.rm;
            case 'bw':
                this.bw = valueToAssign;
                return this.bw;
            case 'mas':
                this.mas = valueToAssign;
                return this.mas;
            case 'mss':
                this.mss = valueToAssign;
                return this.mss;
            case 'hrmax':
                this.hrmax = valueToAssign;
                return this.hrmax;
        }
    }
}