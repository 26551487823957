import { Directive, ElementRef, Input } from '@angular/core';

import { ScrollService } from 'sp-core';

@Directive({
    selector: '[spScroll]'
})
export class ScrollDirective {

    @Input() spScrollDisabled = false;

    @Input() spScrollEndDisabled = false;

    constructor(
        private el: ElementRef,
        private scrollService: ScrollService
    ) {
        const container = this.el.nativeElement as HTMLElement;
        container.onscroll = () => {

            // Emite cada evento scroll del elemento
            if (!this.spScrollDisabled) {
                this.scrollService.onScroll.next(this.el);
            }

            // Emite que el scroll se ha desplazado hasta el final del contenedor
            if (!this.spScrollEndDisabled) {
                if (Math.ceil(container.clientHeight + container.scrollTop) >= container.scrollHeight) {
                    this.scrollService.end.next(this.el);
                }
            }
        }
    }
}