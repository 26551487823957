import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SpClickStopPropagationModule } from '../../directives';

import { ClearFiltersButtonComponent, MoreOptionsComponent } from '.';

const COMPONENTS = [
  ClearFiltersButtonComponent,
  MoreOptionsComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    SpClickStopPropagationModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SpButtonModule { }
