import { ExerciseParameterLink } from './exercise-parameter-link';
import { WorksetTypeValue } from './workset-type-value';
import { WorksetValue } from './workset-value';

export class ExerciseSerie {

  private _columns: Array<ExerciseParameterLink> = [];
  /**
   * Obtiene o establece las columnas de la serie. Hacen referencia a parámetros y valores
   */
  get columns(): Array<ExerciseParameterLink> {
    return this._columns;
  };

  constructor(
    public rowNumber?: number,
    public type?: WorksetTypeValue,
    /**
     * Obtiene o establece si el registro se utiliza únicamente como auxiliar para agregar un nuevo registro (New Row).
     */
    public isNewRowAux: boolean = false
  ) { }

  getColumnByParameterId(parameterId: number): ExerciseParameterLink {
    return this.columns.find(x => x.parameterId === +parameterId);
  }

  clone(): ExerciseSerie {
    const serie = new ExerciseSerie();
    serie.rowNumber = this.rowNumber;
    serie.type = this.type.clone();
    serie.isNewRowAux = this.isNewRowAux;
    serie._columns = this.columns.map(x => x.clone());
    return serie;
  }

  /**
   * Agrega el parámetro indicado a la serie
   * @param parameter Parámetro a agregar
   */
  addParameter(parameterLink: ExerciseParameterLink): void {
    // En caso de que la asignación de parámetro no tenga un valor asignado se asigna uno vacío.
    if (!parameterLink.value) {
      const value = new WorksetValue();
      value.parameterLinkId = parameterLink.id;
      value.rowNumber = this.rowNumber;
      value.value1 = '';
      value.value2 = '';
      value.value3 = '';
      value.value4 = '';
      parameterLink.value = value;
    }
    // Asigna parámetro como columna de la serie
    this._columns.push(parameterLink);
    // Ordena columnas de la serie
    this._columns = this.columns.sort((a, b) => a.parameterId - b.parameterId);
  }
}