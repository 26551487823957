import moment from 'moment';

import { Serializer } from './interfaces/serializer.interface';
import { AuditUserResponse } from './interfaces/audit-user-response.interface';

import { AuditDate } from './audit-date';
import { User } from './user';

export class AuditUser extends AuditDate implements Serializer<AuditUser> {

    constructor(
        public createdBy?: User,
        public updatedBy?: User,
        /**
         * To allow send createdAt in constructor but not redeclare in child class
         */
        createdAt?: Date,
        /**
         * To allow send updatedAt in constructor but not redeclare in child class
         */
        updatedAt?: Date
    ) {
        super(createdAt, updatedAt);
    }

    static fromResponse(response: AuditUserResponse): AuditUser {
        return new AuditUser(
            response.created_by ? new User().fromResponse(response.created_by) : null,
            response.updated_by ? new User().fromResponse(response.updated_by) : null,
            response.created_at ? moment(response.created_at).toDate() : null,
            response.updated_at ? moment(response.updated_at).toDate() : null
        );
    }

    fromResponse(response: AuditUserResponse): AuditUser {
        return AuditUser.fromResponse(response);
    }

    fromResponseUTC(response: AuditUserResponse): AuditUser {
        return new AuditUser(
            response.created_by ? new User().fromResponse(response.created_by) : null,
            response.updated_by ? new User().fromResponse(response.updated_by) : null,
            response.created_at ? moment(response.created_at).toDate() : null,
            response.updated_at ? moment(response.updated_at).toDate() : null
        );
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }

    clone(): AuditUser {
        return new AuditUser(
            // TODO: No se clona el objeto de usuario
            this.createdBy,
            // TODO: Pendiente clonar el objeto usuario
            this.updatedBy,
            this.createdAt,
            this.updatedAt
        );
    }
}