import moment from "moment";

import { Serializer, WorkoutTagResponse } from "./interfaces";
import { SPF_DATETIME_FORMAT } from "../constants";

export class WorkoutTag implements Serializer<WorkoutTag>{

    constructor(
        public id?: number,
        public name?: string,
        public createdAt?: Date,
        public updatedAt?: Date,
        public institutionId?: number
    ) { }

    static fromResponse(response: WorkoutTagResponse): WorkoutTag {
        const tag = new WorkoutTag();

        tag.id = response.id;
        tag.name = response.name;
        tag.createdAt = moment(response.created)?.toDate();
        tag.updatedAt = moment(response.updated)?.toDate();
        tag.institutionId = response.institution;

        return tag;
    }

    static toRequest(tag: WorkoutTag): WorkoutTagResponse {
        return <WorkoutTagResponse>{
            id: tag.id || undefined,
            name: tag.name || undefined,
            created: tag.createdAt
                ? moment(tag.createdAt).format(SPF_DATETIME_FORMAT) :
                undefined,
            updated: tag.updatedAt
                ? moment(tag.updatedAt).format(SPF_DATETIME_FORMAT)
                : undefined,
            institution: tag.institutionId || undefined
        }
    }

    static toUpdateRequest(tag: WorkoutTag): WorkoutTagResponse {
        return <WorkoutTagResponse>{
            name: tag.name || undefined,
            updated: moment().utc().format(SPF_DATETIME_FORMAT)
        }
    }

    /**
     * Ordena una colección de tags por nombre
     * @param tags 
     * @returns 
     */
    static sortByName(tags: Array<WorkoutTag>): Array<WorkoutTag> {
        return tags.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0
        });
    }

    fromResponse(response: WorkoutTagResponse): WorkoutTag {
        return WorkoutTag.fromResponse(response);
    }

    toRequest(): WorkoutTagResponse {
        return WorkoutTag.toRequest(this);
    }

    toUpdateRequest(): WorkoutTagResponse {
        return WorkoutTag.toUpdateRequest(this)
    }
}