<button class="sp-icon-button"
    mat-icon-button
    spClickStopPropagation
    [color]="primary?'primary':null"
    [matMenuTriggerFor]="menu">
    <mat-icon>
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width="100%"
            height="100%"
            fit=""
            preserveAspectRatio="xMidYMid meet"
            focusable="false">
            <path d="M0 0h24v24H0z"
                fill="none"></path>
            <path
                d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z">
            </path>
        </svg>
    </mat-icon>
</button>