<div class="sp-forgot-password">
    <!-- Backdrop -->
    <div class="sp-forgot-password__backdrop">
        <div class="sp-forgot-password__container">
            <!-- Logotipo -->
            <img [src]="logo"
                alt="Solo Performance"
                class="sp-forgot-password__logo">
            <!-- Formulario -->
            <form [formGroup]="form"
                class="sp-forgot-password__form "
                cdkTrapFocus
                [cdkTrapFocusAutoCapture]="true">
                <!-- Email -->
                <mat-form-field appearance="outline"
                    class="sp-forgot-password__form-field">
                    <mat-label>Email</mat-label>
                    <input class="sp-forgot-password__email"
                        type="email"
                        placeholder="Email"
                        matInput
                        [formControlName]="controlNames.email"
                        cdkFocusRegionStart
                        cdkFocusInitial>
                    <mat-error *ngIf="form.get(controlNames.email).hasError('required')">
                        Email required
                    </mat-error>
                </mat-form-field>
                <!-- Solicitar restablecer contraseña -->
                <button mat-raised-button
                    type="submit"
                    color="primary"
                    [disabled]="form.invalid || proccessing"
                    (click)="resetPassword()"
                    cdkFocusRegionEnd>
                    Send link to email
                </button>
                <div class="sp-forgot-password__message"
                    [class.sp-forgot-password__message--shown]="message || messageError"
                    [class.sp-forgot-password__message--proccessing]="proccessing"
                    [class.sp-forgot-password__message--success]="message"
                    [class.sp-forgot-password__message--error]="messageError">
                    {{message?message:messageError}}
                </div>
                <div class="sp-forgot-password__separator"></div>
            </form>
            <!-- Iniciar sesión -->
            <a class="sp-forgot-password__question"
                (click)="goToLoginClick()">
                Login
            </a>
        </div>
    </div>
</div>