<div class="sp-upload-image d-flex"
    [class.disabled]="disabled">

    <!-- Image preview -->
    <div class="sp-upload-image__preview flex-grow-1"
        (click)="file.click()"
        spDragNDrop
        (fileDropped)="onFileDropped($event)"
        (fileDroppedEvt)="onFileDroppedEvt($event)">
        <input #file
            type="file"
            accept="image/gif,image/x-png,image/jpeg"
            (change)="fileChangeEvent($event)">
        <img *ngIf="img;else defaultImg"
            alt="upload"
            [src]="img"
            [class.has-image]="img"
            [class.rounded-circle]="img && round">
        <ng-template #defaultImg>
            <mat-icon class="sp-icon sp-icon--outline"
                [style.margin-top.px]="15"
                svgIcon="image">
            </mat-icon>
            <p>
                {{'libraryComponents.uploadImage.dropImage' | i18next}}
                <a>{{'libraryComponents.uploadImage.browseImage' | i18next}}</a>
            </p>
            <small style="display:inline-block;margin-bottom: 10px;">
                {{'libraryComponents.uploadImage.supportedImageFormats' | i18next}}
            </small>
        </ng-template>
    </div>

    <!-- Actions -->
    <div class="sp-upload-image__actions text-center"
        *ngIf="imageChangedEvent || img">
        <ng-container *ngIf="imageChangedEvent && crop">
            <button type="button"
                mat-icon-button
                color="primary"
                [matTooltip]="'common.actions.zoomOut' | i18next"
                (click)="handleZoomOut()">
                <span class="material-icons">zoom_out</span>
            </button>
            <button type="button"
                mat-icon-button
                color="primary"
                [matTooltip]="'common.actions.zoomIn' | i18next"
                (click)="handleZoomIn()">
                <span class="material-icons">zoom_in</span>
            </button>
        </ng-container>
        <button *ngIf="img"
            type="button"
            mat-icon-button
            color="warn"
            [matTooltip]="'common.actions.remove' | i18next"
            (click)="handleDelete()">
            <mat-icon svgIcon="delete"></mat-icon>
        </button>
    </div>

    <!-- Image cropper -->
    <image-cropper class="sp-upload-image__cropper flex-grow-1"
        *ngIf="imageChangedEvent && crop"
        [roundCropper]="round"
        [imageQuality]="100"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatio"
        [transform]="transform"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()">
    </image-cropper>
</div>