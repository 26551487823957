import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UserInvitationResponse } from '../models/interfaces';
import { UserInvitation } from '../models';

import { ApiService } from './api.service';
import { SubscriptionService } from './subscription.service';
import { UserType } from '../models/enumerations';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  constructor(
    private api: ApiService,
    private subscriptionService: SubscriptionService
  ) { }


  /**
   * Cambia el estatus de una invitación de activo a desactivo y viceversa
   * @param invitationId Identificador de invitación
   * @param isEnabled Estatus de la invitación: Activo/ Desactivo
   * @returns 
   */
  changeStatus(
    invitationId: number,
    isEnabled = false
  ): Observable<UserInvitation> {

    const payload = {
      institutional_enabled: !isEnabled
    }

    return this.api.patch<UserInvitationResponse>(
      `invite/${invitationId}/`,
      payload
    ).pipe(
      catchError(this.api.processError('InvitationService.changeStatus')),
      map(response => UserInvitation.fromResponse(response))
    )
  }

  create(
    invitation: UserInvitation
  ): Observable<UserInvitation> {

    return this.api.post<UserInvitationResponse>(
      `invite-user/`,
      invitation.toRequest()
    ).pipe(
      catchError(this.api.processError('InvitationService.create')),
      map(response => {

        // Actualiza número de subscripciones según el tipo de usuario
        this.updateAthleteCoachesCount(invitation, 1)

        return UserInvitation.fromResponse(response)
      })
    )
  }

  update(
    invitation: UserInvitation
  ): Observable<UserInvitation> {

    return this.api.patch<UserInvitationResponse>(
      `invite-user/${invitation.id}/`,
      invitation.toRequest()
    ).pipe(
      catchError(this.api.processError('InvitationService.update')),
      map(response => UserInvitation.fromResponse(response))
    )
  }

  /**
   * Eliminar una invitación
   * @param invitation Invitación a eliminar
   * @returns 
   */
  delete(invitation: UserInvitation): Observable<any> {

    return this.api.delete(
      `invite-user/${invitation.id}/`
    ).pipe(
      catchError(this.api.processError('InvitationService.delete')),
      map(response => {

        // Actualiza número de subscripciones según el tipo de usuario, sólo si estaba activa en la institución invitada
        if (invitation.institutionalEnabled) {
          this.updateAthleteCoachesCount(invitation, -1)
        }

        return response;
      })
    );
  }

  private updateAthleteCoachesCount(
    invitation: UserInvitation,
    increment: number
  ): void {
    // Actualiza número de subscripciones según el tipo de usuario
    if (invitation.permission == UserType.athlete) {
      this.subscriptionService.updateCurrentAthletes(increment);
    }
    else if (invitation.permission == UserType.institutionManager || invitation.permission == UserType.strengthCoach) {
      this.subscriptionService.updateCurrentCoaches(increment);
    }
  }
}