<form [formGroup]="form"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true">
    <sp-dialog>
        <sp-dialog-title [title]="texts.title || 'Actualizar Contraseña'"
            [subtitle]="texts.subtitle || 'Deberá utilizar la nueva contraseña que asigne la próxima vez que inicie sesión'">
        </sp-dialog-title>
        <sp-dialog-content>
            <!-- Formulario -->
            <div class="d-flex flex-column">
                <!-- New password -->
                <mat-form-field class="mt-3"
                    appearance="outline"
                    cdkFocusInitial>
                    <mat-label>{{texts.password}}</mat-label>
                    <input [type]="passwordShown.password?'text':'password'"
                        [placeholder]="texts.password"
                        matInput
                        [formControlName]="CONTROL_NAMES.password">
                    <mat-icon class="sp-icon sp-icon--filled"
                        matSuffix
                        [svgIcon]="passwordShown.password?'visibility-off':'visibility'"
                        (click)="passwordShown.password=!passwordShown.password">
                    </mat-icon>
                    <mat-error *ngIf="form.get(CONTROL_NAMES.password).hasError('required')">
                        {{texts.requiredValidationMessage}}
                    </mat-error>
                </mat-form-field>
                <!-- New password confirm -->
                <mat-form-field class="mt-3"
                    appearance="outline">
                    <mat-label>{{texts.passwordConfirm}}</mat-label>
                    <input [type]="passwordShown.confirm?'text':'password'"
                        [placeholder]="texts.passwordConfirm"
                        matInput
                        [formControlName]="CONTROL_NAMES.confirm"
                        [errorStateMatcher]="parentErrorStateMatcher">
                    <mat-icon class="sp-icon sp-icon--filled"
                        matSuffix
                        [svgIcon]="passwordShown.confirm?'visibility-off':'visibility'"
                        (click)="passwordShown.confirm=!passwordShown.confirm">
                    </mat-icon>
                    <mat-error *ngIf="form.get(CONTROL_NAMES.confirm).hasError('required')">
                        {{texts.requiredValidationMessage}}
                    </mat-error>
                    <mat-error *ngIf="form.hasError('match')">
                        {{texts.matchValidationMessage}}
                    </mat-error>
                </mat-form-field>
            </div>
        </sp-dialog-content>
        <sp-dialog-actions>
            <button type="button"
                mat-button
                color="primary"
                [mat-dialog-close]="null">
                {{texts.cancel}}
            </button>
            <button type="submit"
                mat-flat-button
                color="primary"
                [disabled]="form.invalid"
                (click)="handleSaveClick()">
                {{texts.ok}}
            </button>
        </sp-dialog-actions>
    </sp-dialog>
</form>