import { ExerciseSerie } from "./exercise-serie";
import { ExerciseParameter } from "./exercise-parameter";
import { ExerciseParameterLink } from "./exercise-parameter-link";
import { WorksetTypeValue } from "./workset-type-value";

const REST_COLUMNS_LENGHT = 3;

export class ExerciseSeries {

    staticColumns: Array<string> = ['rowNumber', 'type'];

    /**
     * Columnas dinámicas
     */
    dynamicColumns: Array<{ name: string, parameter: ExerciseParameter }> = [];

    /**
     * Nombres de columnas dinámicas
     */
    dynamicColumnsNames: Array<string> = [];

    columns: Array<string> = [];

    /**
     * Colección de parámetros seleccionados. Cada una de las series deberá tener los mismos parámetros
     */
    selectedParameters: Array<ExerciseParameter> = [];

    /**
     * Colección de series de ejercicios
     */
    series: Array<ExerciseSerie> = [];

    /**
     * Índice de la última columna que no es de descanso
     * ```
     * Para ampliar y permitir que las columnas de descanso siempre estén hasta la derecha del todo en la tabla
     * ```
     */
    lastParameterNoRestIndex = -1;

    get hasSeries(): boolean {
        return !!this.series.filter(x => !x.isNewRowAux).length;
    }

    get rows(): Array<ExerciseSerie> {
        return this.series;
    }

    get rowsCount(): number {
        return this.rows.length;
    }

    get newRowAux(): ExerciseSerie {
        const newRows = this.series.filter(x => x.isNewRowAux);
        if (!newRows.length) {
            return null;
        }
        return newRows[0];
    }

    /**
     * Obtiene el máximo número de registro asignado. Debido a que no se recorren los números en caso de que alguno se elimine.
     */
    get getMaxRowNumber(): number {
        if (!this.series.length) return 0;
        return this.series.reduce((previous, current) => previous.rowNumber > current.rowNumber ? previous : current).rowNumber;
    }

    /**
     * Obtiene el siguiente número de registro
     */
    get getNextRowNumber(): number {
        return this.getMaxRowNumber + 1;
    }

    constructor(
        parameters: Array<ExerciseParameter> = [],
        private excludeTypeColumn = false
    ) {
        // Agrega columnas seleccionadas
        parameters.forEach(parameter => {
            this.selectedParameters.push(parameter);
        })
        this.selectedParameters = this.sortParameters(this.selectedParameters);
        // Columnas para visualizar en tabla
        this.getColumns();
    }

    createSerie(
        rowNumber?: number
    ): ExerciseSerie {
        const serie = new ExerciseSerie();
        serie.rowNumber = rowNumber ? rowNumber : this.getNextRowNumber;
        // Asigna estructura de tipo de serie.
        serie.type = new WorksetTypeValue();
        serie.type.rowNumber = serie.rowNumber;
        // 
        this.series.push(serie);
        return serie;
    }

    /**
     * Crea un nuevo registro como auxiliar para agregar nueva serie
     */
    createSerieNewRow(): ExerciseSerie {
        const serie = this.createSerie();
        serie.isNewRowAux = true;
        return serie;
    }

    /**
     * Agrega el parámetro indicado a todas las series existentes
     * @param parameter Parámetro a agregar
     */
    addParameter(parameterLink: ExerciseParameterLink): void {
        // Agrega el parámetro a cada una de las series
        this.series.forEach(serie => {
            serie.addParameter(parameterLink.clone());
        });
        // Agrega y ordena el parámetro en la colección de parámetros seleccionados.
        if (!parameterLink.parameter.isRest) {
            this.selectedParameters.push(parameterLink.parameter);
            this.selectedParameters = this.sortParameters(this.selectedParameters);
        }
        // Columnas para visualizar en tabla
        this.getColumns();
    }

    /**
     * Elimina el parámetro indicado de todas las series existentes
     * @param parameterLink Parámetro a eliminar
     */
    removeParameter(parameter: ExerciseParameter): void {
        // Elimina de cada serie
        this.series.forEach(serie => {
            const parametersFound = serie.columns.filter(x => x.parameter.id === parameter.id);
            if (parametersFound.length) {
                const index = serie.columns.indexOf(parametersFound[0]);
                serie.columns.splice(index, 1);
            }
        });
        // Elimina de la colección de parámetros seleccionados.
        if (!parameter.isRest) {
            const parametersFound = this.selectedParameters.filter(x => x.id === parameter.id);
            if (parametersFound.length) {
                const index = this.selectedParameters.indexOf(parametersFound[0]);
                this.selectedParameters.splice(index, 1);
            }
        }
        // Columnas para visualizar en tabla
        this.getColumns();
    }


    removeSerie(serie: ExerciseSerie): void {
        const serieToRemove = this.getSerieByRowNumber(serie.rowNumber);
        if (serieToRemove) {
            const index = this.series.indexOf(serieToRemove);
            this.series.splice(index, 1);
        }
    }

    cloneSerie(serie: ExerciseSerie): ExerciseSerie {
        return serie.clone();
    }

    private sortParameters(parameters: Array<ExerciseParameter>): Array<ExerciseParameter> {
        return parameters
            .filter(x => !x.isRest)
            // Ordena los parámetros según el id
            .sort((a, b) => a.id - b.id)
            // Agrega los parámetros de descanso ordenados alfabeticamente
            .concat(
                parameters
                    .filter(x => x.isRest)
                    .sort((a, b) => {
                        if (a.title < b.title) return -1;
                        if (a.title > b.title) return 1;
                        return 0;
                    })
            );
    }

    private getColumns(): void {

        this.dynamicColumns = this.selectedParameters.map(x => ({ name: x.id.toString(), parameter: x }));
        this.dynamicColumnsNames = this.dynamicColumns.map(x => x.name);

        const staticColumns = this.excludeTypeColumn
            ? this.staticColumns.filter(x => x !== 'type')
            : this.staticColumns;
        this.columns = staticColumns.concat(this.dynamicColumnsNames).concat(['actions']);

        this.lastParameterNoRestIndex = (this.dynamicColumns.length > REST_COLUMNS_LENGHT)
            ? (this.dynamicColumns.length - (REST_COLUMNS_LENGHT + 1))
            : -1;
    }

    private getSerieByRowNumber(rowNumber: number): ExerciseSerie {
        const seriesFound = this.series.filter(x => x.rowNumber === rowNumber);
        if (!seriesFound.length) return null;
        return seriesFound[0];
    }
}