import { Serializer } from './interfaces/serializer.interface';
import { DataResponse } from './interfaces/data-response.interface';

import { Pagination } from './pagination';

export class Data<TItem extends Serializer<TItem>> implements Serializer<Data<TItem>> {

    /**
     * Coleción con los registros obtenidos
     */
    data?: Array<TItem>;

    /**
     * Datos de paginación. Total de registros, página actual...
     */
    pagination?: Pagination;

    constructor(
        /**
         * Clase o tipo de los elementos para crear un nuevo objeto (función constructor)
         */
        private itemType: new () => TItem
    ) { }

    fromResponse<TItemResponse>(response: DataResponse<TItemResponse>): Data<TItem> {
        const data = new Data<TItem>(null);
        data.data = response.data.map(item => new this.itemType().fromResponse(item));
        data.pagination = response.pagination ? new Pagination().fromResponse(response.pagination) : null;
        return data;
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }

    /**
     * Clone the data object. Create a new instance
     * @param deep Specify if the clone is deep, clone every field of every object
     * @returns New instance of data
     */
    clone(deep = false): Data<TItem> {
        const data = new Data<TItem>(null);
        if (deep) {
            // TODO: Implement clone of every x object
            data.data = this.data.map(x => x);
            data.pagination = this.pagination.clone();
        } else {
            data.data = this.data;
            data.pagination = this.pagination;
        }

        return data;
    }

    /**
     * Replace a item for new into data
     * @param itemToReplace Item to replace
     * @param newItem New item to set
     * @returns New instance of data
     */
    replaceItem(
        itemToReplace: TItem,
        newItem: TItem,
        newDataInstance = false
    ): Array<TItem> {

        const index = this.data.indexOf(itemToReplace);
        if (index >= 0) {
            this.data[index] = newItem;
        }

        // Create and return a new instance of array
        return newDataInstance ? this.data.map(x => x) : this.data;
    }

    /**
     * Replace a item for new into data
     * @param itemIndexToReplace Element's index to replace
     * @param newItem New item to set
     * @returns New instance of data
     */
    replaceItemByIndex(
        itemIndexToReplace: number,
        newItem: TItem,
        newDataInstance = false
    ): Array<TItem> {

        if (itemIndexToReplace >= 0) {
            this.data[itemIndexToReplace] = newItem;
        }

        // Create and return a new instance of array
        return newDataInstance ? this.data.map(x => x) : this.data;
    }
}