<div cdkDrag
    cdkDragRootElement=".cdk-overlay-pane">
    <!-- Header -->
    <ng-container *ngIf="title;else fakeDrag">
        <div cdkDragHandle
            class="sp-dialog__drag d-flex mb-3 justify-content-between"
            [ngClass]="headerClasses">
            <div class="d-flex flex-column w-100">
                <!-- Title -->
                <span *ngIf="title.title"
                    class="sp-dialog__title">
                    {{title.title}}
                </span>
                <div *ngIf="title.content"
                    class="sp-dialog__title">
                    <ng-container *ngTemplateOutlet="title.content"></ng-container>
                </div>
                <!-- Subtitle -->
                <span *ngIf="title.subtitle"
                    class="sp-dialog__subtitle">
                    {{title.subtitle}}
                </span>
            </div>
            <button *ngIf="isCloseButtonVisible"
                class="sp-icon-button sp-icon-button--fill sp-dialog__close-button"
                mat-icon-button
                [mat-dialog-close]="null">
                <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="black"
                    width="24px"
                    height="24px">
                    <path d="M0 0h24v24H0z"
                        fill="none" />
                    <path
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
            </button>
        </div>
        <ng-container *ngTemplateOutlet="title.titleContent"></ng-container>
    </ng-container>
    <ng-template #fakeDrag>
        <div class="sp-dialog__fake-drag"
            cdkDragHandle></div>
    </ng-template>
    <!-- Content -->
    <mat-dialog-content spScrollEnd
        [spScrollEndDisabled]="scrollEndDisabled"
        [ngClass]="dialogContentClass?dialogContentClass:null">
        <ng-container *ngIf="content">
            <ng-container *ngTemplateOutlet="content.content"></ng-container>
        </ng-container>
    </mat-dialog-content>
    <!-- Actions -->
    <mat-dialog-actions *ngIf="actions"
        align="end">
        <ng-container *ngTemplateOutlet="actions.content"></ng-container>
    </mat-dialog-actions>
</div>