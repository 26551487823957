import { Serializer, SubscriptionProductsDataResponse } from './interfaces';

import { StripeData } from './stripe-data';
import { SubscriptionProduct } from './subscription-product';

export class SubscriptionProductsData extends StripeData<SubscriptionProduct> implements Serializer<SubscriptionProductsData> {

    constructor() {
        super();
    }

    fromResponse(response: SubscriptionProductsDataResponse): SubscriptionProductsData {
        const productsData = new SubscriptionProductsData();
        productsData.object = response.object;
        productsData.url = response.url;
        productsData.hasMore = response.has_more;
        productsData.data = (response.data && response.data.length) ? response.data.map(x => new SubscriptionProduct().fromResponse(x)) : [];
        return productsData;
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }
}