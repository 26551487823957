import moment from 'moment';

import { AthleteCreateRequest, AthleteResponse, Serializer } from './interfaces';
import { AthleteStatusOption, AthleteStatusOptionColor } from './enumerations';

import { UtilitiesService } from '../services';

import { Team } from './team';
import { User } from './user';
import { InstitutionalImage } from './institutional-image';

export class Athlete implements Serializer<Athlete> {

    get initials(): string {
        return UtilitiesService.getInitials(this.fullName).toUpperCase();
    }

    private _photo: string;
    // Obtiene o establece la foto del usuario. La imágen institucional tiene prioridad vs la foto asignada al crear el usuario
    get photo(): string {
        return this.institutionalImage?.imageURL || this._photo;
    }
    set photo(value: string) {
        this._photo = value;
    }

    constructor(
        public id?: number,
        public fullName?: string,
        public email?: string,
        public birthdate?: Date,
        public phone?: string,
        public totalMessages?: number,
        /**
         * Id from chat group by the athlete
         */
        public room?: number,
        public weigth?: number,
        public heigth?: number,
        public lastEdited?: User,
        public updatedAt?: Date,
        public isActive?: boolean,
        /**
         * Get or set if athlete is disabled
         * ```
         * Frontend auxiliar field
         * ```
         */
        public disabled = false,
        /**
         * Get or set if athlete is selected
         * ```
         * Frontend auxiliar field.
         * Optar por utilizar la clase wrapper SelectableEntity para que posteriormente éste campo se pueda eliminar ya que no forma parte del modelo de atletas (No existe en backend)
         * ```
         */
        public selected = false,
        /**
         * Campo auxiliar para actualizar fotografía
         */
        public photoFile?: File,
        public status?: AthleteStatusOption,
        public institutionalImage?: InstitutionalImage,
        public teams: Array<Team> = []
    ) { }

    fromResponse(response: AthleteResponse): Athlete {
        const athlete = new Athlete(
            response.id,
            response.full_name,
            response.email,
            response.birthday ? moment(response.birthday).toDate() : null,
            response.phone,
            response.total_messages,
            response?.room,
            response.weigth ? +response.weigth : 0,
            response.heigth ? +response.heigth : 0,
            response.last_edited ? new User().fromResponse(response.last_edited) : null,
            response.updated_at ? moment(response.updated_at).toDate() : null,
            (response.is_active !== null && response.is_active !== undefined ? response.is_active : null),
            false,
            false,
            null,
            response.status || AthleteStatusOption.available,
            response.institution_image
                ? InstitutionalImage.fromResponse(response.institution_image)
                : null,
            response.teams ? response.teams.map(x => new Team().fromResponse(x)) : []
        );

        // Foto
        if (response.photo) {
            athlete.photo = response.photo;
        }

        return athlete;
    }

    toRequest(): AthleteCreateRequest {
        return <AthleteCreateRequest>{
            email: this.email || undefined,
            full_name: this.fullName || undefined,
            photo: this.photo || undefined,
            birthday: this.birthdate ? moment(this.birthdate).format('YYYY-MM-DD') : undefined,
            is_active: this.isActive !== null ? this.isActive : undefined
        }
    }

    toFormDataRequest(): FormData {

        const athleteRequest = this.toRequest();

        const formData = new FormData();
        if (athleteRequest.email) {
            formData.append('email', athleteRequest.email);
        }
        if (athleteRequest.full_name) {
            formData.append('full_name', athleteRequest.full_name);
        }
        if (athleteRequest.birthday) {
            formData.append('birthday', athleteRequest.birthday);
        }
        if (athleteRequest.is_active) {
            formData.append('is_active', athleteRequest.is_active ? 'true' : 'false');
        }
        // La foto lo obtiene del modelo y no de la interfaz como los campos anteriores
        if (this.photoFile) {
            formData.append('photo', this.photoFile, this.photoFile.name);
        }

        return formData
    }

    setZeroMessages(): void {
        this.totalMessages = 0;
    }

    setNewMessage(): void {
        this.totalMessages += 1;
    }

    static colorByStatus(status: AthleteStatusOption): string {
        return AthleteStatusOptionColor[status];
    }
}
