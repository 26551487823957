<div class="sp-notification-success">
    <span class="sp-notification-success__message">
        {{message}}
    </span>
    <button class="sp-notification-success__action"
        mat-button
        color="accent"
        (click)="onCloseClick()">
        Close
    </button>
</div>