import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

import { InputValueType } from 'sp-core';

import { EditableComponent } from './../editable/editable.component';

@Component({
  selector: 'sp-editable-input',
  templateUrl: './editable-input.component.html',
  styleUrls: [
    './../editable/editable.component.scss',
    './editable-input.component.scss'
  ]
})
export class EditableInputComponent extends EditableComponent implements OnInit, OnDestroy {

  @ViewChild('input') inputRef: ElementRef;

  private subSink = new SubSink();

  get input(): HTMLInputElement {
    return (this.inputRef.nativeElement as HTMLInputElement);
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.changeEditMode) {
      this.subSink.sink = this.changeEditMode.subscribe(editMode => {
        if (editMode) {
          this.editModeInput();
        } else {
          this.readOnlyMode();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  onInputLabelClick(): void {
    if (this.disabled || !this.editWithSingleClick) return;
    this.editModeInput();
  }

  onInputLabelDoubleClick(): void {
    if (this.disabled || this.editWithSingleClick) return;
    this.editModeInput();
  }

  onInputFocus(event: any): void {
    this.input.select();  // Selecciona el texto actual
    this.input.value = this.input.value;  // Únicamente para colocar el cursor al final del texto
    this.focus.emit(event);
  }

  onInputModelChange(value: string): void {
    this.input.value = this.input.value;
    switch (this.valueType) {
      case InputValueType.int:
        // Se convierte a númerico (+) el dato capturado para eliminar los ceros a la izquierda si tuviera
        this.input.value = (+this.input.value).toString();
        value = this.input.value;
        break;
      case InputValueType.tempo:  // Si el campo es de subparámetro Tempo complementa con ceros en caso de que se capture incompleto.
        const values = value.split(':').filter(x => !!x);
        for (let index = values.length; index < 3; index++) {
          values.push('0');
        }
        value = values.join(':');
        break;
    }

    this.valueModified = value;
  }

  /**
   * Llamar evento blur del control de captura.
   * Se llama de manera externa al componente
   */
  blurInput(): void {
    this.input.blur();
  }

  /**
   * Llamar evento Tab del control de captura.
   * Se llama de manera externa al componente
   */
  keyTabInput(): void {
    this.input.dispatchEvent(new KeyboardEvent("keydown", { key: "Tab" }));
  }

  editModeInput(): void {
    this.input.value = this.value;
    this.editMode();
    setTimeout(() => {
      this.input.focus();
    }, 0);
  }
}