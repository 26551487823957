import { UserType } from './enumerations';
import { InstitutionResponse, Serializer, UserInvitationCreateRequest, UserInvitationResponse } from './interfaces';
import { User } from './user';
import { Institution } from './institution';

export class UserInvitation implements Serializer<UserInvitation> {

    constructor(
        /**
         * Identificador de la invitación
         */
        public id?: number,
        /**
         * Identificador de institución en la que fué invitado el usuario
         */
        public institutionId?: number,
        /**
         * Institución en la que fué invitado el usuario
         */
        public institution?: Institution,
        /**
         * Indica si está activo en la institución en la que fué invitado
         */
        public institutionalEnabled?: boolean,
        /**
         * Identificador de usuario invitado
         */
        public userId?: number,
        /**
         * Permiso, perfil o tipo de usuario con la que se invitó al usuario
         */
        public permission?: UserType,
        /**
         * Indica si la invitación está aceptada
         */
        public accepted?: boolean
    ) { }

    fromResponse(response: UserInvitationResponse): UserInvitation {
        return UserInvitation.fromResponse(response);
    }

    static fromResponse(response: UserInvitationResponse): UserInvitation {

        const invitation = <UserInvitation>{
            id: response.id,
            userId: response.user,
            institutionalEnabled: response.institutional_enabled,
            accepted: response.accepted,
            permission: response.permission as UserType
        }

        // Institution
        if (response.institution) {
            if ((typeof response.institution) === 'number') {
                invitation.institution = new Institution();
                invitation.institution.id = +response.institution;
            } else {
                invitation.institution = new Institution().fromResponse(response.institution as InstitutionResponse);
            }
            invitation.institutionId = invitation.institution.id;
        }

        return invitation;
    }

    static fromUser(user: User): UserInvitation {
        const invitation = new UserInvitation();
        invitation.userId = user.id;
        return invitation;
    }

    toRequest(): UserInvitationCreateRequest {

        return <UserInvitationCreateRequest>{
            user: this.userId || undefined,
            institution: this.institutionId || undefined,
            institutional_enabled: this.institutionalEnabled || undefined,
            accepted: this.accepted || undefined,
            permission: this.permission || undefined
        }
    }
}