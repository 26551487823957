import moment from 'moment';

import { Day } from './enumerations';
import { PhaseDayResponse, Serializer } from './interfaces';

import { Audit } from './audit';
import { Workout } from './workout';
import { PhaseWeek } from './phase-week';
import { WeekDays } from './week-days';
import { WeekDay } from './week-day';

export class PhaseDay implements Serializer<PhaseDay> {

    constructor(
        /**
         * Identificador de día en BD
         */
        public id?: number,
        /**
         * Obtiene o establece el nombre a visualizar del día
         */
        public name?: string,
        public day?: Day,
        public date?: Date,
        public weekId?: number,
        public audit?: Audit,
        /**
         * Semana a la que pertenece el día.
         * ```
         * Se trata de un campo auxiliar para frontend
         * ```
         */
        public week?: PhaseWeek,
        /**
         * 
         */
        public isActive?: boolean,
        /**
         * Colección de workouts
         */
        public workouts: Array<Workout> = [],
        public workoutsCount = 0,
        /**
         * Obtiene o establece si el objeto está oculto en visualización.
         * NOTA: No se obtiene de backend. Es un campo auxiliar para frontend
         */
        public hidden?: boolean
    ) { }

    fromResponse(response: PhaseDayResponse): PhaseDay {
        return new PhaseDay(
            response.id,
            response.day_display,
            response.day,
            response.date ? moment(response.date).toDate() : null,
            response.week,
            new Audit().fromResponse(response),
            null,
            false,
            response.workouts ? response.workouts.map(x => new Workout().fromResponse(x)) : [],
            0,
            false
        )
    }

    /**
     * Obtiene una instancia de día de fase en base a una semana y día
     * @param week Semana para obtener datos que se requieren en el día
     * @param day Día con los datos a asignar
     * @returns Día de fase
     */
    fromWeekAndDay(week: WeekDays, day: WeekDay): PhaseDay {
        return new PhaseDay(
            day.id,
            null,
            day.day,
            null,
            week.id,
            null,
            null,
            null,
            [],
            null,
            false
        );
    }

    toRequest() {
        return <PhaseDayResponse>{
            day: this.day,
            week: this.weekId,
            day_display: this.name
        }
    }
}