import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { SocialLoginService, SocialType } from 'sp-core';

import { blinkAnimation } from '../../../animations';
import { SocialLoginOption } from './social-login-option.interface';

@Component({
  selector: 'sp-social-logins',
  templateUrl: './social-logins.component.html',
  styleUrls: ['./social-logins.component.scss'],
  animations: [
    blinkAnimation
  ]
})
export class SocialLoginsComponent implements OnInit, OnChanges {

  times = 11; // Para saber las veces que parpadea se divide el número entre 2 y se obtiene el número entero. Ejemplo: 11/2=5.5, veces = 5
  state = 'shine';
  counter = 0;

  /**
   * Indica el botón a resaltar
   */
  @Input() focusSocialTypeButton: SocialType;

  socialLogins: Array<SocialLoginOption> = [
    // <SocialLoginOption>{
    //   icon: 'assets/icons/login-facebook.svg',
    //   text: 'Login with Facebook',
    //   type: SocialType.facebook
    // },
    <SocialLoginOption>{
      icon: 'assets/icons/login-google.svg',
      text: 'Login with Google',
      type: SocialType.google
    },
    <SocialLoginOption>{
      icon: 'assets/icons/login-apple.svg',
      text: 'Login with Apple',
      type: SocialType.apple
    }
  ];

  constructor(
    private socialLoginService: SocialLoginService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['focusSocialTypeButton'] && this.focusSocialTypeButton) {

    }
  }

  ngOnInit(): void { }

  handleClick(socialLogin: SocialLoginOption): void {
    this.socialLoginService.loginWithSocial(socialLogin.type);
  }

  /**
   * Se ejecuta cuando ha ocurrido un cambio de estado shine <-> darken
   */
  blinkAnimationDone(): void {
    if (this.counter < this.times) {
      this.state = this.state === 'shine' ? 'darken' : 'shine';
      this.counter++;
    } else {
      this.focusSocialTypeButton = null;
      this.state = 'shine';
      this.counter = 0;
    }
  }
}
