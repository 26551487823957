import { Pipe, PipeTransform } from '@angular/core';

import { WorkoutStatus, WorkoutStatusKey } from 'sp-core';

@Pipe({
  name: 'spWorkoutStatusKey'
})
export class SpWorkoutStatusKeyPipe implements PipeTransform {

  transform(value: WorkoutStatus): string {
    return WorkoutStatusKey[value || WorkoutStatus.scheduled];
  }

}
