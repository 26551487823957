import moment from "moment";

import { AuditDateResponse, Serializer } from "./interfaces";

export class AuditDate implements Serializer<AuditDate> {

    constructor(
        public createdAt?: Date,
        public updatedAt?: Date
    ) { }

    static fromResponse(response: AuditDateResponse): AuditDate {

        // If response is null, return empty AuditDate
        if (!response) return new AuditDate();

        return new AuditDate(
            response.created_at ? moment(response.created_at).toDate() : null,
            response.updated_at ? moment(response.updated_at).toDate() : null
        );
    }

    fromResponse(response: AuditDateResponse): AuditDate {
        return AuditDate.fromResponse(response);
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}