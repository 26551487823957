import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'sp-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent {

  @ViewChild(TemplateRef) content: TemplateRef<any>;
}
