import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpWorkoutStatusKeyPipe } from './pipes';

const PIPES = [
  SpWorkoutStatusKeyPipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...PIPES
  ]
})
export class SpWorkoutsModule { }
