import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';

import { SPF_DATETIME_FORMAT, User } from 'sp-core';

import { ChatService } from '../../services';
import { SocketChatMessage } from '../../models';
import { SubSink } from 'subsink';

@Component({
  selector: 'sp-chat-new-message',
  templateUrl: './chat-new-message.component.html',
  styleUrls: ['./chat-new-message.component.scss']
})
export class ChatNewMessageComponent implements OnInit, OnDestroy {

  @Input() user: User;

  @Input() recipient: User;

  allowEmojis = false;

  msg = '';

  isEmojiPickerVisible: boolean = false;

  private subSink = new SubSink();

  constructor(
    private chatService: ChatService<SocketChatMessage>
  ) { }

  ngOnInit(): void {
    this.subSink.sink = this.chatService.allowEmojis$.subscribe((value: boolean) => {
      this.allowEmojis = value;
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  send(val: NgForm): void {

    if (!val || val.invalid || !this.msg.trim().length) return;

    const message = {} as SocketChatMessage;
    message.userId = this.user.id;
    message.recipientId = this.recipient.id;
    message.comment = this.msg;
    message.createdAt = moment().utc().format(SPF_DATETIME_FORMAT);

    this.chatService.sendNewMessage(message);
    this.msg = '';
  }

  public addEmoji(event: any): void {
    this.msg = `${this.msg}${event.emoji.native}`;
    this.isEmojiPickerVisible = false;
  }
}