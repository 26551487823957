import moment from 'moment';

import { Serializer, TeamRequest, TeamResponse } from './interfaces';
import { Institution } from './institution';
import { User } from './user';
import { Athlete } from './athlete';
import { InstitutionManager } from './institution-manager';

export class Team implements Serializer<Team> {

    get nameHasChanges(): boolean {
        return this.name !== this.initialValues.name;
    }

    get initialsHasChanges(): boolean {
        return this.initials !== this.initialValues.initials;
    }

    get colorHasChanges(): boolean {
        return this.color !== this.initialValues.color;
    }

    get institutionHasChanges(): boolean {
        return this.institution?.id !== this.initialValues.institution?.id;
    }

    get imageFileHasChanges(): boolean {
        return this.imageFile?.name !== this.initialValues.imageFile?.name;
    }

    get imageHasChange(): boolean {
        return this.image !== this.initialValues.image;
    }

    /**
     * Asigna el path de la imagen a asignar
     * ```
     * 1.- Verifica si la imagen se modificó
     * 2.- Si se modificó asigna el path indicado
     * 3.- Si el path es vacío o nulo verifica si existe un path inicial, en ese caso significa que se debe eliminar (desasignar) la imagen
     * ```
     */
    get imageToSet(): string {
        return this.imageHasChange
            ? this.image || (this.initialValues.image ? null : undefined)
            : undefined;
    }

    get coachesHasChanges(): boolean {

        if (this.coaches.length !== this.initialValues.coaches.length) return true;

        const coachesIds = this.coaches.map(x => x.id).sort((a, b) => a - b);
        const initialcoachesIds = this.initialValues.coaches.map(x => x.id).sort((a, b) => a - b);
        return coachesIds.join() !== initialcoachesIds.join();
    }

    get athletesHasChanges(): boolean {

        if (this.athletes.length !== this.initialValues.athletes.length) return true;

        const athletesIds = this.athletes.map(x => x.id).sort((a, b) => a - b);
        const initialAthletesIds = this.initialValues.athletes.map(x => x.id).sort((a, b) => a - b);
        return athletesIds.join() !== initialAthletesIds.join();
    }

    get isActiveHasChanges(): boolean {
        return this.isActive !== this.initialValues.isActive;
    }

    /**
     * Verifica si existen cambios en modelo respecto a sus valores iniciales de creación
     */
    get hasChanges(): boolean {
        return (
            this.nameHasChanges ||
            this.initialsHasChanges ||
            this.colorHasChanges ||
            this.imageFileHasChanges ||
            this.institutionHasChanges ||
            this.isActiveHasChanges ||
            this.coachesHasChanges ||
            this.athletesHasChanges
        );
    }

    /**
     * Objeto para verificar valores previos a una modificación y permitir enviar sólo los campos modificados
     */
    private initialValues: Team;

    constructor(
        public id?: number,
        public name?: string,
        public image?: string,
        public isActive?: boolean,
        public institution?: Institution,
        public institutionManagers?: Array<InstitutionManager>,
        public updatedBy?: User,
        public createdAt?: Date,
        public updatedAt?: Date,
        public totalMessages?: number,
        /**
         * Id from chat group by the team
         */
        public room?: number,
        public initials?: string,
        public color?: string,
        /**
         * Get or set if team is selected
         * ```
         * Frontend auxiliar field
         * ```
         */
        public isSelected = false,
        /**
         * Get or set if team has selected athletes.
         * ```
         * Frontend auxiliar field
         * ```
         */
        public hasAthletesSelected = false,
        /**
         * Campo auxiliar para actualizar fotografía
         */
        public imageFile?: File,
        public athletes: Array<Athlete> = [],
        public coaches: Array<User> = []
    ) {
        // Se inicializan los valores iniciales sólo indicado la estructura para evitar instanciar y generar max stack
        this.initialValues = {} as Team;
        this.initialValues.institution = {} as Institution;

        this.setInitialValues(this);
    }

    fromResponse(response: TeamResponse): Team {
        const team = new Team(
            response.id,
            response.name ? response.name : '',
            response.image ? response.image : null,
            response.active ? response.active : false,
            response.institution ? <Institution>{ id: response.institution } : null,
            response.institution_managers ? response.institution_managers.map(manager => new InstitutionManager().fromResponse(manager)) : [],
            response.updated_by ? <User>{
                id: response.updated_by.id,
                photo: response.updated_by.photo,
                fullName: response.updated_by.full_name
            } : null,
            response.created_at ? moment(response.created_at, false).toDate() : null,
            response.updated_at ? moment(response.updated_at, false).toDate() : null,
            response.total_messages || 0,
            response.room || 0,
            response.initials,
            response.color,
            false,
            false,
            null,
            response.athletes ? response.athletes.map(x => new Athlete().fromResponse(x)) : [],
            response.coaches ? response.coaches.map(x => User.fromResponse(x)) : []
        );

        this.setInitialValues(team);

        return team;
    }

    toRequest(): TeamRequest {
        return <TeamRequest>{
            name: this.nameHasChanges
                ? (this.name || (this.initialValues.name ? '' : undefined))
                : undefined,
            initials: this.initialsHasChanges
                ? (this.initials || (this.initialValues.initials ? '' : undefined))
                : undefined,
            color: this.colorHasChanges
                ? (this.color || (this.initialValues.color ? '' : undefined))
                : undefined,
            active: this.isActiveHasChanges
                ? ((this.isActive !== null && this.isActive !== undefined) ? this.isActive : undefined)
                : undefined,
            institution: this.institutionHasChanges ?
                this.institution && this.institution.id ? this.institution.id : undefined
                : undefined,
            coaches: this.coachesHasChanges
                ? this.coaches.map(x => x.id)
                : undefined,
            athletes: this.athletesHasChanges
                ? this.athletes.map(x => x.id)
                : undefined
        }
    }

    /**
     * Convierte el modelo actual a formato FormData para permitir envío de datos blob
     * @returns
     */
    toFormDataRequest(): FormData {

        const teamRequest = this.toRequest();

        const formData = new FormData();
        if (teamRequest.name && this.nameHasChanges) {
            formData.append('name', teamRequest.name);
        }
        if (teamRequest.initials && this.initialsHasChanges) {
            formData.append('initials', teamRequest.initials);
        }
        if (teamRequest.color && this.colorHasChanges) {
            formData.append('color', teamRequest.color);
        }
        if (teamRequest.institution && this.institutionHasChanges) {
            formData.append('institution', teamRequest.institution.toString());
        }
        if (teamRequest.active && this.isActiveHasChanges) {
            formData.append('active', teamRequest.active ? 'true' : 'false');
        }
        if (teamRequest.athletes && this.athletesHasChanges) {
            this.athletes.forEach(x => formData.append('athletes', x.id.toString()));
        }
        if (teamRequest.coaches && this.coachesHasChanges) {
            this.coaches.forEach(x => formData.append('coaches', x.id.toString()));
        }
        // La foto lo obtiene del modelo y no de la interfaz como los campos anteriores
        if (this.imageFileHasChanges && this.imageFile) {
            formData.append('image', this.imageFile, this.imageFile.name);
        }

        return formData;
    }

    select(): void {
        this.isSelected = true;
    }

    unselect(): void {
        this.isSelected = false;
    }

    toggleSelection(): void {
        this.isSelected = !this.isSelected;
    }

    setZeroMessages(): void {
        this.totalMessages = 0;
    }

    setNewMessage(): void {
        this.totalMessages += 1;
    }

    /**
     * Aplica los valores actuales del objeto para una siguiente validación de cambios
     */
    applyChanges(): void {
        this.setInitialValues(this);
    }

    /**
     * 
     * @param deep Check if any property object is cloned. TODO: Doesn't implemented
     * @returns 
     */
    clone(deep = false): Team {
        const clonedTeam = new Team();
        clonedTeam.id = this.id;
        clonedTeam.name = this.name;
        clonedTeam.image = this.image;
        clonedTeam.isActive = this.isActive;
        clonedTeam.institution = this.institution;
        clonedTeam.institutionManagers = this.institutionManagers?.map(x => x);
        clonedTeam.createdAt = this.createdAt;
        clonedTeam.updatedBy = this.updatedBy;
        clonedTeam.updatedAt = this.updatedAt;
        clonedTeam.totalMessages = this.totalMessages;
        clonedTeam.room = this.room;
        clonedTeam.initials = this.initials;
        clonedTeam.color = this.color;
        clonedTeam.isSelected = this.isSelected;
        clonedTeam.hasAthletesSelected = this.hasAthletesSelected;
        clonedTeam.imageFile = this.imageFile;
        clonedTeam.coaches = this.coaches.map(x => x);
        clonedTeam.athletes = this.athletes.map(x => x);
        return clonedTeam;
    }

    /**
     * Asigna valores iniciales a los campos de validación de cambios
     * @param team Equipo con los datos actuales
     */
    private setInitialValues(team: Team): void {
        this.initialValues.name = team.name;
        this.initialValues.initials = team.initials;
        this.initialValues.color = team.color;
        this.initialValues.image = team.image;
        this.initialValues.imageFile = team.imageFile;
        this.initialValues.coaches = team.coaches.slice();
        this.initialValues.athletes = team.athletes.slice();
        this.initialValues.institution.id = team.institution?.id;
        this.initialValues.isActive = team.isActive;
    }
}