/**
 * Estatus de publicación de elemento (Programa, Ejercicio)
 */
export enum PublishStatus {
    /**
     * Estado que se asigna cuando se crea el elemento (Programa, Ejercicio)
     * En caso de que se haya enviado a revisión y se cancela, se regresa a éste estatus
     */
    empty = 1,
    /**
     * Estado que indica que el elemento se publicó o envió a verificación
     */
    notVerified,
    /**
     * Estado cuando se verifica o acepta la publicación
     */
    verified,
    /**
     * Estado cuando se cancela la publicación
     */
    rejected,
}