export enum SubscriptionType {
  /**
   * One coach and athlete. No expiration
   */
  free = 1,
  /**
   * With suscription
   */
  paying,
  /**
   *
   */
  demo,
  /**
   * For coaches with influencers functions
   */
  sponsored,
  // TODO: this data was improved,needs to be approved
  trial = 5,
  custom = 6,
  extraCoach = 7
}

export const SubscriptionTypeString: { [keys in SubscriptionType]: string } = {
  1: 'Free',
  2: 'Paying',
  3: 'Demo',
  4: 'Sponsored',
  5: 'Trial',
  6: 'Custom',
  7: 'Extra Coach'
};
