export enum Specialty {
  performance = 1,
  injuries = 2,
  health = 3
}
export const SpecialtyString: {[keys in Specialty]: string} = {
  1: 'Performance',
  2: 'Injuries',
  3: 'Health'
};
