import { CatalogItemType } from "./types"

import { Team } from "./team"
import { Athlete } from "./athlete"
import { AthleteStatusOption } from "./enumerations"

/**
 * Estructura genérica para contener información de diferente tipos de catálogos: Equipo, atletas, etc
 */
export class CatalogItem {

    constructor(
        public type?: CatalogItemType,
        public id?: number,
        public name?: string,
        public image?: string,
        public initials?: string,
        public color?: string,
        public athletes: Array<Athlete> = [],
        public athletesStatus: Array<AthleteStatusOption> = []
    ) { }

    static fromTeam(team: Team): CatalogItem {
        return new CatalogItem(
            'team',
            team.id,
            team.name,
            team.image,
            team.initials,
            team.color,
            team.athletes,
            team.athletes.map(x => x.status)
        )
    }

    toTeam(): Team {
        const team = new Team();
        team.id = this.id;
        team.name = this.name;
        team.image = this.image;
        team.initials = this.initials;
        team.color = this.color;
        team.athletes = this.athletes;
        return team;
    }

    static fromAthlete(athlete: Athlete): CatalogItem {
        return new CatalogItem(
            'athlete',
            athlete.id,
            athlete.fullName,
            athlete.photo,
            null,
            null,
            [athlete],
            [athlete.status]
        )
    }

    toAthlete(): Athlete {
        const athlete = new Athlete();
        athlete.id = this.id;
        athlete.fullName = this.name;
        athlete.photo = this.image;
        return athlete;
    }
}