import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'sp-core';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('@admin/modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'admin',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@admin/modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'users',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@admin/modules/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'library',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@admin/modules/library/library.module').then(m => m.LibraryModule)
      },
      {
        path: 'finance',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@admin/modules/finance/finance.module').then(m => m.FinanceModule)
      },
      {
        path: 'management',
        canActivate: [AuthGuard],
        loadChildren: () => import('@admin/modules/management/management.module').then(m => m.ManagementModule)
      },
      {
        path: 'online-education',
        canActivate: [AuthGuard],
        loadChildren: () => import('@admin/modules/online-education/online-education.module').then((m) => m.OnlineEducationModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
