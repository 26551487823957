import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscriber, merge, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import moment from 'moment';

import { UserInvitation, User, UserProfile, Users, InstitutionalImage, UserMetadata } from '../models';
import { UserLanguageCode, UserType } from '../models/enumerations';
import { InstitutionalImageResponse, UserInvitationResponse, UserPostRequest, UserProfileResponse, UserResponse, UsersResponse } from '../models/interfaces';

import { SP_CORE_CONFIG } from '../sp-core.constants';
import { SpCoreConfig } from '../sp-core-config.interface';

import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { SubscriptionService } from './subscription.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private authenticatedUserSubject$ = new BehaviorSubject<User>(null);

  /**
   * Usuario autenticado. Se llama desde el inicio de sesión para cargar valores necesarios desde el inicio (PE: Widgets)
   *```
   * IMPORTANTE: 
   * - No filtrar por objeto nulo (filter(x => !!x)) ya que se realiza lógica para obtener la información si ese fuera el caso
   * - No suscribirse a éste observable ya que se asigna en el login y si el usuario ya estuviera autenticado no se notificaría, en su lugar suscribirse a user$
   * ```
   */
  authenticatedUser$ = this.authenticatedUserSubject$.asObservable();

  private userRetrievedSubject$ = new BehaviorSubject<User>(null);
  /**
   * Escucha cuando la información del usuario en sesión es recuperada
   */
  userRetrieved$ = this.userRetrievedSubject$.asObservable().pipe(filter(x => !!x));

  private userUpdatedSubject$ = new BehaviorSubject<User>(null);
  /**
   * Escucha cuando la información del usuario en sesión es actualizada
   */
  userUpdated$ = this.userUpdatedSubject$.asObservable();

  private userSubject$ = new BehaviorSubject<User>(null);
  /**
   * Escucha la información actual del usuario en sesión. Cuando se lee y actualiza
   */
  user$ = this.userSubject$.asObservable().pipe(filter(x => !!x && this.checkLoggedInUser(x.id)));

  private institutionalImageSubject$ = new BehaviorSubject<InstitutionalImage>(null);
  /**
   * Escucha cambios en la imagen de institución del usuario en sesión
   */
  institutionalImage$ = this.institutionalImageSubject$.asObservable().pipe(filter(x => !!x && this.checkLoggedInUser(x.userId)));

  private profileSubject$ = new BehaviorSubject<UserProfile>(null);
  /**
   * Escucha la información actual del perfil del usuario
   */
  profile$ = this.profileSubject$.asObservable().pipe(filter(x => !!x));

  /**
   * Usuario autenticado con datos de imagen institucional.
   * ```
   * Se asigna su propiedad InstitutionalImage por lo que al obtener user.photo se verifica si tiene información previamente a esa propiedad
   * ```
   */
  authenticatedUserWithInstitutionalImage$: Observable<User> = merge(
    // Escucha cuando se lee información de perfil del usuario en sesión
    this.profile$.pipe(
      map(x => x.institutionalImage)
    ),
    // Escucha asignación o modificación de imagen institucional del usuario (Cuando se modifica desde el perfil o desde cualquier parte)
    this.institutionalImage$
  ).pipe(
    // Importante: Se utiliza defaultIfEmpty para que se emita el usuario aunque no tenga imagen institucional (no emitan valor los observables del merge)
    defaultIfEmpty(),
    mergeMap(institutionalImage => {
      // Asigna los datos de imagen institucional al usuario
      return this.user$.pipe(
        map(user => {
          if (!institutionalImage) return user;
          user.institutionalImage = institutionalImage;
          return user;
        })
      )
    })
  );

  constructor(
    @Inject(SP_CORE_CONFIG) private config: SpCoreConfig,
    private api: ApiService,
    private authService: AuthService,
    private subscriptionService: SubscriptionService
  ) { }

  /**
   * Obtiene información del usuario autenticado. Se llama desde el inicio de sesión para cargar valores necesarios previos a la primera pantalla
   * ```
   * EP GET me
   * ```
   * @returns 
   */
  getAuthenticatedUser(): Observable<User> {

    // Indica que la solicitud de información proviene de aplicaciones web (admin/web).
    // En backend se considera para crear en automático la institución en caso de no existir
    const params = new HttpParams().set('dashboard', '1');

    const userInfoUrl = this.config.userInfoUrl || 'me';
    return this.api.get<UserResponse>(
      userInfoUrl,
      params
    ).pipe(
      map(response => {
        const user = new User().fromResponse(response);
        // Almacena el identificador del usuario autenticado
        this.authService.userAuthenticatedId = user.id;
        this.authService.userAuthenticatedLanguage = user.language ? UserLanguageCode[user.language] : null;
        // Almacena la institución del usuario autenticado. En caso de que el usuario no tenga institutión por backend se crea
        this.authService.institutionId = user.institution.id;
        return user;
      }),
      // Obtiene las invitaciones hechas al usuario y las asigna al modelo
      mergeMap(user => {
        return this.api.get<Array<UserInvitationResponse>>(
          'user-permission/'
        ).pipe(
          map(response => {
            const invitations = response.map(x => UserInvitation.fromResponse(x));
            user.invitations = invitations;
            return user;
          })
        )
      }),
      tap(user => {
        // Institución seleccionada por el usuario almacenada en BD
        let currentInstitutionId = user.metadata?.institutionId;
        // Sino tiene institución almacenada, selecciona la primera institución donde se tenga invitación
        // En caso que no se tenga invitaciones a institución selecciona la del usuario
        if (!currentInstitutionId) {
          currentInstitutionId = (user.institutions.find(x => x.isInvitation === true) || user.institution)?.id;
        }
        this.authService.currentInstitutionId = currentInstitutionId;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  /**
   * Obtiene información del usuario autenticado.
   * ```
   * EP: GET me/
   * ```
   */
  get(): Observable<User> {

    const notifyUserData = (observer: Subscriber<User>, user: User) => {
      // Notifica que la información del usuario ha sido obtenido
      if (this.checkLoggedInUser(user.id)) {
        this.userSubject$.next(user);
        this.userRetrievedSubject$.next(user);
      }
      observer.next(user);
      observer.complete();
    }

    return new Observable<User>(observer => {
      this.authenticatedUser$.pipe(take(1)).subscribe(user => {
        if (user) {
          // Notifica que la información del usuario ha sido obtenido. Información previamente obtenida
          notifyUserData(observer, user);
        } else {
          // Obtiene y notifica que la información del usuario ha sido obtenido
          this.getAuthenticatedUser().pipe(take(1)).subscribe(user => {
            notifyUserData(observer, user);
          });
        }
      });
    });
  }

  /**
   * Obtiene datos de perfil del usuario indicado (Por lo general del usuario autenticado)
   * @param userId Identificador de usuario
   * @returns 
   */
  getProfile(userId: number): Observable<UserProfile> {
    return this.api.get<UserProfileResponse>(
      `profile/${userId}/`
    ).pipe(
      catchError(this.api.processError('UserService.getProfile')),
      map(response => {
        const profile = UserProfile.fromResponse(response);
        this.profileSubject$.next(profile);
        return profile;
      })
    );
  }

  getManagementCoachesPaginated(
    params: HttpParams
  ): Observable<Users> {
    return this.api.get<UsersResponse>(
      'management-coaches/',
      params
    ).pipe(
      catchError(this.api.processError('UserService.getManagementCoachesPaginated')),
      map(response => new Users().fromResponse(response))
    );
  }

  getManagementAthletesPaginated(
    params: HttpParams
  ): Observable<Users> {
    return this.api.get<UsersResponse>(
      'management-athletes/',
      params
    ).pipe(
      catchError(this.api.processError('UserService.getManagementAthletesPaginated')),
      map(response => new Users().fromResponse(response))
    );
  }

  /**
   * Agrega/crea un nuevo atleta a la institución. TODO: Realizar pruebas.
   * @param user Datos de usuario atleta
   */
  createAthlete(
    user: User
  ): Observable<UserResponse> {

    const body: UserPostRequest = {
      full_name: user.fullName,
      email: user.email,
      password: user.password,
      confirm_password: user.password,
      country_code: user.countryCode,
      birthday: moment(user.birthdate).format('YYYY-MM-DD'),
      photo: user.photo,
      phone: user.phone,
      type: UserType.athlete,
      region: user.region.id,
      gender: user.gender,
      heigth: user.height,
      weigth: user.weight,
      institution: user.institution.id
    };

    return this.api.post<UserResponse>('users/', body);
  }

  /**
   * Agrega/crea un nuevo strenght coach a la institución. TODO: Realizar pruebas
   * @param user Datos de usuario
   */
  createCoach(
    user: User
  ): Observable<UserResponse> {

    const body: UserPostRequest = {
      full_name: user.fullName,
      email: user.email,
      password: user.password,
      confirm_password: user.password,
      country_code: user.countryCode,
      birthday: moment(user.birthdate).format('YYYY-MM-DD'),
      photo: user.photo,
      phone: user.phone,
      type: UserType.strengthCoach,
      region: user.region.id,
      gender: user.gender,
      heigth: user.height,
      weigth: user.weight,
      institution: user.institution.id
    };

    return this.api.post<UserResponse>('users/', body);
  }

  /**
   * Agrega/crea un nuevo institution manager a la institución. TODO: Realizar pruebas
   * @param user Datos de usuario
   */
  createInstitutionManager(
    user: User
  ): Observable<UserResponse> {

    const body: UserPostRequest = {
      full_name: user.fullName,
      email: user.email,
      password: user.password,
      confirm_password: user.password,
      country_code: user.countryCode,
      birthday: moment(user.birthdate).format('YYYY-MM-DD'),
      photo: user.photo,
      phone: user.phone,
      type: UserType.institutionManager,
      region: user.region ? user.region.id : undefined,
      gender: user.gender,
      heigth: user.height,
      weigth: user.weight,
      team: user.team.id,
      institution: user.institution.id
    };

    return this.api.post<UserResponse>('users/', body);
  }

  /**
   * Crea un usuario
   * @param user Objeto con datos de usuario a crear
   * @param withFormData Indica si los datos se requieren enviar en formato FormData. Necesario para los datos tipo blob como File
   * @returns
   */
  create(
    user: User,
    withFormData = false
  ): Observable<User> {
    return this.api.post<UserResponse>(
      'users/',
      withFormData ? user.toFormDataRequest() : user.toRequest()
    ).pipe(
      catchError(this.api.processError('UserService.create')),
      map(response => User.fromResponse(response))
    );
  }

  /**
   * Crea un usuario
   * ```
   * Se indica que a diferencía de userService.create éste EP tiene varias validaciones específicas del módulo management
   * ```
   * @param user Objeto con datos de usuario a crear
   * @param withFormData Indica si los datos se requieren enviar en formato FormData. Necesario para los datos tipo blob como File
   * @returns
   */
  managementCreate(
    user: User,
    withFormData = false
  ): Observable<User> {
    return this.api.post<UserResponse>(
      'management-users/',
      withFormData ? user.toFormDataRequest() : user.toRequest()
    ).pipe(
      catchError(this.api.processError('UserService.managementCreate')),
      map(response => {

        // Actualiza número de subscripciones según el tipo de usuario
        this.updateAthleteCoachesCount(user, 1)

        return User.fromResponse(response);
      })
    );
  }

  /**
   * Actualiza los datos del usuario indicado
   * ```
   * EP: user/<userId>/
   * ```
   * @param user Objeto con datos de usuario a crear
   * @param withFormData Indica si los datos se requieren enviar en formato FormData. Necesario para los datos tipo blob como File
   * @returns 
   */
  update(
    user: User,
    withFormData = false
  ): Observable<User> {

    const userId = user.id || this.authService.userAuthenticatedId;

    return this.api.patch<UserResponse>(
      `user/${userId}/`,
      withFormData ? user.toFormDataRequest() : user.toRequest()
    ).pipe(
      catchError(this.api.processError('UserService.update')),
      map(response => {
        const userUpdated = User.fromResponse(response);
        // Notifica cambios en la información del usuario
        this.emitUserUpdate(userUpdated);
        return userUpdated;
      })
    );
  }

  setInstitution(
    userId: number,
    institutionId: number
  ): Observable<User> {

    const data = {
      institution_id: institutionId
    };

    return this.api.patch<UserResponse>(
      `user/${userId}/`,
      data
    ).pipe(
      catchError(this.api.processError('UserService.setInstitution')),
      map(response => {
        const userUpdated = User.fromResponse(response);
        // Notifica cambios en la información del usuario
        this.emitUserUpdate(userUpdated);
        return userUpdated;
      })
    );
  }

  /**
   * Asigna institución por defecto. Institución por defecto para usuarios no asignados a ninguna institución de la aplicación (Institución cero)
   * @param userId 
   * @param institutionId Identificador de la institución por defecto
   * @returns 
   */
  setDefaultInstitution(
    userId: number,
    institutionId: number
  ): Observable<User> {

    const data = {
      institution_id: institutionId,
      // Indica que el perfil está incompleto para que al iniciar sesión se solicite la captura de los datos
      is_profile_complete: false
    };

    return this.api.patch<UserResponse>(
      `user/${userId}/`,
      data
    ).pipe(
      catchError(this.api.processError('UserService.setInstitution')),
      map(response => {
        const userUpdated = User.fromResponse(response);
        // Notifica cambios en la información del usuario
        this.emitUserUpdate(userUpdated);
        return userUpdated;
      })
    );
  }

  /**
   * Asigna perfil de institution manager y la institución sobre la que será manager
   * @param userId Identificador de usuario
   * @param institutionId Identificador de institución a asignar
   * @returns 
   */
  setAsInstitutionManager(
    userId: number,
    institutionId: number
  ): Observable<User> {

    const data = {
      institution_id: institutionId,
      is_manager: true
    };

    return this.api.patch<UserResponse>(
      `user/${userId}/`,
      data
    ).pipe(
      catchError(this.api.processError('UserService.setInstitution')),
      map(response => {
        const userUpdated = User.fromResponse(response);
        // Notifica cambios en la información del usuario
        this.emitUserUpdate(userUpdated);
        return userUpdated;
      })
    );
  }

  /**
   * Elimina el usuario con el identificador indicado
   * @param userId Identificador de usuario
   * @returns 
   */
  delete(
    userId: number
  ): Observable<null> {
    return this.api.delete(
      `user/${userId}/`
    ).pipe(
      catchError(this.api.processError('UserService.delete'))
    );
  }

  searchByEmail(
    email: string
  ): Observable<Array<User>> {
    const params = new HttpParams().set('search', email);
    return this.api.get<UsersResponse>(
      'user-search/',
      params
    ).pipe(
      catchError(this.api.processError('UserService.searchByEmail')),
      map(response => response.data.map(x => new User().fromResponse(x)))
    );
  }

  saveSelectedInstitution(
    user: User,
    institutionId: number
  ): Observable<User> {

    const userToUpdate = new User();
    userToUpdate.id = user.id;
    userToUpdate.metadata = user.metadata || new UserMetadata();
    userToUpdate.metadata.institutionId = institutionId;

    return this.update(userToUpdate).pipe(
      tap(() => {
        // Guarda en almacenamiento local la nueva institución seleccionada
        this.authService.currentInstitutionId = institutionId;
      })
    );
  }

  /**
   * Cambia el estatus de un usuario, de activo a Inactivo y viceversa
   * ```
   * institutional_enabled: Indica activo/inactivo
   * is_active: Indica si el registro está eliminado
   * ```
   * @param userId Identificador de usuario
   * @param isEnabled Estatus del usuario: Activo/ Inactivo
   * @returns 
   */
  changeStatus(
    userId: number,
    isEnabled = false
  ): Observable<User> {

    const data = {
      institutional_enabled: !isEnabled
    }

    return this.api.patch<UserResponse>(
      `user/${userId}/`,
      data
    ).pipe(
      catchError(this.api.processError('UserService.changeStatus')),
      map(response => {
        const userUpdated = User.fromResponse(response);
        // Notifica cambios en la información del usuario
        this.emitUserUpdate(userUpdated);
        return userUpdated;
      })
    );
  }

  /**
   * Revisa si el usuario indicado es el mismo que inició sesión
   */
  checkLoggedInUser(userId: number): boolean {
    return userId === this.authService.userAuthenticatedId;
  }

  /**
   * Notifica cambios en la información del usuario
   * @param user Objeto con datos de usuario actualizados
   */
  private emitUserUpdate(user: User): void {

    // En caso de que el usuario modificado no sea el de la sesión iniciada no realiza la notificación
    if (!this.checkLoggedInUser(user.id)) return;

    // Emite el usuario con información actualizada
    this.userSubject$.next(user);

    // Notifica cambios en la información del usuario
    this.userUpdatedSubject$.next(user);
    // Indica como si se haya realizado una obtención de datos para notificar que el nuevo estado de perfil completo
    if (user.isProfileComplete) {
      this.userRetrievedSubject$.next(user);
    }
  }

  saveInstitutionalImage(
    institutionalImage: InstitutionalImage
  ): Observable<InstitutionalImage> {
    return institutionalImage.id
      ? this.changeInstitutionalImage(institutionalImage)
      : this.setInstitutionalImage(institutionalImage);
  }

  setInstitutionalImage(
    institutionalImage: InstitutionalImage
  ): Observable<InstitutionalImage> {

    if (!institutionalImage.institutionId) {
      institutionalImage.institutionId = this.authService.currentInstitutionId;
    }

    return this.api.post<InstitutionalImageResponse>(
      `institution-image/`,
      institutionalImage.toFormDataRequest()
    ).pipe(
      map(response => {
        const updatedObject = InstitutionalImage.fromResponse(response);
        this.institutionalImageSubject$.next(updatedObject);
        return updatedObject;
      })
    );
  }

  changeInstitutionalImage(
    institutionalImage: InstitutionalImage
  ): Observable<InstitutionalImage> {

    if (!institutionalImage.institutionId) {
      institutionalImage.institutionId = this.authService.currentInstitutionId;
    }

    return this.api.patch<InstitutionalImageResponse>(
      `institution-image/${institutionalImage.id}/`,
      institutionalImage.toFormDataRequest()
    ).pipe(
      map(response => {
        const updatedObject = InstitutionalImage.fromResponse(response);
        this.institutionalImageSubject$.next(updatedObject);
        return updatedObject;
      })
    );
  }

  /**
   * Envía correo de activación de cuenta
   * @param user 
   * @returns 
   */
  sendActivationEmail(
    user: User
  ): Observable<string> {

    const params = new HttpParams().set('email', user.email);

    return this.api.get<{ token: string }>(
      `mail-new-user/`,
      params
    ).pipe(
      map(response => response.token),
      catchError(this.api.processError('UserService.sendActivationEmail')),
    );
  }

  emitAuthenticatedUser(user: User): void {
    this.authenticatedUserSubject$.next(user);
  }

  private updateAthleteCoachesCount(
    user: User,
    increment: number
  ): void {
    // Actualiza número de subscripciones según el tipo de usuario
    if (user.type == UserType.athlete) {
      this.subscriptionService.updateCurrentAthletes(increment);
    }
    else if (user.type == UserType.institutionManager || user.type == UserType.strengthCoach) {
      this.subscriptionService.updateCurrentCoaches(increment);
    }
  }
}
