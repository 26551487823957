import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { InputValueType, UtilitiesService } from 'sp-core';

@Component({
  selector: 'sp-editable',
  templateUrl: './editable.component.html',
  styleUrls: ['./editable.component.scss']
})
export class EditableComponent implements OnChanges {

  @Input() valueType: InputValueType;

  @Input() value: string;

  /**
   * Only for time input sufix
   */
  @Input() timeInputSufix: 'hours' | 'min';

  @Input() mask = '';

  @Input() placeholder = '';

  @Input() isEditMode = false;

  @Input() tabIndex = 0;

  @Input() editWithSingleClick = false;

  @Input() changeEditMode: Subject<boolean>;

  @Input() disabled: false;

  @Input() fullWidth = false;

  /**
   * Indica si el valor capturado en el campo de captura se aplica incluso al perder el foco y no solamente con el enter
   */
  @Input() changeOnBlur = false;

  @Output() valueChange = new EventEmitter<string>();

  @Output() keyUpEnter = new EventEmitter<any>();

  @Output() keyUpTab = new EventEmitter<any>();

  @Output() focus = new EventEmitter<any>();

  @Output() blur = new EventEmitter<any>();

  @Output() modeChange = new EventEmitter<boolean>();

  valuesType = InputValueType;

  localPlaceholder = '';
  localMask = '';
  localSpecialCharacters = [];

  separator: string;

  /**
   * Valor auxiliar que se estará modificando al capturar pero que se aplica al valor mediante el enter
   */
  valueModified = '';

  constructor() {
    this.setPropertiesBasedValueType();
  }

  ngOnChanges(changes: SimpleChanges): void {

    // Escucha cuando externamente se modifica el modo de edición del texto de captura
    if (changes['isEditMode']) {
      this.emitModeChange(this.isEditMode);
    }

    const valueChanges = changes.value;
    const valueTypeChanges = changes.valueType;
    const timeInputSufixChanges = changes.timeInputSufix;

    // Initial values for time input. Listen for first change of all properties related
    if (valueChanges && valueChanges.isFirstChange()
      && valueTypeChanges && valueTypeChanges.isFirstChange()
      && timeInputSufixChanges && timeInputSufixChanges.isFirstChange()) {
      this.setTimeValue();
    }

    if (valueChanges) {
      this.valueModified = this.value;
      // Listen next changes
      if (!valueChanges.isFirstChange()) this.setTimeValue();
    }

    if (valueTypeChanges) {
      this.setPropertiesBasedValueType();
      // Listen next changes
      if (!valueTypeChanges.isFirstChange()) this.setTimeValue();
    }

    if (timeInputSufixChanges) {
      this.setPropertiesBasedValueType();
      // Listen next changes
      if (!timeInputSufixChanges.isFirstChange()) this.setTimeValue();
    }
  }

  onLabelClick(): void {
    if (this.disabled) return;
    this.editMode();
  }

  onInputBlur(event: any): void {
    if (this.changeOnBlur) {
      this.value = this.valueModified;
      this.valueChange.emit(this.value);
    }
    this.blur.emit(event);
    this.readOnlyMode();
  }

  onKeyEnter(event: any): void {
    // Aplica el valor capturado. Aplica el cambio de valor en el componente
    this.value = this.valueModified;
    this.valueChange.emit(this.value);
    this.keyUpEnter.emit(event);
    this.readOnlyMode();
  }

  onKeyTab(event: any): void {
    if (this.changeOnBlur) {
      this.value = this.valueModified;
      this.valueChange.emit(this.value);
    }
    this.keyUpTab.emit(event);
    this.readOnlyMode();
  }

  protected editMode(): void {
    this.emitModeChange(true);
  }

  protected readOnlyMode(): void {
    this.emitModeChange(false);
  }

  protected emitModeChange(isEditMode: boolean): void {
    this.isEditMode = isEditMode;
    this.modeChange.emit(this.isEditMode);
  }

  private setTimeValue() {

    // Only time values
    if (!this.value || this.valueType !== InputValueType.time) return;

    // For time values set corresponding format
    this.value = this.formatTimeValue(this.value);
  }

  private setPropertiesBasedValueType(): void {

    if (!this.valueType) return;

    this.separator = null;
    switch (this.valueType) {
      case InputValueType.text:
        this.localPlaceholder = this.placeholder ? this.placeholder : '...';
        this.localMask = null;
        break;
      case InputValueType.int:
        this.localPlaceholder = this.placeholder ? this.placeholder : '0';
        this.localMask = this.mask ? this.mask : '0*'; // TODO: Pendiente aplicar máscara
        break;
      case InputValueType.decimal:
        this.localPlaceholder = this.placeholder ? this.placeholder : '0.00';
        this.localMask = this.mask ? this.mask : '0*.00'; // TODO: Pendiente aplicar máscara
        this.separator = ',';// Para visualizar en etiqueta/label
        break;
      case InputValueType.time:
        this.localPlaceholder = this.placeholder
          ? this.placeholder
          : this.formatTimeValue("");
        break;
      case InputValueType.tempo:
        this.localPlaceholder = this.placeholder ? this.placeholder : '0:0:0';
        this.localMask = this.mask ? this.mask : '0:0:0';
        break;
      default:
        this.localPlaceholder = '';
        this.localMask = null;
        break;
    }
  }

  private formatTimeValue(value): string {
    return UtilitiesService.formatToTimeWithSufixes(value,
      (this.timeInputSufix === 'hours' ? "h" : "'"),
      (this.timeInputSufix === 'hours' ? "'" : "''")
    );
  }
}
