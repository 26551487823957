import { Serializer } from './interfaces/serializer.interface';
import { PaginationLinkResponse } from './interfaces/pagination-link-response.interface';

export class PaginationLink implements Serializer<PaginationLink> {
    constructor(
        public first?: string,
        public last?: string,
        public next?: string,
        public prev?: string
    ) { }
    fromResponse(response: PaginationLinkResponse): PaginationLink {
        return new PaginationLink(
            response.first,
            response.last,
            response.next,
            response.prev
        )
    }
    toRequest() {
        throw new Error('Method not implemented.');
    }
}