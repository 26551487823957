import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

const MODULES = [
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatButtonModule,
  MatTooltipModule
]

@NgModule({
  declarations: [TableComponent],
  imports: [
    ...MODULES,
    CommonModule
  ],
  exports: [
    ...MODULES,
    TableComponent
  ]
})
export class SpTableModule { }
