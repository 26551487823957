<div class="sp-warning-dialog">
  <!-- Title -->
  <h1 mat-dialog-title>{{data?.title || ('common.titles.warning' | i18next)}}</h1>
  <!-- Message -->
  <div mat-dialog-content>
    <p>{{data?.text}}</p>
  </div>
  <!-- Action buttons -->
  <div class="justify-content-end"
    mat-dialog-actions
    style="box-sizing:initial">
    <button mat-button
      color="primary"
      mat-dialog-close>
      {{data?.cancelText || ('common.actions.cancel' | i18next)}}
    </button>
    <button mat-flat-button
      color="primary"
      [mat-dialog-close]="WarningDialogResult.ok"
      cdkFocusInitial>
      {{data?.action || ('common.actions.delete' | i18next)}}
    </button>
    <button *ngIf="data?.move"
      mat-flat-button
      color="primary"
      [mat-dialog-close]="WarningDialogResult.deleteAndMove">
      {{data?.deleteAndMoveText || ('common.actions.deleteAndMove' | i18next)}}
    </button>
  </div>
</div>