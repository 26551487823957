import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { AuthService, ServiceResponse, SocialType } from 'sp-core';
import { CONTROL_NAMES } from './forgot-password.constants';

@Component({
  selector: 'sp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @Output() emailSent = new EventEmitter<null>();

  @Output() goToLogin = new EventEmitter<null>();

  @Output() error = new EventEmitter<string>();

  controlNames = CONTROL_NAMES;

  form: UntypedFormGroup;

  message: string;

  messageError: string;

  proccessing = false;

  logo = 'assets/images/solo-performance.svg';

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  resetPassword(): void {

    this.proccessing = true;

    const userName = (this.form.get(this.controlNames.email).value as string).toLowerCase();

    this.authService.forgotPassword(
      userName
    ).pipe(
      catchError((error: ServiceResponse) => {
        // Si ocurre error en el login verifica si existe el tipo de usuario con social login
        return this.authService.findSocialLogin(
          userName
        ).pipe(
          map(socialType => {
            // Sino existe el usuario (SocialType.notSet) o si es de tipo con usuario y contraseña (default) visualiza el error original
            if (socialType === SocialType.notSet || socialType === SocialType.default) {
              this.messageError = error.errorMessage.firstError;
            }
            else {
              // Visualiza el mensaje de que el usuario está registrado con social login
              this.messageError = `The email is registered with social login. Please use the Google or Apple social login.`;
            }
            throw (this.messageError);
          })
        )
      }),
      finalize(() => {
        this.proccessing = false;
      })
    ).subscribe(() => {
      this.messageError = null;
      this.message = 'Email sent. Please check it for instructions';
      this.emailSent.next();
    }, (error: string) => {
      this.error.emit(error);
    });
  }

  goToLoginClick(): void {
    this.goToLogin.emit();
  }

  private createForm() {

    this.form = this.fb.group({});

    const usernameControl = this.fb.control(null, [Validators.required, Validators.email]);

    this.form.addControl(this.controlNames.email, usernameControl);
  }
}
