import { HttpParams } from '@angular/common/http';

/**
 * Struct for request paginated data
 */
export class PaginatedParams {
    
    /**
     * Obtiene el índice en la que deberá iniciar la lectura de datos en base a la página actual
     */
    get startPageIndex(): number {
        return this.pageSize * (this.page - 1);
    }

    /**
     * Obtiene el índice en la que deberá finalizar la lectura de datos en base a la página actual
     */
    get endPageIndex(): number {
        return this.startPageIndex + this.pageSize - 1;
    }

    constructor(
        /**
         * Search value to filter
         */
        public search?: string,
        /**
         * Page number to retrieve. Index = 1
         */
        public page?: number,
        /**
         * Number of items to retrieve
         */
        public pageSize?: number
    ) { }

    toRequest(params?: HttpParams): HttpParams {

        let paginatedParams = params || new HttpParams();

        if (this.search) {
            paginatedParams = paginatedParams.set('search', this.search);
        }

        if (this.page) {
            paginatedParams = paginatedParams.set('page', this.page.toString());
        }

        if (this.pageSize) {
            paginatedParams = paginatedParams.set('perpage', this.pageSize.toString());
        }
        // En caso de que no se envíe tamaño de página 0 indica paginación 10000 para simular que no tiene paginación
        else if (this.pageSize === 0) {
            paginatedParams = paginatedParams.set('perpage', '10000');
        }

        return paginatedParams;
    }
}