import { Serializer, UserAccessResponse } from './interfaces';
import { UserPermission } from './user-permission';

export class UserAccess implements Serializer<UserAccess> {

    constructor(
        public token?: string,
        public refreshToken?: string,
        public institutionId?: number,
        public permission?: UserPermission
    ) { }

    fromResponse(response: UserAccessResponse): UserAccess {
        return new UserAccess(
            response.access,
            response.refresh,
            response.institution,
            response.permission ? new UserPermission().fromResponse(response.permission) : null
        )
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }
}