import { Serializer, UserProfileExperienceResponse } from "./interfaces";
import { Validity } from "./validity";

export class UserProfileExperience implements Serializer<UserProfileExperience> {

    constructor(
        public id?: number,
        public company?: string,
        public position?: string,
        public image?: string,
        public validity?: Validity
    ) { }

    fromResponse(response: UserProfileExperienceResponse): UserProfileExperience {
        return UserProfileExperience.fromResponse(response);
    }

    static fromResponse(response: UserProfileExperienceResponse): UserProfileExperience {
        return new UserProfileExperience(
            response.id,
            response.company,
            response.position,
            response.image,
            Validity.fromResponse(response)
        );
    }
    
    toRequest() {
        throw new Error("Method not implemented.");
    }
}