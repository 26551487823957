import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { UserCredential } from 'firebase/auth';

import { Institution, InstitutionManager, SocialLogin, SocialLoginRegister, Team } from '../models';
import { SocialType } from '../models/enumerations';
import { SocialLoginResponse, SocialLoginValidation } from '../models/interfaces';

@Injectable()
export class SocialLoginService {

  private socialLoginRequestedSubject = new Subject<SocialType>();
  /**
   * Indica cuando un inicio de sesión con social login se ha solicitado
   */
  socialLoginRequested$ = this.socialLoginRequestedSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Valida si el usuario existe en el sistema, en caso contrario lo registra
   * ```
   * credential of type UserCredential is for apple login @angular/fire does not support apple login
   * ```
   * @param socialType 
   * @param credential 
   * @returns 
   */
  validateSocialLogin(
    socialType: SocialType,
    credential: firebase.auth.UserCredential | UserCredential
  ): Observable<SocialLoginValidation> {

    const email = credential.user.email;
    const socialToken = credential.user.uid;
    const socialPhotoURL = (socialType === SocialType.facebook)
      ? (<any>(<firebase.auth.UserCredential>(credential)).additionalUserInfo.profile).picture.data.url
      : credential.user.photoURL;

    return this.validate(
      email,
      socialType
    ).pipe(
      mergeMap(response => {

        // Verifica si el usuario ya existe en SoloPerformance
        if (response.success) {

          // Si es de tipo institution manager o coach se retorna el usuario
          if (response.data.permission.isManager || response.data.permission.isCoach) {
            return of(<SocialLoginValidation>{
              email: email,
              institutionId: response.data.institutionId,
              token: response.data.token,
              refreshToken: response.data.refreshToken
            });
          }
          // En caso contrario se emite un error para visualizar al usuario
          else {
            return throwError('The user is not an institution manager or coach');
          }

        }
        // El usuario no existe en SPF entonces se registra con datos de institución, equipo y manager por defecto
        else {

          // Datos de equipo
          const team = new Team();
          team.name = email;

          // Datos de administrador de institución
          const manager = new InstitutionManager();
          manager.fullName = email;
          manager.email = email;
          manager.photo = socialPhotoURL;
          manager.phone = '9999999999';

          const socialLoginRegister = new SocialLoginRegister();
          socialLoginRegister.socialType = socialType;
          socialLoginRegister.socialLoginToken = socialToken;
          socialLoginRegister.institution = new Institution().getWithDefaults(email);
          socialLoginRegister.team = team;
          socialLoginRegister.institutionManager = manager;

          return this.register(
            socialLoginRegister
          ).pipe(
            map(response => {
              return <SocialLoginValidation>{
                email: email,
                institutionId: response.institution,
                token: response.access,
                refreshToken: response.refresh
              };
            })
          );
        }
      })
    );
  }

  /**
   * Solicita inicio de sesión con red social
   * @param socialType Tipo de login social
   */
  loginWithSocial(socialType: SocialType): void {
    this.socialLoginRequestedSubject.next(socialType);
  }

  validate(
    email: string,
    socialType: SocialType
  ): Observable<SocialLogin> {

    const payload = {
      email: email,
      social_type: socialType
    };

    return this.http.post<SocialLoginResponse>(
      'validate-social-login/',
      payload
    ).pipe(
      map(response => new SocialLogin().fromResponse(response)),
      catchError(ex => {
        if (ex.error?.errors?.length) {
          throw ex.error.errors[0];
        }
        throw ex;
      })
    );
  }

  register(
    socialLoginRegister: SocialLoginRegister
  ): Observable<any> {
    return this.http.post(
      `social-register/`,
      socialLoginRegister.toRequest()
    ).pipe(
      catchError(ex => {
        if (ex.error?.errors?.length) {
          throw ex.error.errors[0];
        }
        throw ex;
      })
    );
  }
}
