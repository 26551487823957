import { ExerciseParameterLinkResponse, Serializer } from './interfaces';

import { ExerciseParameter } from './exercise-parameter';
import { ExerciseSubparameter } from './exercise-subparameter';
import { WorksetValue } from './workset-value';

/**
 * Representa una asignación entre parámetro de ejercicio con un bloque<->ejercicio
 */
export class ExerciseParameterLink implements Serializer<ExerciseParameterLink> {

    private _parameter: ExerciseParameter;
    get parameter() {
        return this._parameter;
    }
    set parameter(value: ExerciseParameter) {
        if (value) {
            this.parameterId = value.id;
        }
        this._parameter = value;
    }

    constructor(
        public id?: number,
        public parameterId?: number,
        /**
         * Valor asignado.
         * No se obtiene en el endpoint donde se mapea el objeto actual. Es un campo que se asigna desde frontend
        */
        public value?: WorksetValue,
    ) { }

    fromResponse(response: ExerciseParameterLinkResponse): ExerciseParameterLink {

        const parameterLink = new ExerciseParameterLink(
            response.id,
            response.block_exercise_catalog_id,
            null
        );

        parameterLink.parameter = response.block_exercise_catalog
            ? new ExerciseParameter().fromResponse(response.block_exercise_catalog)
            : null

        if (!parameterLink.parameterId) throw new Error('Null parameter Id');

        return parameterLink;
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }

    clone(): ExerciseParameterLink {

        const parameterLink = new ExerciseParameterLink(
            this.id,
            this.parameterId,
            this.value ? this.value.clone() : null
        );

        parameterLink.parameter = this.parameter
            ? this.parameter.clone()
            : null;

        return parameterLink;
    }
}