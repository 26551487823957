<mat-form-field class="w-100"
  appearance="outline">
  <mat-label>Tags. {{label}}</mat-label>
  <mat-chip-list #chipList aria-label="tags selection">
    <mat-chip (removed)="removeTag(tag)" *ngFor="let tag of tags">
      {{tag}}
      <mat-icon matChipRemove *ngIf="input">cancel</mat-icon>
    </mat-chip>
    <input
      [disabled]="tags.length == max"
      [placeholder]="tags.length < max ? 'New tag...' : ''"
      #tagInput
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addTag($event)"
      *ngIf="input">
  </mat-chip-list>
</mat-form-field>