import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'sp-notification-success',
  templateUrl: './notification-success.component.html',
  styleUrls: ['./notification-success.component.scss']
})
export class NotificationSuccessComponent {

  message: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) private data: string,
    private snackBar: MatSnackBar
  ) {
    this.message = data;
  }

  onCloseClick(): void {
    this.snackBar.dismiss();
  }
}
