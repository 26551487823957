import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DbNamePipe } from './db-name.pipe';

@NgModule({
  declarations: [
    DbNamePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DbNamePipe
  ]
})
export class SpDbNameModule { }
