import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputTimeDirective } from './input-time.directive';
import { TimeInputSufixDirective } from './time-input-sufix.directive';

const DIRECTIVES = [
  InputTimeDirective,
  TimeInputSufixDirective
];

@NgModule({
  declarations: [
    ...DIRECTIVES
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...DIRECTIVES
  ]
})
export class SpInputModule { }
