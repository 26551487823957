import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

import { SP_LIBRARY_CONFIG } from '../../../sp-library.constants';
import { SpLibraryConfig } from '../../../sp-library-config.interface';

import { SocketChatMessage } from '../models/interfaces';
import { User } from 'sp-core';

@Injectable()
export class ChatService<TMessage extends SocketChatMessage> {

  private wsSubject$: WebSocketSubject<TMessage>;

  private newMessageSubject$ = new Subject<TMessage>();
  newMessage$ = this.newMessageSubject$.asObservable().pipe(filter(x => !!x));

  private remitentSubject$ = new BehaviorSubject<User>(null);
  remitent$ = this.remitentSubject$.asObservable().pipe(filter(x => !!x));

  private recipientSubject$ = new BehaviorSubject<User>(null);
  recipient$ = this.recipientSubject$.asObservable().pipe(filter(x => !!x));

  private allowEmojisSubject$ = new BehaviorSubject<boolean>(null);
  allowEmojis$ = this.allowEmojisSubject$.asObservable().pipe(filter(x => x !== undefined && x !== null));

  set remitent(value: User) {
    this.remitentSubject$.next(value);
  }

  set recipient(value: User) {
    this.recipientSubject$.next(value);
  }

  set allowEmojis(value: boolean) {
    this.allowEmojisSubject$.next(value);
  }

  constructor(
    @Inject(SP_LIBRARY_CONFIG) private config: SpLibraryConfig,
  ) { }

  createChannel(room: string): WebSocketSubject<TMessage> {
    this.wsSubject$ = webSocket(`${this.config.ws}chat-messages/${room}/`);
    return this.wsSubject$;
  }

  connect(actions?: (message: TMessage) => void): void {
    this.wsSubject$.subscribe((message: TMessage) => {
      // Called whenever there is a message from the server.
      actions(message);
    }, () => {
      // Called if at any point WebSocket API signals some kind of error.
      this.reconnect(actions)
    }, () => {
      // Called when connection is closed (for whatever reason).
      console.log('Chat service disconnected')
    });
  }

  disconnect(): void {
    this.wsSubject$?.complete();
  }

  /**
   * Notifica la intención de enviar un mensaje
   * ```
   * - Ésto permite que el componente que consume el chat pueda almacenar los mensajes en BD
   * - Posteriormente es necesario llamar al método sendMessageToChannel para enviarlo por el canal con el id ya asignado
   * ```
   * @param message 
   */
  sendNewMessage(message: TMessage): void {
    this.newMessageSubject$.next(message);
  }

  /**
   * Envia mensaje al canal
   * @param message
   */
  sendMessageToChannel(message: TMessage): void {
    this.wsSubject$.next(message);
  }

  private reconnect(actions?: (message: TMessage) => void): void {
    this.disconnect();
    this.connect(actions);
  }
}
