<div class="sp-exercise-filter">
    <sp-card [classes]="['sp-exercise-filter__card-container']">
        <div class="sp-exercise-filter__container"
            [class.sp-exercise-filter__container--item-selected]="subcategoriesSelected.length">
            <!-- Niveles/grupos de categorías -->
            <ng-container *ngFor="let categoryGroup of exerciseCategoryGroups">
                <sp-exercise-filter-category-group class="sp-exercise-filter__category-group"
                    [readonly]="readonly"
                    [categoryGroup]="categoryGroup"
                    (subcategoryChange)="onCategoryGroupSubcategoryChange()"
                    (selectUnselectAll)="onCategoryGroupSelectUnselectAll()">
                </sp-exercise-filter-category-group>
            </ng-container>
            <!-- Des-seleccionar todo -->
            <button class="sp-icon sp-icon--fill sp-exercise-filter__unselect-all"
                mat-icon-button
                [hidden]="!subcategoriesSelected.length || readonly"
                [matTooltip]="'Clear all filters'"
                (click)="onClearAllClick()">
                <mat-icon svgIcon="filter-alt"></mat-icon>
                <mat-icon class="sp-remove-icon" svgIcon="remove"></mat-icon>
            </button>
        </div>
    </sp-card>
    <div class="sp-spinner"
        [class.sp-spinner--loading]="loading">
        <sp-progress-spinner [isRunning]="loading"
            size="small">
        </sp-progress-spinner>
    </div>
</div>