import { Serializer, BlockSubcodingResponse } from './interfaces';

export class BlockSubcoding implements Serializer<BlockSubcoding> {
    constructor(
        public id?: number,
        public name?: string,
        public blockCodingId?: number
    ) { }
    fromResponse(response: BlockSubcodingResponse): BlockSubcoding {
        return new BlockSubcoding(
            response.id,
            response.name,
            response.coding
        )
    }
    toRequest() {
        throw new Error('Method not implemented.');
    }
}