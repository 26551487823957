export enum WorkoutStatus {
    scheduled = 1,
    started = 2,
    completed = 3,
    missed = 4
}

export const WorkoutStatusKey: { [key in WorkoutStatus]: string } = {
    1: 'scheduled',
    2: 'started',
    3: 'completed',
    4: 'missed'
};