import moment from "moment";

import { Serializer, WorkoutCountResponse } from "./interfaces";

export class WorkoutCount implements Serializer<WorkoutCount>{

    constructor(
        public date?: Date,
        public count?: number
    ) { }

    static fromResponse(response: WorkoutCountResponse): WorkoutCount {
        return new WorkoutCount(
            moment(response.date).toDate(),
            response.count
        );
    }

    fromResponse(response: WorkoutCountResponse): WorkoutCount {
        return WorkoutCount.fromResponse(response);
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}