import { CountryISO, UserLanguage } from "../models/enumerations";

/**
 * Modelo de País
 * ```
 * Referencias:
 * Países: https://www.iso.org/obp/ui/#search
 * Códigos de país: https://countrycode.org/
 * ```
 */
export class Country {

    constructor(
        public countryISO?: CountryISO,
        public name?: string,
        public alpha2Code?: string,
        public alpha3Code?: string,
        public countryCode?: number,
        /**
         * Región correspondiente según SoloPerformance
         * ```
         * En caso de que no tenga asignado región significa que corresponde a la región resto del mundo
         * ```
         */
        public spfRegionId?: number,
        /**
         * Idioma o lenguaje materna del país
         */
        public language?: number
    ) { }

    static get countries(): Array<Country> {
        return [
            {
                countryISO: CountryISO.Afghanistan,
                name: 'Afghanistan',
                alpha2Code: 'AF',
                alpha3Code: 'AFG',
                countryCode: 93,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Albania,
                name: 'Albania',
                alpha2Code: 'AL',
                alpha3Code: 'ALB',
                countryCode: 355,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Algeria,
                name: 'Algeria',
                alpha2Code: 'DZ',
                alpha3Code: 'DZA',
                countryCode: 213,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.AmericanSamoa,
                name: 'American Samoa',
                countryCode: 1684,
                alpha2Code: 'AS',
                alpha3Code: 'ASM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Andorra,
                name: 'Andorra',
                alpha2Code: 'AD',
                alpha3Code: 'AND',
                countryCode: 376,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Angola,
                name: 'Angola',
                alpha2Code: 'AO',
                alpha3Code: 'AGO',
                countryCode: 244,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Anguilla,
                name: 'Anguilla',
                countryCode: 1264,
                alpha2Code: 'AI',
                alpha3Code: 'AIA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.NorfolkIsland,   // Por el código de país al parecer se refiere a Antarctica
                name: 'Norfolk Island',
                alpha2Code: 'AQ',
                alpha3Code: 'ATA',
                countryCode: 672,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.AntiguaAndBarbuda,
                name: 'Antigua and Barbuda',
                alpha2Code: 'AG',
                alpha3Code: 'ATG',
                countryCode: 1268,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Argentina,
                name: 'Argentina',
                alpha2Code: 'AR',
                alpha3Code: 'ARG',
                countryCode: 54,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Armenia,
                name: 'Armenia',
                alpha2Code: 'AM',
                alpha3Code: 'ARM',
                countryCode: 374,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Aruba,
                name: 'Aruba',
                alpha2Code: 'AW',
                alpha3Code: 'ABW',
                countryCode: 297,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Australia,
                name: 'Australia',
                alpha2Code: 'AU',
                alpha3Code: 'AUS',
                countryCode: 61,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Austria,
                name: 'Austria',
                alpha2Code: 'AT',
                alpha3Code: 'AUT',
                countryCode: 43,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Azerbaijan,
                name: 'Azerbaijan',
                alpha2Code: 'AZ',
                alpha3Code: 'AZE',
                countryCode: 994,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Bahamas,
                name: 'Bahamas (the)',
                alpha2Code: 'BS',
                alpha3Code: 'BHS',
                countryCode: 1242,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Bahrain,
                name: 'Bahrain',
                alpha2Code: 'BH',
                alpha3Code: 'BHR',
                countryCode: 973,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Bangladesh,
                name: 'Bangladesh',
                alpha2Code: 'BD',
                alpha3Code: 'BGD',
                countryCode: 880,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Barbados,
                name: 'Barbados',
                alpha2Code: 'BB',
                alpha3Code: 'BRB',
                countryCode: 1246,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Belarus,
                name: 'Belarus',
                alpha2Code: 'BY',
                alpha3Code: 'BLR',
                countryCode: 375,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Belgium,
                name: 'Belgium',
                alpha2Code: 'BE',
                alpha3Code: 'BEL',
                countryCode: 32,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Belize,
                name: 'Belize',
                alpha2Code: 'BZ',
                alpha3Code: 'BLZ',
                countryCode: 501,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Benin,
                name: 'Benin',
                alpha2Code: 'BJ',
                alpha3Code: 'BEN',
                countryCode: 229,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Bermuda,
                name: 'Bermuda',
                alpha2Code: 'BM',
                alpha3Code: 'BMU',
                countryCode: 1441,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Bhutan,
                name: 'Bhutan',
                alpha2Code: 'BT',
                alpha3Code: 'BTN',
                countryCode: 975,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Bolivia,
                name: 'Bolivia',
                alpha2Code: 'BO',
                alpha3Code: 'BOL',
                countryCode: 591,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.BosniaAndHerzegovina,
                name: 'Bosnia And Herzegovina',
                alpha2Code: 'BA',
                alpha3Code: 'BIH',
                countryCode: 387,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Botswana,
                name: 'Botswana',
                alpha2Code: 'BW',
                alpha3Code: 'BWA',
                countryCode: 267,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Brazil,
                name: 'Brazil',
                countryCode: 55,
                alpha2Code: 'BR',
                alpha3Code: 'BRA',
                spfRegionId: 6
            },
            {
                countryISO: CountryISO.BritishIndianOceanTerritory,
                name: 'British Indian Ocean Territory',
                alpha2Code: 'IO',
                alpha3Code: 'IOT',
                countryCode: 246,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.BritishVirginIslands,
                name: 'British Virgin Islands',
                alpha2Code: 'VG',
                alpha3Code: 'VGB',
                countryCode: 1284,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Brunei,
                name: 'Brunei',
                alpha2Code: 'BN',
                alpha3Code: 'BRN',
                countryCode: 673,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Bulgaria,
                name: 'Bulgaria',
                alpha2Code: 'BG',
                alpha3Code: 'BGR',
                countryCode: 359,
                spfRegionId: null
            },
            {
                countryISO: CountryISO.BurkinaFaso,
                name: 'Burkina Faso',
                countryCode: 226,
                alpha2Code: 'BF',
                alpha3Code: 'BFA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Burundi,
                name: 'Burundi',
                countryCode: 257,
                alpha2Code: 'BI',
                alpha3Code: 'BDI',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Cambodia,
                name: 'Cambodia',
                countryCode: 855,
                alpha2Code: 'KH',
                alpha3Code: 'KHM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Cameroon,
                name: 'Cameroon',
                countryCode: 237,
                alpha2Code: 'CM',
                alpha3Code: 'CMR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Canada,
                name: 'Canada',
                countryCode: 1,
                alpha2Code: 'CA',
                alpha3Code: 'CAN',
                spfRegionId: 7
            },
            {
                countryISO: CountryISO.CapeVerde,
                name: 'Cape Verde',
                countryCode: 238,
                alpha2Code: 'CV',
                alpha3Code: 'CPV',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.CaymanIslands,
                name: 'Cayman Islands',
                countryCode: 1345,
                alpha2Code: 'KY',
                alpha3Code: 'CYM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.CentralAfricanRepublic,
                name: 'Central African Republic',
                countryCode: 236,
                alpha2Code: 'CF',
                alpha3Code: 'CAF',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Chad,
                name: 'Chad',
                countryCode: 235,
                alpha2Code: 'TD',
                alpha3Code: 'TCD',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Chile,
                name: 'Chile',
                countryCode: 56,
                alpha2Code: 'CL',
                alpha3Code: 'CHL',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.China,
                name: 'China',
                countryCode: 86,
                alpha2Code: 'CN',
                alpha3Code: 'CHN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.ChristmasIsland,
                name: 'Christmas Island',
                countryCode: 61,
                alpha2Code: 'CX',
                alpha3Code: 'CXR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Cocos,
                name: 'Cocos Islands',
                countryCode: 61,
                alpha2Code: 'CC',
                alpha3Code: 'CCK',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Colombia,
                name: 'Colombia',
                countryCode: 57,
                alpha2Code: 'CO',
                alpha3Code: 'COL',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Comoros,
                name: 'Comoros',
                countryCode: 269,
                alpha2Code: 'KM',
                alpha3Code: 'COM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.CookIslands,
                name: 'Cook Islands',
                countryCode: 682,
                alpha2Code: 'CK',
                alpha3Code: 'COK',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.CostaRica,
                name: 'Costa Rica',
                countryCode: 506,
                alpha2Code: 'CR',
                alpha3Code: 'CRI',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Croatia,
                name: 'Croatia',
                countryCode: 385,
                alpha2Code: 'HR',
                alpha3Code: 'HRV',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Cuba,
                name: 'Cuba',
                countryCode: 53,
                alpha2Code: 'CU',
                alpha3Code: 'CUB',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Curaçao,
                name: 'Curacao',
                countryCode: 599,
                alpha2Code: 'CW',
                alpha3Code: 'CUW',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Cyprus,
                name: 'Cyprus',
                countryCode: 357,
                alpha2Code: 'CY',
                alpha3Code: 'CYP',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.CzechRepublic,
                name: 'Czech Republic',
                countryCode: 420,
                alpha2Code: 'CZ',
                alpha3Code: 'CZE',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.CongoDRCJamhuriYaKidemokrasiaYaKongo,
                name: 'Democratic Republic of the Congo',
                countryCode: 243,
                alpha2Code: 'CD',
                alpha3Code: 'COD',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Denmark,
                name: 'Denmark',
                countryCode: 45,
                alpha2Code: 'DK',
                alpha3Code: 'DNK',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Djibouti,
                name: 'Djibouti',
                countryCode: 253,
                alpha2Code: 'DJ',
                alpha3Code: 'DJI',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Dominica,
                name: 'Dominica',
                countryCode: 1767,
                alpha2Code: 'DM',
                alpha3Code: 'DMA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.DominicanRepublic,
                name: 'Dominican Republic',
                countryCode: 1,
                alpha2Code: 'DO',
                alpha3Code: 'DOM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.TimorLeste,
                name: 'TimorLeste',
                countryCode: 670,
                alpha2Code: 'TL',
                alpha3Code: 'TLS',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Ecuador,
                name: 'Ecuador',
                countryCode: 593,
                alpha2Code: 'EC',
                alpha3Code: 'ECU',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Egypt,
                name: 'Egypt',
                countryCode: 20,
                alpha2Code: 'EG',
                alpha3Code: 'EGY',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.ElSalvador,
                name: 'El Salvador',
                countryCode: 503,
                alpha2Code: 'SV',
                alpha3Code: 'SLV',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.EquatorialGuinea,
                name: 'Equatorial Guinea',
                countryCode: 240,
                alpha2Code: 'GQ',
                alpha3Code: 'GNQ',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Eritrea,
                name: 'Eritrea',
                countryCode: 291,
                alpha2Code: 'ER',
                alpha3Code: 'ERI',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Estonia,
                name: 'Estonia',
                countryCode: 372,
                alpha2Code: 'EE',
                alpha3Code: 'EST',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Ethiopia,
                name: 'Ethiopia',
                countryCode: 251,
                alpha2Code: 'ET',
                alpha3Code: 'ETH',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.FalklandIslands,
                name: 'Falkland Islands',
                countryCode: 500,
                alpha2Code: 'FK',
                alpha3Code: 'FLK',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.FaroeIslands,
                name: 'Faroe Islands',
                countryCode: 298,
                alpha2Code: 'FO',
                alpha3Code: 'FRO',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Fiji,
                name: 'Fiji',
                countryCode: 679,
                alpha2Code: 'FJ',
                alpha3Code: 'FJI',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Finland,
                name: 'Finland',
                countryCode: 358,
                alpha2Code: 'FI',
                alpha3Code: 'FIN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.France,
                name: 'France',
                countryCode: 33,
                alpha2Code: 'FR',
                alpha3Code: 'FRA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.FrenchPolynesia,
                name: 'French Polynesia',
                countryCode: 689,
                alpha2Code: 'PF',
                alpha3Code: 'PYF',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Gabon,
                name: 'Gabon',
                countryCode: 241,
                alpha2Code: 'GA',
                alpha3Code: 'GAB',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Gambia,
                name: 'Gambia',
                countryCode: 220,
                alpha2Code: 'GM',
                alpha3Code: 'GMB',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Georgia,
                name: 'Georgia',
                countryCode: 995,
                alpha2Code: 'GE',
                alpha3Code: 'GEO',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Germany,
                name: 'Germany',
                countryCode: 49,
                alpha2Code: 'DE',
                alpha3Code: 'DEU',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Ghana,
                name: 'Ghana',
                countryCode: 233,
                alpha2Code: 'GH',
                alpha3Code: 'GHA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Gibraltar,
                name: 'Gibraltar',
                countryCode: 350,
                alpha2Code: 'GI',
                alpha3Code: 'GIB',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Greece,
                name: 'Greece',
                countryCode: 30,
                alpha2Code: 'GR',
                alpha3Code: 'GRC',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Greenland,
                name: 'Greenland',
                countryCode: 299,
                alpha2Code: 'GL',
                alpha3Code: 'GRL',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Grenada,
                name: 'Grenada',
                countryCode: 1473,
                alpha2Code: 'GD',
                alpha3Code: 'GRD',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Guam,
                name: 'Guam',
                countryCode: 1671,
                alpha2Code: 'GU',
                alpha3Code: 'GUM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Guatemala,
                name: 'Guatemala',
                countryCode: 502,
                alpha2Code: 'GT',
                alpha3Code: 'GTM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Guernsey,
                name: 'Guernsey',
                countryCode: 44,
                alpha2Code: 'GG',
                alpha3Code: 'GGY',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Guinea,
                name: 'Guinea',
                countryCode: 224,
                alpha2Code: 'GN',
                alpha3Code: 'GIN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.GuineaBissau,
                name: 'Guinea Bissau',
                countryCode: 245,
                alpha2Code: 'GW',
                alpha3Code: 'GNB',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Guyana,
                name: 'Guyana',
                countryCode: 592,
                alpha2Code: 'GY',
                alpha3Code: 'GUY',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Haiti,
                name: 'Haiti',
                countryCode: 509,
                alpha2Code: 'HT',
                alpha3Code: 'HTI',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Honduras,
                name: 'Honduras',
                countryCode: 504,
                alpha2Code: 'HN',
                alpha3Code: 'HND',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.HongKong,
                name: 'Hong Kong',
                countryCode: 852,
                alpha2Code: 'HK',
                alpha3Code: 'HKG',
                spfRegionId: 10
            },
            {
                countryISO: CountryISO.Hungary,
                name: 'Hungary',
                countryCode: 36,
                alpha2Code: 'HU',
                alpha3Code: 'HUN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Iceland,
                name: 'Iceland',
                countryCode: 354,
                alpha2Code: 'IS',
                alpha3Code: 'ISL',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.India,
                name: 'India',
                countryCode: 91,
                alpha2Code: 'IN',
                alpha3Code: 'IND',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Indonesia,
                name: 'Indonesia',
                countryCode: 62,
                alpha2Code: 'ID',
                alpha3Code: 'IDN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Iran,
                name: 'Iran',
                countryCode: 98,
                alpha2Code: 'IR',
                alpha3Code: 'IRN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Iraq,
                name: 'Iraq',
                countryCode: 964,
                alpha2Code: 'IQ',
                alpha3Code: 'IRQ',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Ireland,
                name: 'Ireland',
                countryCode: 353,
                alpha2Code: 'IE',
                alpha3Code: 'IRL',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.IsleOfMan,
                name: 'Isle of Man',
                countryCode: 44,
                alpha2Code: 'IM',
                alpha3Code: 'IMN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Israel,
                name: 'Israel',
                countryCode: 972,
                alpha2Code: 'IL',
                alpha3Code: 'ISR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Italy,
                name: 'Italy',
                countryCode: 39,
                alpha2Code: 'IT',
                alpha3Code: 'ITA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.CôteDIvoire,
                name: 'Ivory Coast',
                countryCode: 225,
                alpha2Code: 'CI',
                alpha3Code: 'CIV',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Jamaica,
                name: 'Jamaica',
                countryCode: 1,
                alpha2Code: 'JM',
                alpha3Code: 'JAM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Japan,
                name: 'Japan',
                countryCode: 81,
                alpha2Code: 'JP',
                alpha3Code: 'JPN',
                spfRegionId: 8
            },
            {
                countryISO: CountryISO.Jersey,
                name: 'Jersey',
                countryCode: 44,
                alpha2Code: '',
                alpha3Code: '',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Jordan,
                name: 'Jordan',
                countryCode: 962,
                alpha2Code: 'JO',
                alpha3Code: 'JOR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Kazakhstan,
                name: 'Kazakhstan',
                countryCode: 7,
                alpha2Code: 'KZ',
                alpha3Code: 'KAZ',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Kenya,
                name: 'Kenya',
                countryCode: 254,
                alpha2Code: 'KE',
                alpha3Code: 'KEN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Kiribati,
                name: 'Kiribati',
                countryCode: 686,
                alpha2Code: 'KI',
                alpha3Code: 'KIR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Kosovo,
                name: 'Kosovo',
                countryCode: 383,
                alpha2Code: 'XK',
                alpha3Code: 'XKX',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Kuwait,
                name: 'Kuwait',
                countryCode: 965,
                alpha2Code: 'KW',
                alpha3Code: 'KWT',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Kyrgyzstan,
                name: 'Kyrgyzstan',
                countryCode: 996,
                alpha2Code: 'KG',
                alpha3Code: 'KGZ',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Laos,
                name: 'Laos',
                countryCode: 856,
                alpha2Code: 'LA',
                alpha3Code: 'LAO',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Latvia,
                name: 'Latvia',
                countryCode: 371,
                alpha2Code: 'LV',
                alpha3Code: 'LVA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Lebanon,
                name: 'Lebanon',
                countryCode: 961,
                alpha2Code: 'LB',
                alpha3Code: 'LBN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Lesotho,
                name: 'Lesotho',
                countryCode: 266,
                alpha2Code: 'LS',
                alpha3Code: 'LSO',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Liberia,
                name: 'Liberia',
                countryCode: 231,
                alpha2Code: 'LR',
                alpha3Code: 'LBR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Libya,
                name: 'Libya',
                countryCode: 218,
                alpha2Code: 'LY',
                alpha3Code: 'LBY',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Liechtenstein,
                name: 'Liechtenstein',
                countryCode: 423,
                alpha2Code: 'LI',
                alpha3Code: 'LIE',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Lithuania,
                name: 'Lithuania',
                countryCode: 370,
                alpha2Code: 'LT',
                alpha3Code: 'LTU',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Luxembourg,
                name: 'Luxembourg',
                countryCode: 352,
                alpha2Code: 'LU',
                alpha3Code: 'LUX',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Macau,
                name: 'Macau',
                countryCode: 853,
                alpha2Code: 'MO',
                alpha3Code: 'MAC',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Macedonia,
                name: 'Macedonia',
                countryCode: 389,
                alpha2Code: 'MK',
                alpha3Code: 'MKD',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Madagascar,
                name: 'Madagascar',
                countryCode: 261,
                alpha2Code: 'MG',
                alpha3Code: 'MDG',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Malawi,
                name: 'Malawi',
                countryCode: 265,
                alpha2Code: 'MW',
                alpha3Code: 'MWI',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Malaysia,
                name: 'Malaysia',
                countryCode: 60,
                alpha2Code: 'MY',
                alpha3Code: 'MYS',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Maldives,
                name: 'Maldives',
                countryCode: 960,
                alpha2Code: 'MV',
                alpha3Code: 'MDV',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Mali,
                name: 'Mali',
                countryCode: 223,
                alpha2Code: 'ML',
                alpha3Code: 'MLI',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Malta,
                name: 'Malta',
                countryCode: 356,
                alpha2Code: 'MT',
                alpha3Code: 'MLT',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.MarshallIslands,
                name: 'Marshall Islands',
                countryCode: 692,
                alpha2Code: 'MH',
                alpha3Code: 'MHL',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Mauritania,
                name: 'Mauritania',
                countryCode: 222,
                alpha2Code: 'MT',
                alpha3Code: 'MRT',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Mauritius,
                name: 'Mauritius',
                countryCode: 230,
                alpha2Code: 'MU',
                alpha3Code: 'MUS',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Mayotte,
                name: 'Mayotte',
                countryCode: 262,
                alpha2Code: 'YT',
                alpha3Code: 'MYT',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Mexico,
                name: 'Mexico',
                countryCode: 52,
                alpha2Code: 'MX',
                alpha3Code: 'MEX',
                spfRegionId: 1
            },
            {
                countryISO: CountryISO.Micronesia,
                name: 'Micronesia',
                countryCode: 691,
                alpha2Code: 'FM',
                alpha3Code: 'FSM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Moldova,
                name: 'Moldova',
                countryCode: 373,
                alpha2Code: 'MD',
                alpha3Code: 'MDA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Monaco,
                name: 'Monaco',
                countryCode: 377,
                alpha2Code: 'MC',
                alpha3Code: 'MCO',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Mongolia,
                name: 'Mongolia',
                countryCode: 976,
                alpha2Code: 'MN',
                alpha3Code: 'MNG',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Montenegro,
                name: 'Montenegro',
                countryCode: 382,
                alpha2Code: 'ME',
                alpha3Code: 'MNE',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Montserrat,
                name: 'Montserrat',
                countryCode: 1664,
                alpha2Code: 'MS',
                alpha3Code: 'MSR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Morocco,
                name: 'Morocco',
                countryCode: 212,
                alpha2Code: 'MA',
                alpha3Code: 'MAR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Mozambique,
                name: 'Mozambique',
                countryCode: 258,
                alpha2Code: 'MZ',
                alpha3Code: 'MOZ',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Myanmar,
                name: 'Myanmar',
                countryCode: 95,
                alpha2Code: 'MM',
                alpha3Code: 'MMR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Namibia,
                name: 'Namibia',
                countryCode: 264,
                alpha2Code: 'NA',
                alpha3Code: 'NAM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Nauru,
                name: 'Nauru',
                countryCode: 674,
                alpha2Code: 'NR',
                alpha3Code: 'NRU',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Nepal,
                name: 'Nepal',
                countryCode: 977,
                alpha2Code: 'NP',
                alpha3Code: 'NPL',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Netherlands,
                name: 'Netherlands',
                countryCode: 31,
                alpha2Code: 'NL',
                alpha3Code: 'NLD',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.CaribbeanNetherlands,    // Netherlands Antilles
                name: 'Caribbean Netherlands',
                countryCode: 599,
                alpha2Code: 'AN',
                alpha3Code: 'ANT',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.NewCaledonia,
                name: 'NewCaledonia',
                countryCode: 687,
                alpha2Code: 'NC',
                alpha3Code: 'NCL',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.NewZealand,
                name: 'New Zealand',
                countryCode: 64,
                alpha2Code: 'NZ',
                alpha3Code: 'NZL',
                spfRegionId: 9
            },
            {
                countryISO: CountryISO.Nicaragua,
                name: 'Nicaragua',
                countryCode: 505,
                alpha2Code: 'NI',
                alpha3Code: 'NIC',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Niger,
                name: 'Niger',
                countryCode: 227,
                alpha2Code: 'NE',
                alpha3Code: 'NER',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Nigeria,
                name: 'Nigeria',
                countryCode: 234,
                alpha2Code: 'NG',
                alpha3Code: 'NGA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Niue,
                name: 'Niue',
                countryCode: 683,
                alpha2Code: 'NU',
                alpha3Code: 'NIU',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.NorthKorea,
                name: 'North Korea',
                countryCode: 850,
                alpha2Code: 'KP',
                alpha3Code: 'PRK',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.NorthernMarianaIslands,
                name: 'Northern Mariana Islands',
                countryCode: 1670,
                alpha2Code: 'MP',
                alpha3Code: 'MNP',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Norway,
                name: 'Norway',
                countryCode: 47,
                alpha2Code: 'NO',
                alpha3Code: 'NOR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Oman,
                name: 'Oman',
                countryCode: 968,
                alpha2Code: 'OM',
                alpha3Code: 'OMN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Pakistan,
                name: 'Pakistan',
                countryCode: 92,
                alpha2Code: 'PK',
                alpha3Code: 'PAK',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Palau,
                name: 'Palau',
                countryCode: 680,
                alpha2Code: 'PW',
                alpha3Code: 'PLW',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Palestine,
                name: 'Palestine',
                countryCode: 970,
                alpha2Code: 'PS',
                alpha3Code: 'PSE',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Panama,
                name: 'Panama',
                countryCode: 507,
                alpha2Code: 'PA',
                alpha3Code: 'PAN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.PapuaNewGuinea,
                name: 'Papua New Guinea',
                countryCode: 675,
                alpha2Code: 'PG',
                alpha3Code: 'PNG',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Paraguay,
                name: 'Paraguay',
                countryCode: 595,
                alpha2Code: 'PY',
                alpha3Code: 'PRY',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Peru,
                name: 'Peru',
                countryCode: 51,
                alpha2Code: 'PE',
                alpha3Code: 'PER',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Philippines,
                name: 'Philippines',
                countryCode: 63,
                alpha2Code: 'PH',
                alpha3Code: 'PHL',
                spfRegionId: null
            },
            // TODO: Revisar el país pitcairn que se visualiza en countrycode.org. No se encuentra entre los código de país
            // Pitcairn
            {
                countryISO: CountryISO.Poland,
                name: 'Poland',
                countryCode: 48,
                alpha2Code: 'PL',
                alpha3Code: 'POL',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Portugal,
                name: 'Portugal',
                countryCode: 351,
                alpha2Code: 'PT',
                alpha3Code: 'PRT',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.PuertoRico,
                name: 'Puerto Rico',
                countryCode: 1,
                alpha2Code: 'PR',
                alpha3Code: 'PRI',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Qatar,
                name: 'Qatar',
                countryCode: 974,
                alpha2Code: 'QA',
                alpha3Code: 'QAT',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.CongoRepublicCongoBrazzaville,
                name: 'Republic of the Congo',
                countryCode: 242,
                alpha2Code: 'CG',
                alpha3Code: 'COG',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Réunion,
                name: 'Reunion',
                countryCode: 262,
                alpha2Code: 'RE',
                alpha3Code: 'REU',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Romania,
                name: 'Romania',
                countryCode: 40,
                alpha2Code: 'RO',
                alpha3Code: 'ROU',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Russia,
                name: 'Russia',
                countryCode: 7,
                alpha2Code: 'RU',
                alpha3Code: 'RUS',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Rwanda,
                name: 'Rwanda',
                countryCode: 250,
                alpha2Code: 'RW',
                alpha3Code: 'RWA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SaintBarthélemy,
                name: 'Saint Barthélemy',
                countryCode: 590,
                alpha2Code: 'BL',
                alpha3Code: 'BLM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SaintHelena,
                name: 'Saint Helena',
                countryCode: 290,
                alpha2Code: 'SH',
                alpha3Code: 'SHN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SaintKittsAndNevis,
                name: 'Saint Kitts And Nevis',
                countryCode: 1869,
                alpha2Code: 'KN',
                alpha3Code: 'KNA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SaintLucia,
                name: 'Saint Lucia',
                countryCode: 1758,
                alpha2Code: 'LC',
                alpha3Code: 'LCA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SaintMartin,
                name: 'Saint Martin',
                countryCode: 590,
                alpha2Code: 'MF',
                alpha3Code: 'MAF',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SaintPierreAndMiquelon,
                name: 'Saint Pierre And Miquelon',
                countryCode: 508,
                alpha2Code: 'PM',
                alpha3Code: 'SPM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SaintVincentAndTheGrenadines,
                name: 'Saint Vincent And The Grenadines',
                countryCode: 1784,
                alpha2Code: 'VC',
                alpha3Code: 'VCT',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Samoa,
                name: 'Samoa',
                countryCode: 685,
                alpha2Code: 'WS',
                alpha3Code: 'WSM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SanMarino,
                name: 'San Marino',
                countryCode: 378,
                alpha2Code: 'SM',
                alpha3Code: 'SMR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SãoToméAndPríncipe,
                name: 'Sao Tome And Principe',
                countryCode: 239,
                alpha2Code: 'ST',
                alpha3Code: 'STP',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SaudiArabia,
                name: 'Saudi Arabia',
                countryCode: 966,
                alpha2Code: 'SA',
                alpha3Code: 'SAU',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Senegal,
                name: 'Senegal',
                countryCode: 221,
                alpha2Code: 'SN',
                alpha3Code: 'SEN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Serbia,
                name: 'Serbia',
                countryCode: 381,
                alpha2Code: 'RS',
                alpha3Code: 'SRB',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Seychelles,
                name: 'Seychelles',
                countryCode: 248,
                alpha2Code: 'SC',
                alpha3Code: 'SYC',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SierraLeone,
                name: 'Sierra Leone',
                countryCode: 232,
                alpha2Code: 'SL',
                alpha3Code: 'SLE',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Singapore,
                name: 'Singapore',
                countryCode: 65,
                alpha2Code: 'SG',
                alpha3Code: 'SGP',
                spfRegionId: 11
            },
            {
                countryISO: CountryISO.SintMaarten,
                name: 'Sint Maarten',
                countryCode: 1721,
                alpha2Code: 'SX',
                alpha3Code: 'SXM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Slovakia,
                name: 'Slovakia',
                countryCode: 421,
                alpha2Code: 'SK',
                alpha3Code: 'SVK',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Slovenia,
                name: 'Slovenia',
                countryCode: 386,
                alpha2Code: 'SI',
                alpha3Code: 'SVN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SolomonIslands,
                name: 'Solomon Islands',
                countryCode: 677,
                alpha2Code: 'SB',
                alpha3Code: 'SLB',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Somalia,
                name: 'Somalia',
                countryCode: 252,
                alpha2Code: 'SO',
                alpha3Code: 'SOM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SouthAfrica,
                name: 'South Africa',
                countryCode: 27,
                alpha2Code: 'ZA',
                alpha3Code: 'ZAF',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SouthKorea,
                name: 'South Korea',
                countryCode: 82,
                alpha2Code: 'KR',
                alpha3Code: 'KOR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SouthSudan,
                name: 'South Sudan',
                countryCode: 211,
                alpha2Code: 'SS',
                alpha3Code: 'SSD',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Spain,
                name: 'Spain',
                countryCode: 34,
                alpha2Code: 'ES',
                alpha3Code: 'ESP',
                spfRegionId: 3,
                language: UserLanguage.Spanish
            },
            {
                countryISO: CountryISO.SriLanka,
                name: 'SriLanka',
                countryCode: 94,
                alpha2Code: 'LK',
                alpha3Code: 'LKA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Sudan,
                name: 'Sudan',
                countryCode: 249,
                alpha2Code: 'SD',
                alpha3Code: 'SDN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Suriname,
                name: 'Suriname',
                countryCode: 597,
                alpha2Code: 'SR',
                alpha3Code: 'SUR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.SvalbardAndJanMayen,
                name: 'Svalbard And Jan Mayen',
                countryCode: 47,
                alpha2Code: 'SJ',
                alpha3Code: 'SJM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Swaziland,
                name: 'Swaziland',
                countryCode: 268,
                alpha2Code: 'SZ',
                alpha3Code: 'SWZ',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Sweden,
                name: 'Sweden',
                countryCode: 46,
                alpha2Code: 'SE',
                alpha3Code: 'SWE',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Switzerland,
                name: 'Switzerland',
                countryCode: 41,
                alpha2Code: 'CH',
                alpha3Code: 'CHE',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Syria,
                name: 'Syria',
                countryCode: 963,
                alpha2Code: 'SY',
                alpha3Code: 'SYR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Taiwan,
                name: 'Taiwan',
                countryCode: 886,
                alpha2Code: 'TW',
                alpha3Code: 'TWN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Tajikistan,
                name: 'Tajikistan',
                countryCode: 992,
                alpha2Code: 'TJ',
                alpha3Code: 'TJK',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Tanzania,
                name: 'Tanzania',
                countryCode: 255,
                alpha2Code: 'TZ',
                alpha3Code: 'TZA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Thailand,
                name: 'Thailand',
                countryCode: 66,
                alpha2Code: 'TH',
                alpha3Code: 'THA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Togo,
                name: 'Togo',
                countryCode: 228,
                alpha2Code: 'TG',
                alpha3Code: 'TGO',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Tokelau,
                name: 'Tokelau',
                countryCode: 690,
                alpha2Code: 'TK',
                alpha3Code: 'TKL',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Tonga,
                name: 'Tonga',
                countryCode: 676,
                alpha2Code: 'TO',
                alpha3Code: 'TON',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.TrinidadAndTobago,
                name: 'Trinidad And Tobago',
                countryCode: 1868,
                alpha2Code: 'TT',
                alpha3Code: 'TTO',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Tunisia,
                name: 'Tunisia',
                countryCode: 216,
                alpha2Code: 'TN',
                alpha3Code: 'TUN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Turkey,
                name: 'Turkey',
                countryCode: 90,
                alpha2Code: 'TR',
                alpha3Code: 'TUR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Turkmenistan,
                name: 'Turkmenistan',
                countryCode: 993,
                alpha2Code: 'TM',
                alpha3Code: 'TKM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.TurksAndCaicosIslands,
                name: 'Turks And Caicos Islands',
                countryCode: 1649,
                alpha2Code: 'TC',
                alpha3Code: 'TCA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Tuvalu,
                name: 'Tuvalu',
                countryCode: 688,
                alpha2Code: 'TV',
                alpha3Code: 'TCA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.USVirginIslands,
                name: 'U.S. Virgin Islands',
                countryCode: 1340,
                alpha2Code: 'VI',
                alpha3Code: 'VIR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Uganda,
                name: 'Uganda',
                countryCode: 256,
                alpha2Code: 'UG',
                alpha3Code: 'UGA',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Ukraine,
                name: 'Ukraine',
                countryCode: 380,
                alpha2Code: 'UA',
                alpha3Code: 'UKR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.UnitedArabEmirates,
                name: 'United Arab Emirates',
                countryCode: 971,
                alpha2Code: 'AE',
                alpha3Code: 'ARE',
                spfRegionId: null,
                language: UserLanguage.English
            },
            {
                countryISO: CountryISO.UnitedKingdom,
                name: 'United Kingdom',
                countryCode: 44,
                alpha2Code: 'GB',
                alpha3Code: 'GBR',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.UnitedStates,
                name: 'United States',
                countryCode: 1,
                alpha2Code: 'US',
                alpha3Code: 'USA',
                spfRegionId: 2,
                language: UserLanguage.English
            },
            {
                countryISO: CountryISO.Uruguay,
                name: 'Uruguay',
                countryCode: 598,
                alpha2Code: 'UY',
                alpha3Code: 'URY',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Uzbekistan,
                name: 'Uzbekistan',
                countryCode: 998,
                alpha2Code: 'UZ',
                alpha3Code: 'UZB',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Vanuatu,
                name: 'Vanuatu',
                countryCode: 678,
                alpha2Code: 'VU',
                alpha3Code: 'VUT',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.VaticanCity,
                name: 'Vatican City',
                countryCode: 379,
                alpha2Code: 'VA',
                alpha3Code: 'VAT',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Venezuela,
                name: 'Venezuela',
                countryCode: 58,
                alpha2Code: 'VE',
                alpha3Code: 'VEN',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Vietnam,
                name: 'Vietnam',
                countryCode: 84,
                alpha2Code: 'VN',
                alpha3Code: 'VNM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.WallisAndFutuna,
                name: 'Wallis And Futuna',
                countryCode: 681,
                alpha2Code: 'WF',
                alpha3Code: 'WLF',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.WesternSahara,
                name: 'Western Sahara',
                countryCode: 212,
                alpha2Code: 'EH',
                alpha3Code: 'ESH',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Yemen,
                name: 'Yemen',
                countryCode: 967,
                alpha2Code: 'YE',
                alpha3Code: 'YEM',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Zambia,
                name: 'Zambia',
                countryCode: 260,
                alpha2Code: 'ZM',
                alpha3Code: 'ZMB',
                spfRegionId: null
            },
            {
                countryISO: CountryISO.Zimbabwe,
                name: 'Zimbabwe',
                countryCode: 263,
                alpha2Code: 'ZW',
                alpha3Code: 'ZWE',
                spfRegionId: null
            },
        ];
    }

    /**
     * Obtiene el país según el código de país indicado.
     * NOTA: Existen países con el mismo código de país. Retornará la primera coincidencia
     * @param countryCode Código de país
     * @returns 
     */
    static getByCountryCode(countryCode: number): Country {
        return this.countries.find(x => x.countryCode === countryCode);
    }

    /**
     * Obtiene el país según su código.
     * @param code Código
     * @returns 
     */
    static getByCode(code: string): Country {
        return this.countries.find(x => x.alpha2Code.toLowerCase() === code.toLowerCase());
    }
}