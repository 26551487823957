import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Athlete, AthletesData, User } from '../models';
import { AthleteResponse, AthletesDataResponse, UserResponse } from '../models/interfaces';

import { ApiService } from './api.service';

const BASE_URL = 'management-athletes';

/**
 * 
 */
@Injectable()
export class AthleteService {

  constructor(
    private api: ApiService
  ) { }

  /**
   * Obtiene información del atleta con el identificador indicado
   * @param athleteId Identificador de atleta
   * @returns 
   */
  getById(athleteId: number): Observable<User> {
    return this.api.get<UserResponse>(
      `${BASE_URL}/${athleteId}/`
    ).pipe(
      map(response => new User().fromResponse(response))
    );
  }

  /**
   * @deprecated Verificar si se utiliza o se puede reemplazar por userService.getManagementAthletesPaginated
   * @param params 
   * @returns 
   */
  getPaginated(
    params: HttpParams
  ): Observable<AthletesData> {
    return this.api.get<AthletesDataResponse>(
      `${BASE_URL}/`,
      params
    ).pipe(
      map(response => new AthletesData().fromResponse(response))
    );
  }

  /**
   * Update the athlete sent
   * @param athlete Athlete to update
   * @returns Athlete with updated data
   */
  update(athlete: Athlete): Observable<Athlete> {
    return this.api.patch<AthleteResponse>(
      `user/${athlete.id}/`,
      athlete.toRequest()
    ).pipe(
      map(response => athlete.fromResponse(response))
    );
  }

  /**
   * Update the athlete sent with data in FormData
   * @param athlete Athlete to update
   * @returns Athlete with updated data
   */
  updateFromFormData(athlete: Athlete): Observable<Athlete> {
    return this.api.patch<AthleteResponse>(
      `user/${athlete.id}/`,
      athlete.toFormDataRequest()
    ).pipe(
      map(response => athlete.fromResponse(response))
    );
  }
}
