import { ErrorMessage } from './error-message';

/**
 * Estructura con datos de proceso correcto o error para respuestas de solicitudes HTTP a EP o servicios
 */
export class ServiceResponse {

    constructor(
        /**
         * Indica si la solicitud fué correcta o tuvo error
         */
        public success = true,
        /**
         * Mensaje de error si el indicador success = false
         */
        public errorMessage?: ErrorMessage
    ) { }
}