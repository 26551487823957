<div class="sp-chat d-flex flex-column w-100">
    <!-- Chat messages -->
    <ng-scrollbar class="flex-grow-1 w-100 h-full relative">
        <div #chatsContainer
            scrollViewport
            spScrollEnd
            class="custom-viewport">
            <div #chatMessages
                class="sp-chat__messages">
                <!-- Message list -->
                <div class='d-flex flex-column'
                    *ngFor="let message of messages; let i = index;">
                    <!-- Date -->
                    <div class="sp-chat__date align-self-center px-4">
                        <small>{{getDate(message?.id)}}</small>
                    </div>
                    <!-- Message -->
                    <div class="sp-chat__user border-none px-4 d-flex"
                        [class.sp-chat__user--me]="message.userId == remitent?.id">
                        <!-- User avatar -->
                        <div class="sp-chat__user-avatar">
                            <img class="border"
                                *ngIf="!nextMsgIsMe(i) || i == messages.length - 1"
                                [src]="message.userId == remitent?.id? remitent?.photo : recipient?.photo"
                                alt="">
                        </div>
                        <!-- Message -->
                        <sp-chat-message class="flex-grow-1"
                            [user]="remitent"
                            [message]="message">
                        </sp-chat-message>
                    </div>
                </div>
            </div>
        </div>
    </ng-scrollbar>
    <!-- New message and options -->
    <sp-chat-new-message class="d-block flex-shrink-0"
        [user]="remitent"
        [recipient]="recipient">
    </sp-chat-new-message>
</div>