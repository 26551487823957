export enum EMOMType {
    emom = 1,
    e2mom,
    e3mom,
    e4mom,
    e5mom,
    e6mom,
    e7mom,
    e8mom,
    e9mom,
    e10mom
}