import { Serializer, UserWidgetResponse } from './interfaces';

import { WidgetMetadata } from './widget-metadata';
import { Widget } from './widget';

export class UserWidget implements Serializer<UserWidget> {

    constructor(
        /**
         * Identificador del widget en la lista de widget de usuario
         */
        public id?: number,
        /**
         * Posición X configurada para el widget
         */
        public axisX?: number,
        /**
         * Posición Y configurada para el widget
         */
        public axisY?: number,
        /**
         * Tamaño de columna definida por el usuario. Por defecto 12 columnas
         */
        public width?: number,
        public height?: number,
        public widget?: Widget,
        /**
         * Datos adicionales relacionados al widget asignado al usuario
         */
        public metadata?: WidgetMetadata
    ) { }

    fromResponse(response: UserWidgetResponse): UserWidget {
        return new UserWidget(
            response.id,
            response.axis_x,
            response.axis_y,
            response.width,
            response.height,
            response.widget ? new Widget().fromResponse(response.widget) : null,
            response.objectsData ? new WidgetMetadata().fromResponse(response.objectsData) : null
        )
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }
}