import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { OAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
  AuthService,
  SocialLoginService,
  ProgressSpinnerService,
  ServiceResponse,
  SocialType,
  SocialLoginValidation,
  User,
} from 'sp-core';

@Injectable()
export class SpLoginService {

  errorMessageSubject$ = new BehaviorSubject<string>(null);
  /**
   * Escucha alguna notificación de error para visualizar en pantalla
   */
  errorMessage$ = this.errorMessageSubject$.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private spinnerService: ProgressSpinnerService,
    private authService: AuthService,
    private socialLoginService: SocialLoginService
  ) { }

  loginWithFacebook(): Observable<SocialLoginValidation> {
    
    return new Observable(subscribe => {
      this.afAuth.signInWithPopup(
        new firebase.auth.FacebookAuthProvider()
      ).then(result => {

        this.spinnerService.start();
        this.socialLoginService.validateSocialLogin(
          SocialType.facebook,
          result
        ).pipe(
          finalize(() => this.spinnerService.stop())
        ).subscribe(response => {
          subscribe.next(response);
        }, error => {
          subscribe.error(error);
        }, () => {
          subscribe.complete();
        });

      }).catch(error => {
        subscribe.error(error);
        subscribe.complete();
      });
    });
  }

  loginWithGoogle(
    userToActivate?: User,
    activationToken?: string
  ): Observable<SocialLoginValidation> {
    
    return new Observable(subscribe => {
      this.afAuth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      ).then(result => {
        if (userToActivate) {
          userToActivate.socialType = SocialType.google;
          userToActivate.tokenSocialLogin = result.user.uid;
          this.spinnerService.start();
          this.authService.activateByToken(
            activationToken,
            userToActivate
          ).pipe(
            finalize(() => {
              this.spinnerService.stop();
            })
          ).subscribe(response => {
            subscribe.next(<SocialLoginValidation>{
              email: userToActivate.email,
              institutionId: response.data.institutionId,
              token: response.data.token,
              refreshToken: response.data.refreshToken
            });
          }, (error: ServiceResponse) => {
            subscribe.error(error.errorMessage.firstError);
          }, () => {
            subscribe.complete();
          });
        } else {
          this.spinnerService.start();
          this.socialLoginService.validateSocialLogin(
            SocialType.google,
            result
          ).pipe(
            finalize(() => this.spinnerService.stop())
          ).subscribe(response => {
            subscribe.next(response);
          }, error => {
            subscribe.error(error);
          }, () => {
            subscribe.complete();
          });
        }

      }).catch(error => {
        subscribe.error(error);
        subscribe.complete();
      });
    });
  }

  loginWithApple(
    userToActivate?: User,
    activationToken?: string
  ): Observable<SocialLoginValidation> {
    
    return new Observable(subscribe => {
      
      const appleSignInProvider: firebase.FirebaseSignInProvider = 'apple.com';
      const provider = new OAuthProvider(appleSignInProvider);
      const auth = getAuth();
      
      signInWithPopup(
        auth,
        provider
      ).then(result => {

        if (userToActivate) {
          userToActivate.socialType = SocialType.apple;
          userToActivate.tokenSocialLogin = result.user.uid;
          this.spinnerService.start();
          this.authService.activateByToken(
            activationToken,
            userToActivate
          ).pipe(
            finalize(() => {
              this.spinnerService.stop();
            })
          ).subscribe(response => {
            subscribe.next(<SocialLoginValidation>{
              email: userToActivate.email,
              institutionId: response.data.institutionId,
              token: response.data.token,
              refreshToken: response.data.refreshToken
            });
          }, (error: ServiceResponse) => {
            subscribe.error(error.errorMessage.firstError);
          }, () => {
            subscribe.complete();
          });
        } else {
          this.spinnerService.start();
          this.socialLoginService.validateSocialLogin(
            SocialType.apple,
            result
          ).pipe(
            finalize(() => this.spinnerService.stop())
          ).subscribe(response => {
            subscribe.next(response);
          }, error => {
            subscribe.error(error);
          }, () => {
            subscribe.complete();
          });
        }

      }).catch(error => {
        subscribe.error(error);
        subscribe.complete();
      });
    });
  }

  /**
   * Emit a error message
   * @param error Error message
   */
  emitErrorMessage(error: string): void {
    this.errorMessageSubject$.next(error);
  }
}