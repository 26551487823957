<form class="sp-institution-manager-detail sp-form" [formGroup]="form">
    <sp-dialog>
        <sp-dialog-title
            [title]="title"
            subtitle="Select the following information for your new user">
        </sp-dialog-title>
        <sp-dialog-content>
            <mat-vertical-stepper [linear]="true" (selectionChange)="managerFormSelectionChange($event)">
                <!-- Manager user data -->
                <ng-container *ngIf="!userDataHidden"
                    [formGroupName]="formGroups.managerUser">
                    <mat-step [stepControl]="managerUserForm">
                        <ng-template matStepLabel>User</ng-template>
                        <mat-form-field class="w-100"
                            appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput
                                [formControlName]="controlNames.managerEmail"
                                placeholder="Email"
                                required>
                        </mat-form-field>
                        <div class="row no-gutters">
                            <div class="col-6">
                                <div class="mr-2">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Password</mat-label>
                                        <input type="password"
                                            matInput
                                            [formControlName]="controlNames.password"
                                            placeholder="Password"
                                            required>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="ml-2">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Password Confirm</mat-label>
                                        <input type="password" matInput
                                            [formControlName]="controlNames.passwordConfirm"
                                            placeholder="Password Confirm"
                                            required>
                                    </mat-form-field>
                                </div>
                            </div>
                            <mat-error *ngIf="managerUserForm.hasError('match')">
                                Password and confirmation do not match
                            </mat-error>
                        </div>
                        <button mat-button matStepperNext>Next</button>
                    </mat-step>
                </ng-container>
                <!-- Institution data -->
                <ng-container *ngIf="withInstitutionData"
                    [formGroupName]="formGroups.institution">
                    <mat-step [stepControl]="institutionForm">
                        <ng-template matStepLabel>Institution</ng-template>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Institution's name</mat-label>
                            <input matInput
                                [formControlName]="controlNames.institutionName"
                                placeholder="Institution's name"
                                required>
                            <mat-hint style="font-size:12px">
                                <span class="font-weight-bold">DB Name: </span>
                                {{institutionForm.get(controlNames.institutionName).value | dbName}}
                            </mat-hint>
                        </mat-form-field>
                        <ng-container [formGroupName]="formGroups.institutionTeam">
                            <mat-form-field appearance="outline" class="w-100 mt-4">
                                <mat-label>Associate team</mat-label>
                                <input matInput
                                    [formControlName]="controlNames.teamName"
                                    placeholder="Associate team">
                            </mat-form-field>
                        </ng-container>
                        <button *ngIf="!userDataHidden" mat-button matStepperPrevious>Previous</button>
                        <button mat-button matStepperNext>Next</button>
                    </mat-step>
                </ng-container>
                <!-- Manager names data -->
                <ng-container [formGroupName]="formGroups.managerNames">
                    <mat-step [stepControl]="managerNamesForm">
                        <ng-template matStepLabel>Name</ng-template>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Name</mat-label>
                            <input matInput
                                [formControlName]="controlNames.managerName"
                                placeholder="Name"
                                required>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Last Name</mat-label>
                            <input matInput
                                [formControlName]="controlNames.managerLastname"
                                placeholder="Last Name"
                                required>
                        </mat-form-field>
                        <button *ngIf="!userDataHidden || withInstitutionData" mat-button
                            matStepperPrevious>Previous</button>
                        <button mat-button matStepperNext>Next</button>
                    </mat-step>
                </ng-container>
                <!-- Manager contact data -->
                <ng-container [formGroupName]="formGroups.managerContact">
                    <mat-step [stepControl]="managerContactForm">
                        <ng-template matStepLabel>Contact</ng-template>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Phone Number</mat-label>
                            <input matInput
                                [formControlName]="controlNames.managerPhone"
                                placeholder="Phone Number">
                        </mat-form-field>
                        <button mat-button matStepperPrevious>Previous</button>
                        <button mat-button matStepperNext>Next</button>
                    </mat-step>
                </ng-container>
                <!-- Manager professional data -->
                <ng-container [formGroupName]="formGroups.managerProfessional">
                    <mat-step [stepControl]="managerProfessionalForm">
                        <ng-template matStepLabel>Professional</ng-template>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Professional Type</mat-label>
                            <mat-select [formControlName]="controlNames.managerProfessionalType">
                                <mat-option *ngFor="let professionalType of professionalTypes"
                                    [value]="professionalType.id">
                                    {{professionalType.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button mat-button matStepperPrevious>Previous</button>
                    </mat-step>
                </ng-container>
            </mat-vertical-stepper>
            <div #messageContainer class="sp-message">
                <span *ngIf="message">{{message}}</span>
                <span *ngIf="errorMessage" class="sp-message--error">{{errorMessage}}</span>
            </div>
        </sp-dialog-content>
        <sp-dialog-actions>
            <button mat-stroked-button
                color="primary"
                [mat-dialog-close]="null"
                type="button">
                Cancel
            </button>
            <button mat-flat-button
                color="primary"
                [disabled]="form.invalid || managerNamesForm.disabled"
                (click)="save()"
                type="submit">
                Save
            </button>
        </sp-dialog-actions>
    </sp-dialog>
</form>

<!-- <mat-form-field class="w-100"
    appearance="outline">
    <mat-label>Type</mat-label>
    <mat-select [formControlName]="controlNames.institutionType" required>
        <mat-option *ngFor="let subscription of subscriptionTypes"
            [value]="subscription"
            [disabled]="subscription===subscriptionType.paying?true:null">
            {{subscription | subscriptionType}}
        </mat-option>
    </mat-select>
</mat-form-field> -->

<!-- <sp-gender-select class="w-100"
    [required]="true"
    (genderChange)="genderChange($event)">
</sp-gender-select> -->

<!-- <mat-form-field appearance="outline" class="w-100">
    <mat-label>Date of Birth</mat-label>
    <input matInput [matDatepicker]="birthdate"
        [formControlName]="controlNames.managerBirthdate">
    <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
    <mat-datepicker #birthdate></mat-datepicker>
</mat-form-field> -->