import { Serializer, SubscriptionProductMetadataResponse } from "./interfaces";

export class SubscriptionProductMetadata implements Serializer<SubscriptionProductMetadata> {
    constructor(
        public coaches?: number
    ) { }

    fromResponse(response: SubscriptionProductMetadataResponse): SubscriptionProductMetadata {
        return new SubscriptionProductMetadata(
            response.coaches || 0
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}