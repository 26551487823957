<div class="sp-editable sp-editable-input">
    <!-- Input -->
    <ng-container *ngIf="valueType!=valuesType.time;else timeValue">
        <input #input
            type="text"
            class="sp-editable__input"
            matInput
            [ngModel]="value"
            [tabindex]="tabIndex"
            [placeholder]="localPlaceholder"
            [mask]="localMask"
            [dropSpecialCharacters]="false"
            [hidden]="!isEditMode"
            (ngModelChange)="onInputModelChange($event)"
            (focus)="onInputFocus($event)"
            (blur)="onInputBlur($event)"
            (keyup.enter)="onKeyEnter($event)"
            (keydown.tab)="onKeyTab($event)" />
    </ng-container>
    <!-- Time input -->
    <ng-template #timeValue>
        <input #input
            type="text"
            class="sp-editable__input"
            [spTimeInputSufix]="timeInputSufix"
            matInput
            [ngModel]="value"
            [tabindex]="tabIndex"
            [placeholder]="localPlaceholder"
            [hidden]="!isEditMode"
            (ngModelChange)="onInputModelChange($event)"
            (focus)="onInputFocus($event)"
            (blur)="onInputBlur($event)"
            (keyup.enter)="onKeyEnter($event)"
            (keydown.tab)="onKeyTab($event)" />
    </ng-template>
    <!-- Label -->
    <span [hidden]="isEditMode"
        class="sp-editable__label"
        [class.sp-editable__label--edit-on-click]="editWithSingleClick"
        [class.sp-editable__label--disabled]="disabled"
        [class.sp-editable__label--full-width]="fullWidth"
        (click)="onInputLabelClick()"
        (dblclick)="onInputLabelDoubleClick()">
        {{value?(localMask?(separator?(value|mask:'separator':separator):(value|mask:localMask)):value):localPlaceholder}}
    </span>
</div>