import { Component, Inject, Input } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sp-request-name-dialog',
  templateUrl: './request-name-dialog.component.html',
  styleUrls: ['./request-name-dialog.component.scss']
})
export class RequestNameDialogComponent {

  @Input() title: string;
  @Input() name = '';

  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, name: string },
    private formBuilder: UntypedFormBuilder
  ) {
    this.title = data.title;
    this.name = data.name;
    this.form = this.formBuilder.group({
      name: this.formBuilder.control(this.name, [Validators.required])
    });
  }
}