import { InstitutionalImageResponse } from "./institutional-image-response.interface";
import { TeamResponse } from "./team-response.interface";
import { UserResponse } from './user-response.interface';

export class AthleteResponse {
  id: number;
  full_name: string;
  email?: string;
  birthday?: string;
  photo?: string;
  phone?: string;
  total_messages?: number;
  room?: number;
  weigth?: string;
  heigth?: string;
  is_active?: boolean;
  last_edited?: UserResponse;
  updated_at?: string;
  status?: number;
  institution_image: InstitutionalImageResponse;
  teams?: Array<TeamResponse>;
}
