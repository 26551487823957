/**
 * Estatus de atletas
 * @author  José Chin
 */
export enum AthleteStatusOption {
    available = 1,
    modified,
    injured,
    sick,
    away
}

/**
 * Claves correspondientes a cada estatus de atleta
 */
export const AthleteStatusOptionKey: { [key in AthleteStatusOption]: string } = {
    1: 'available',
    2: 'modified',
    3: 'injured',
    4: 'sick',
    5: 'away'
};

/**
 * Colores correspondientes a cada estatus de atleta
 */
export const AthleteStatusOptionColor: { [key in AthleteStatusOption]: string } = {
    1: '#03DAC6',
    2: '#BB86FC',
    3: '#B00020',
    4: '#CF6679',
    5: '#3700B3'
};