import { Serializer, ExerciseBlockCommentResponse, ExerciseBlockCommentRequest } from "./interfaces";

import { AuditDate } from "./audit-date";
import { User } from "./user";

export class ExerciseBlockComment implements Serializer<ExerciseBlockComment> {

    constructor(
        public id?: number,
        public exerciseBlockId?: number,
        public user?: User,
        public recipient?: User,
        public comment?: string,
        public audit?: AuditDate
    ) { }

    static fromResponse(response: ExerciseBlockCommentResponse): ExerciseBlockComment {
        return new ExerciseBlockComment(
            response.id,
            response.exercise_block,
            response.user
                ? User.fromResponse(response.user)
                : null,
            response.recipient
                ? User.fromResponse(response.recipient)
                : null,
            response.comment,
            AuditDate.fromResponse(response)
        );
    }

    fromResponse(response: ExerciseBlockCommentResponse): ExerciseBlockComment {
        return ExerciseBlockComment.fromResponse(response);
    }

    toRequest(): ExerciseBlockCommentRequest {
        return <ExerciseBlockCommentRequest>{
            exercise_block: this.exerciseBlockId,
            user: this.user?.id || undefined,
            recipient: this.recipient?.id,
            comment: this.comment,
            all_athletes: false
        }
    }

    toAllAthletesRequest(): ExerciseBlockCommentRequest {
        return <ExerciseBlockCommentRequest>{
            exercise_block: this.exerciseBlockId,
            user: this.user?.id || undefined,
            recipient: undefined,
            comment: this.comment,
            all_athletes: true
        }
    }
}