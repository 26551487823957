/**
 * Colección de tipos de entidades de aplicación
 */
export enum EntityType {
    event = 1,
    program,
    phase,
    workout,
    athlete,
    team,
    coach,
    client
}