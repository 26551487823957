import { PAGE_SIZE_OPTIONS, Pagination } from "sp-core";

export interface Links {
  first: string;
  last: string;
  next: string;
  prev: string;
}

export interface PaginationDto {
  total_rows: number;
  per_page: number;
  current_page: number;
  links: Links;
}

export interface PaginatorConfig {
  length: number;
  pageSize: number;
  pageIndex: number;
  pageSizeOptions: Array<number>;
}

export class PageConfig {
  length: number;
  pageSize: number;
  pageIndex: number;
  pageSizeOptions: Array<number>;

  constructor(pagination: PaginationDto) {
    if (pagination instanceof Pagination) {
      this.length = pagination.totalRows;
      this.pageSize = pagination.perPage;
      this.pageIndex = pagination.currentPage;
    } else {
      this.length = pagination.total_rows;
      this.pageSize = Number(pagination.per_page);
      this.pageIndex = pagination.current_page;
    }
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
  }
}

/**
 * A plain version of MatSort
 */
export class Sort {
  active: string;
  direction: 'asc' | 'desc';
}
