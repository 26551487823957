import { Serializer, SubscriptionProductPriceRecurringResponse } from './interfaces';

export class SubscriptionProductPriceRecurring implements Serializer<SubscriptionProductPriceRecurring> {
    constructor(
        public aggregateUsage?: any,
        public interval?: string,
        public intervalCount?: number,
        public trialPeriodDays?: any,
        public usageType?: string
    ) { }

    fromResponse(response: SubscriptionProductPriceRecurringResponse): SubscriptionProductPriceRecurring {
        return new SubscriptionProductPriceRecurring(
            response.aggregate_usage,
            response.interval,
            response.interval_count,
            response.trial_period_days,
            response.usage_type
        )
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}