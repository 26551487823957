import { Injectable } from '@angular/core';
import moment from 'moment';

import { EntityIdentifier } from '../models/interfaces';
import { SPF_TIME_FORMAT } from '../constants';

@Injectable()
export class UtilitiesService {

  constructor() { }

  /**
   * Obtiene el valor indicado en formato DbName
   * @param value Valor a convertir en formato DbName
   * @param prefix Prefijo a agregar al texto
   */
  static getDbName(value: string, prefix?: string): string {

    if (!value) return '';

    return `${prefix ? prefix : 'spf_'}${value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')  // Elimina acentos.
      .replace(/[^a-zA-Z0-9]/g, '')     // Elimina todos los caracteres que no sean letras o números
      .toLowerCase()
      }`;
  }

  static getInitials(value: string): string {
    return value
      .replace(/[^a-zA-Z- ]/g, "")
      .match(/\b\w/g)
      .join('');
  }

  static getHash(value: string): number {
    let hash = 0, i: number, chr: number;
    if (value.length === 0) return hash;
    for (i = 0; i < value.length; i++) {
      chr = value.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  /**
   * Agrega un nuevo elemento en el índice indicado. En caso de que el índice especificado sea mayor a la colección el elemento se agrega al final
   * @param collection Colección/ arreglo en la cual se agregará el nuevo elemento
   * @param index Índice en la que se agregará el nuevo elemento
   * @param item Elemento a agregar
   * @returns
   */
  static insertAt(collection: Array<any>, index: number, item: any): void {
    if (index >= collection.length) {
      collection.push(item);
      return;
    }
    collection.splice(index, 0, item);
  }

  /**
   * Remove an item from collection
   * @param collection Collection contains the item to remove
   * @param itemToRemove Item to remove
   */
  static removeFromCollection(collection: Array<any>, itemToRemove: any): void {
    const index = collection.indexOf(itemToRemove);
    collection.splice(index, 1);
  }

  /**
   * Replace an item into collection
   * @param collection Collection contains the item to replace
   * @param itemToReplace Item to replace
   * @param itemReplace Item to replace for
   */
  static replace(collection: Array<any>, itemToReplace: any, itemReplace: any): void {
    const index = collection.indexOf(itemToReplace);
    if (index >= 0) {
      collection.splice(index, 1, itemReplace);
    }
  }

  /**
   * Verifica si la fecha indicada corresponde a la fecha actual del sistema.
   * Se validan sólo las fechas para evitar problemas con la sección tiempo.
   * @param date Fecha a verificar si es la fecha actual.
   * @param currentDate Por defecto se compara la fecha actual del sistema. Si se envía se compara sobre la fecha indicada
   */
  static checkCurrentDate(date: Date, currentDate?: Date): boolean {
    return moment(date).format('YYYYMMDD') === moment(currentDate || new Date()).format('YYYYMMDD');
  }

  /**
   * Verifica si el mes de la fecha indicada corresponde al mes actual del sistema.
   * Se validan sólo a nivel año-mes
   * @param date Fecha a verificar si es del mes actual.
  */
  static checkCurrentMonth(date: Date, currentDate?: Date): boolean {
    return moment(date).format('YYYYMM') === moment(currentDate || new Date()).format('YYYYMM');
  }

  /**
   * Format string to format: 00:00 or the specified
   * @author  José Chin
   */
  static formatToTime(
    value: string,
    format = '$1:$2',
    valueHasOnlyDigits = false,
    splitterFormatChar = '',
    removeLeftZeros = false
  ): string {

    const maxLength = 4;

    let formatedValue = value;

    let firstFormatSection = '';
    let secondFormatSection = '';
    if (splitterFormatChar) {
      [firstFormatSection, secondFormatSection] = format.split(splitterFormatChar);
    }

    if (!valueHasOnlyDigits) {
      formatedValue = formatedValue
        // Mantiene únicamente dígitos
        .replace(/\D/g, '')
        // Remove aditional zeros on the left
        .replace(/^0+/, '');
    }

    formatedValue = formatedValue
      // Complementa con ceros a la izquierda en caso de que se tenga menos de 4 dígitos enteros
      .padStart(maxLength, '0')
      // Sólo mantiene los dígitos indicados
      .substring(0, maxLength);

    // ---------------------------------------------
    // Format
    // ---------------------------------------------

    const regexReplace = /(\d{2})(\d{2})$/;
    // Apply format to separate section
    // In this step formatedValue have only numbers = '0000'
    if (splitterFormatChar) {
      // Check if number result of first section is greater than zero
      let firstValueSection = formatedValue.replace(regexReplace, firstFormatSection);
      if (removeLeftZeros && +formatedValue.substring(0, 2))
        firstValueSection = firstValueSection.replace(/^0+/, '');

      // Check if number result of second section is greater than zero. Don't remove left zeros to avoid bad formats
      let secondValueSection = formatedValue.replace(regexReplace, secondFormatSection);

      if (firstValueSection) {
        formatedValue = firstValueSection + splitterFormatChar + secondValueSection;
      } else {
        formatedValue = secondValueSection;
      }
    } else {
      formatedValue = formatedValue
        // Replace with the sufixes
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_string_as_the_replacement
        .replace(regexReplace, format);
    }

    return formatedValue;
  }

  /**
   * Format string to formats: 00h 00' or 00' 00'' based sufixes
   * @param valueHasOnlyDigits  Get if value contains only digits, if sure only have digits avoid some innecesary replaces
   * @author  José Chin
   */
  static formatToTimeWithSufixes(
    value: string,
    firstSufix: string,
    secondSufix: string,
    valueHasOnlyDigits = false
  ): string {

    return this.formatToTime(value,
      `$1${firstSufix} $2${secondSufix}`,
      valueHasOnlyDigits,
      value ? ' ' : null, // Splitter format char
      value ? true : false  // Remove left zeros
    );
  }

  static getOnlyDate(date: Date): Date {
    return moment(date).startOf('day').toDate();
  }

  static getTimeString(date: Date): string {
    if (!date) return null;
    return moment(date).format(SPF_TIME_FORMAT);
  }

  static getDateTimeFromTimeString(time: string) {
    if (!time) return null;
    return moment(time, SPF_TIME_FORMAT).toDate();
  }

  /**
   * Use to optimize ngFor rendering
   * @param index 
   * @param item 
   * @returns 
   */
  static trackByItemId(index: number, item: EntityIdentifier) {
    return item.id;
  }

  /**
   * Check if a object is empty. Only own properties are checked. Inherited properties are not checked.
   * @param object 
   * @returns 
   */
  static isEmptyObject(object: any) {
    return Object.keys(object).length === 0;
  }
}
