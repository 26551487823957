import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PendingOperationsService} from './services/pending-operations.service';
import {NotificationService} from 'sp-library';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [],
  providers: [PendingOperationsService, NotificationService]
})
export class CoreModule { }
