import { Serializer, WidgetMetadataResponse } from "./interfaces";
import { WidgetMetadataTeams } from "./widget-metadata-teams";

export class WidgetMetadata implements Serializer<WidgetMetadata> {

    constructor(
        public selectedTeams?: Array<WidgetMetadataTeams>,
        public filterNames?: Array<string>
    ) { }

    fromResponse(response: WidgetMetadataResponse): WidgetMetadata {
        return new WidgetMetadata(
            response.selectedTeams ? response.selectedTeams.map(x => WidgetMetadataTeams.fromResponse(x)) : [],
            response.filters
        );
    }

    toRequest(): WidgetMetadataResponse {
        return <WidgetMetadataResponse>{
            filters: this.filterNames ? this.filterNames : undefined,
            selectedTeams: this.selectedTeams ? this.selectedTeams.map(x => x.toRequest()) : undefined
        }
    }

    clone(): WidgetMetadata {
        return new WidgetMetadata(
            this.selectedTeams ? this.selectedTeams.map(x => x) : null,
            this.filterNames
        )
    }

    teamsByInstitution(institutionId: number): WidgetMetadataTeams {

        let selectedTeams = this.selectedTeams?.find(x => x.institutionId === institutionId);
        if (selectedTeams) return selectedTeams;

        selectedTeams = new WidgetMetadataTeams();
        selectedTeams.institutionId = institutionId;
        selectedTeams.teams = [];

        this.selectedTeams = this.selectedTeams || [];
        this.selectedTeams.push(selectedTeams);

        return selectedTeams;
    }
}