import moment from 'moment';

import { Serializer } from './interfaces/serializer.interface';
import { AuditResponse } from './interfaces/audit-response.interface';

export class Audit implements Serializer<Audit> {

    constructor(
        public createdAt?: Date,
        public createdBy?: number,
        public updatedAt?: Date,
        public updatedBy?: number
    ) { }

    fromResponse(response: AuditResponse): Audit {
        return new Audit(
            response.created_at ? moment(response.created_at).toDate() : null,
            response.created_by ? response.created_by : null,
            response.updated_at ? moment(response.updated_at).toDate() : null,
            response.updated_by ? response.updated_by : null
        );
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }
}