import moment from "moment";

import { EventSummaryItemResponse, Serializer } from "./interfaces";
import { SPFEvent } from "./spf-event";

export class EventSummaryItem implements Serializer<EventSummaryItem> {

    constructor(
        public date?: Date,
        public events?: number,
        public listEvents: Array<SPFEvent> = []
    ) { }

    fromResponse(response: EventSummaryItemResponse): EventSummaryItem {
        return new EventSummaryItem(
            moment(response.date).toDate(),
            response.events,
            response.list_event ? response.list_event.map(x => new SPFEvent().fromResponse(x)) : []
        )
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}