<div #container class="sp-input-search__container"
    [style.width.%]="width"
    [class.sp-input-search__container--no-animation]="disableAnimation">
    <!-- Ícono -->
    <div class="sp-input-search__icon">
        <mat-icon svgIcon="search"></mat-icon>
    </div>
    <!-- Entrada de texto -->
    <input #input
        type="search"
        class="sp-input-search__input"
        [placeholder]="placeholder"
        (focus)="onFocus()"
        [(ngModel)]="search"
        (blur)="onBlur()"
        (keyup.enter)="onKeyEnter(input.value)"
        (ngModelChange)="onSearchChange()">
</div>