import { Serializer, WidgetMetadataTeamResponse } from "./interfaces";

export class WidgetMetadataTeam implements Serializer<WidgetMetadataTeam>{

    constructor(
        public teamId?: number,
        public selected?: boolean
    ) { }

    static fromResponse(response: WidgetMetadataTeamResponse): WidgetMetadataTeam {
        return new WidgetMetadataTeam(
            response.team_id,
            response.selected
        )
    }

    fromResponse(response: WidgetMetadataTeamResponse): WidgetMetadataTeam {
        return WidgetMetadataTeam.fromResponse(response);
    }

    toRequest(): WidgetMetadataTeamResponse {
        return <WidgetMetadataTeamResponse>{
            team_id: this.teamId,
            selected: this.selected
        }
    }
}