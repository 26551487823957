<div class="sp-editable sp-editable-select">
    <!-- Select -->
    <select #select
        class="sp-editable__input sp-editable__input--select"
        [ngModel]="value"
        [tabindex]="tabIndex"
        [hidden]="!isEditMode"
        (ngModelChange)="valueModified=$event"
        (focus)="onSelectFocus($event)"
        (blur)="onInputBlur($event)"
        (keyup.enter)="onKeyEnter($event)"
        (keydown.tab)="onKeyTab($event)">
        <!-- <option value="">{{localPlaceholder}}</option> -->
        <option *ngFor="let item of dataSource"
            [value]="item.key">
            {{item.value}}
        </option>
    </select>
    <!-- Label -->
    <span class="sp-editable__label"
        [hidden]="isEditMode"
        (click)="onSelectLabelClick()">
        {{value?(localMask?(value|mask:localMask):value):localPlaceholder}}
    </span>
</div>