import { Serializer, WorksetResponse } from './interfaces';

import { WorksetTypeValue } from './workset-type-value';
import { WorksetValue } from './workset-value';

export class Workset implements Serializer<Workset> {

    constructor(
        public type?: WorksetTypeValue,
        public values: Array<WorksetValue> = []
    ) { }

    fromResponse(response: WorksetResponse): Workset {
        return new Workset(
            response.type ? new WorksetTypeValue().fromResponse(response.type) : null,
            response.values.map(x => new WorksetValue().fromResponse(x))
        );
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }
}