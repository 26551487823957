import { AthleteStatusOption, AthleteStatusOptionColor, AthleteStatusOptionKey } from "./enumerations";

export class AthleteStatus {

    static get availableStatus(): AthleteStatus {
        return <AthleteStatus>{
            status: AthleteStatusOption.available,
            key: AthleteStatusOptionKey[AthleteStatusOption.available],
            color: AthleteStatusOptionColor[AthleteStatusOption.available]
        }
    }

    static get modifiedStatus(): AthleteStatus {
        return <AthleteStatus>{
            status: AthleteStatusOption.modified,
            key: AthleteStatusOptionKey[AthleteStatusOption.modified],
            color: AthleteStatusOptionColor[AthleteStatusOption.modified]
        };
    }

    static get sickStatus(): AthleteStatus {
        return <AthleteStatus>{
            status: AthleteStatusOption.sick,
            key: AthleteStatusOptionKey[AthleteStatusOption.sick],
            color: AthleteStatusOptionColor[AthleteStatusOption.sick]
        };
    }

    static get injuredStatus(): AthleteStatus {
        return <AthleteStatus>{
            status: AthleteStatusOption.injured,
            key: AthleteStatusOptionKey[AthleteStatusOption.injured],
            color: AthleteStatusOptionColor[AthleteStatusOption.injured]
        };
    }

    static get awayStatus(): AthleteStatus {
        return <AthleteStatus>{
            status: AthleteStatusOption.away,
            key: AthleteStatusOptionKey[AthleteStatusOption.away],
            color: AthleteStatusOptionColor[AthleteStatusOption.away]
        };
    }

    private _options: Array<AthleteStatus> = [];
    get options() {
        return this._options;
    }

    private _optionsIndex = {} as Record<AthleteStatusOption, AthleteStatus>;
    get optionsIndex() {
        return this._optionsIndex;
    }

    constructor(
        public status?: AthleteStatusOption,
        public key?: string,
        public color?: string
    ) {
        const available = AthleteStatus.availableStatus;
        const modified = AthleteStatus.modifiedStatus;
        const sick = AthleteStatus.sickStatus;
        const injured = AthleteStatus.injuredStatus;
        const away = AthleteStatus.awayStatus;

        this._options = [
            available,
            modified,
            sick,
            injured,
            away
        ]

        this._optionsIndex[AthleteStatusOption.available] = available;
        this._optionsIndex[AthleteStatusOption.modified] = modified;
        this._optionsIndex[AthleteStatusOption.sick] = sick;
        this._optionsIndex[AthleteStatusOption.injured] = injured;
        this._optionsIndex[AthleteStatusOption.away] = away;
    }
}