import { Serializer, SubscriptionResponse } from "./interfaces";
import { SubscriptionProduct } from "./subscription-product";

export class SubscriptionDowngrade implements Serializer<SubscriptionDowngrade> {

    constructor(
        /**
         * Identificador de producto stripe a la que se hizo downgrade
         */
        public productId?: string,
        /**
         * Identificador de precio stripe a la que se hizo downgrade
         */
        public priceId?: string,
        /**
         * Producto a la que se hizo downgrade
         * ```
         * Campo auxiliar frontend. No se obtiene directamente por backend
         * ```
         */
        public product?: SubscriptionProduct
    ) { }

    fromResponse(response: SubscriptionResponse): SubscriptionDowngrade {
        return new SubscriptionDowngrade(
            response.down_product_id || null,
            response.down_price_id || null
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}
