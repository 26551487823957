import { Serializer, WorkoutTypeResponse } from "./interfaces";

export class WorkoutType implements Serializer<WorkoutType>{

    get logoSVG(): string {
        if (!this.logoSVGArray.length) return '';
        return this.logoSVGArray[0];
    }

    constructor(
        public id?: number,
        public key?: string,
        public logo?: string,
        public title?: string,
        private logoSVGArray?: Array<string>
    ) { }

    static fromResponse(response: WorkoutTypeResponse): WorkoutType {
        return new WorkoutType(
            response.id,
            response.key,
            response.logo,
            response.title,
            response.logo_svg
        );
    }

    fromResponse(response: WorkoutTypeResponse): WorkoutType {
        return WorkoutType.fromResponse(response);
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}