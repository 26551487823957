<div class="sp-table">
  <table class="w-100"
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows>

    <!-- Header row -->
    <tr mat-header-row
      *matHeaderRowDef="columns;sticky:stickyHeader">
    </tr>

    <!-- Rows -->
    <tr [@listStagger]="dataSource"
      mat-row
      *matRowDef="let row; columns: columns;"
      class="sp-table__row"
      [class.sp-table__row--selectable]="selectableRow"
      (click)="handleRowClick(row)">
    </tr>

    <!-- Rows: Detail -->
    <div *ngIf="expandedDetail">
      <tr mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row">
      </tr>
    </div>

  </table>

  <!-- paginator -->
  <mat-paginator *ngIf="!loader && dataSource?.length > 0"
    [length]="paginatorConfig?.length"
    [pageSize]="defaultPageSize || paginatorConfig?.pageSize"
    [pageSizeOptions]="paginatorConfig?.pageSizeOptions"
    (page)="setPage($event)"
    showFirstLastButtons>
  </mat-paginator>

  <!-- not content -->
  <div *ngIf="!loader && dataSource.length == 0"
    class="sp-table__no-content-wrapper">
    <ng-container *ngTemplateOutlet="noContent"></ng-container>
  </div>
  <ng-template #noContent>
    <div [@noContent]
      class="sp-table__no-content">
      {{noContentText}}
    </div>
  </ng-template>
</div>