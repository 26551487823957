import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';

import { PAGE_SIZE, PaginatedParams, Pagination } from 'sp-core';

import { Sort } from '../../../../components/sp-table';

@Component({
  selector: 'sp-paginable',
  templateUrl: './paginable.component.html',
  styleUrls: ['./paginable.component.css']
})
export class PaginableComponent<TData> implements OnInit {

  dataSource: TData;

  columns: string[] = [];

  defaultPageSize = PAGE_SIZE;

  inputSearchValue = '';
  sort: Sort;
  paginatedParams: PaginatedParams;
  subSink = new SubSink();

  getData$ = new Subject<null>();

  constructor() {
    this.initPagination();
  }

  ngOnInit(): void { }

  handleInputSearchValueChange(value: string): void {
    this.inputSearchValue = value;
    this.paginatedParams.page = 1;
    this.getData$.next();
  }

  /**
   * Ordenamiento de tabla
   * TODO: De momento se elimina la opción de ordenar de las columnas
   * @param sort 
   */
  handleTableSort(
    sort: Sort
  ): void {
    this.sort = sort;
    this.getData$.next();
  }

  handlePageChange(pageEvent: PageEvent): void {
    if (pageEvent) {
      this.paginatedParams.pageSize = pageEvent.pageSize;
      this.paginatedParams.page = pageEvent.pageIndex + 1;
    }
    this.getData$.next();
  }

  initPagination() {
    this.paginatedParams = new PaginatedParams();
    this.paginatedParams.page = 1;
    this.paginatedParams.pageSize = this.defaultPageSize;
  }

  resetPage() {
    this.paginatedParams.page = 1;
  }

  /**
   * Update page for next request
   * @param pagination
   */
  updatePage(pagination: Pagination) {
    this.paginatedParams.page = pagination.links.next ? this.paginatedParams.page + 1 : null;
  }
}
