import { ErrorMessageResponse, Serializer } from "./interfaces";

export class ErrorMessage implements Serializer<ErrorMessage>{

    /**
     * Obtiene el primer error de los errores devueltos
     */
    get firstError(): string {
        if (!this.errors.length) return '';
        return this.errors[0];
    }

    constructor(
        public message?: string,
        public exception?: string,
        public errors: Array<string> = []
    ) { }

    fromResponse(response: ErrorMessageResponse): ErrorMessage {
        return new ErrorMessage(
            response.message,
            response.exception,
            response.errors
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}