import moment from 'moment';

import { Guid } from 'guid-typescript';

import { Gender, SocialType, UserLanguage, UserLanguageCode, UserType } from './enumerations';
import {
    InstitutionResponse, Serializer, UserPasswordRequest, UserRequest, UserResponse,
    TypeProfession
} from './interfaces';

import { Institution } from './institution';
import { Team } from './team';
import { ServiceResponse } from './service-response';
import { Region } from './region';
import { UserPermission } from './user-permission';
import { UserInvitation } from './user-invitation';
import { InstitutionalImage } from './institutional-image';
import { UserInstitution } from './user-institution';
import { Athlete } from './athlete';
import { UserMetadata } from './user-metadata';

export class User extends ServiceResponse implements Serializer<User> {

    get isNew(): boolean {
        return !this.id
    }

    private _photo: string;
    // Obtiene o establece la foto del usuario. La imágen institucional tiene prioridad vs la foto asignada al crear el usuario
    get photo(): string {
        return this.institutionalImage?.imageURL || this._photo;
    }
    set photo(value: string) {
        this._photo = value;
    }

    /**
     * Obtiene si el registro de usuario es referente a una invitación. Tiene datos de invitación y la institución deberá ser diferente al de la sesión iniciada
     * @returns
     */
    get isInvitation(): boolean {
        return !!this.invitation;
    }

    /**
     * Obtiene si el usuario se creó mediante mediante la pantalla de registro de nuevo usuario auth/register
     */
    get isFromRegister(): boolean {

        // Si no tiene dato de quien lo registró entonces es un usuario que se registró por sí mismo. Desde la pantalla de registro
        if (!this.createdBy) return true;

        // Si tiene dato de usuario de creación y éste es el mismo que el actual registro entonces fué registrado desde la pantalla de registro
        if (this.createdBy.id === this.id) return true;

        // En caso contrario el usuario fué creado por otra persona. Por ejemplo desde management -> coaches
        return false;
    }

    /**
     * Obtiene las instituciones a las que podrá acceder el usuario
     * ```
     * Institución asignada al usuario + Instituciones sobre las que se le han invitado
     * ```
     */
    get institutions(): Array<UserInstitution> {

        const userInstitution = this.institution.toUserInstitution(this.type);

        // Sino tiene invitaciones únicamente devuelve la institución asignada
        if (!this.invitations || !this.invitations.length) return [userInstitution];

        // Agrega las invitaciones a instituciones
        return [userInstitution].concat(
            this.invitations.map(x => x.institution.toUserInstitution(x.permission, true))
        );
    }

    /**
     * Obtiene el código del idioma/lenguaje según el identificador (1, 2, ...)
     */
    get languageCode(): string {
        if (!this.language) return null;
        return UserLanguageCode[this.language];
    }

    get isAdmin(): boolean {
        return this.permissions?.isAdmin;
    }

    get isManager(): boolean {
        return this.permissions?.isManager;
    }

    get isCoach(): boolean {
        return this.permissions?.isCoach;
    }

    get isAthlete(): boolean {
        return this.permissions?.isAthlete;
    }

    constructor(
        public id?: number,
        public type?: UserType,
        public fullName?: string,
        public email?: string,
        public gender?: Gender,
        public phone?: string,
        public birthdate?: Date,
        public height?: number,
        public weight?: number,
        public region?: Region,
        public countryCode?: number,
        public country?: string,
        public language?: UserLanguage,
        public professionalTypeId?: number,
        public FCM?: number,
        public MAS?: number,
        public MSS?: number,
        public href?: string,
        /**
         * Tipo de social login con la que se registró el usuario
         */
        public socialType?: SocialType,
        /**
         * Token en caso de que el registro sea por social login
         */
        public tokenSocialLogin?: string,
        public username?: string,
        public password?: string,
        public emailVerified?: boolean,
        public isSubscriptionActive?: boolean,
        public isProfileComplete?: boolean,
        public onboardingComplete?: boolean,
        public isConnect?: boolean,
        public connect?: string,
        /**
         * Indica si el registro está eliminado
         */
        public isActive?: boolean,
        /**
         * Equipo asignado. TODO: Verificar si se requiere ésta propiedad o siempre vendrá con arreglo de equipos en this.teams
         */
        public team?: Team,
        public institution?: Institution,
        public institutionalImage?: InstitutionalImage,
        public city?: string,
        public state?: string,
        public line1?: string,
        public line2?: string,
        public postalCode?: string,
        public companyName?: string,
        public fiscalId?: string,
        public createdAt?: Date,
        /**
         * Usuario quien creó el registro
         */
        public createdBy?: User,
        public updatedAt?: Date,
        public lastEdited?: User,
        /**
         * Indica si el registro está activo o inactivo en la institución a la que pertenece o está asignado (institutional_enabled)
         */
        public isEnabled?: boolean,
        /**
         * Indica los perfiles que tiene el usuario: Admin, Institution manager, etc...
         */
        public permissions?: UserPermission,
        /**
         * Datos de invitación
         */
        public invitation?: UserInvitation,
        /**
         * Fotografía tipo File
         * ```
         * Campo auxiliar para frontend. Para enviar imagen a backend en formato FormData
         * ```
         */
        public photoFile?: File,
        /**
         * Indica si el correo es opcional. En caso de que sea opcional generará un GUID y enviará el correo como: noemail.com
         * ```
         * Campo auxiliar para frontend
         * ```
         */
        public isEmailOptional?: boolean,
        public url?: string,
        public typeOfProfession?: TypeProfession | null,
        public metadata?: UserMetadata,
        public teams: Array<Team> = [],
        public invitations: Array<UserInvitation> = []
    ) {
        super();
    }

    static toAthlete(user: User): Athlete {

        const athlete = new Athlete();

        athlete.id = user.id;
        athlete.fullName = user.fullName;
        athlete.photo = user.photo;

        return athlete;
    }

    static fromAthlete(athlete: Athlete): User {

        const user = new User();

        user.id = athlete.id;
        user.fullName = athlete.fullName;
        user.photo = athlete.photo;

        return user;
    }

    /**
     * Mapea la respuesta con datos de usuario a un modelo de usuario (Clase). Método estático
     * @param response
     * @returns
     */
    static fromResponse(response: UserResponse): User {
        const user = new User(
            response.id,
            response.type as UserType,
            response.full_name,
            response.email,
            response.gender as Gender,
            response.phone,
            response.birthday ? moment(response.birthday).toDate() : null,
            response.heigth || 0,
            response.weigth || 0,
            response.region ? new Region().fromResponse(response.region) : null,
            response.country_code,
            response.country,
            response.languaje,
            null,
            response.FCM || 0,
            response.MAS || 0,
            response.MSS || 0,
            response.href,
            response.social_type ? response.social_type as SocialType : null,
            null,
            response.username,
            '',
            response.email_verified,
            response.subscription_active,
            response.is_profile_complete ? response.is_profile_complete : false,
            response.onboarding_complete,
            response.is_connect,
            response.connect,
            response.is_active,
            null,
            null,
            response.institution_image
                ? InstitutionalImage.fromResponse(response.institution_image)
                : null,
            response?.city ?? '',
            response?.state ?? '',
            response?.line_1 ?? '',
            response?.line_2 ?? '',
            response?.postal_code ?? '',
            response?.company_name ?? '',
            response?.fiscal_id ?? '',
            response.created_at ? moment(response.created_at).toDate() : null,
            response.created_by ? User.fromResponse(response.created_by) : null,
            response.updated_at ? moment(response.updated_at).toDate() : null,
            response.last_edited ? new User().fromResponse(response.last_edited) : null,
            response.institutional_enabled ? response.institutional_enabled : false,
            null,
            response.invite_user ? new UserInvitation().fromResponse(response.invite_user) : null,
            null,
            null,
            response.url ?? '',
            response?.type_of_profession ?? null,
            response.jsonData ? UserMetadata.fromResponse(response.jsonData) : null,
            response.teams ? response.teams.map(x => new Team().fromResponse(x)) : [],
            []
        );

        // Foto
        if (response.photo) {
            user.photo = response.photo;
        }

        // Institution
        if (response.institution) {
            if ((typeof response.institution) === 'number') {
                user.institution = new Institution();
                user.institution.id = +response.institution;
            } else {
                user.institution = new Institution().fromResponse(response.institution as InstitutionResponse);
            }
        }

        // Permisos
        user.permissions = new UserPermission();
        user.permissions.isAdmin = response.is_admin ? response.is_admin : false;
        user.permissions.isManager = response.is_manager ? response.is_manager : false;
        user.permissions.isCoach = response.is_coach ? response.is_coach : false;
        user.permissions.isAthlete = response.is_athlete ? response.is_athlete : false;

        // Tipo de usuario según el permiso. Sólo en caso de que no se haya asignado en la respuesta
        if (!user.type) {
            if (user.permissions.isAdmin) {
                user.type = UserType.superAdmin;
            } else if (user.permissions.isManager) {
                user.type = UserType.institutionManager;
            } else if (user.permissions.isCoach) {
                user.type = UserType.strengthCoach;
            } else if (user.permissions.isAthlete) {
                user.type = UserType.athlete;
            }
        }

        // Usuario correspondiente a una invitación
        if (response.invite_user) {
            user.type = response.invite_user.permission as UserType;
            // Para el caso de que sea un usuario correspondiente a una invitación el indicador de activo se obtiene de dicha invitación
            user.isEnabled = response.invite_user.institutional_enabled;
        }

        // En caso de que el email asignado sea uno FAKE se elimina del objeto para no visualizar en la aplicación
        if (response.email?.indexOf?.('noemail.com') >= 0) {
            user.email = null;
        }

        return user;
    }

    /**
     * Mapea la respuesta con datos de usuario a un modelo de usuario (Clase)
     * @param response Respuesta con datos de usuario
     * @returns
     */
    fromResponse(response: UserResponse): User {
        return User.fromResponse(response);
    }

    toRequest(): UserRequest {
        const user = <UserRequest>{
            email: this.email ? this.email.toLowerCase() : undefined,
            full_name: this.fullName || undefined,
            type: this.type || undefined,
            phone: this.phone || undefined,
            photo: this.photo || undefined,
            country_code: this.countryCode || undefined,
            birthday: this.birthdate ? moment(this.birthdate).format('YYYY-MM-DD') : undefined,
            region: this.region ? this.region.id : undefined,
            gender: this.gender || undefined,
            languaje: this.language || undefined,
            is_profile_complete: this.isProfileComplete || undefined,
            type_of_profession: this.professionalTypeId || undefined,
            institution: this.institution && this.institution.id ? this.institution.id : undefined,
            password: this.password || undefined,
            confirm_password: this.password || undefined,
            institutional_enabled: (this.isEnabled !== null && this.isEnabled !== undefined) ? this.isEnabled : undefined,
            is_active: (this.isActive !== null && this.isActive !== undefined) ? this.isActive : undefined,
            jsonData: this.metadata
                ? this.metadata.toRequest()
                : undefined
        };

        // Si se envía email indica al backend que se requiere enviar email para complementar flujo de activación
        if (this.email) {
            user.send_email = true;
        }
        // En caso de que no se tenga email pero intencionalmente se indicó que es opcional entonces genera uno genérico
        else if (this.isEmailOptional) {
            user.send_email = false;
            user.email = `${Guid.create()}@noemail.com`;
        }

        return user;
    }

    toSetPasswordRequest(): UserPasswordRequest {
        return <UserPasswordRequest>{
            social_type: this.socialType,
            token_social_login: this.tokenSocialLogin,
            password: this.password,
            confirm_password: this.password
        };
    }

    /**
     * Convierte el modelo actual a formato FormData para permitir envío de datos blob
     * @returns
     */
    toFormDataRequest(): FormData {

        const userRequest = this.toRequest();

        const formData = new FormData();
        if (userRequest.email) {
            formData.append('email', userRequest.email);
        }
        if (userRequest.full_name) {
            formData.append('full_name', userRequest.full_name);
        }
        if (userRequest.type) {
            formData.append('type', userRequest.type.toString());
        }
        if (userRequest.phone) {
            formData.append('phone', userRequest.phone);
        }
        if (userRequest.country_code) {
            formData.append('country_code', userRequest.country_code.toString());
        }
        if (userRequest.birthday) {
            formData.append('birthday', userRequest.birthday);
        }
        if (userRequest.region) {
            formData.append('region', userRequest.region.toString());
        }
        if (userRequest.gender) {
            formData.append('gender', userRequest.gender.toString());
        }
        if (userRequest.languaje) {
            formData.append('languaje', userRequest.languaje.toString());
        }
        if (userRequest.is_profile_complete) {
            formData.append('is_profile_complete', userRequest.is_profile_complete ? 'true' : 'false');
        }
        if (userRequest.type_profesion) {
            formData.append('type_of_profession', userRequest.type_profesion.toString());
        }
        if (userRequest.institution) {
            formData.append('institution', userRequest.institution.toString());
        }
        if (userRequest.password) {
            formData.append('password', userRequest.password);
        }
        if (userRequest.confirm_password) {
            formData.append('confirm_password', userRequest.confirm_password);
        }
        if (userRequest.is_active) {
            formData.append('is_active', userRequest.is_active ? 'true' : 'false');
        }
        if (userRequest.institutional_enabled) {
            formData.append('institutional_enabled', userRequest.institutional_enabled ? 'true' : 'false');
        }
        if (userRequest.send_email) {
            formData.append('send_email', userRequest.send_email ? 'true' : 'false');
        }
        // La foto lo obtiene del modelo y no de la interfaz como los campos anteriores
        if (this.photoFile) {
            formData.append('photo', this.photoFile, this.photoFile.name);
        }

        return formData;
    }

    /**
     * Clona el objeto de usuario
     * @param deep Indica si se clonará incluso las propiedades que son objeto. Ejemplo: Institution, team, teams
     * ```
     * TODO: El parámetro deep aún no está implementado
     * ```
     * @returns
     */
    clone(
        deep = false
    ): User {
        const user = new User();
        user.id = this.id;
        user.fullName = this.fullName;
        user.email = this.email;
        user.type = this.type;
        user.gender = this.gender;
        user.birthdate = this.birthdate;
        user.height = this.height;
        user.weight = this.weight;
        user.href = this.href;
        user.socialType = this.socialType;
        user.institution = this.institution;
        user.isEnabled = this.isEnabled;
        user.invitation = this.invitation;
        user.isActive = this.isActive;
        user.isConnect = this.isConnect;
        user.isProfileComplete = this.isProfileComplete;
        user.isSubscriptionActive = this.isSubscriptionActive;
        user.language = this.language;
        user.line1 = this.line1;
        user.line2 = this.line2;
        user.onboardingComplete = this.onboardingComplete;
        user.password = this.password;
        user.permissions = this.permissions;
        user.phone = this.phone;
        user.photo = this.photo;
        user.photoFile = this.photoFile;
        user.postalCode = this.postalCode;
        user.professionalTypeId = this.professionalTypeId;
        user.country = this.country;
        user.state = this.state;
        user.region = this.region;
        user.team = this.team;
        user.teams = this.teams;
        user.createdAt = this.createdAt;
        user.createdBy = this.createdBy;
        user.updatedAt = this.updatedAt;
        user.lastEdited = this.lastEdited;
        user.username = this.username;
        user.FCM = this.FCM;
        user.MAS = this.MAS;
        user.MSS = this.MSS;
        user.city = this.city;
        user.companyName = this.companyName;
        user.connect = this.connect;
        user.countryCode = this.countryCode;
        user.emailVerified = this.emailVerified;
        user.fiscalId = this.fiscalId;
        user.institutionalImage = this.institutionalImage;
        return user;
    }
}
