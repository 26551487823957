import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { Day, Week, WeekDay, WeekDays, UtilitiesService } from 'sp-core';

@Component({
  selector: 'sp-weeks-days',
  templateUrl: './weeks-days.component.html',
  styleUrls: ['./weeks-days.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WeeksDaysComponent implements OnInit, OnChanges {

  @Input() selectedWeekId: number;

  @Input() weeks: Array<WeekDays> = [];

  /**
   * Obtiene o establece si se podrá seleccionar más de 1 día en el control. 
   * En caso contrario al seleccionar otro día el anterior se des-selecciona.
   */
  @Input() multipleDays = false;

  /**
   * Obtiene o establece si un día puede tener más de un item. Para el caso de workouts que pueden existir más de 1 workout en un mismo día.
   */
  @Input() multipleDayItems = false;

  /**
   * Obtiene o establece si las semanas se pueden borrar
   */
  @Input() weeksRemovable = false;

  /**
   * Obtiene o establece si las semanas se pueden clonar
   */
  @Input() weeksClonable = false;

  @Input() weeksSortable = false;

  @Input() newWeekHidden = false;

  @Output() change = new EventEmitter<Array<WeekDays>>();

  @Output() weekChange = new EventEmitter<WeekDays>();

  @Output() dayChange = new EventEmitter<WeekDay>();

  @Output() removeWeek = new EventEmitter<WeekDays>();

  @Output() cloneWeek = new EventEmitter<WeekDays>();

  @ViewChild('newWeek') newWeekRef: ElementRef;

  get newWeek(): HTMLElement {
    return this.newWeekRef ? this.newWeekRef.nativeElement : null;
  }

  week: Week = new Week();

  lastOrder = 0;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['weeks']) {
      this.calculateWeeksOrder();
    }
  }

  ngOnInit(): void { }

  onDayClick(weekDay: WeekDay, weekDays: WeekDays): void {

    // Cambia estado de día
    weekDay.selected = !weekDay.selected;

    this.dayClicked(weekDay, weekDays);
  }

  addWeek(day: Day): void {

    let week = new WeekDays();
    let weekDay = week.selectDay(day);
    this.weeks.push(week);

    this.dayClicked(weekDay, week);

    this.calculateWeeksOrder();
    setTimeout(() => {
      if (this.newWeek) {
        this.newWeek.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }

  /**
   * Elimina la semana indicada por el índice especificado
   * @param index Índice de semana a eliminar
   */
  onRemoveWeekClick(index: number): void {
    const weekToRemove = this.weeks[index];
    this.removeWeek.emit(weekToRemove);

    // Si la semana está recién agregado es decir no existe en BD se elimina
    if (weekToRemove.isNew) {
      this.weeks.splice(index, 1);
    }
    // De lo contrario sólo se marca para eliminar posteriormente
    else {
      this.weeks[index].markAsRemoved();
    }

    this.calculateWeeksOrder();

    this.weeksChanged();
  }

  /**
   * Clona la semana indicada
   * @param index Índice de semana a clonar
   */
  onCloneWeekClick(index: number): void {
    const weekToClone = this.weeks[index];
    this.cloneWeek.emit(weekToClone);

    const clonedWeek = weekToClone.clone();
    clonedWeek.markAsCloned();
    UtilitiesService.insertAt(this.weeks, index + 1, clonedWeek);

    this.calculateWeeksOrder();

    this.weeksChanged();
  }

  dropWeek(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.weeks, event.previousIndex, event.currentIndex);
  }

  /**
   * Verifica si se visualizará la opción de nuevo registro.
   */
  checkNewWeekOption(): boolean {
    if (this.newWeekHidden) {
      return false;
    }
    if (this.multipleDays) {
      return true;
    } else {
      // Si no se permite seleccionar varios días el registro de nueva semana sólo se visualiza sino se ha agregado previamente uno.
      // Cuando es nueva semana el id es nulo.
      return this.weeks.filter(week => !week.id).length ? false : true;
    }
  }

  private calculateWeeksOrder(): void {

    const weeksNotRemoved = this.weeks
      .filter(x => !x.isRemoved);

    // Asigna consecutivos a las semanas NO marcadas como eliminadas
    weeksNotRemoved
      .forEach((week, index) => week.order = index + 1);

    // Obtiene el consecutivo siguiente
    this.lastOrder = weeksNotRemoved.length + 1;
  }

  private dayClicked(
    weekDay: WeekDay,
    weekDays: WeekDays
  ): void {

    // En caso de que no se permita seleccionar más de 1 día el resto se des-selecciona.
    if (!this.multipleDays) {
      this.weeks.forEach(week => {
        week.days
          .filter(day => day.selected && day !== weekDay)
          .forEach(day => {
            day.selected = false;
            if (this.multipleDayItems) {
              day.itemsCount -= 1;
            }
          });
      })
    }

    // En caso de que permita seleccionar más de 1 una vez el mismo día. Se incrementa/reduce el contador.
    if (this.multipleDayItems) {
      weekDay.itemsCount += weekDay.selected ? 1 : -1;
    }

    // Elimina las semanas nuevas que no tengan ningún día seleccionado.
    while (this.weeks.length > 0 &&
      this.weeks.filter(week => week.isNew && !(week.checkDaySelected() || week.checkItemsCount())).length
    ) {
      const firtWeekNewAndNotSelected = this.weeks.filter(week => week.isNew && !(week.checkDaySelected() || week.checkItemsCount()))[0];
      this.weeks.splice(this.weeks.indexOf(firtWeekNewAndNotSelected), 1)
    }

    // Emite la acción de cambio de estado de día a componente padre.
    this.dayChange.emit(weekDay);

    // Emite la acción de cambio de estado de semana a componente padre
    this.weekChange.emit(weekDays);

    // Indica un cambio de semanas, días.
    this.weeksChanged();
  }

  private weeksChanged(): void {
    this.change.emit(this.weeks);
  }
}