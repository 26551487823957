import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';

import { KeyValue } from 'sp-core';

import { EditableComponent } from './../editable/editable.component';

@Component({
  selector: 'sp-editable-select',
  templateUrl: './editable-select.component.html',
  styleUrls: [
    './../editable/editable.component.scss',
    './editable-select.component.scss'
  ]
})
export class EditableSelectComponent extends EditableComponent implements OnInit {

  @Input() dataSource: Array<KeyValue> = [];

  @ViewChild('select') selectRef: ElementRef;

  get select(): HTMLSelectElement {
    return (this.selectRef.nativeElement as HTMLSelectElement);
  }

  constructor() {
    super();
  }

  ngOnInit(): void { }

  onSelectLabelClick(): void {
    if (!this.editWithSingleClick) return;
    this.editModeSelect();
  }

  onSelectLabelDoubleClick(): void {
    if (this.editWithSingleClick) return;
    this.editModeSelect();
  }

  onSelectFocus(event: any): void {
    this.focus.emit(event);
  }

  /**
   * Llamar evento blur del control de captura.
   * Se llama de manera externa al componente
   */
  blurSelect(): void {
    this.select.blur();
  }

  /**
   * Llamar evento Tab del control de captura.
   * Se llama de manera externa al componente
   */
  keyTabSelect(): void {
    this.select.dispatchEvent(new KeyboardEvent("keydown", { key: "Tab" }));
  }

  editModeSelect(): void {
    this.select.value = this.value;
    this.editMode();
    setTimeout(() => {
      this.select.focus();
    }, 0);
  }
}