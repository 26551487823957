import { AfterContentInit, Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CollapsableDirection } from './enums/collapsable-direction.enum';
import { PanelBodyComponent } from './panel-body/panel-body.component';
import { PanelHeaderComponent } from './panel-header/panel-header.component';

@Component({
  selector: 'sp-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit, AfterContentInit {

  @Input() collapsable = false;
  @Input() collapsableDirection = CollapsableDirection.right;
  @Input() bordered = false;
  @Input() collapsed = false;

  @Output() collapsedChange = new EventEmitter<boolean>();

  @ContentChild(PanelHeaderComponent) headerComponent: PanelHeaderComponent;
  @ContentChild(PanelBodyComponent) bodyComponent: PanelBodyComponent;

  header: PanelHeaderComponent;
  body: PanelBodyComponent;

  private timerSingleClick: any;
  private preventSingleClick = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.header = this.headerComponent;
      this.body = this.bodyComponent;
    }, 0);
  }

  expandCollapse(): void {
    if (!this.collapsable) return;
    this.preventSingleClick = false;
    this.timerSingleClick = setTimeout(() => {
      if (!this.preventSingleClick) {
        this.collapsed = !this.collapsed;
        this.collapsedChange.emit(this.collapsed);
      }
    }, 250)
  }

  onHeaderDblclick(): void {
    this.preventSingleClick = true;
    clearTimeout(this.timerSingleClick);
  }
}
