<form class="sp-institution-manager-detail"
    [formGroup]="form">
    <sp-dialog>
        <sp-dialog-title title="Institution Detail"
            subtitle="Fill the following information for your institution">
        </sp-dialog-title>
        <sp-dialog-content>
            <mat-form-field class="w-100"
                appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput
                    [formControlName]="CONTROL_NAMES.name"
                    placeholder="Institution name"
                    required>
            </mat-form-field>
            <sp-upload-image class="d-block"
                [style.width.px]="500"
                [round]="true"
                [img]="imageCtrl.value"
                (file)="handleUploadImageFile($event)">
            </sp-upload-image>
        </sp-dialog-content>
        <sp-dialog-actions>
            <button mat-button
                color="primary"
                [mat-dialog-close]="null"
                type="button">
                Cancel
            </button>
            <button mat-flat-button
                color="primary"
                [disabled]="form.invalid"
                (click)="handleSaveClick()"
                type="submit">
                Save
            </button>
        </sp-dialog-actions>
    </sp-dialog>
</form>