import moment from "moment";

import { SPF_DATE_FORMAT } from "../constants";
import { EventPriority } from "./enumerations";
import { SPFEventResponse, SPFEventRequest, Serializer } from "./interfaces";

import { Entity } from "./entity";
import { SPFTime } from "./spf-time";
import { Team } from "./team";
import { Athlete } from "./athlete";

export class SPFEvent extends Entity implements Serializer<SPFEvent> {
    constructor(
        public date?: Date,
        public hourStart?: SPFTime,
        public hourEnd?: SPFTime,
        public priority?: EventPriority,
        public location?: string,
        public teams: Array<Team> = [],
        public athletes: Array<Athlete> = []
    ) {
        super();
    }

    fromResponse(response: SPFEventResponse): SPFEvent {

        const event = new SPFEvent(
            moment(response.date).toDate(),
            null,
            null,
            response.priority ? response.priority as EventPriority : null,
            response.location,
            response.teams ? response.teams.map(x => new Team().fromResponse(x)) : [],
            response.athletes ? response.athletes.map(x => new Athlete().fromResponse(x)) : []
        )

        event.id = response.id;
        event.name = response.name;

        if (response.hour_start) {
            event.hourStart = new SPFTime().fromString(response.hour_start);
        }
        else if (response.start) {
            const hourStart = new SPFTime();
            hourStart.hours = response.start.hour;
            hourStart.minutes = response.start.minute;
            event.hourStart = hourStart;
        }

        if (response.hour_end) {
            event.hourEnd = new SPFTime().fromString(response.hour_end);
        }
        else if (response.end) {
            const hourEnd = new SPFTime();
            hourEnd.hours = response.end.hour;
            hourEnd.minutes = response.end.minute;
            event.hourEnd = hourEnd;
        }

        return event;
    }

    toRequest(): SPFEventRequest {
        return <SPFEventRequest>{
            name: this.name,
            date: moment(this.date).format(SPF_DATE_FORMAT),
            hour_start: this.hourStart.toString(),
            hour_end: this.hourEnd.toString(),
            priority: this.priority,
            location: this.location,
            teams_id: this.teams.map(x => x.id),
            athletes_id: this.athletes.map(x => x.id)
        }
    }
}