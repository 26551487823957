/**
 * Estructura para objetos seleccionables
 * @author José Chin
 */
export class SelectableEntity<T> {
    constructor(
        public entity?: T,
        public selected = false
    ) { }

    static fromEntity<TEntity>(entity: TEntity) {
        const resultEntity = new SelectableEntity<TEntity>();
        resultEntity.entity = entity;
        return resultEntity;
    }

    fromEntity(entity: T) {
        return SelectableEntity.fromEntity(entity);
    }

    toggleSelection(): void {
        this.selected = !this.selected;
    }
}