import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WarningDialogComponent } from './../components/sp-dialog/warning-dialog/warning-dialog.component';
import { ApiService, Data, Pagination, ProgressSpinnerService } from 'sp-core';
import { PaginationDto } from '../components/sp-table/table/table.interface';
import { finalize } from 'rxjs/operators';

export class Response {
  item: any;
  type: type;
}

type type = 'add' | 'edit' | 'delete' | 'clone';

@Injectable()
export class CrudService {

  item = new EventEmitter<any>(); //evento emitido para el formulario para pasar el item seleccionado
  submit = new EventEmitter<boolean>(); //evento emitido del modal para el formulario para enviar el formulario
  action = new EventEmitter<Response>(); //evento emitido para la tabla cuando se agrega, edita o elimina

  constructor(
    private API: ApiService,
    private MD: MatDialog,
    private MSB: MatSnackBar,
    private spinnerService: ProgressSpinnerService
  ) { }

  /**
   * dispara una alerta de tipo success.
   * @param msg mensaje para completar la alerta
   * @author Martin Batun Tec.
  */
  msg(msg: string,): void {
    this.MSB.open(`${msg} successfully!`, 'ok', {
      duration: 3000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }


  // funcion para enviar evento con el item que se selecciona en la tabla
  select(e: any): void {
    this.item.next(e);
  }


  /**
  * funcion para regresar la respuesta despues de agregar o editar
  * @param item item que sera manipulado.
  * @param action define la accion a realizar con el item realizado.
  * @param msg complementa el mensaje que sera mostrado.
  * @author Martin Batun Tec.
  */
  response(item: any, type: type, msg: string): void {
    let msg2 = type === 'add' ? 'added' : (type === 'edit' ? 'edited' : (type === 'delete' ? 'deleted' : 'clone'))
    this.msg(`${msg} ${msg2}`);
    this.action.next({ item: item, type: type });
  }


  /**
  * funcion para manipular los datos listados en la tabla
  * @param data recive la data completa listada.
  * @param item item que sera manipulado.
  * @param action define la accion a realizar con el item realizado.
  * @param start define si se agregaria al principio del array.
  * @return resultado de data completa listado.
  * @author Martin Batun Tec.
  */
  actions(
    data: any | Data<any>,
    item: Object,
    action: type,
    start?: boolean
  ): any | Data<any> {

    if (action === 'add') {
      start ? data.data.unshift(item) : data.data.push(item);
    } else if (action === 'edit') {
      for (var i = 0; i < data.data.length; i++) {
        if (data.data[i].id === item['id']) { data.data[i] = item }
      }
    } else if (action === 'delete') {
      data.data = data.data.filter(elem => elem.id != item['id']);
    } else if (action === 'clone') {
      data.data.splice(item['index'] + 1, 0, item);
    }

    let pagination: PaginationDto | Pagination;
    if (data.pagination instanceof Pagination) {
      pagination = new Pagination();
      pagination.totalRows = data.data.length;
      pagination.perPage = data.pagination.perPage;
      pagination.currentPage = data.pagination.currentPage;
    } else {
      pagination = {} as PaginationDto;
      pagination.total_rows = (<PaginationDto>data.pagination).total_rows;
      pagination.per_page = (<PaginationDto>data.pagination).per_page;
      pagination.current_page = (<PaginationDto>data.pagination).current_page;
    }
    pagination.links = data.pagination.links;

    data = <Data<any>>{
      data: data.data.map((x: any) => x),
      pagination: pagination
    }

    return data;
  }


  /**
  * funcion para manipular los datos en un cierto array
  * @param data recive un array para manipular.
  * @param item item que sera manipulado.
  * @param action define la accion a realizar con el item realizado.
  * @return resultado de data completa listado.
  * @author Martin Batun Tec.
  */
  actionsInArray(data: any[], item: Object, action: type): any[] {
    if (action === 'add') {
      data.unshift(item);
    } else if (action === 'edit') {
      for (var i = 0; i < data.length; i++) {
        if (data[i].id === item['id']) { data[i] = item }
      }
    } else if (action === 'delete') {
      data = data.filter(elem => elem.id != item['id']);
    }

    data = data.map(function (e) { return e; });

    return data;
  }


  /**
  * elimina un item
  * @param item item a eliminar.
  * @author Martin Batun Tec.
  */
  delete(url: string, item: any): void {
    this.MD.open(WarningDialogComponent, {
      width: '352px',
      data: { text: `Are you sure you want to delete?` }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.spinnerService.start();
        this.API
          .delete(`${url}/${item.id}/`)
          .pipe(
            finalize(() => this.spinnerService.stop())
          ).subscribe(() => {
            this.action.next({ item: item, type: 'delete' })
            this.msg('Deleted')
          })
      }
    });
  }


  /**
  * Clona
  * @param item item a eliminar.
  * @author Martin Batun Tec.
  */
  clone(): void {

  }

}
