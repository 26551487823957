import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { A11yModule } from '@angular/cdk/a11y';
import { I18NextModule } from 'angular-i18next';

import { DialogTitleComponent } from './dialog-title/dialog-title.component';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { DialogActionsComponent } from './dialog-actions/dialog-actions.component';
import { DialogComponent } from './dialog.component';

import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { RequestNameDialogComponent } from './request-name-dialog/request-name-dialog.component';

import { SpScrollModule } from '../../directives';

const COMPONENTS = [
  DialogComponent,
  DialogTitleComponent,
  DialogContentComponent,
  DialogActionsComponent,
  WarningDialogComponent,
  RequestNameDialogComponent
];

const EXPORTABLE_MODULES = [
  MatDialogModule
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    DragDropModule,
    I18NextModule,
    A11yModule,
    SpScrollModule,
    ...EXPORTABLE_MODULES
  ],
  exports: [
    ...COMPONENTS,
    ...EXPORTABLE_MODULES
  ]
})
export class SpDialogModule { }
