import { trigger, transition, style, animate, state, query, stagger, AnimationTriggerMetadata } from '@angular/animations';

/**
* Efecto para collapsed de row detail.
* @author Martin Batun Tec.
*/
export const DetailExpand = trigger('detailExpand', [
  state('collapsed', style({ height: '0px', minHeight: '0' })),
  state('expanded', style({ height: '*' })),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
])


/**
* Efecto para mensaje de no contenido.
* @author Martin Batun Tec.
*/
export const NoContent = trigger('noContent', [
  transition(':enter', [
    style({ transform: 'translateY(100%)', opacity: 0 }),
    animate('750ms', style({ transform: 'translateY(0)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0)', opacity: 1 }),
    animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 }))
  ])
])


export const List = trigger('listStagger', [
  transition('* <=> *', [
    query(':enter', [
      style({ transform: 'translateX(-45px)', opacity: 0 }),
      stagger('50ms', animate('400ms ease-out', style({ transform: 'translateX(0px)', opacity: 1 })))
    ], { optional: true }),
  ])
])

/**
 * Animación parpadeo
 * ```
 * Para utilizarlo correctamente es necesario lógica adicional en el componente que lo utilizará. 
 * Se requieren las variables:
 *  times: Número de veces que parpadeará
 *  state: Estado actual del elemento animado: shine o darken
 *  counter: Conteo de veces que se ha ejecutado la animación o cambio de estado de shine <-> darken
 * ```
 * @author José Chin
 */
export const blinkAnimation = trigger('blink', [
  state('shine', style({
    boxShadow: 'inset 0 0 0 2px #3C6CF7'
  })),
  state('darken', style({
    boxShadow: 'none'
  })),
  transition('* <=> *', [
    animate(500)
  ])
]);

/**
 * Animación para que la carga de componente se visualice de una manera más fluida. Cambio de opacidad de 0 a 1 y viceversa
 * @author José Chin
 */
export const loadAnimation = trigger('load', [
  state('pending', style({
    opacity: 0
  })),
  state('loading', style({
    opacity: 0
  })),
  state('loaded', style({
    opacity: 1
  })),
  transition('loading => loaded', [
    animate('0.5s')
  ]),
  transition('loaded => loading', [
    animate('0.5s')
  ]),
  transition('loaded => pending', [
    animate(`0.5s`)
  ])
]);

export function parametrizedLoadAnimation(animateTime = 0.5): AnimationTriggerMetadata {
  return trigger('parametrizedLoad', [
    state('pending', style({
      opacity: 0
    })),
    state('loading', style({
      opacity: 0
    })),
    state('loaded', style({
      opacity: 1
    })),
    transition('loading => loaded', [
      animate(`${animateTime}s`)
    ]),
    transition('loaded => loading', [
      animate(`${animateTime}s`)
    ]),
    transition('loaded => pending', [
      animate(`${animateTime}s`)
    ])
  ]);
}