const FORM_GROUPS = {
    institution: 'institution',
    institutionTeam: 'institutionTeam',
    managerNames: 'managerNames',
    managerContact: 'managerContact',
    managerProfessional: 'managerProfessional',
    managerUser: 'managerUser'
}

const CONTROL_NAMES = {
    institutionName: 'institutionName',
    institutionDbName: 'institutionDbName',
    institutionType: 'institutionType',

    teamName: 'teamName',

    managerName: 'managerName',
    managerLastname: 'managerLastname',
    managerEmail: 'managerEmail',
    managerProfessionalType: 'managerProfessionalType',
    managerPhone: 'managerPhone',
    managerGender: 'managerGender',
    managerBirthdate: 'managerBirthdate',

    password: 'password',
    passwordConfirm: 'passwordConfirm'
}

export { FORM_GROUPS as GROUPS, CONTROL_NAMES };