import { Subject } from 'rxjs';

import { ExerciseBlockCategoryRequest, ExerciseBlockCategoryResponse, Serializer } from './interfaces';
import { Institution } from './institution';

export class ExerciseBlockCategory implements Serializer<ExerciseBlockCategory>{

    /**
     * Objeto para verificar valores previos a una modificación y permitir enviar sólo los campos modificados
     */
    private initialValues: ExerciseBlockCategory;

    private _isSelected = false;

    get hasChanges(): boolean {
        return this.name !== this.initialValues.name;
    }

    /**
     * Obtiene si la categoría está seleccionado. No se obtiene de backend, es un campo auxiliar para frontend
     */
    get isSelected(): boolean {
        return this._isSelected;
    }

    constructor(
        public id?: number,
        public name?: string,
        public hasLibrary?: boolean,
        public institution?: Institution,
        /**
         * Campo auxiliar para front. Se utiliza para solicitar modo edición para el nombre de la categoría
         */
        public changeEditMode?: Subject<boolean>
    ) {
        // Se inicializan los valores iniciales sólo indicado la estructura para evitar instanciar y generar max stack
        this.initialValues = {} as ExerciseBlockCategory;
        this.setInitialValues(this);
    }

    fromResponse(response: ExerciseBlockCategoryResponse): ExerciseBlockCategory {

        const category = new ExerciseBlockCategory(
            response.id,
            response.name,
            response.has_library
        );

        if (response.institution) {
            const institution = new Institution();
            institution.id = response.institution;
            category.institution = institution;
        }

        return category;
    }

    toRequest(): ExerciseBlockCategoryRequest {
        return <ExerciseBlockCategoryRequest>{
            name: this.name || undefined,
            has_library: this.hasLibrary != null ? this.hasLibrary : undefined
        }
    }

    select(): void {
        this._isSelected = true;
    }

    unselect(): void {
        this._isSelected = false;
    }

    /**
     * Selecciona o des-selecciona la categoría en base al su estatus actual. Si el elemento NO está seleccionado lo seleciona y viceversa
     */
    selectUnselect(): void {
        if (this.isSelected) {
            this.unselect();
        } else {
            this.select();
        }
    }

    /**
     * Aplica los valores actuales del objeto para una siguiente validación de cambios
     */
    applyChanges(): void {
        this.setInitialValues(this);
    }

    /**
     * Asigna valores iniciales a los campos de validación de cambios
     * @param category Categoría con los datos actuales
     */
    private setInitialValues(category: ExerciseBlockCategory): void {
        this.initialValues.name = category.name;
    }
}