<div class="sp-clear-filters-button">
    <button class="sp-clear-filters"
        mat-button
        [hidden]="hidden"
        [matTooltip]="tooltip||'Clear all filters'"
        [class.mat-icon-button]="!text"
        (click)="onClearClick()">
        <ng-container *ngIf="text;else iconTemplate">
            <span class="sp-clear-filters__text">{{text}}</span>
        </ng-container>
        <ng-template #iconTemplate>
            <svg class="sp-clear-filters__icon"
                xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="black"
                width="24px" height="24px">
                <g>
                    <path d="M0,0h24 M24,24H0" fill="none" />
                    <path
                        d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
                    <path d="M0,0h24v24H0V0z" fill="none" />
                </g>
            </svg>
            <svg class="sp-clear-filters__icon sp-clear-filters__icon--remove"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
        </ng-template>
    </button>
</div>