export const REQUEST_PARAM_NAMES = {
    sort: 'ordering',
    orderAsc: '',
    orderDesc: '-',
    search: 'search',
    page: 'page',
    pageSize: 'perpage',
    tab: 'tab',
    institutionId: 'institution',
    createdBy: 'created_by'
}