import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sp-clear-filters-button',
  templateUrl: './clear-filters-button.component.html',
  styleUrls: ['./clear-filters-button.component.scss']
})
export class ClearFiltersButtonComponent implements OnInit {

  @Input() hidden = false;

  @Input() tooltip: string;

  /**
   * Indica el texto que se visualizará en lugar del ícono. En caso de que se envíe el ícono ya no es visible
   */
  @Input() text: string;

  @Output() clear = new EventEmitter<null>();

  constructor() { }

  ngOnInit(): void {
  }

  onClearClick(): void {
    this.clear.emit();
  }
}
