import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, map, mergeMap, catchError } from 'rxjs/operators';

import moment from 'moment';

import {
  InstitutionManagerData, SubscriptionType, InstitutionService, TeamService, InstitutionManagerService,
  Team, Institution, InstitutionManager, UtilitiesService, AuthValidators,
  CatalogService, ProgressSpinnerService, ProfessionalType, SocialLoginRegister, SocialLoginService, SocialType, UserRegister, AuthService, TeamCreateResponse
} from 'sp-core';

import { GROUPS, CONTROL_NAMES } from './institution-manager-detail.constants';

@Component({
  selector: 'sp-institution-manager-detail',
  templateUrl: './institution-manager-detail.component.html',
  styleUrls: ['./institution-manager-detail.component.scss']
})
export class InstitutionManagerDetailComponent implements OnInit {

  @ViewChild('messageContainer') messageContainerRef: ElementRef;

  withInstitutionData = false;
  institutionId = 0;
  title = '';

  registerWithSocialMedia = false;
  manualRegister = false;

  userDataHidden = false;

  formGroups = GROUPS;
  controlNames = CONTROL_NAMES;

  form: UntypedFormGroup;

  subscriptionType = SubscriptionType;

  subscriptionTypes: Array<SubscriptionType> = [
    SubscriptionType.trial,
    SubscriptionType.paying,
    SubscriptionType.demo
  ]

  professionalTypes: Array<ProfessionalType> = [];

  message: string;
  errorMessage: string;

  get institutionForm(): UntypedFormGroup {
    return this.form.get(this.formGroups.institution) as UntypedFormGroup;
  }

  get teamForm(): UntypedFormGroup {
    return this.institutionForm.get(this.formGroups.institutionTeam) as UntypedFormGroup;
  }

  get managerNamesForm(): UntypedFormGroup {
    return this.form.get(this.formGroups.managerNames) as UntypedFormGroup;
  }

  get managerContactForm(): UntypedFormGroup {
    return this.form.get(this.formGroups.managerContact) as UntypedFormGroup;
  }

  get managerProfessionalForm(): UntypedFormGroup {
    return this.form.get(this.formGroups.managerProfessional) as UntypedFormGroup;
  }

  get managerUserForm(): UntypedFormGroup {
    return this.form.get(this.formGroups.managerUser) as UntypedFormGroup;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<InstitutionManagerDetailComponent>,
    private spinnerService: ProgressSpinnerService,
    private authService: AuthService,
    private institutionService: InstitutionService,
    private teamService: TeamService,
    private managerService: InstitutionManagerService,
    private socialLoginService: SocialLoginService,
    private catalogService: CatalogService,
    @Inject(MAT_DIALOG_DATA) private data: InstitutionManagerData
  ) {

    // Photo por defecto. TODO: Verificar con backend si existe alguna URL de imagen por defecto
    const defaultPhotoURL = 'https://graph.facebook.com/3056927404539766/picture';

    if (!this.data) {
      this.registerWithSocialMedia = false;
      this.data = <InstitutionManagerData>{
        institutionId: null,
        socialLogin: {
          email: '',
          type: null,
          token: '',
          photoURL: defaultPhotoURL
        }
      }
    } else {
      this.registerWithSocialMedia = !!this.data.socialLogin;
      this.data.socialLogin.email = this.data.socialLogin.email ? this.data.socialLogin.email : null;
      this.data.socialLogin.type = this.data.socialLogin.type ? this.data.socialLogin.type : SocialType.default;
      this.data.socialLogin.token = this.data.socialLogin.token ? this.data.socialLogin.token : '';
      this.data.socialLogin.photoURL = this.data.socialLogin.photoURL ? this.data.socialLogin.photoURL : defaultPhotoURL;
    }

    this.institutionId = this.data.institutionId;
    this.withInstitutionData = !this.institutionId; // Si se envía ID de institución no debe pedir datos de institución.
    this.title = this.withInstitutionData ? 'New User' : 'New Institution Manager';
    // Regsitro manual
    this.manualRegister = this.data.socialLogin.type && this.data.socialLogin.type === SocialType.default;
    // Oculta los campos de usuario cuando sea registro con social media. Siempre y cuando no sea del tipo manual
    this.userDataHidden = this.registerWithSocialMedia && !this.manualRegister;

    this.createForm();
  }

  ngOnInit(): void {

    // Tipo de profesional
    this.spinnerService.start();
    this.catalogService
      .getProfessionalTypes()
      .pipe(
        finalize(() => this.spinnerService.stop())
      )
      .subscribe(data => {
        this.professionalTypes = data;
      });
  }

  save() {

    this.message = null;
    this.errorMessage = null;

    // Registro manual
    if (this.manualRegister) {
      this.register();
    }
    // Realiza el registro para un usuario que inició sesión con social media
    else if (this.registerWithSocialMedia) {
      this.registerForSocialMedia();
    }
    else {
      // Si se indicó que se requiere datos de institución entonces se crea un institution, team, institution manager.
      if (this.withInstitutionData) {
        this.createWithInstitution();
      } else {
        // En caso contrario sólo se crea un institution manager.
        this.createManager();
      }
    }
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  managerFormSelectionChange(e: any): void {
    console.log(e);
  }

  /**
   * Registra un administrador de institución con datos de institución y equipo por defecto para un registro manual
   */
  private register(): void {

    // Verifica que la información del formulario esté correcto antes de proceder
    if (this.form.invalid) {
      return;
    }

    // Realiza el mapeo de los campos de formulario a los modelos
    const userRegister = this.mapToManualRegister();

    // Envía a crear la institución
    this.spinnerService.start();
    this.authService
      .register(userRegister)
      .pipe(
        catchError(error => {
          this.errorMessage = error;
          this.scrollToMessage();
          throw error;
        }),
        finalize(() => this.spinnerService.stop())
      )
      .subscribe(() => {
        this.form.disable();
        this.message = 'An email has been sent for activation instructions';
        this.scrollToMessage();
      });
  }

  private scrollToMessage(): void {
    setTimeout(() => {
      (<HTMLElement>this.messageContainerRef.nativeElement).scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  /**
   * Registra un administrador de institución con datos de institución y equipo por defecto para un inicio de sesión con social media
   */
  private registerForSocialMedia(): void {

    // Verifica que la información del formulario esté correcto antes de proceder
    if (this.form.invalid) {
      return;
    }

    // Realiza el mapeo de los campos de formulario a los modelos
    const socialLoginRegister = this.mapToSocialLogin();

    // Envía a crear la institución
    this.spinnerService.start();
    this.socialLoginService
      .register(socialLoginRegister)
      .pipe(
        catchError(error => {
          this.errorMessage = error;
          throw error;
        }),
        finalize(() => this.spinnerService.stop())
      )
      .subscribe(response => {
        this.dialogRef.close(response);
      });
  }

  /**
   * Crea la institución, así como un equipo y un administrador predeterminados.
   */
  private createWithInstitution(): void {

    // Verifica que la información del formulario esté correcto antes de proceder
    if (this.form.invalid) {
      return;
    }

    // Realiza el mapeo de los campos de formulario a los modelos
    const institution = new Institution().getWithDefaults();
    const team = this.mapFormToTeam();
    const manager = this.mapFormToManager();

    // Envía a crear la institución
    this.institutionService
      .createResponse(institution)
      .pipe(mergeMap(institutionResponse => {
        // Envía a crear el equipo
        return this.teamService
          .create(team, institutionResponse.id)
          .pipe(mergeMap(teamCreated => {
            // Envía a crear al administrador de la institución
            return this.managerService
              .create(manager, teamCreated.id, institutionResponse.id)
              .pipe(map(managerResponse => {
                institutionResponse.team = <TeamCreateResponse>{ id: teamCreated.id, name: teamCreated.name, institution: teamCreated.institution?.id };
                institutionResponse.manager = managerResponse;
                return institutionResponse;
              }));
          }));
      }))
      .subscribe(institutionResponse => {
        this.dialogRef.close(institutionResponse);
      }, (error) => {
        this.errorMessage = error.error.errors;
      }, () => {
        // TODO: Detener spinner
      });
  }

  /**
   * Crea un administrador de institución
   */
  private createManager(): void {

    // Verifica que la información del formulario esté correcto antes de proceder
    if (this.form.invalid) {
      return;
    }

    // Realiza el mapeo de los campos de formulario a los modelos
    const manager = this.mapFormToManager();

    // Envía a crear un administrador de la institución seleccionada.
    this.managerService
      .create(manager, null, this.institutionId)
      .subscribe(institutionResponse => {
        console.log(institutionResponse);
        this.dialogRef.close(institutionResponse);
      }, (error) => {
        this.errorMessage = error.error.errors;
      }, () => {
        // TODO: Detener spinner.
        //console.log('Completo');
      });
  }

  private createForm() {

    this.form = this.fb.group({});

    // Sólo agrega el grupo de institución si se indicó que se requiere.
    // Aplica para cuando la pantalla se llama desde la creación de una nueva institución/usuario.
    if (this.withInstitutionData) {
      this.form.addControl(this.formGroups.institution, this.createInstitutionForm());
    }

    this.form.addControl(this.formGroups.managerNames, this.createManagerNamesForm());
    this.form.addControl(this.formGroups.managerContact, this.createManagerContactForm());
    this.form.addControl(this.formGroups.managerProfessional, this.createManagerProfessionalForm());
    if (!this.userDataHidden) {
      this.form.addControl(this.formGroups.managerUser, this.createManagerUserForm());
    }
  }

  private createInstitutionForm(): UntypedFormGroup {

    const institutionGroup = this.fb.group({});

    const institutionNameCtrl = this.fb.control(null, [Validators.required]);
    institutionNameCtrl.valueChanges.subscribe(value => {
      this.teamForm.get(this.controlNames.teamName).setValue(value);
    });
    institutionGroup.addControl(this.controlNames.institutionName, institutionNameCtrl);

    const institutionDbNameCtrl = this.fb.control(null);
    institutionGroup.addControl(this.controlNames.institutionDbName, institutionDbNameCtrl);

    const institutionTypeCtrl = this.fb.control(null, [Validators.required]);
    institutionTypeCtrl.setValue(SubscriptionType.trial);
    institutionGroup.addControl(this.controlNames.institutionType, institutionTypeCtrl);

    // Team controls
    const teamGroup = this.fb.group({});
    const teamNameCtrl = this.fb.control({ value: null, disabled: true });
    teamGroup.addControl(this.controlNames.teamName, teamNameCtrl);
    institutionGroup.addControl(this.formGroups.institutionTeam, teamGroup);

    return institutionGroup;
  }

  private createManagerNamesForm(): UntypedFormGroup {

    const namesGroup = this.fb.group({});

    const managerNameCtrl = this.fb.control(null, [Validators.required]);
    namesGroup.addControl(this.controlNames.managerName, managerNameCtrl);

    const managerLastnameCtrl = this.fb.control(null, [Validators.required]);
    namesGroup.addControl(this.controlNames.managerLastname, managerLastnameCtrl);

    return namesGroup;
  }

  private createManagerContactForm(): UntypedFormGroup {

    const contactGroup = this.fb.group({});

    const managerPhoneCtrl = this.fb.control(null, [Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]);
    contactGroup.addControl(this.controlNames.managerPhone, managerPhoneCtrl);

    return contactGroup;
  }

  private createManagerProfessionalForm(): UntypedFormGroup {

    const professionalGroup = this.fb.group({});

    const managerProfessionalTypeCtrl = this.fb.control(null);
    professionalGroup.addControl(this.controlNames.managerProfessionalType, managerProfessionalTypeCtrl);

    return professionalGroup;
  }

  private createManagerUserForm(): UntypedFormGroup {

    const userGroup = this.fb.group({});

    const managerEmailCtrl = this.fb.control(null, [Validators.required, Validators.email]);
    userGroup.addControl(this.controlNames.managerEmail, managerEmailCtrl);
    managerEmailCtrl.setValue(this.data.socialLogin.email);

    const passwordCtrl = this.fb.control(null, [Validators.required, Validators.minLength(8)]);
    userGroup.addControl(this.controlNames.password, passwordCtrl);

    const passwordConfirmCtrl = this.fb.control(null, [Validators.required]);
    userGroup.addControl(this.controlNames.passwordConfirm, passwordConfirmCtrl);

    userGroup.setValidators(AuthValidators.confirmPassword);

    return userGroup;
  }

  private mapToManualRegister(): UserRegister {

    const userRegister = new UserRegister();

    userRegister.institution = new Institution().getWithDefaults();
    userRegister.team = this.mapFormToTeam();
    userRegister.institutionManager = this.mapFormToManager();

    return userRegister;

  }

  private mapToSocialLogin(): SocialLoginRegister {

    const socialLoginRegister = new SocialLoginRegister();

    socialLoginRegister.institution = new Institution().getWithDefaults();
    socialLoginRegister.team = this.mapFormToTeam();
    socialLoginRegister.institutionManager = this.mapFormToManager();
    socialLoginRegister.socialType = this.data.socialLogin.type;
    // Para registro manual no se tiene token por lo que se obtiene del correo capturado
    socialLoginRegister.socialLoginToken = this.data.socialLogin.token ? this.data.socialLogin.token : UtilitiesService.getHash(socialLoginRegister.institutionManager.email).toString();

    return socialLoginRegister;

  }

  private mapFormToTeam(): Team {
    const team = new Team();
    team.name = this.teamForm.get(this.controlNames.teamName).value;
    return team;
  }

  private mapFormToManager(): InstitutionManager {

    const manager = new InstitutionManager();

    manager.fullName = `${this.managerNamesForm.get(this.controlNames.managerName).value} ${this.managerNamesForm.get(this.controlNames.managerLastname).value}`;

    // Datos de contacto
    if (!this.userDataHidden) {
      manager.email = this.managerUserForm.get(this.controlNames.managerEmail).value;
      manager.password = this.managerUserForm.get(this.controlNames.password).value;
      manager.passwordConfirm = this.managerUserForm.get(this.controlNames.passwordConfirm).value;
    } else {
      manager.email = this.data.socialLogin.email;
      manager.password = '';
      manager.passwordConfirm = '';
    }
    manager.phone = this.managerContactForm.get(this.controlNames.managerPhone).value;
    manager.photo = this.data.socialLogin.photoURL;

    // Datos de profesión
    manager.professionalType = this.managerProfessionalForm.get(this.controlNames.managerProfessionalType).value;

    return manager;
  }
}
