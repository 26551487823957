import { Serializer, WorkoutLocationResponse } from "./interfaces";

/**
 * Representa una estructura para ubicación de entrenamiento
 */
export class WorkoutLocation implements Serializer<WorkoutLocation> {

    constructor(
        public id?: number,
        public name?: string,
        public hasLibrary?: boolean,
        public institutionId?: number
    ) { }

    fromResponse(response: WorkoutLocationResponse): WorkoutLocation {
        return new WorkoutLocation(
            response.id,
            response.name,
            response.has_library,
            response.institution
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}