import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';

import { ProgressSpinnerService } from 'sp-core';

@Component({
  selector: 'sp-scrollable',
  templateUrl: './sp-scrollable.component.html',
  styleUrls: ['./sp-scrollable.component.css']
})
export class SpScrollableComponent implements OnInit, OnDestroy {

  subSink = new SubSink();

  spinnerIsRunning = false;

  page = 1;

  pageSize = 15;

  search = '';

  constructor(
    private _spinnerService: ProgressSpinnerService
  ) { }

  ngOnInit(): void {
    this.subSink.sink = this._spinnerService.stateChange.subscribe(state => this.spinnerIsRunning = state);
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
