/**
 * Estructura para objeto tiempo: Horas y minutos
 * @author José Chin
 */
export class SPFTime {
    constructor(
        public hours?: number,
        public minutes?: number
    ) { }

    toString(): string {
        return `${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}`;
    }

    fromString(time: string): SPFTime {
        return new SPFTime(
            +this.timeStringExtractHour(time),
            +this.timeStringExtractMinutes(time)
        )
    }

    timeStringExtractHour(time: string): string {
        return time.substr(0, 2);
    }

    timeStringExtractMinutes(time: string): string {
        return time.substr(3, 2);
    }
}