import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { AuthService, User, UserService } from 'sp-core';
import { HeaderGroupAlignment } from 'sp-library';
import {PendingOperationsService, ReviewNotifications} from '@admin/core/services/pending-operations.service';

@Component({
  selector: 'admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: User;

  headerGroupAlignment = HeaderGroupAlignment;

  logo = 'assets/images/solo-performance.svg';

  notifications: ReviewNotifications;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private pendingOperationsService: PendingOperationsService
  ) {
    this.matIconRegistry.addSvgIcon('logo', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/solo-performance.svg'));
    this.matIconRegistry.addSvgIcon('settings', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings.svg'));
    this.matIconRegistry.addSvgIcon('notifications', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bell.svg'));
    this.matIconRegistry.addSvgIcon('expand', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/expand.svg'));
    this.matIconRegistry.addSvgIcon('account', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/account-circle-outline.svg'));
    this.matIconRegistry.addSvgIcon('logout', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/keyboard-backspace.svg'));
  }

  ngOnInit(): void {
    this.userService
      .get()
      .subscribe(data => {
        this.user = data;
      });

    this.pendingOperationsService.getPendingOperations();
    this.pendingOperationsService.$pendingOperations.subscribe((res) => {
        this.notifications = res;
    });
  }

  logout() {
    this.authService.logout().subscribe(result => {
      this.router.navigate(['/auth'], { replaceUrl: true });
    })
  }
}
