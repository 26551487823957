import {Serializer} from './interfaces';
import {DispersionResponse} from './interfaces';
import {FontDispersionString} from './enumerations';
export class Dispersion implements Serializer<Dispersion> {
  get fontString(): string {
    return FontDispersionString[this.font];
  }
  constructor(
    public id?: number,
    public font?: number,
    public product?: string,
    public spfFee?: number,
    public coachFee?: number,
    public androidFee?: number,
    public iosFee?: number,
  ) {
  }

  fromResponse(response: DispersionResponse): Dispersion {
    return new Dispersion(
      response.id,
      response.font,
      response.product,
      response.spf_fee,
      response.coach_fee,
      response.android_fee,
      response.ios_fee
    );
  }

  toRequest(): DispersionResponse {
    return {
      font: this.font,
      product: this.product,
      spf_fee: this.spfFee,
      coach_fee: this.coachFee,
      android_fee: this.androidFee,
      ios_fee: this.iosFee,
    };
  }
}
