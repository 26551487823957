import { InstitutionalImageRequest, InstitutionalImageResponse, Serializer } from "./interfaces";

/**
 * Imagen institutional. Imagen asignada a un usuario en base a la institución actualmente visualizada (imagen de usuario por institución)
 * @author  José Chin
 */
export class InstitutionalImage implements Serializer<InstitutionalImage> {

    constructor(
        /**
         * Identificador de registro
         */
        public id?: number,
        /**
         * Identificador de usuario
         */
        public userId?: number,
        /**
         * Identificador de institución
         */
        public institutionId?: number,
        /**
         * URL de imagen
         */
        public imageURL?: string,
        /**
         * Archivo de imagen
         * ```
         * Campo auxiliar para frontend
         * - No se obtiene de backend
         * - Se utiliza para enviar a crear o modificar los datos en formato formData
         * ```
         */
        public imageFile?: File
    ) { }

    static fromResponse(response: InstitutionalImageResponse): InstitutionalImage {
        return new InstitutionalImage(
            response.id,
            response.user,
            response.institution,
            response.photo
        );
    }

    fromResponse(response: InstitutionalImageResponse): InstitutionalImage {
        return InstitutionalImage.fromResponse(response);
    }

    toRequest(): InstitutionalImageRequest {

        const institutionalImage = <InstitutionalImageRequest>{
            user: this.userId || undefined,
            institution: this.institutionId || undefined,
            photo: this.imageURL || undefined
        };

        return institutionalImage;
    }

    toFormDataRequest(): FormData {

        const request = this.toRequest();

        const formData = new FormData();

        if (request.user) {
            formData.append('user', request.user.toString());
        }

        if (request.institution) {
            formData.append('institution', request.institution.toString());
        }

        // La imagen la obtiene del modelo y no de la interfaz, ya que éste campo únicamente se tiene en la clase (modelo)
        if (this.imageFile) {
            formData.append('photo', this.imageFile, this.imageFile.name);
        }

        return formData;
    }
}