import { UserType } from "./enumerations";
import { Institution } from "./institution";

export class UserInstitution extends Institution {
    constructor(
        /**
         * Tipo de perfil o rol que posee el usuario en la institución
         */
        public userType?: UserType,
        /**
         * Indica si la institución es por una invitación
         */
        public isInvitation?: boolean
    ) {
        super();
    }
}