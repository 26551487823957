import moment from 'moment';

import { Serializer, WidgetDataResponse } from './interfaces';
import { WorkoutType } from './workout-type';

export class WidgetData implements Serializer<WidgetData> {

    constructor(
        public id?: number,
        public name?: string,
        public starts?: Date,
        public ends?: Date,
        /**
         * Datos de programa o fase
         * ```
         * Cuando los datos (WidgetData) corresponden a fases son datos del programa
         * Cuando los datos (WidgetData) corresponden a workouts son datos de la fase
         * ```
         */
        public related?: { id: number, name: string },
        /**
         * Datos de programa cuando los datos (WidgetData) corresponden a workouts
         */
        public related2?: { id: number, name: string },
        /**
         * Identificador de día al que corresponde el workout
         * ```
         * Cuando los datos (WidgetData) corresponden a workouts
         * ```
         */
        public related3?: number,
        /**
         * Tipo de workout. Cuando la información corresponde a un workout
         */
        public workoutType?: WorkoutType,
        public teams: Array<number> = [],
        public athletes: Array<number> = []
    ) { }

    fromResponse(response: WidgetDataResponse): WidgetData {
        return new WidgetData(
            response.id,
            response.name,
            moment(response.starts).toDate(),
            moment(response.ends).toDate(),
            response.related,
            response.related2,
            response.related3,
            response.place_catalog ? new WorkoutType().fromResponse(response.place_catalog) : null,
            response.teams,
            response.athletes
        )
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }
}