/**
 * Se utiliza para indicar a backend el modelo que retornará en la respuesta de solicitud de información
 * @author  José Chin
 */
export enum WorkoutView {
    /**
     * Visualización desde workout builder
     */
    workoutBuilder = 1,
    /**
     * Visualización desde librería
     */
    library
}