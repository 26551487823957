import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sp-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  /**
   * Obtiene o establece si la tarjeta se expandirá hasta ocupar el 100% de su altura.
   */
  @Input() expand = false;
  
  @Input() classes: string;
  
  @Input() calendar: boolean;

  /**
   * Obtiene o establece si la tarjeta tendrá el padding por defecto.
   */
  @Input() gaps = true;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void { }

}
