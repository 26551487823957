import { ProgramPhaseDetailResponse, Serializer } from "./interfaces";

import { Phase } from "./phase";
import { Program } from "./program";

export class ProgramPhaseDetail implements Serializer<ProgramPhaseDetail> {
    constructor(
        public program?: Program,
        public phases: Array<Phase> = []
    ) { }
    fromResponse(response: ProgramPhaseDetailResponse): ProgramPhaseDetail {
        return new ProgramPhaseDetail(
            response.program ? new Program().fromResponse(response.program) : null,
            response.phases ? response.phases.map(x => new Phase().fromResponse(x)) : []
        )
    }
    toRequest() {
        throw new Error("Method not implemented.");
    }
}