import { EMOMType } from '../models/enumerations';
import { EMOMTypeValue } from '../models/interfaces';

export const EMOM_TYPES: Array<EMOMTypeValue> = [
    {
        key: EMOMType.emom.toString(),
        value: 'EMOM',
        minutes: 1
    },
    {
        key: EMOMType.e2mom.toString(),
        value: 'E2MOM',
        minutes: 2
    },
    {
        key: EMOMType.e3mom.toString(),
        value: 'E3MOM',
        minutes: 3
    },
    {
        key: EMOMType.e4mom.toString(),
        value: 'E4MOM',
        minutes: 4
    },
    {
        key: EMOMType.e5mom.toString(),
        value: 'E5MOM',
        minutes: 5
    },
    {
        key: EMOMType.e6mom.toString(),
        value: 'E6MOM',
        minutes: 6
    },
    {
        key: EMOMType.e7mom.toString(),
        value: 'E7MOM',
        minutes: 7
    },
    {
        key: EMOMType.e8mom.toString(),
        value: 'E8MOM',
        minutes: 8
    },
    {
        key: EMOMType.e9mom.toString(),
        value: 'E9MOM',
        minutes: 9
    },
    {
        key: EMOMType.e10mom.toString(),
        value: 'E10MOM',
        minutes: 10
    }
];