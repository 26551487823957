/**
 * ==================================================================
 * Referencias:
 * https://angular-academy.com/angular-jwt/
 * ==================================================================
 */

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';

import { SP_CORE_CONFIG } from '../sp-core.constants';
import { SpCoreConfig } from '../sp-core-config.interface';

import { SocialType } from '../models/enumerations';
import { TokenResponse, UserRegisterResponse, UserResponse, ForgotPassword, ForgotPasswordResponse, SocialLoginResponse } from '../models/interfaces';
import { ServiceResponse, SocialLogin, Token, User, UserRegister } from '../models';

import { LocalStorageService } from './local-storage.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly TOKEN_KEY = 'TOKEN';
  private readonly REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
  private readonly USER_ID = 'USER_ID';
  private readonly USERNAME = 'USERNAME';
  private readonly USER_LANGUAGE = 'USER_LANGUAGE';
  private readonly INSTITUTION_ID = 'INSTITUTION_ID';
  private readonly CURRENT_INSTITUTION_ID = 'CURRENT_INSTITUTION_ID';
  private readonly TOUR_DONE = 'TOUR_CLOSED';

  /**
   * Obtiene o establece el identificador de usuario autenticado.
   */
  get userAuthenticatedId(): number {
    return +this.lsService.get(this.USER_ID);
  }
  set userAuthenticatedId(id: number) {
    if (id === null) {
      this.lsService.remove(this.USER_ID);
    } else {
      this.lsService.set(this.USER_ID, id.toString());
    }
  }

  /**
   * Obtiene o establece el nombre de usuario autenticado.
   */
  get userAuthenticated(): string {
    return this.lsService.get(this.USERNAME);
  }
  set userAuthenticated(username: string) {
    if (username === null) {
      this.lsService.remove(this.USERNAME);
    } else {
      this.lsService.set(this.USERNAME, username);
    }
  }

  /**
   * Obtiene o establece el lenguaje del usuario autenticado.
   */
  get userAuthenticatedLanguage(): string {
    return this.lsService.get(this.USER_LANGUAGE);
  }
  set userAuthenticatedLanguage(language: string) {
    if (language === null) {
      this.lsService.remove(this.USER_LANGUAGE);
    } else {
      this.lsService.set(this.USER_LANGUAGE, language);
    }
  }

  /**
   * Obtiene o establece la institución del usuario autenticado.
   */
  get institutionId(): number {
    return this.currentInstitutionId
      ? this.currentInstitutionId
      : +this.lsService.get(this.INSTITUTION_ID);
  }
  set institutionId(institutionId: number) {
    if (institutionId === null) {
      this.lsService.remove(this.INSTITUTION_ID);
    } else {
      // Almacena identificador de institución, priorizando la institución actual si aplica
      if (this.currentInstitutionId) {
        institutionId = this.currentInstitutionId;
      }
      this.lsService.set(this.INSTITUTION_ID, institutionId.toString());
    }
  }

  /**
   * Obtiene o establece la institución actualmente seleccionada o en visualización. Múltiple institución
   */
  get currentInstitutionId(): number {
    const institutionId = this.lsService.get(this.CURRENT_INSTITUTION_ID);
    return institutionId ? +institutionId : null;
  }
  set currentInstitutionId(institutionId: number) {
    if (institutionId === null) {
      this.lsService.remove(this.CURRENT_INSTITUTION_ID);
    } else {
      this.lsService.set(this.CURRENT_INSTITUTION_ID, institutionId.toString());
    }
  }

  /**
   * Obtiene o establece si la guía inicial ha sido cerrado o se ha indicado como realizado (Tour)
   */
  get tourDone(): number {
    const tourClosed = this.lsService.get(this.TOUR_DONE);
    return tourClosed ? +tourClosed : null;
  }
  set tourDone(closed: number) {
    if (closed === null) {
      this.lsService.remove(this.TOUR_DONE);
    } else {
      this.lsService.set(this.TOUR_DONE, closed.toString());
    }
  }

  constructor(
    @Inject(SP_CORE_CONFIG) private config: SpCoreConfig,
    private http: HttpClient,
    private lsService: LocalStorageService,
    private api: ApiService
  ) { }


  getRegisteredUser(
    activationToken: string
  ): Observable<User> {
    return this.api
      .get<UserResponse>(`resgister-user/${activationToken}`)
      .pipe(
        catchError(this.api.processError('AuthService.getRegisteredUser')),
        map(response => User.fromResponse(response))
      );
  }

  /**
   * Inicia sesión del usuario
   * @param apiBaseUrl Url base del API
   * @param username Nombre de usuario
   * @param password Contraseña del usuario
   */
  login(
    username: string,
    password: string
  ): Observable<Token> {

    const tokenUrl = this.config.tokenUrl || 'token/';

    const params = new HttpParams()
      .set('email', username)
      .set('password', password);

    return this.http
      .post<TokenResponse>(`${this.config.apiBaseUrl}${tokenUrl}`, params)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Cualquier error que ocurra con el login visualiza el mensaje genérico para no dar pistas a algún atacante e intente con otras opciones
          return throwError('The username or password is incorrect');
        }),
        map(response => {
          const token = new Token().fromResponse(response);
          // No permite el acceso a atletas. Elimina los datos de token y refresh token para indicar que el usuario no tiene permitido iniciar sesión
          // TODO: Analizar enviar la aplicación admin/ dashboard para que según el perfil le permita o no ingresar al correspondiente sistema. De momento se verifica desde el componente sp-login
          if (!(token.permission.hasDashboardAccess || token.permission.hasAdminAccess)) {
            token.token = null;
            token.refreshToken = null;
            return token;
          }
          // Almacena el nombre de usuario autenticado.
          this.userAuthenticated = username;
          // Almacena la institución del usuario autenticado.
          this.currentInstitutionId = null;
          this.institutionId = token.institutionId;
          this.tourDone = 0;
          // Almacena los tokens (token, refresh token).
          this.storeTokens(token.token, token.refreshToken);
          return token;
        })
      );
  }

  /**
   * Inicia sesión mediante datos de token y refresh token
   * @param userName Nombre de usuario/correo
   * @param institutionId Identificador de institución
   * @param token Token de inicio de sesión
   * @param refreshToken Token para actualizar o iniciar de sesión en caso de que se finalice por algún motivo que no sea acción del usuario
   */
  loginWithToken(
    userName: string,
    institutionId: number,
    token: string,
    refreshToken: string
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userAuthenticated = userName;
      this.institutionId = institutionId;
      this.storeTokens(token, refreshToken);
      resolve(true);
    });
  }

  /**
   * Obtiene la clave de refresh token en caso de caducidad de la clave token
   */
  refreshToken(): Observable<string> {

    const refreshTokenUrl = this.config.refreshTokenUrl || 'token-refresh/';

    const params = new HttpParams()
      .set('refresh', this.getRefreshToken());

    return this.http
      .post<any>(`${this.config.apiBaseUrl}${refreshTokenUrl}`, params)
      .pipe(
        tap(result => {
          this.setToken(result.access);
        }),
        map(result => result.access),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }));
  }

  /**
   * Envía correo para recuperación o restablecimiento de contraseña
   */
  forgotPassword(
    email: string
  ): Observable<ForgotPassword> {

    const forgotPasswordUrl = this.config.forgotPasswordUrl || 'reset-password/';

    const params = new HttpParams()
      .set('email', email);

    return this.http.post<ForgotPasswordResponse>(
      forgotPasswordUrl,
      params
    ).pipe(
      catchError(this.api.processError('AuthService.forgotPassword')),
      map(response => {
        return <ForgotPassword>{
          email: response.email
        }
      })
    );
  }

  /**
   * Verifica o busca si el email corresponde a un usuario con inicio de sesión social login
   * @param email Email a verificar o buscar
   * @returns 
   */
  findSocialLogin(
    email: string
  ): Observable<SocialType> {

    const data = {
      email: email
    };

    return this.api
      .post<any>(`find-sl/`, data)
      .pipe(
        catchError(this.api.processError('AuthService.findSocialLogin')),
        map(response => response.social_type
          ? (response.social_type as SocialType)
          : SocialType.notSet)
      );
  }

  register(
    user: UserRegister
  ): Observable<UserRegister> {
    return this.api
      .post<UserRegisterResponse>(`register/`, user.toRequest())
      .pipe(
        map(response => {
          const register = new UserRegister().fromResponse(response);
          return register;
        }),
        catchError(this.api.handleError('AuthService.register', new UserRegister()))
      );
  }

  /**
   * Activa el usuario registrado mediante token de activación
   * @param userId User identification
   * @param password Password to set
   * @param confirmPassword Password confirmation to set
   * @returns 
   */
  activateByToken(
    activationToken: string,
    user: User
  ): Observable<SocialLogin> {

    return this.api
      .post<SocialLoginResponse>(`resgister-user/${activationToken}`, user.toSetPasswordRequest())
      .pipe(
        catchError(this.api.processError('AuthService.setPassword')),
        map((response) => {
          return new SocialLogin().fromResponse(response);
        })
      );
  }

  /**
   * Change password for especified user
   * @param userId User identification
   * @param password Password to set
   * @param confirmPassword Password confirmation to set
   * @returns 
   */
  changePassword(
    userId: number,
    password: string,
    confirmPassword: string,
  ): Observable<ServiceResponse> {

    const body = {
      id: userId,
      password: password,
      confirm_password: confirmPassword
    };

    return this.api
      .post('change-password/', body)
      .pipe(
        catchError(this.api.processError('AuthService.changePassword')),
        map(() => {
          return new ServiceResponse();
        })
      );
  }

  /**
   * Cierra sesión.
   * TODO: Confirmar si existe servicio para cerrar sesión
   */
  logout(): Observable<boolean> {
    // Elimina dato de nombre de usuario en sesión.
    this.userAuthenticated = null;
    // Elimina identificador de usuario autenticado.
    this.userAuthenticatedId = null;
    this.userAuthenticatedLanguage = null;
    // Elimina dato de institución.
    this.institutionId = null;
    this.currentInstitutionId = null;
    this.tourDone = null;
    // Elimina registro de autenticación.
    this.removeTokens();
    return of(true);
  }

  /**
   * Verifica si el usuario está autenticado. 
   * Cuando se tenga almacenado el token entonces el usuario está autenticado.
   */
  isUserAuthenticated(): boolean {
    return !!this.getToken();
  }

  /**
   * Obtiene el token de autenticación
   */
  getToken(): string {
    return this.lsService.get(this.TOKEN_KEY);
  }

  /**
   * Obtiene el refresh token de autenticación almacenado en el login
   */
  getRefreshToken(): string {
    return this.lsService.get(this.REFRESH_TOKEN_KEY);
  }

  /**
   * Almacena las claves de token y refresk token
   * @param token Token de autenticación
   * @param refreshToken Refresh token de autenticación
   */
  storeTokens(token: string, refreshToken: string): void {
    this.setToken(token);
    this.setRefreshToken(refreshToken);
  }

  /**
   * Elimina las claves de token y refresh token
   */
  removeTokens(): void {
    this.lsService.remove(this.TOKEN_KEY);
    this.lsService.remove(this.REFRESH_TOKEN_KEY);
  }

  /**
   * Almacena token de autenticación
   * @param token Token de autenticación
   */
  private setToken(token: string) {
    this.lsService.set(this.TOKEN_KEY, token);
  }

  /**
   * Almacena refresh token de autenticación
   * @param refreshToken Refresh token de autenticación
   */
  private setRefreshToken(refreshToken: string) {
    this.lsService.set(this.REFRESH_TOKEN_KEY, refreshToken);
  }
}