import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ExerciseCategoryGroup, ExerciseSubcategory } from 'sp-core';

@Component({
  selector: 'sp-exercise-filter-category-group',
  templateUrl: './exercise-filter-category-group.component.html',
  styleUrls: ['./exercise-filter-category-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExerciseFilterCategoryGroupComponent implements OnInit {

  @Input() readonly = false;

  @Input() categoryGroup: ExerciseCategoryGroup;

  @Output() subcategoryChange = new EventEmitter<null>();

  @Output() selectUnselectAll = new EventEmitter<boolean>();

  @Output() menuClose = new EventEmitter<null>();

  menuOpened = false;

  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIcon('arrow-down', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-down.svg'));
  }

  ngOnInit(): void {
  }

  onClick(): void {
    this.menuOpened = true;
  }

  onMenuClosed(): void {
    this.menuOpened = false;
    this.menuClose.emit()
  }

  onInputSearchValueChange(value: string): void {
    this.categoryGroup.filterSubcategories(value);
  }

  onSubcategoryClick(subcategory: ExerciseSubcategory): void {
    subcategory.selected = !subcategory.selected;
    // Indica que ocurrió un cambio de selección en alguna subcategoría.
    this.subcategoryChange.emit();
  }

  onSelectAllClick(): void {
    const unselectedSubcategories = this.categoryGroup.getUnselectedSubcategories();
    if (!unselectedSubcategories.length) return;
    unselectedSubcategories.forEach(subcategory => subcategory.select());
    this.selectUnselectAll.emit(true);
  }

  onUnselectAllClick(): void {
    const selectedSubcategories = this.categoryGroup.getSelectedSubcategories();
    if (!selectedSubcategories.length) return;
    selectedSubcategories.forEach(subcategory => subcategory.unselect())
    this.selectUnselectAll.emit(false);
  }
}
