import { Day } from './enumerations';

import { Week } from './week';
import { WeekDay } from './week-day';

export class WeekDays {

    public readonly days = new Week().days.map(day => new WeekDay(null, day))

    /**
     * Obtiene o establece si la semana está recién agregada
     */
    get isNew(): boolean {
        return !this.id
    }

    /**
     * Obtiene o establece si la semana es un clon de alguna otra
     * @returns 
     */
    get isCloned(): boolean {
        return !!this.originWeekId;
    }

    constructor(
        /**
         * Obtiene o establece el identificador de registro de semana de fase al que está relacionado.
         * En caso de que no tenga valor en éste campo significa que el registro fué recién agregado o que no existe en BD.
         */
        public id: number = null,
        /**
         * En caso de que sea una semana/día clonada obtiene o establece el identificador de la semana de la cual origen o que se clonó
         */
        public originWeekId: number = null,
        public isRemoved = false,
        /**
         * Para correcto consecutivo debido a que por índice no se podría conocer ya que pueden existir elementos eliminados
         */
        public order: number = 0
    ) { }

    /**
     * Selecciona el día indicado de la semana
     * @param day Día a seleccionar
     */
    selectDay(day: Day): WeekDay {

        let weekDays = this.days.filter(weekDay => weekDay.day === day);

        let weekDay: WeekDay;
        if (weekDays) {
            weekDay = weekDays[0];
            weekDay.selected = true;
        }

        return weekDay;
    }

    selectedDays(): Array<WeekDay> {
        return this.days.filter(weekDay => weekDay.selected);
    }

    /**
     * Obtiene el primer día seleccionado de la semana
     */
    selectedDay(): WeekDay {
        const selectedDays = this.selectedDays();
        if (!selectedDays.length) {
            return null;
        }
        return selectedDays[0];
    }

    /**
     * Asigna el conteo de elementos del día indicado de la semana
     * @param day Día a seleccionar
     */
    setItemsCount(day: Day, count: number): WeekDay {

        let weekDays = this.days.filter(weekDay => weekDay.day === day);

        let weekDay: WeekDay;
        if (weekDays) {
            weekDay = weekDays[0];
            weekDay.itemsCount = count
        }

        return weekDay;
    }

    setDayId(day: Day, id: number): WeekDay {

        let weekDays = this.days.filter(weekDay => weekDay.day === day);

        let weekDay: WeekDay;
        if (weekDays) {
            weekDay = weekDays[0];
            weekDay.id = id;
        }

        return weekDay;
    }

    /**
     * Verifica si existe algún día seleccionado en la semana
     */
    checkDaySelected() {
        return this.days.filter(weekDay => weekDay.selected === true).length > 0 ? true : false;
    }

    /**
     * Verifica si existe algún día con items agregados
     */
    checkItemsCount() {
        return this.days.filter(weekDay => weekDay.itemsCount > 0).length > 0 ? true : false;
    }

    /**
     * Clona un objeto semana días.
     * Útil cuando no se quiere modificar un arreglo de semanas días sino hasta que así lo especifica alguna acción del usuario.
     * @param weekDays Semana, días a clonar
     */
    clone(weekDays?: WeekDays): WeekDays {
        const weekToClone = weekDays || this;
        const weekCloned = new WeekDays();
        weekCloned.id = weekToClone.id;
        weekCloned.originWeekId = weekToClone.originWeekId;
        weekCloned.isRemoved = weekToClone.isRemoved;
        weekToClone.days.map(weekDay => {
            weekCloned.days
                .filter(weekDayCloned => weekDayCloned.day === weekDay.day)
                .forEach(weekDayCloned => {
                    weekDayCloned.id = weekDay.id;
                    weekDayCloned.itemsCount = weekDay.itemsCount;
                    weekDayCloned.selected = weekDay.selected;
                    weekDayCloned.initialItemsCount = weekDay.initialItemsCount;
                    weekDayCloned.initialSelected = weekDay.initialSelected;
                });
        });
        return weekCloned;
    }

    setInitialValuesOfDays(): void {
        this.days.forEach(x => x.setInitialValues());
    }

    resetStatusOfDays(): void {
        this.days.forEach(x => x.resetStatus());
    }

    /**
     * Marca la semana como una semana clonada
     */
    markAsCloned(): void {
        // Asigna el identificador de la semana clonada. En caso de que incluso la semana que está siendo clonada es un clon entonces se asigna la misma referencia de la semana origen
        this.originWeekId = this.isCloned ? this.originWeekId : this.id;
        this.id = null;
    }

    /**
     * Marca el registro como eliminado para su posterior procesamiento
     */
    markAsRemoved(): void {
        this.isRemoved = true;
        // Reinicia a los estados de seleccionado iniciales
        this.resetStatusOfDays();
    }
}