import { Serializer, SubscriptionProductResponse } from "./interfaces";

import { SubscriptionProductPrice } from "./subscription-product-price";
import { SubscriptionProductMetadata } from "./subscription-product-metadata";

/**
 * Representa la estructura de un producto de stripe
 */
export class SubscriptionProduct implements Serializer<SubscriptionProduct> {

    /**
     * Obtiene el precio por defecto del producto. Se considera el primer precio
     */
    get defaultPrice(): SubscriptionProductPrice {
        if (!this.prices.length) return null;
        return this.prices[0];
    }

    constructor(
        public id?: string,
        public object?: string,
        public name?: string,
        public description?: string,
        public attributes?: Array<string>,
        public images?: Array<string>,
        public liveMode?: boolean,
        public metadata?: SubscriptionProductMetadata,
        public packageDimensions?: any,
        public shippable?: any,
        public statementDescriptor?: any,
        public taxCode?: any,
        public type?: string,
        public unitLabel?: string,
        public url?: string,
        public created?: Date,
        public updated?: Date,
        public isActive?: boolean,
        /**
         * Precios del producto
         * ```
         * Campo auxiliar para frontend. Se pobla posteriormente por medio de otro endpoint
         * ```
         */
        public prices: Array<SubscriptionProductPrice> = []
    ) { }

    fromResponse(response: SubscriptionProductResponse): SubscriptionProduct {
        return new SubscriptionProduct(
            response.id,
            response.object,
            response.name,
            response.description,
            response.attributes,
            response.images,
            response.livemode,
            new SubscriptionProductMetadata().fromResponse(response.metadata),
            response.package_dimensions,
            response.shipapable,
            response.statement_descriptor,
            response.tax_code,
            response.type,
            response.unit_label,
            response.url,
            /**
             * TODO: Verificar si el valor numerico que retorna stripe es equivalente a los milisegundos de fecha para convertir mediante momentjs
             */
            null,
            /**
             * TODO: Verificar si el valor numerico que retorna stripe es equivalente a los milisegundos de fecha para convertir mediante momentjs
             */
            null,
            response.active
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}