import { WidgetType } from './enumerations';
import { Serializer, WidgetResponse } from './interfaces';

import { WidgetMetadata } from './widget-metadata';

export class Widget implements Serializer<Widget> {

    constructor(
        public id?: number,
        public name?: string,
        public image?: string,
        public resizable?: boolean,
        public minWidth?: number,
        public maxWidth?: number,
        public minHeight?: number,
        public maxHeight?: number,
        /**
         * Widget de usuario al que pertenece el widget
         */
        public userWidgetId?: number,
        public metadata?: WidgetMetadata,
        /**
         * Campo auxiliar que define un id para un item de gridstack. No se obtiene de backend sino que se procesa desde front
         */
        public gridStackItemId?: number,
        /**
         * Tipo de widget. De momento no se tiene un identificador en backend por lo que se mapea manualmente según el id.
         */
        public type?: WidgetType
    ) { }

    fromResponse(response: WidgetResponse): Widget {

        return new Widget(
            response.id,
            response.name,
            response.image,
            response.resizable,
            response.min_width,
            response.max_width,
            response.min_height,
            response.max_height,
            null,
            null,
            null,
            this.getType(response.id)
        );
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }

    clone(): Widget {
        return new Widget(
            this.id,
            this.name,
            this.image,
            this.resizable,
            this.minWidth,
            this.maxWidth,
            this.minHeight,
            this.maxHeight,
            this.userWidgetId,
            this.metadata?.clone(),
            this.gridStackItemId,
            this.type
        )
    }

    private getType(widgetId: number): WidgetType {
        switch (widgetId) {
            case 1:
                return WidgetType.teamList;
            case 2:
                return WidgetType.athleteList;
            case 3:
                return WidgetType.athletesCalendar;
            case 4:
                return WidgetType.teamsCalendar;
            case 5:
                return WidgetType.mainWidget;
            case 6:
                return WidgetType.calendarWidget
            default:
                return WidgetType.mainWidget;
        }
    }
}