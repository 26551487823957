import moment from 'moment';

import { SubscriptionType, UserType } from './enumerations';
import { InstitutionRequest, InstitutionResponse, Serializer } from './interfaces';

import { Subscription } from './subscription';
import { Team } from './team';
import { InstitutionManager } from './institution-manager';

import { UtilitiesService } from '../services';
import { UserInstitution } from './user-institution';

export class Institution implements Serializer<Institution> {

    constructor(
        public id?: number,
        public name?: string,
        public identifierName?: string,
        public image?: string,
        public isActive?: boolean,
        public hasTrial?: boolean,
        public revenue?: number,
        public subscription?: Subscription,
        public team = new Team(),
        public managers: Array<InstitutionManager> = [],
        public createdAt?: Date,
        public updatedAt?: Date,
        /**
         * Obtiene o establece el archivo correspondiente a la imagen
         * ```
         * Campo auxiliar para frontend. No se almacena en backend
         * ```
         */
        public imageFile?: File
    ) { }

    fromResponse(response: InstitutionResponse): Institution {
        return new Institution(
            response.id,
            response.name,
            response.identifier_name,
            response.image,
            response.active,
            response.has_trial,
            response.revenue,
            response.subscription ? new Subscription().fromResponse(response.subscription) : null,
            null,
            [],
            response.created_at ? moment(response.created_at, false).toDate() : null,
            response.updated_at ? moment(response.updated_at, false).toDate() : null,
            null
        );
    }

    toRequest(): InstitutionRequest {
        return <InstitutionRequest>{
            name: this.name || undefined,
            active: this.isActive || undefined,
            identifier_name: this.identifierName || undefined,
            type: this.subscription?.type || undefined,
            ends: this.subscription?.expirationDate ? moment(this.subscription.expirationDate).format('YYYY-MM-DD') : undefined,
            total_athletes: this.subscription?.totalAthletes || undefined,
            total_coaches: this.subscription?.totalCoaches || undefined,
            total_team: this.subscription?.totalTeams || undefined,
            total: this.subscription?.total || undefined,
            tax: this.subscription?.tax || undefined,
            price: this.subscription?.price || undefined,
            fee_stripe: this.subscription?.stripeFee || undefined,
            has_renewable: this.subscription?.hasRenewable || undefined
        };
    }

    /**
     * Convierte el modelo actual a formato FormData para permitir envío de datos blob.
     * TODO: De momento sólo considera ciertos campos. Revisar si se requiere alguno otro y agregarlo como append al FormData respuesta
     * @returns 
     */
    toFormDataRequest(): FormData {

        const dataRequest = this.toRequest();

        const data = new FormData();
        if (dataRequest.name) {
            data.append('name', dataRequest.name);
        }
        if (dataRequest.identifier_name) {
            data.append('identifier_name', dataRequest.identifier_name);
        }
        if (dataRequest.active) {
            data.append('active', dataRequest.active ? 'true' : 'false');
        }
        // La foto lo obtiene del modelo y no de la interfaz como los campos anteriores
        if (this.imageFile) {
            data.append('image', this.imageFile, this.imageFile.name);
        }

        return data;
    }

    /**
     * Obtiene una instancia de institución con valores por defecto
     * @param name Nombre de institución
     * @returns Objeto inicial de institución
     */
    getWithDefaults(
        name?: string
    ): Institution {
        let institution = new Institution();

        if (name) {
            institution.name = name;
            institution.identifierName = UtilitiesService.getDbName(name);
        }

        // Suscripción
        const subscription = new Subscription();
        subscription.type = SubscriptionType.trial;
        let currentDate = moment();
        subscription.expirationDate = currentDate.add(14, 'days').toDate();
        subscription.totalTeams = 1;
        subscription.totalAthletes = 10;
        subscription.totalCoaches = 10;
        subscription.hasRenewable = false;
        institution.subscription = subscription;

        institution.isActive = true;

        return institution;
    }

    toUserInstitution(
        userType: UserType,
        isInvitation = false
    ): UserInstitution {
        const userInstitution = { ...this } as UserInstitution;
        userInstitution.userType = userType;
        userInstitution.isInvitation = isInvitation;
        return userInstitution;
    }
}