import { Serializer, UserMetadataResponse } from "./interfaces";

export class UserMetadata implements Serializer<UserMetadata> {

    constructor(
        public institutionId?: number
    ) { }

    static fromResponse(response: UserMetadataResponse): UserMetadata {
        return new UserMetadata(
            response.institution_id
        );
    }

    fromResponse(response: UserMetadataResponse): UserMetadata {
        return UserMetadata.fromResponse(response);
    }

    toRequest(): UserMetadataResponse {
        return <UserMetadataResponse>{
            institution_id: this.institutionId
        };
    }
}