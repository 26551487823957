import moment from 'moment';

import { PhaseType } from './enumerations';
import { PhaseCreateRequest, PhaseResponse, Serializer, ProgramSortPhasesRequest } from './interfaces';

import { AuditUser } from './audit-user';
import { PhaseWeek } from './phase-week';

import { Program } from './program';
import { SPF_DATE_FORMAT } from '../constants';

const PHASE_DEFAULT_NAME = "Phase's name";

export class Phase implements Serializer<Phase>{

    get defaultName(): string {
        return this.name || PHASE_DEFAULT_NAME;
    }

    /**
     * Obtiene el tipo de fase, según el programa asignado a él
     */
    get type(): PhaseType {
        // Sin programa asignado. Debe ser una fase de librería
        if (!this.program?.id) {
            return PhaseType.library;
        }
        // Programa Playlist
        else if (this.program.isPlaylist) {
            return PhaseType.programTemplate;
        }
        // Programa Calendar
        else {
            return PhaseType.programCalendar
        }
    }

    constructor(
        public id?: number,
        public name?: string,
        public starts?: Date,
        public ends?: Date,
        public duration?: string,
        public daysPerWeek?: string,
        public programId?: number,
        public audit?: AuditUser,
        public order?: number,
        public hasLibrary?: boolean,
        /**
         * TODO: Los campos total, active, index se tomaron del modelo Phase de program.interface. Verificar si son necesarios o se eliminan
         */
        public total?: number,
        public active?: boolean,
        public index?: number,
        /**
         * Programa asignado a la fase.
         * NOTA: No se obtiene del endpoint donde se mapea el objeto actual. Es un campo auxiliar para frontend
         */
        public program?: Program,
        public weeks: Array<PhaseWeek> = []
    ) { }

    static fromResponse(response: PhaseResponse): Phase {
        return new Phase(
            response.id,
            response.name || PHASE_DEFAULT_NAME,
            response.starts ? moment(response.starts).toDate() : null,
            response.ends ? moment(response.ends).toDate() : null,
            response.duration || '',
            response.days_per_week || '',
            response.program || null,
            new AuditUser().fromResponse(response),
            response.order || null,
            response.has_library || false,
            0,
            false,
            0,
            null,
            response.weeks ? response.weeks.map(x => new PhaseWeek().fromResponse(x)) : []
        );
    }

    fromResponse(response: PhaseResponse): Phase {
        return Phase.fromResponse(response);
    }

    toRequest(): PhaseCreateRequest {
        return <PhaseCreateRequest>{
            name: this.name || undefined,
            order: this.order || undefined,
            program: this.programId || undefined,
            weeks: this.weeks.map(x => x.toCreatePhaseRequest())
        }
    }

    toProgramSortPhasesRequest(): ProgramSortPhasesRequest {
        return <ProgramSortPhasesRequest>{
            id: this.id,
            starts: moment(this.starts).format(SPF_DATE_FORMAT)
        }
    }

    /**
     * Completa los 7 días de cada semana de la fase
     * @param phase Fase que se requiere completar sus semanas
     * @returns 
     */
    static completeWeeks(
        phase: Phase
    ): Phase {
        phase.weeks.forEach(week => {
            PhaseWeek.completeWeek(week);
        });
        return phase;
    }
}