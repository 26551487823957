import { Directive, ElementRef, forwardRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[spInputTime]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputTimeDirective),
    multi: true,
  }]
})
export class InputTimeDirective implements ControlValueAccessor {

  private maxLength = 5;

  @HostListener('keypress', ['$event'])
  onKeyPressHandler(event: KeyboardEvent): void {
    // Permite sólo dígitos del 0 al 9
    var regex = /[0-9]/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('focus', ['$event'])
  onFocusHandler(event: any) {
    if (!this.input.value) {
      this.input.value = '00:00';
    }
    this.setCursorToEnd();
  }

  @HostListener('click', ['$event'])
  onClickHandler(event: any) {
    this.setCursorToEnd();
  }

  /**
   * Se requiere para el caso que se borre (backspace) ya que keydown sólo se utiliza para captura
   * @param event 
   */
  @HostListener('input', ['$event'])
  onInputHandler(event: any) {

    const maxLength = this.maxLength - 1;

    let value = this.input.value;

    // Elimina el separador de tiempo para mantener sólo dígitos enteros
    value = value.replace(':', '');

    // Convierte a entero para eliminar los ceros de la izquierda
    value = (+value).toString();

    // Sólo mantiene 4 dígitos
    if (value.length > maxLength) {
      value = value.substr(0, maxLength);
    }

    // Complementa con ceros a la izquierda en caso de que se tenga menos de 4 dígitos enteros
    while (value.length < maxLength) {
      value = "0" + value;
    }

    let inputValue = value.replace(/(\d{2})(\d{2})$/, "$1:$2");

    // Valida que los minutos capturados no sean mayor a 60
    if (+inputValue.substr(0, 2) > 60) {
      inputValue = "60" + inputValue.substr(2);
    }

    // Aplica máscara 00:00
    this.input.value = inputValue;

    // Notifica a ngModel que se ha modificado el valor
    this.onChange(this.input.value);
  }

  get input(): HTMLInputElement {
    return this.el.nativeElement;
  }

  onChange = (_: any) => { }

  constructor(
    private el: ElementRef
  ) {
    //this.input.setAttribute('maxLength', '4');
  }

  writeValue(value: any): void { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }
  setDisabledState(isDisabled: boolean): void { }

  private setCursorToEnd(): void {
    this.input.selectionStart = this.input.selectionEnd = this.input.value.length;
  }
}
