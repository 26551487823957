import { Serializer } from './interfaces';

import { Audit } from './audit';
import { ExerciseCategory } from './exercise-category';
import { ExerciseSubcategory } from './exercise-subcategory';
import { ExerciseCategoryGroupResponse } from './interfaces/exercise-category-group-response';

export class ExerciseCategoryGroup implements Serializer<ExerciseCategoryGroup> {
    constructor(
        public id?: number,
        public name?: string,
        public isActive?: boolean,
        public audit?: Audit,
        /**
         * Categorías de ejercicios.
         * NOTA: No se obtienen del endpoint. Es un campo auxiliar para frontend
         */
        public categories: Array<ExerciseCategory> = []
    ) { }

    fromResponse(response: ExerciseCategoryGroupResponse): ExerciseCategoryGroup {
        return new ExerciseCategoryGroup(
            response.id,
            response.name,
            response.active,
            new Audit().fromResponse(response)
        );
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }

    /**
     * Obtiene todas las subcategorías NO seleccionadas
     */
    getUnselectedSubcategories(): Array<ExerciseSubcategory> {
        return this.categories
            .map(category => category.subcategories)
            .reduce((accumulator, subcategories) => accumulator.concat(subcategories.filter(subcategory => !subcategory.selected)), []);
    }

    /**
     * Obtiene todas las subcategorías seleccionadas
     */
    getSelectedSubcategories(): Array<ExerciseSubcategory> {
        return this.categories
            .map(category => category.subcategories)
            .reduce((accumulator, subcategories) => accumulator.concat(subcategories.filter(subcategory => subcategory.selected)), []);
    }

    /**
     * Verifica si se tienen subcategorías NO seleccionadas
     */
    checkUnselectedSubcategories(): boolean {
        return !!this.getUnselectedSubcategories().length;
    }

    /**
     * Verifica si se tienen subcategorías seleccionadas
     */
    checkSelectedSubcategories(): boolean {
        return !!this.getSelectedSubcategories().length;
    }

    selectUnselectAllSubcategories(select: boolean): void {
        this.categories.forEach(category => category.selectUnselectAllSubcategories(select));
    }

    filterSubcategories(search: string): void {
        this.categories.forEach(category => {
            category.subcategories.forEach(subcategory => {
                subcategory.hidden = subcategory.name.toLowerCase().indexOf(search.toLowerCase()) < 0;
            })
        });
    }
}