import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { NgxMaskModule } from 'ngx-mask';

import { SpInputModule } from './../../directives/sp-input/sp-input.module';

import { EditableInputComponent } from './editable-input/editable-input.component';
import { EditableSelectComponent } from './editable-select/editable-select.component';
import { EditableComponent } from './editable/editable.component';

const COMPONENTS = [
  EditableComponent,
  EditableInputComponent,
  EditableSelectComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    NgxMaskModule.forRoot({}),
    SpInputModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SpEditableModule { }
