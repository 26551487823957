import { GaleryResponse, Serializer } from "./interfaces";

export class Galery implements Serializer<Galery> {

    constructor(
        public id?: number,
        public name?: string
    ) { }

    fromResponse(response: GaleryResponse): Galery {
        return Galery.fromResponse(response);
    }

    static fromResponse(response: GaleryResponse): Galery {
        return new Galery(
            response.id,
            response.name
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}