import moment from "moment";

import { SPF_SHORTTIME_FORMAT, User } from "sp-core";

import { ChatMessageType } from "./types";

export class ChatMessage {

    private _timeString: string;
    get timeString() {
        return this._timeString
    }

    private _createdAt?: Date;
    public get createdAt(): Date {
        return this._createdAt;
    }
    public set createdAt(value: Date) {
        this._createdAt = value;
        this._timeString = moment(value).format(SPF_SHORTTIME_FORMAT);
    }

    constructor(
        public type?: ChatMessageType,
        /**
         * Text for message type text
        */
        public text?: string,
        /**
         * User who send the message
         */
        public user?: User,
        /**
         * User who receive the message
         */
        public recipient?: User
    ) { }

    static newTextMessage(
        text?: string,
        user?: User,
        recipient?: User
    ): ChatMessage {
        return new ChatMessage('text', text, user, recipient);
    }
}