import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollDirective } from './scroll.directive';
import { ScrollEndDirective } from './scroll-end.directive';

@NgModule({
  declarations: [
    ScrollDirective,
    ScrollEndDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ScrollDirective,
    ScrollEndDirective
  ]
})
export class SpScrollModule { }
