<span class="sp-notification-error">
    <span class="sp-notification-error__message">
        {{message}}
    </span>
    <button class="sp-notification-error__action"
        mat-button
        color="accent"
        (click)="onCloseClick()">
        Close
    </button>
</span>