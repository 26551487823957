import moment from 'moment';

import { PhaseWeekView } from './enumerations';
import { PhaseWeekCreatePhaseRequest, PhaseWeekCreateRequest, PhaseWeekResponse, Serializer } from './interfaces';

import { Audit } from './audit';
import { Phase } from './phase';
import { PhaseDay } from './phase-day';
import { WeekDays } from './week-days';
import addDays from 'date-fns/addDays';

export class PhaseWeek implements Serializer<PhaseWeek> {

    constructor(
        public id?: number,
        public name?: string,
        public starts?: Date,
        public ends?: Date,
        /**
         * Campo que hace referencia a la fecha del día que se intenta agregar y por lo que se requiere la creación de la semana
         */
        public date?: Date,
        public weekNumber?: number,
        public volumen?: number,
        public order?: number,
        public isActive?: boolean,
        public hasLibrary?: boolean,
        public phaseId?: number,
        public audit?: Audit,
        /**
         * Fase al que corresponde la semana.
         * NOTA: No se obtiene del endpoint donde se mapea el objeto actual. Es un campo auxiliar para frontend
         */
        public phase?: Phase,
        public view: PhaseWeekView = PhaseWeekView.workoutBuilder,
        public days: Array<PhaseDay> = [],
        /**
         * Obtiene o establece el índice real de la semana en la fase. 
         * Cuando no se visualizan todas las semanas que contiene la fase. 
         * Se utiliza para no tener que recalcular en la vista (indexOf), se calcula y asigna en la clase del componente
         * ```
         * Campo auxiliar para frontend. No se almacena en backend
         * ```
         */
        public index?: number
    ) { }

    fromResponse(response: PhaseWeekResponse): PhaseWeek {

        // Fecha de inicio de la semana
        const starts = response.starts
            ? moment(response.starts).toDate()
            : null;

        // Fecha de fin de la semana. Si se obtiene del EP se asigna, en caso contrario se obtiene en base a la fecha de inicio (starts) siempre y cuando se tenga dicha fecha
        const ends = response.ends
            ? moment(response.ends).toDate()
            : (starts ? addDays(starts, 6) : null);

        return new PhaseWeek(
            response.id,
            response.name || null,
            starts,
            ends,
            response.date ? moment(response.date).toDate() : null,
            response.number_week,
            response.volumen,
            response.order,
            response.active,
            response.has_library || false,
            response.phase,
            new Audit().fromResponse(response),
            null,
            null,
            response.days ? response.days.map(dayResponse => new PhaseDay().fromResponse(dayResponse)) : [],
            null
        );
    }

    fromWeekDays(week: WeekDays): PhaseWeek {
        const phaseWeek = new PhaseWeek();
        phaseWeek.id = week.isCloned ? week.originWeekId : week.id;
        return phaseWeek;
    }

    toRequest(): PhaseWeekResponse {
        return <PhaseWeekResponse>{
            name: this.name || undefined,
            date: this.date
                ? moment(this.date).format('YYYY-MM-DD')
                : undefined,
            starts: this.starts
                ? moment(this.starts).format('YYYY-MM-DD')
                : undefined,
            ends: this.ends
                ? moment(this.ends).format('YYYY-MM-DD')
                : undefined,
            number_week: this.weekNumber || undefined,
            volumen: this.volumen || undefined,
            order: this.order || undefined,
            has_library: (this.hasLibrary !== null && this.hasLibrary !== undefined)
                ? this.hasLibrary
                : undefined,
            phase: this.phaseId || undefined
        }
    }

    toCreatePhaseRequest(): PhaseWeekCreatePhaseRequest {
        return <PhaseWeekCreatePhaseRequest>{
            date: moment(this.date).format('YYYY-MM-DD'),
            days: this.days.map(x => x.day)
        };
    }

    forPlaylistProgramRequest(): PhaseWeekCreateRequest {
        return <PhaseWeekCreateRequest>{
            name: this.name || 'Week',
            phase: this.phaseId
        }
    }

    toCreateWithDateRequest(): PhaseWeekCreateRequest {
        return <PhaseWeekCreateRequest>{
            date: moment(this.date).format('YYYY-MM-DD'),
            phase: this.phaseId,
            builder: this.view === PhaseWeekView.workoutBuilder
        }
    }

    /**
     * Completa los 7 días de la semana
     * @param week Semana que se requiere completar sus días
     * @returns 
     */
    static completeWeek(
        week: PhaseWeek
    ): PhaseWeek {

        const days: Array<PhaseDay> = [];

        for (var i = 1; i < 8; i++) {
            let dayFound = week.days.find(e => e.day == i);
            if (!dayFound) {
                dayFound = new PhaseDay();
                dayFound.day = i;
            }
            days.push(dayFound);
        }

        week.days = days;
        return week;
    }
}