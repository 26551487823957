import { RegionResponse, Serializer } from "./interfaces";
import { SPFCurrency } from "./spf-currency";

export class Region implements Serializer<Region> {

    constructor(
        public id?: number,
        public name?: string,
        public currency?: SPFCurrency,
        public country?: string
    ) { }

    fromResponse(response: RegionResponse): Region {
        return new Region(
            response.id,
            response.name,
            SPFCurrency.getById(response.currency),
            response.country
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }
}