import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import moment from 'moment';

import { ApiService } from './api.service';

import { SPF_DATE_FORMAT } from '../constants';
import { AthleteStatusLogDataResponse, AthleteStatusLogResponse } from '../models/interfaces';
import { AthleteStatusOption } from '../models/enumerations';
import { AthleteStatus, AthleteStatusLog, AthleteStatusLogData, PaginatedParams } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AthleteStatusLogService {

  private statusLogCreatedSubject$ = new Subject<AthleteStatusLog>();
  statusLogCreated$ = this.statusLogCreatedSubject$.asObservable();

  /**
   * Emite cuando un registro de log/histórico es modificado
   */
  private statusLogUpdatedSubject$ = new Subject<AthleteStatusLog>();
  statusLogUpdated$ = this.statusLogUpdatedSubject$.asObservable();

  private statusLogDeletedSubject$ = new Subject<AthleteStatusLog>();
  statusLogDeleted$ = this.statusLogDeletedSubject$.asObservable();

  /**
   * Emite cuando el estatus de un atleta es modificado
   */
  private statusUpdatedSubject$ = new Subject<AthleteStatusLog>();
  statusUpdated$ = this.statusUpdatedSubject$.asObservable();

  constructor(
    private api: ApiService
  ) { }

  /**
   * Obtiene la lista paginada de logs correspondientes a estatus de atleta
   * ```
   * EP: GET player-status-history/
   * ```
   * @param athleteId 
   * @param sortActive 
   * @param sortDirection 
   * @param paginatedParams
   * @param latest  Indica si se retorna el último registro
   * @param exclude Identificador de registro de log a excluir en la búsqueda
   * @returns 
   */
  getPaginated(
    athleteId?: number,
    startDate?: Date,
    endDate?: Date,
    sortActive?: string,
    sortDirection?: 'asc' | 'desc',
    paginatedParams?: PaginatedParams,
    latest = false,
    exclude = 0
  ): Observable<AthleteStatusLogData> {

    let params = new HttpParams();

    if (athleteId) {
      params = params.set('athlete', athleteId.toString());
    }

    if (startDate) {
      params = params.set('date_start', moment(startDate).format(SPF_DATE_FORMAT));
    }

    if (endDate) {
      params = params.set('date_end', moment(endDate).format(SPF_DATE_FORMAT));
    }

    if (sortActive && sortDirection) {
      params = params.set('ordering', `${(sortDirection == 'asc' ? '' : '-')}${sortActive}`);
    } else {
      params = params.set('ordering', '-date_start');
    }

    if (paginatedParams) {
      params = paginatedParams.toRequest(params);
    }

    if (latest) {
      params = params.set('latest', 1);
    }

    if (exclude) {
      params = params.set('exclude', exclude.toString());
    }

    return this.api.get<AthleteStatusLogDataResponse>(
      `player-status-history/`,
      params
    ).pipe(
      map(response => new AthleteStatusLogData().fromResponse(response))
    );
  }

  get(
    athleteId?: number,
    startDate?: Date,
    endDate?: Date,
    sortActive?: string,
    sortDirection?: 'asc' | 'desc',
    paginatedParams?: PaginatedParams,
    latest = false,
    exclude = 0
  ): Observable<Array<AthleteStatusLog>> {
    return this.getPaginated(
      athleteId,
      startDate,
      endDate,
      sortActive,
      sortDirection,
      paginatedParams,
      latest,
      exclude
    ).pipe(
      map(response => response.data)
    )
  }

  getByAthleteAndPeriod(
    athleteId: number,
    startDate: Date,
    endDate: Date
  ): Observable<Array<AthleteStatusLog>> {
    return this.get(
      athleteId,
      startDate,
      endDate
    );
  }

  /**
   * Obtiene el último registro o estatus actual del atleta
   * @param athleteId Identificador de atleta
   * @returns 
   */
  getLatestByAthlete(athleteId: number): Observable<AthleteStatusLog> {
    return this.getPaginated(
      athleteId,
      null,
      null,
      null,
      null,
      null,
      true
    ).pipe(
      map(response => {

        // Si se encontró log se retorna éste
        if (response?.data && response?.data?.length) {
          return response.data[0];
        }

        // Sino se encontró log se devuelve un registro con estatus por defecto available
        const availableStatus = new AthleteStatusLog();
        availableStatus.athleteId = athleteId;
        availableStatus.status = AthleteStatus.availableStatus;

        return availableStatus;
      })
    );
  }

  /**
   * Obtiene los registros de estatus que se solaparían en base a la fecha inicio y fin indicadas
   * @param athleteId 
   * @param startDate 
   * @param endDate 
   * @param logToExclude 
   * @returns 
   */
  getOverloapStatus(
    athleteId: number,
    startDate: Date,
    endDate?: Date,
    logToExclude?: number
  ): Observable<Array<AthleteStatusLog>> {
    return this.getPaginated(
      athleteId,
      startDate,
      // En caso de que no se indique fecha de fin se indica la misma fecha de inicio para correcta búsqueda de rangos
      endDate || startDate,
      null, null, null, null,
      logToExclude
    ).pipe(
      map(response => response.data)
    )
  }

  /**
   * 
   * ```
   * EndPoint: player-status-history/
   * ```
   * @returns 
   */
  create(
    statusLog: AthleteStatusLog,
  ): Observable<AthleteStatusLog> {

    return this.api.post(
      `player-status-history/`,
      statusLog.toRequest()
    ).pipe(
      catchError(this.api.processError('AthleteStatusLogService.create')),
      map(response => {
        const statusLogCreated = new AthleteStatusLog().fromResponse(response);
        this.statusLogCreatedSubject$.next(statusLogCreated)
        return statusLogCreated;
      })
    );
  }

  /**
   * 
   * ```
   * EndPoint: player-status-history/{statusLogId}/
   * ```
   * @returns 
   */
  update(
    statusLog: AthleteStatusLog,
  ): Observable<AthleteStatusLog> {

    return this.api.patch(
      `player-status-history/${statusLog.id}/`,
      statusLog.toRequest()
    ).pipe(
      catchError(this.api.processError('AthleteStatusLogService.update')),
      map(response => {
        const statusLogUpdated = new AthleteStatusLog().fromResponse(response);
        this.statusLogUpdatedSubject$.next(statusLogUpdated);
        return statusLogUpdated;
      })
    );
  }

  /**
   * 
   * ```
   * EndPoint: player-status-history/{statusLogId}/
   * ```
   * @returns 
   */
  delete(
    statusLog: AthleteStatusLog,
  ): Observable<boolean> {

    return this.api.delete(
      `player-status-history/${statusLog.id}/`,
    ).pipe(
      catchError(this.api.processError('AthleteStatusLogService.delete')),
      map(() => true)
    );
  }

  /**
   * ```
   * EndPoint: player-status/
   * Method: PATCH
   * ```
   * @param athleteId 
   * @returns 
   */
  updateAthleteStatus(
    athleteId: number,
    status: AthleteStatusOption
  ): Observable<AthleteStatusLog> {

    const payload = {
      athletes: [athleteId],
      status
    };

    return this.api.patch<Array<AthleteStatusLogResponse>>(
      `player-status/`,
      payload
    ).pipe(
      catchError(this.api.processError('AthleteStatusLogService.updateAthleteStatus')),
      map(response => {
        if (!response || !response.length) return null;
        const statusUpdated = new AthleteStatusLog().fromResponse(response[0]);
        this.statusUpdatedSubject$.next(statusUpdated);
        return statusUpdated;
      })
    );
  }
}