import { Serializer, WorksetValueResponse, WorksetValueRequest } from './interfaces';

export class WorksetValue implements Serializer<WorksetValue> {

    /**
     * Obtiene un valor por defecto para el parámetro Reps
     * @returns 
     */
    static get valueForParameterReps(): WorksetValue {
        return new WorksetValue(
            null,
            '0',
            null,
            '',
            null,
            '',
            '',
            false,
            1,
            1
        )
    }

    /**
     * Datos actuales para validación de cambio y no enviarlo a guardar en BD innecesariamente
     */
    private initialId: number;
    private initialValue1: string;
    private initialValue2: string;
    private initialValue3: string;
    private initialValue4: string;
    private initialIsRange: boolean;
    private initialRowNumber: number;
    private initialSubparameterId: number;
    private initialParameterLinkId: number;

    /**
     * Obtiene si el dato de serie tiene valor. Verifica los 4 posibles datos; si alguno tiene, entonces el dato tiene valor
     */
    get hasValue(): boolean {
        return (this.value1 || this.value2 || this.value3 || this.value4) ? true : false;
    }

    /**
     * Obtiene si el dato de serie está vacío. Verifica los 4 posibles datos; si ninguno tiene, entonces el dato está vacío
     */
    get isEmpty(): boolean {
        return !this.hasValue;
    }

    /**
     * Verifica si el valor de serie tiene cambios respecto a sus valores iniciales para evitar enviar a guardar innecesariamente.
     */
    get hasChanges(): boolean {

        // Verifica si la información actual del valor es diferente a los iniciales.
        if (this.initialId !== this.id ||
            this.initialValue1 !== this.value1 ||
            this.initialValue2 !== this.value2 ||
            this.initialValue3 !== this.value3 ||
            this.initialValue4 !== this.value4 ||
            this.initialIsRange !== this.isRange ||
            this.initialRowNumber !== this.rowNumber ||
            this.initialParameterLinkId !== this.parameterLinkId ||
            this.initialSubparameterId !== this.subparameterId
        ) {
            return true;
        }

        // Indica que no tiene cambios para guardar en bd.
        return false;
    }

    constructor(
        public id?: number,
        public value1?: string,
        /**
         * Auxiliar field when the format of value1 is different from value shown
         */
        public value1ToSave?: string,
        /**
         * Obtiene o establece el valor del rango máximo cuando el valor se indicó como rango
         */
        public value2?: string,
        /**
         * Auxiliar field when the format of value2 is different from value shown
         */
        public value2ToSave?: string,
        /**
         * Obtiene o establece el valor mínimo del rango cuando el valor se indicó como rango y es del tipo character of effort
         */
        public value3?: string,
        /**
         * Obtiene o establece el valor máximo del rango cuando el valor se indicó como rango y es del tipo character of effort
         */
        public value4?: string,
        public isRange?: boolean,
        public rowNumber?: number,
        public subparameterId?: number,
        /**
         * Identificador de asignación de parámetro a bloque<->ejercicio
         */
        public parameterLinkId?: number
    ) {
        this.assignInitialValues(this);
    }

    fromResponse(response: WorksetValueResponse): WorksetValue {

        const value = new WorksetValue(
            response.id,
            response.value1,
            null,
            response.value2,
            null,
            response.value3,
            response.value4,
            response.has_range,
            response.row,
            response.catalog_subparameter,
            response.catalog
        )

        // Se inicializan los datos para validación
        this.assignInitialValues(value);

        return value;
    }

    toRequest(): WorksetValueRequest {
        return <WorksetValueRequest>{
            value1: this.value1ToSave || this.value1,
            value2: this.value2ToSave || this.value2,
            value3: this.value3,
            value4: this.value4,
            has_range: this.isRange,
            row: this.rowNumber ? this.rowNumber : undefined,
            catalog_subparameter: this.subparameterId ? this.subparameterId : undefined,
            catalog: this.parameterLinkId ? this.parameterLinkId : undefined
        };
    }

    clone(): WorksetValue {
        return new WorksetValue(
            this.id,
            this.value1,
            null,
            this.value2,
            null,
            this.value3,
            this.value4,
            this.isRange,
            this.rowNumber,
            this.subparameterId,
            this.parameterLinkId
        );
    }

    /**
     * Aplica cambios guardados. Para una siguiente validación de cambios
     */
    applyChanges(): void {
        this.assignInitialValues(this);
    }

    private assignInitialValues(value: WorksetValue) {
        // Se inicializan los datos para validación
        this.initialId = value.id;
        this.initialValue1 = value.value1;
        this.initialValue2 = value.value2;
        this.initialValue3 = value.value3;
        this.initialValue4 = value.value4;
        this.initialIsRange = value.isRange;
        this.initialRowNumber = value.rowNumber;
        this.initialParameterLinkId = value.parameterLinkId;
        this.initialSubparameterId = value.subparameterId;
    }
}