import { WorkoutStatus } from "./enumerations";
import { Serializer, WorkoutJsonDataResponse } from "./interfaces";

export class WorkoutJsonData implements Serializer<WorkoutJsonData> {

    constructor(
        public rpe?: number,
        public status?: WorkoutStatus
    ) { }

    fromResponse(response: WorkoutJsonDataResponse): WorkoutJsonData {
        return new WorkoutJsonData(
            response.RPE || 0,
            response.status || WorkoutStatus.scheduled
        );
    }

    toRequest(): WorkoutJsonDataResponse {
        return <WorkoutJsonDataResponse>{
            RPE: this.rpe,
            status: this.status
        }
    }
}