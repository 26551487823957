import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { SpCoreConfig, SpCoreModule } from 'sp-core';
import { SpLibraryModule, SpProgressSpinnerModule, SpScrollModule } from 'sp-library';

import { environment } from '@admin/env/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { defaultInterpolationFormat, I18NEXT_SERVICE, I18NextModule, ITranslationService } from 'angular-i18next';
import HttpApi from 'i18next-http-backend';
import languageDetector from 'i18next-browser-languagedetector';

const i18nextOptions = {
  whitelist: ['en'],
  fallbackLng: 'en',
  debug: true,
  returnEmptyString: false,
  ns: [
    'translation'
  ],
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
  },
  backend: {
    loadPath: 'assets/locales/{{lng}}/{{lng}}.{{ns}}.json',
  },
  detection: {
    order: ['localStorage'],
    lookupLocalStorage: 'lang',
    caches: ['localStorage'],
    cookieDomain: 'document.location.hostname'
  }
};

const I18N_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    useFactory: localeIdFactory,
    deps: [I18NEXT_SERVICE]
  }
];

// tslint:disable-next-line:typedef
function appInit(i18next: ITranslationService) {
  return () => {
    return i18next
      .use(HttpApi)
      .use(languageDetector)
      .init(i18nextOptions);
  };
}

export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

const spCoreConfig = <SpCoreConfig>{
  apiBaseUrl: environment.apiBaseUrl,
  baseUrl: environment.baseUrl,
  tokenUrl: environment.tokenUrl,
  refreshTokenUrl: environment.refreshTokenUrl,
  userInfoUrl: environment.userInfoUrl,
  forgotPasswordUrl: environment.forgotPasswordUrl,
  loginUrl: environment.loginUrl,
  youtubeApiKey: environment.youtubeApiV3.apiKey
}

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatProgressBarModule,
    SpCoreModule.forRoot(spCoreConfig),
    SpLibraryModule,
    AppRoutingModule,
    SpProgressSpinnerModule,
    CoreModule,
    SharedModule,
    SpScrollModule,
    I18NextModule.forRoot()
  ],
  providers: [I18N_PROVIDER],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
