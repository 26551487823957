import { Serializer, SocialLoginResponse } from "./interfaces";
import { UserAccess } from "./user-access";

export class SocialLogin implements Serializer<SocialLogin> {
    constructor(
        public data?: UserAccess,
        public success?: boolean
    ) { }
    fromResponse(response: SocialLoginResponse): SocialLogin {
        return new SocialLogin(
            response.data ? new UserAccess().fromResponse(response.data) : null,
            response.success
        );
    }
    toRequest() {
        throw new Error("Method not implemented.");
    }
}