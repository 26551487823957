import { HttpParams } from '@angular/common/http';
import moment from 'moment';

export class ProgramRequestParams {

    constructor(
        public active = true,
        public hasLibrary = false,
        public teamId?: number,
        public athleteIds: Array<number> = [],
        public start?: Date,
        public end?: Date
    ) { }

    toRequest(params?: HttpParams): HttpParams {

        let requestParams = params || new HttpParams();

        requestParams
            .set('active', this.active ? '1' : '0')
            .set('has_library', this.hasLibrary ? '1' : '0');

        if (this.teamId) {
            requestParams = requestParams.set('team', this.teamId.toString());
        }

        if (this.athleteIds.length) {
            requestParams = requestParams.set('athletes', this.athleteIds.join(','));
        }

        if (this.start) {
            requestParams = requestParams.set('start', moment(this.start).format('YYYY-MM-DD'));
        }

        if (this.end) {
            requestParams = requestParams.set('end', moment(this.end).format('YYYY-MM-DD'));
        }

        return requestParams;
    }
}