import moment from "moment";

import { SPF_DATE_FORMAT } from "../constants";
import { ProgramAssignmentRequest, Serializer } from "./interfaces";
import { Team } from "./team";
import { Athlete } from "./athlete";

export class ProgramAssignment implements Serializer<ProgramAssignment> {

    constructor(
        public date?: Date,
        public isPlaylist = false,
        public teams: Array<Team> = [],
        public athletes: Array<Athlete> = []
    ) { }

    fromResponse(response: any): ProgramAssignment {
        throw new Error("Method not implemented.");
    }

    toRequest(): ProgramAssignmentRequest {
        return <ProgramAssignmentRequest>{
            date: this.date ? moment(this.date).format(SPF_DATE_FORMAT) : null,
            playlist: this.isPlaylist,
            teams_id: this.teams.map(x => x.id),
            athletes_id: this.athletes.map(x => x.id)
        }
    }
}